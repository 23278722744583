import icFantom from "img/ic_ftm_24.svg";
import LayerZeroIcon from "img/layer0.svg";
import styled from "styled-components";
import "./style.scss";

import { useWeb3React } from "@web3-react/core";
import GMYMigration from "abis/GMYMigration.json";
import ReaderV2 from "abis/ReaderV2.json";
import Token from "abis/Token.json";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Footer from "components/Footer/Footer";
import { ARBITRUM, FANTOM, OP, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useMigrateApi } from "domain/legacy";
import { approveTokens } from "domain/tokens";
import { ethers } from "ethers";
import bg from "img/earn/bg.svg";
import icOp from "img/ic_op_24.svg";
import icArb from "img/ic_arbitrum_24.svg";
import gmyIcon from "img/ic_gmy_40.svg";
import icScanWhite from "img/ic_scan_white.svg";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { PLACEHOLDER_ACCOUNT, getBalanceAndSupplyData } from "lib/legacy";
import { expandDecimals, formatAmount } from "lib/numbers";
import { switchNetwork } from "lib/wallets";
import Pagination from "pages/Analytics/components/Pagination";
import { useEffect, useState } from "react";
import useSWR from "swr";
import moment, { now } from "moment";
const FANTOMBRIDGE = 112;
const ARBITRUMBRIDGE = 110;
const chains = [
  { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom },
  // { chainId: OP, name: "Optimism", img: icOp },
  { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb },
];
const MigrateOP = ({ setPendingTxns, pendingTxns, connectWallet }) => {
  const { active, library, account, chainId: waletChainId } = useWeb3React();
  // const account = "0xaa305f47225f9c49a51786012448f9928e94b936";

  const { chainId } = useChainId();
  const [isApproving, setIsApproving] = useState(false);
  const [fromChain, setFromChain] = useState(chainId === ARBITRUM ? ARBITRUMBRIDGE : FANTOMBRIDGE);
  const [toChain, setToChain] = useState(chainId === ARBITRUM ? FANTOMBRIDGE : ARBITRUMBRIDGE);
  const [historyData, setHistoryData] = useState([]);
  const [amount, setAmount] = useState("");
  const migrateAddress = getContract(chainId, "GMYMigration");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const MAX_NUMBER_PAGE = 70;
  const histories = useMigrateApi(account, OP);
  useEffect(() => {
    if (histories) {
      setHistoryData([...histories?.data?.rows]);
    } else setHistoryData([]);
  }, [histories]);
  useEffect(() => {
    let length = historyData.length;
    setRows(length);
  }, [historyData]);
  useEffect(() => {
    if (chainId === FANTOM && fromChain !== FANTOMBRIDGE) {
      setFromChain(FANTOMBRIDGE);
      setToChain(ARBITRUMBRIDGE);
    } else if (chainId === ARBITRUM && fromChain !== ARBITRUMBRIDGE) {
      setFromChain(ARBITRUMBRIDGE);
      setToChain(FANTOMBRIDGE);
    }
  }, [chainId, fromChain]);
  const getAmountValue = () => {
    if (!amount) return expandDecimals(0, 18);
    else {
      if (!amount.includes(".")) return expandDecimals(amount, 18);
      else {
        let after = amount.split(".")[1];
        return expandDecimals(amount.replace(".", ""), 18 - after.length);
      }
    }
  };

  const readerAddress = getContract(chainId, "Reader");
  const gmyAddress = getContract(chainId, "GMY");
  const multiGMYOP = '0x47536f17f4ff30e64a96a7555826b8f9e66ec468'

  const lzGMYAddress = getContract(chainId, "Bridge");
  const walletTokens = [gmyAddress];
  const { data: walletBalances } = useSWR(
    [
      `Bridge:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );
  const { data: lzGMYBalances } = useSWR(
    [`Bridge:walletBalances:${active}`, chainId, lzGMYAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  
  const { data: multiGMYBalances } = useSWR(
    [`Bridge:multiGMYBalanceswalletBalances:${active}`, chainId, multiGMYOP, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { balanceData } = getBalanceAndSupplyData(walletBalances);
  const gmyBalance = multiGMYBalances;

  const onInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      let after = value.split(".")[1];
      if (after.length <= 18) setAmount(value);
    } else {
      setAmount(value);
    }
  };
  const { data: tokenAllowance } = useSWR(
    active && multiGMYOP && [active, chainId, multiGMYOP, "allowance", account, migrateAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const { AddressZero } = ethers.constants;

  let checkAmount = getAmountValue();
  const needApproval = multiGMYOP !== AddressZero && tokenAllowance && checkAmount && checkAmount.gt(tokenAllowance);
  const ApproveTokens = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: multiGMYOP,
      spender: migrateAddress,
      chainId,
    });
  };
  const migrateToken = () => {
    const contract = new ethers.Contract(migrateAddress, GMYMigration.abi, library.getSigner());
    callContract(chainId, contract, "migrate", [getAmountValue()], {
      successMsg: `Migrate completed`,
      sentMsg: `Migrate submitted!`,
      failMsg: `Migrate failed.`,
      setPendingTxns,
      pendingTxns,
    });
  };

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const getBridgeContract = (selectedChainId) => {
    return getContract(selectedChainId === 112 ? FANTOM : ARBITRUM, "Bridge");
  };
  const isBridgeDisabled = () => {
    if (!amount || !account) return true;
    const valueAmount = getAmountValue();
    if (valueAmount.gt(gmyBalance || 0)) return true;
    return false;
  };
  const handleMaxBalance = () => {
    if (gmyBalance && gmyBalance.gt(0)) setAmount(formatAmount(gmyBalance || 0, 18, 18, false));
    else setAmount("0.0000");
  };

  const showData =
    historyData.length > 0
      ? historyData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, historyData.length))
      : [];

  return (
    <Wrapper>
      <div className="bridge-wrapper">
        <div className="page-title">Migrate {<img src={icOp} alt="op24Icon" />}</div>
        <div className="page-desc">
          Convert{" "}
          <ExternalLink href="https://optimistic.etherscan.io/token/0x47536f17f4ff30e64a96a7555826b8f9e66ec468">
            <span>GMY</span>
          </ExternalLink>{" "}
          on Optimism to{" "}
          <ExternalLink href="https://optimistic.etherscan.io/token/0x385719545Ef34d457A88e723504544A53F0Ad9BC">
            <span>GMY (L0)</span>
          </ExternalLink>
        </div>
        <div className="power-by">{/* Powered by <img alt="" src={LayerZeroIcon} /> */}</div>
        <div className="form-bridge">
          <div className="chains">
            <div className="chain-select-dropdown">
              <div className="dropdown-name">
                <div>From </div>{" "}
                <div onClick={handleMaxBalance} style={{ cursor: "pointer" }}>
                  Avail: {formatAmount(gmyBalance, 18, 2, true)}
                </div>{" "}
              </div>
              <div className="input-box">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={amount}
                  onChange={onInputChange}
                />
                <div className="input-token">
                  {" "}
                  <img src={gmyIcon} className="input-token-ic" /> GMY
                </div>
              </div>
            </div>
            <div style={{ cursor: "default" }} className={`swap-ball`}>
              <svg
                className="swap-ball-icon"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3646 13.0005L14.9844 9.49853L11.3646 6.0001L11.3646 8.62568L5.00367 8.62568L5.00367 10.3749L11.3646 10.3749L11.3646 13.0005Z"
                  fill="#080715"
                />
              </svg>
            </div>
            <div className="chain-select-dropdown">
              <div className="dropdown-name">
                <div>To </div> <div>Avail: {formatAmount(lzGMYBalances, 18, 2, true)} </div>{" "}
              </div>
              <div className="input-box">
                <input
                  disabled
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={amount}
                />
                <div style={{ whiteSpace: "nowrap" }} className="input-token">
                  {" "}
                  <img src={gmyIcon} className="input-token-ic" /> GMY (L0)
                </div>
              </div>
            </div>
          </div>

          <div className="recieve-info">
            <div>
              Migration rate: <span className="white-text">1 GMY = 1 GMY (L0)</span>
            </div>
            <div>
              You will receive: <span className="white-text"> {amount ? amount : "0.0"} GMY (L0)</span>
            </div>
          </div>
          <div className="actions">
            {!active ? (
              <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                Connect Wallet
              </button>
            ) : chainId !== OP ? (
              <button className="App-button-option App-card-option" onClick={() => switchNetwork(OP, active)}>
                Switch to Optimism
              </button>
            ) : needApproval ? (
              <button className="App-button-option App-card-option" onClick={() => ApproveTokens()}>
                Approve
              </button>
            ) : (
              <button
                className="App-button-option App-card-option"
                disabled={isBridgeDisabled()}
                onClick={() => migrateToken()}
              >
                Migrate
              </button>
            )}
          </div>
        </div>
        <div className="down-content">
          <div className="page-title">Transactions </div>
          <div className="transaction-table">
            <div className="transaction-line transaction-header">
              <div className="table-column">Time</div>
              <div className="table-column">Amount</div>
              <div className="table-column">Status</div>

              <div className="table-column column-view">View</div>
            </div>
            <div className="transaction-content">
              {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
              {historyData &&
                showData.map((item) => {
                  const time = moment.unix(item.timestamp).format("MMM DD, YYYY hh:mm:ss");

                  return (
                    <div className="transaction-line transaction-row" key={item.tx}>
                      <div className="table-column">{time}</div>
                      <div className="table-column">{item?.amount ? Number(item.amount)?.toFixed(4) : "--"} GMY</div>

                      <div className={`table-column delivered `}>Success</div>
                      <div className="table-column column-view">
                        <ExternalLink href={`https://optimistic.etherscan.io/tx/${item.tx}`}>
                          <img alt="" src={icScanWhite} className="icon-view" />
                        </ExternalLink>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="transaction-table-mobile">
            {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
            {historyData &&
              showData.map((item) => (
                <div className="table-mobile-content" key={item.tx}>
                  <div className="content-line">
                    <div className="title">Time</div>
                    <div className="value">{moment.unix(item.timestamp).format("MMM DD, YYYY hh:mm:ss")}</div>
                  </div>

                  <div className="content-line">
                    <div className="title">Amount</div>
                    <div className="value">{item?.amount ? Number(item.amount)?.toFixed(4) : "--"} GMY</div>
                  </div>
                  <div className="content-line">
                    <div className="title">Status</div>
                    <div className={`value delivered`}>Success</div>
                  </div>
                  <div className="content-line">
                    <div className="title">View</div>
                    <div className="value">
                      <ExternalLink href={`https://optimistic.etherscan.io/tx/${item.tx}`}>
                        <img alt="" src={icScanWhite} className="icon-view" />
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {historyData && historyData.length > 0 && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                maxNumberOfPage={MAX_NUMBER_PAGE}
                row={row}
              />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
};
export default MigrateOP;
const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
`;
