import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import cx from "classnames";
import totalVolumnIc from "img/analytics/totalVolumn.svg";
import totalFeeIc from "img/analytics/totalFee.svg";
import totalUserIc from "img/analytics/totalUser.svg";
import openInterestIc from "img/analytics/openInterest.svg";
import glpPoolIc from "img/analytics/glpPool.svg";

import totalVolumnLightIc from "img/analytics/totalVolumnLight.svg";
import totalFeeLightIc from "img/analytics/totalFeeLight.svg";
import totalUserLightIc from "img/analytics/totalUserLight.svg";
import openInterestLightIc from "img/analytics/openInterestLight.svg";
import glpPoolLightIc from "img/analytics/glpPoolLight.svg";

import {
  useAumPerformanceData,
  useFee24HData,
  useFeesData,
  useFundingRateData,
  useGlpData,
  useGlpPerformanceData,
  useLastBlock,
  useLastSubgraphBlock,
  useSwapSources,
  useTradersData,
  useUsersData,
  useVolume24HData,
  useVolumeData,
} from "dataProvider";
import DateRangeSelect from "./DateRangeSelect";
import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  tsToIsoDate,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  LIGHT_COLORS,
  GREEN,
  RED,
  convertToPercents,
  formatNumberStats,
  yaxisFormatterNumber1,
} from "lib/helper";
import { formatNumber } from "lib/numbers";
import { RiLoader5Fill } from "react-icons/ri";
import VolumeChart from "./VolumeChart";
import FeesChart from "./FeesChart";
import ChartWrapper from "./ChartWrapper";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PoolAmountChart from "./PoolAmountChart";
import GenericChart from "./GenericChart";
import TradersProfitLossChart from "./TradersProfitLossChart";
import { useChainId } from "lib/chains";
import { ARBITRUM, BASE, FANTOM, OP, getExplorerUrl } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";

const dateRangeOptions = [
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "Last 2 Months",
    id: 2,
    isDefault: true,
  },
  // {
  //   label: "Last 3 Months",
  //   id: 3,
  // },
  {
    label: "All time",
    id: 4,
  },
];
export default function Overview() {
  const { chainId } = useChainId();
  const DEFAULT_GROUP_PERIOD = 86400;
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD);

  const { isLightTheme, lightThemeClassName } = useThemeContext();

  const [dataRange, setDataRange] = useState({
    fromValue: moment()
      .subtract(chainId === 2, "month")
      .toDate(),
    toValue: null,
  });
  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };
  const getCurrentChain = () => {
    return chainId === FANTOM ? "fantom" : chainId === OP ? "optimism" : chainId === BASE ? "base" : "arbitrum";
  };
  const NOW = Math.floor(Date.now() / 1000);
  const from = dataRange.fromValue ? Math.floor(+new Date(dataRange.fromValue) / 1000) : undefined;
  const to = dataRange.toValue ? Math.floor(+new Date(dataRange.toValue) / 1000) : NOW;
  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock(getCurrentChain());
  const [lastBlock] = useLastBlock();
  const isObsolete = lastSubgraphBlock && lastBlock && lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600;
  const [totalVolumeData, totalVolumeLoading] = useVolumeData({
    chainName: getCurrentChain(),
  });
  const params =
    chainId === FANTOM ? { from, to, groupPeriod } : { from, to, groupPeriod, chainName: getCurrentChain() };

  const [volume24HData, volume24HLoading] = useVolume24HData(params);
  const [totalVolume, totalVolumeDelta] = useMemo(() => {
    if (!totalVolumeData) {
      return [];
    }
    const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative;

    const delta = chainId === FANTOM ? volume24HData : total - totalVolumeData[totalVolumeData.length - 2]?.cumulative;
    return [total, delta];
  }, [totalVolumeData, volume24HData]);

  const [feesData, feesLoading] = useFeesData(params);
  // const [totalFeesData, totalFeesLoading] = useFeesData({ chainName: getCurrentChain() });
  const totalFeesData = feesData;
  const [fee24HData, fee24HLoading] = useFee24HData({});
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return [];
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative;
    const delta = chainId === FANTOM ? fee24HData : total - totalFeesData[totalFeesData.length - 2]?.cumulative;
    return [total, delta];
  }, [totalFeesData, fee24HData]);

  const [glpData, glpLoading] = useGlpData(params);
  const [totalGlpData, totalGlpLoading] = useGlpData({ chainName: getCurrentChain() });
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!glpData || !totalGlpData) {
      return [];
    }
    const total = chainId === FANTOM ? glpData[glpData.length - 1]?.aum : totalGlpData[totalGlpData.length - 1]?.aum;
    const delta =
      chainId === FANTOM
        ? total - glpData[glpData.length - 2]?.aum
        : total - totalGlpData[totalGlpData.length - 2]?.aum;
    return [total, delta];
  }, [glpData, totalGlpData]);

  const [usersData, usersLoading] = useUsersData(params);
  usersData?.map((item) => ({ ...item, all: item.newCount }));

  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null];
    }
    const total = usersData[usersData.length - 1]?.uniqueCountCumulative;
    const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative;
    const delta = total && prevTotal ? total - prevTotal : null;
    return [total, delta];
  }, [usersData]);

  const [tradersData, tradersLoading] = useTradersData(params, feesData);
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersData) {
      return [];
    }
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest;
    const delta = total - tradersData.data[tradersData.data.length - 2]?.openInterest;
    return [total, delta];
  }, [tradersData]);

  const [volumeData, volumeLoading] = useVolumeData(params);

  const [glpPerformanceData, glpPerformanceLoading] = useGlpPerformanceData(glpData, feesData, params);
  const [isExperiment, setIsExperiment] = useState(false);
  useEffect(() => {
    setIsExperiment(window.localStorage.getItem("experiment"));
  }, [setIsExperiment]);

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params);
  const [aumPerformanceData, aumPerformanceLoading] = useAumPerformanceData(params, feesData, feesLoading);

  const [swapSources, swapSourcesLoading] = useSwapSources(params);

  const swapSourcesKeys = Object.keys(
    (swapSources || []).reduce((memo, el) => {
      Object.keys(el).forEach((key) => {
        if (key === "all" || key === "timestamp") return;
        memo[key] = true;
      });
      return memo;
    }, {})
  );

  const CustomTooltip = (props) => {
    const { active, payload, label, hideUnit } = props;
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <div className="label-total">{tooltipLabelFormatter(label, payload)}</div>
          {payload.map((item, index) => {
            return (
              <div className="item-group" key={index}>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>{item.name}: </div>
                <div style={{ color: item.color || item.stroke || item.fill || "white" }}>
                  {tooltipFormatter(item.value, item.name, item)} {!hideUnit && (item.unit || "")}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  const ChartColorForTheme = (index) => {
    const result = isLightTheme ? LIGHT_COLORS[index] : COLORS[index];
    return result;
  };

  return (
    <Wrapper className={`analytic-container ${lightThemeClassName}`}>
      <HeadWrapper>
        <div className="page-title-block">
          {lastSubgraphBlock && lastBlock && (
            <p className={cx("page-description", { warning: isObsolete })}>
              {isObsolete && "Data is obsolete. "}
              Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block{" "}
              <a rel="noreferrer" target="_blank" href={`${getExplorerUrl(chainId)}/block/${lastSubgraphBlock.number}`}>
                {lastSubgraphBlock.number}
              </a>
            </p>
          )}
          {lastSubgraphBlockError && (
            <p className="page-description warning">Subgraph data is temporarily unavailable.</p>
          )}
        </div>
        <div className="form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </HeadWrapper>
      <div className="chart-grid">
        <StatsWrap className="stat-overview-wrapper">
          <div className="chart-cell stats">
            {totalVolume ? (
              <>
                <img src={isLightTheme ? totalVolumnLightIc : totalVolumnIc} alt="img" />
                <div>
                  <div className="total-stat-label">Total Volume</div>
                  <div className="total-stat-value">
                    {formatNumberStats(totalVolume, { currency: true })}
                    {totalVolumeDelta && (
                      <span className="total-stat-delta plus" title="Change since previous day">
                        +
                        {formatNumberStats(totalVolumeDelta, {
                          currency: true,
                          compact: true,
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              // <RiLoader5Fill size="3em" className="loader" />
              <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
            )}
          </div>
          <div className="chart-cell stats">
            {totalFees ? (
              <>
                <img src={isLightTheme ? totalFeeLightIc : totalFeeIc} alt="img" />
                <div>
                  <div className="total-stat-label">Total Fees</div>
                  <div className="total-stat-value">
                    {formatNumberStats(totalFees, { currency: true })}
                    <span className="total-stat-delta plus" title="Change since previous day">
                      +
                      {formatNumberStats(totalFeesDelta, {
                        currency: true,
                        compact: true,
                      })}
                    </span>
                  </div>
                </div>
              </>
            ) : feesLoading ? (
              // <RiLoader5Fill size="3em" className="loader" />
              <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
            ) : null}
          </div>
          <div className="chart-cell stats">
            {totalAum ? (
              <>
                {" "}
                <img src={isLightTheme ? glpPoolLightIc : glpPoolIc} alt="img" />
                <div>
                  <div className="total-stat-label">GLP Pool</div>
                  <div className="total-stat-value">
                    {formatNumberStats(totalAum, { currency: true })}
                    <span
                      className={cx("total-stat-delta", totalAumDelta > 0 ? "plus" : "minus")}
                      title="Change since previous day"
                    >
                      {totalAumDelta && totalAumDelta > 0 ? "+" : ""}
                      {totalAumDelta
                        ? formatNumberStats(totalAumDelta, {
                            currency: true,
                            compact: true,
                          })
                        : ""}
                    </span>
                  </div>{" "}
                </div>
              </>
            ) : chainId === FANTOM ? (
              glpLoading
            ) : totalGlpLoading ? (
              // <RiLoader5Fill size="3em" className="loader" />
              <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
            ) : null}
          </div>
          <div className="chart-cell stats">
            {totalUsers ? (
              <>
                <img src={isLightTheme ? totalUserLightIc : totalUserIc} alt="img" />
                <div>
                  <div className="total-stat-label">Total Users</div>
                  <div className="total-stat-value">
                    {formatNumberStats(totalUsers)}
                    <span className="total-stat-delta plus" title="Change since previous day">
                      +{formatNumber(totalUsersDelta)}
                    </span>
                  </div>
                </div>
              </>
            ) : usersLoading ? (
              // <RiLoader5Fill size="3em" className="loader" />
              <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
            ) : null}
          </div>
          <div className="chart-cell stats">
            {openInterest ? (
              <>
                <img src={isLightTheme ? openInterestLightIc : openInterestIc} alt="img" />
                <div>
                  <div className="total-stat-label">Open Interest</div>
                  <div className="total-stat-value">
                    {formatNumberStats(openInterest, { currency: true })}
                    <span
                      className={cx("total-stat-delta", openInterestDelta > 0 ? "plus" : "minus")}
                      title="Change since previous day"
                    >
                      {openInterestDelta && openInterestDelta > 0 ? "+" : ""}
                      {openInterestDelta
                        ? formatNumberStats(openInterestDelta, {
                            currency: true,
                            compact: true,
                          })
                        : ""}
                    </span>
                  </div>{" "}
                </div>
              </>
            ) : tradersLoading ? (
              // <RiLoader5Fill size="3em" className="loader" />
              <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
            ) : null}
          </div>
        </StatsWrap>
        <ChartContainer>
          <div className="chart-cell">
            <VolumeChart
              data={volumeData}
              loading={volumeLoading}
              chartHeight={CHART_HEIGHT}
              yaxisWidth={YAXIS_WIDTH}
              xaxisTickFormatter={tooltipLabelFormatter}
              yaxisTickFormatter={yaxisFormatter}
              tooltipLabelFormatter={tooltipLabelFormatter}
              tooltipFormatter={tooltipFormatter}
              isLightTheme={isLightTheme}
            />
          </div>
          <div className="chart-cell">
            <FeesChart data={feesData} loading={feesLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
          </div>
          <div className="chart-cell">
            <ChartWrapper
              title="AUM & GLP Supply"
              loading={glpLoading}
              data={glpData}
              csvFields={[{ key: "aum" }, { key: "glpSupply" }]}
            >
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                  <YAxis dataKey="aum" tickFormatter={yaxisFormatter} width={YAXIS_WIDTH} />
                  <Tooltip content={<CustomTooltip hideUnit />} />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    strokeWidth={2}
                    unit="$"
                    dot={false}
                    dataKey="aum"
                    stackId="a"
                    name="AUM"
                    stroke={ChartColorForTheme(0)}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    strokeWidth={2}
                    dot={false}
                    dataKey="glpSupply"
                    stackId="a"
                    name="GLP Supply"
                    stroke={ChartColorForTheme(1)}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
          <div className="chart-cell">
            <PoolAmountChart from={from} to={to} syncId="syncGlp" chainName={params.chainName} />
          </div>
          <div className="chart-cell">
            <ChartWrapper
              title="GLP Performance"
              loading={glpLoading}
              data={glpPerformanceData}
              csvFields={[
                { key: "syntheticPrice" },
                { key: "glpPrice" },
                { key: "glpPlusFees" },
                { key: "lpBtcPrice" },
                { key: "lpEthPrice" },
                { key: "performanceSyntheticCollectedFees" },
                { key: "indexBtcCount" },
                { key: "indexEthCount" },
                { key: "indexAvaxCount" },
                { key: "indexStableCount" },
                { key: "BTC_WEIGHT" },
                { key: "ETH_WEIGHT" },
                { key: "BNB_WEIGHT" },
                { key: "STABLE_WEIGHT" },
              ]}
            >
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                  <YAxis
                    dataKey="performanceSyntheticCollectedFees"
                    domain={[80, 180]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip content={<CustomTooltip />} />

                  <Legend />
                  <Line
                    dot={false}
                    isAnimationActive={false}
                    type="monotone"
                    unit="%"
                    dataKey="performanceLpBtcCollectedFees"
                    name="% LP BTC-USDC (w/ fees)"
                    stroke={ChartColorForTheme(2)}
                  />
                  <Line
                    dot={false}
                    isAnimationActive={false}
                    type="monotone"
                    unit="%"
                    dataKey="performanceLpEthCollectedFees"
                    name="% LP ETH-USDC (w/ fees)"
                    stroke={ChartColorForTheme(4)}
                  />
                  <Line
                    dot={false}
                    isAnimationActive={false}
                    type="monotone"
                    unit="%"
                    dataKey="performanceSyntheticCollectedFees"
                    name="% Index (w/ fees)"
                    stroke={ChartColorForTheme(0)}
                  />
                </LineChart>
              </ResponsiveContainer>
              <div className="chart-description">
                <p>
                  <span style={{ color: ChartColorForTheme(0) }}>% of Index (with fees)</span> is GLP with fees / Index
                  Price * 100. Index is a basket of 25% BTC, 25% ETH, 50% USDC rebalanced once&nbsp;a&nbsp;day
                  <br />
                  <span style={{ color: ChartColorForTheme(4) }}>% of LP ETH-USDC (with fees)</span> is GLP Price with
                  fees / LP ETH-USDC * 100
                  <br />
                </p>
              </div>
            </ChartWrapper>
          </div>
          <div className="chart-cell">
            <ChartWrapper
              title="GLP Price Comparison"
              loading={glpLoading}
              data={glpPerformanceData}
              csvFields={
                chainId === FANTOM
                  ? [
                      { key: "syntheticPrice" },
                      { key: "glpPrice" },
                      { key: "glpPlusFees" },
                      { key: "lpBtcPrice" },
                      { key: "lpEthPrice" },
                      { key: "performanceSyntheticCollectedFees" },
                      { key: "indexBtcCount" },
                      { key: "indexEthCount" },
                      { key: "indexAvaxCount" },
                      { key: "indexStableCount" },
                      { key: "BTC_WEIGHT" },
                      { key: "ETH_WEIGHT" },
                      { key: "BNB_WEIGHT" },
                      { key: "STABLE_WEIGHT" },
                    ]
                  : [
                      { key: "syntheticPrice" },
                      { key: "glpPrice" },
                      { key: "glpPlusFees" },
                      { key: "lpBtcPrice" },
                      { key: "lpEthPrice" },
                    ]
              }
            >
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                  <YAxis
                    dataKey="glpPrice"
                    domain={[0, 10]}
                    // domain={}
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip content={<CustomTooltip hideUnit />} />

                  <Legend />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    unit="$"
                    strokeWidth={1}
                    dot={false}
                    dataKey="syntheticPrice"
                    name="Index Price"
                    stroke={ChartColorForTheme(2)}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    unit="$"
                    strokeWidth={1}
                    dot={false}
                    dataKey="glpPrice"
                    name="GLP Price"
                    stroke={ChartColorForTheme(1)}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    unit="$"
                    strokeWidth={2}
                    dot={false}
                    dataKey="glpPlusFees"
                    name="GLP w/ fees"
                    stroke={ChartColorForTheme(3)}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    unit="$"
                    strokeWidth={1}
                    dot={false}
                    dataKey="lpBtcPrice"
                    name="LP BTC-USDC"
                    stroke={ChartColorForTheme(2)}
                  />
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    unit="$"
                    strokeWidth={1}
                    dot={false}
                    dataKey="lpEthPrice"
                    name="LP ETH-USDC"
                    stroke={ChartColorForTheme(4)}
                  />
                </LineChart>
              </ResponsiveContainer>
              <div className="chart-description">
                <p>
                  <span style={{ color: ChartColorForTheme(3) }}>GLP with fees</span> is based on GLP share of fees
                  received and excluding esGUGMY rewards
                  <br />
                  <span style={{ color: ChartColorForTheme(2) }}>Index Price</span> is a basket of 25% BTC, 25% ETH, 50%
                  USDC rebalanced once&nbsp;a&nbsp;day
                </p>
              </div>
            </ChartWrapper>
          </div>
          {isExperiment && (
            <div className="chart-cell experiment">
              <ChartWrapper title="Performance vs. Index" loading={glpLoading}>
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <LineChart data={glpPerformanceData} syncId="syncGlp">
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis
                      dataKey="performanceSyntheticCollectedFees"
                      domain={[80, 120]}
                      unit="%"
                      tickFormatter={yaxisFormatterNumber}
                      width={YAXIS_WIDTH}
                    />
                    <Tooltip content={<CustomTooltip />} />

                    <Legend />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceSyntheticCollectedFees"
                      name="Collected Fees"
                      stroke={ChartColorForTheme(0)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceSyntheticDistributedUsd"
                      name="Distributed Usd"
                      stroke={ChartColorForTheme(1)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceSyntheticDistributedEth"
                      name="Distributed Eth"
                      stroke={ChartColorForTheme(2)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceSynthetic"
                      name="No Fees"
                      stroke={ChartColorForTheme(3)}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </div>
          )}
          {isExperiment && (
            <div className="chart-cell experiment">
              <ChartWrapper title="Performance vs. ETH LP" loading={glpLoading}>
                <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                  <LineChart data={glpPerformanceData} syncId="syncGlp">
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                    <YAxis
                      dataKey="performanceLpEthCollectedFees"
                      domain={[80, 120]}
                      unit="%"
                      tickFormatter={yaxisFormatterNumber}
                      width={YAXIS_WIDTH}
                    />
                    <Tooltip content={<CustomTooltip />} />

                    <Legend />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceLpEthCollectedFees"
                      name="Collected Fees"
                      stroke={ChartColorForTheme(0)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceLpEthDistributedUsd"
                      name="Distributed Usd"
                      stroke={ChartColorForTheme(1)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceLpEthDistributedEth"
                      name="Distributed Eth"
                      stroke={ChartColorForTheme(2)}
                    />
                    <Line
                      isAnimationActive={false}
                      dot={false}
                      type="monotone"
                      unit="%"
                      strokeWidth={2}
                      dataKey="performanceLpEth"
                      name="No Fees"
                      stroke={ChartColorForTheme(3)}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </div>
          )}
          <div className="chart-cell">
            <ChartWrapper
              title="Traders Net PnL"
              loading={tradersLoading}
              data={tradersData?.data}
              csvFields={[
                { key: "pnl", name: "Net PnL" },
                { key: "pnlCumulative", name: "Cumulative PnL" },
              ]}
            >
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <ComposedChart data={tradersData?.data} syncId="tradersId">
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="timestamp" tickFormatter={tooltipLabelFormatter} minTickGap={30} />
                  <YAxis
                    domain={[
                      -tradersData?.stats.maxAbsCumulativePnl * 1.05,
                      tradersData?.stats.maxAbsCumulativePnl * 1.05,
                    ]}
                    orientation="right"
                    yAxisId="right"
                    tickFormatter={yaxisFormatter}
                    width={YAXIS_WIDTH}
                    tick={{ fill: ChartColorForTheme(4) }}
                  />
                  <YAxis
                    domain={[-tradersData?.stats.maxAbsPnl * 1.05, tradersData?.stats.maxAbsPnl * 1.05]}
                    tickFormatter={yaxisFormatter}
                    width={YAXIS_WIDTH}
                  />
                  <Tooltip content={<CustomTooltip />} />

                  <Legend />
                  <Bar type="monotone" fill={"#fff"} dot={false} dataKey="pnl" name="Net PnL">
                    {(tradersData?.data || []).map((item, i) => {
                      return <Cell key={`cell-${i}`} fill={item.pnl > 0 ? "#22c761" : "#f93333"} />;
                    })}
                  </Bar>
                  <Line
                    type="monotone"
                    strokeWidth={2}
                    stroke={ChartColorForTheme(4)}
                    dataKey="currentPnlCumulative"
                    name="Cumulative PnL"
                    yAxisId="right"
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <div className="chart-description">
                <p>Considers settled (closed) positions</p>
                <p>Fees are not factored into PnL</p>
              </div>
            </ChartWrapper>
          </div>
          <div className="chart-cell">
            <TradersProfitLossChart
              syncId="tradersId"
              loading={tradersLoading}
              tradersData={tradersData}
              yaxisWidth={YAXIS_WIDTH}
              chartHeight={CHART_HEIGHT}
            />
          </div>
          <div className="chart-cell">
            <GenericChart
              loading={tradersLoading}
              title="Open Interest"
              data={tradersData?.data.map((item) => ({
                all: item.openInterest,
                ...item,
              }))}
              controls={{
                convertToPercents: convertToPercents,
              }}
              yaxisDataKey="all"
              items={[
                { key: "shortOpenInterest", name: "Short", color: "#f93333" },
                { key: "longOpenInterest", name: "Long", color: "#22c761" },
              ]}
              type="Bar"
            />
          </div>
          <div className="chart-cell">
            <GenericChart
              loading={fundingRateLoading}
              title="Borrowing Rate Annualized"
              data={fundingRateData}
              yaxisDataKey="ETH"
              yaxisTickFormatter={yaxisFormatterPercent}
              tooltipFormatter={tooltipFormatterPercent}
              items={
                chainId === FANTOM
                  ? [
                      { key: "ETH" },
                      { key: "BTC" },
                      { key: "FTM" },
                      { key: "LINK" },
                      { key: "CRV" },
                      { BNB: "FTM" },
                      { key: "DAI" },
                      { key: "USDC" },
                      { key: "USDT" },
                    ]
                  : chainId === OP
                  ? [
                      { key: "ETH" },
                      { key: "BTC" },
                      { key: "OP" },
                      { key: "LINK" },
                      { key: "SNX" },
                      { key: "FXS" },
                      { key: "DAI" },
                      { key: "USDC" },
                      { key: "USDT" },
                    ]
                  : [
                      { key: "ETH" },
                      { key: "BTC" },
                      { key: "ARB" },
                      { key: "LINK" },
                      { key: "UNI" },
                      { key: "GMY" },
                      { key: "RDNT" },
                      { key: "DAI" },
                      { key: "USDC" },
                      { key: "USDT" },
                    ]
              }
              type="Line"
              yaxisDomain={[0, 90 /* ~87% is a maximum yearly borrow rate */]}
              isCoinChart={true}
            />
          </div>

          <>
            <div className="chart-cell">
              <GenericChart
                syncId="syncGlp"
                loading={aumPerformanceLoading}
                title="AUM Performance Annualized"
                data={aumPerformanceData}
                yaxisDataKey="apr"
                yaxisTickFormatter={yaxisFormatterPercent}
                tooltipFormatter={tooltipFormatterPercent}
                items={[{ key: "apr", name: "APR", color: ChartColorForTheme(0) }]}
                description="Formula = Daily Fees / GLP Pool * 365 days * 100"
                type="Composed"
              />
            </div>{" "}
            <div className="chart-cell">
              <GenericChart
                syncId="syncGlp"
                loading={aumPerformanceLoading}
                title="AUM Daily Usage"
                data={aumPerformanceData}
                yaxisDataKey="usage"
                yaxisTickFormatter={yaxisFormatterPercent}
                tooltipFormatter={tooltipFormatterPercent}
                items={[{ key: "usage", name: "Daily Usage", color: ChartColorForTheme(4) }]}
                description="Formula = Daily Volume / GLP Pool * 100"
                type="Composed"
              />
            </div>
          </>

          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={usersLoading}
              title="Unique Users"
              data={usersData}
              truncateYThreshold={6500}
              yaxisDataKey="uniqueSum"
              yaxisTickFormatter={yaxisFormatterNumber1}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                { key: "uniqueSwapCount", name: "Swaps" },
                { key: "uniqueMarginCount", name: "Margin trading" },
                { key: "uniqueMintBurnCount", name: "Mint & Burn GLP" },
              ]}
              type="Composed"
            />
          </div>
          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={usersLoading}
              title="New Users"
              data={usersData?.map((item) => ({ ...item, all: item.newCount }))}
              truncateYThreshold={6000}
              yaxisDataKey="newCount"
              rightYaxisDataKey="uniqueCountCumulative"
              yaxisTickFormatter={yaxisFormatterNumber1}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                { key: "newSwapCount", name: "Swap" },
                { key: "newMarginCount", name: "Margin trading" },
                { key: "newMintBurnCount", name: "Mint & Burn" },
                {
                  key: "cumulativeNewUserCount",
                  name: "Cumulative",
                  type: "Line",
                  yAxisId: "right",
                  strokeWidth: 2,
                  color: ChartColorForTheme(4),
                },
              ]}
              type="Composed"
            />
          </div>

          {chainId === FANTOM ? (
            <>
              <div className="chart-cell">
                <GenericChart
                  syncId="syncGlp"
                  loading={usersLoading}
                  title="New vs. Existing Users"
                  data={usersData?.map((item) => ({
                    ...item,
                    all: item.uniqueCount,
                  }))}
                  truncateYThreshold={7000}
                  yaxisDataKey="uniqueCount"
                  rightYaxisDataKey="oldPercent"
                  yaxisTickFormatter={yaxisFormatterNumber1}
                  tooltipFormatter={tooltipFormatterNumber}
                  tooltipLabelFormatter={tooltipLabelFormatterUnits}
                  items={[
                    { key: "newCount", name: "New" },
                    { key: "oldCount", name: "Existing" },
                    {
                      key: "oldPercent",
                      name: "Existing %",
                      yAxisId: "right",
                      type: "Line",
                      strokeWidth: 2,
                      color: ChartColorForTheme(4),
                      unit: "%",
                    },
                  ]}
                  type="Composed"
                />
              </div>
              <div className="chart-cell">
                <GenericChart
                  syncId="syncGlp"
                  loading={usersLoading}
                  title="User Actions"
                  data={(usersData || []).map((item) => ({
                    ...item,
                    all: item.actionCount,
                  }))}
                  truncateYThreshold={25000}
                  yaxisDataKey="actionCount"
                  yaxisTickFormatter={yaxisFormatterNumber}
                  tooltipFormatter={tooltipFormatterNumber}
                  tooltipLabelFormatter={tooltipLabelFormatterUnits}
                  items={[
                    { key: "actionSwapCount", name: "Swaps" },
                    { key: "actionMarginCount", name: "Margin trading" },
                    { key: "actionMintBurnCount", name: "Mint & Burn GLP" },
                  ]}
                  type="Composed"
                />
              </div>
            </>
          ) : null}

          <div className="chart-cell">
            <GenericChart
              loading={swapSourcesLoading}
              title="Swap Sources"
              data={swapSources}
              items={swapSourcesKeys.map((key) => ({ key }))}
            />
          </div>
        </ChartContainer>
      </div>
    </Wrapper>
  );
}

const HeadWrapper = styled.div`
  .form {
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: flex-end;
    }
    @media (max-width: 767px) {
      width: 100%;
      > div {
        width: inherit;
      }
    }
  }
  .date-range-selector-wrapper .rdrDayNumber span {
    color: #fff;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;
const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  gap: 24px;
  margin-bottom: 286px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .recharts-tooltip-wrapper .recharts-default-tooltip {
    background-color: #353651 !important;
    box-shadow: 0 3px 6px #0000005c;
    border: 1px solid #484b6e !important;
    border-radius: 4px;
  }
  .chart-header {
    display: flex;
    justify-content: space-between;
  }
  .chart-control-checkbox {
    width: fit-content;
    background: #333;
    color: #aaa;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 8px;
    padding: 8px 7px;

    font-weight: 700;

    border-radius: 4px;
    transition: background-color 0.1s, color 0.1s;
    font-size: 14px;
  }
  .chart-control-checkbox.active {
    background: #609ade;
    color: #fff;
  }

  .recharts-legend-item-text {
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .recharts-legend-item {
    padding-bottom: 0;
  }

  .chart-cell {
    padding: 24px;
    position: relative;
    background: #191b2e;
    border-radius: 16px;
    .chart-header {
      h3 {
        margin-top: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        a {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 16px;
    }
  }
`;
const StatsWrap = styled.div`
  background-image: linear-gradient(to left, #002e58cc, #191b2ecc);
  margin-bottom: 24px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  gap: 24px;
  padding: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
    padding: 16px;
    gap: 16px;
  }

  .chart-cell {
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
      > div {
        display: flex;
        justify-content: space-between;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        .total-stat-value {
          text-align: right;
        }
      }
    }

    img {
      width: 60px;
      height: 60px;
      margin-right: 8px;
    }
    .total-stat-label {
      color: rgb(185, 185, 185);
      text-align: left;
      font-size: 14px;
    }
    .total-stat-value {
      margin-top: 4px;
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      .total-stat-delta {
        margin-top: 4px;
        display: block;
        font-size: 14px;
        color: #34f5ae;
        font-weight: normal;

        @media (max-width: 1023px) {
          text-align: left;
        }

        @media (max-width: 767px) {
          display: inline;
          margin-top: 0;
        }

        &.minus {
          color: rgb(246, 71, 93);
        }
      }
    }

    @media (max-width: 767px) {
      .total-stat-value {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;

        > span {
          font-size: 12px !important;
        }
      }
    }
  }
`;
const Wrapper = styled.div`
  flex-direction: column;

  .react-dropdown-select-dropdown-handle {
    display: none;
  }
  .react-dropdown-select {
    border: 1px solid #e6e6f7 !important;
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 2px;
    padding: 2px 5px;
    flex-direction: row;
    direction: ltr;
    align-items: center;
    cursor: pointer;
    min-height: 36px;
    pointer-events: all;
    background-color: #fff;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .date-range-items {
    overflow: auto;
    min-height: 10px;
    max-height: 200px;
  }
  .react-dropdown-select {
    background: #191b2e;
    border: 1px solid #ffffff1a !important;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
  }
  .date-range-item {
    display: flex;
    margin: 0.5rem;
    padding: 0.5rem;
    align-items: baseline;
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
  }
  .rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .date-range-selector-wrapper .rdrMonthAndYearPickers select {
    color: #fff;
    background: #16182e;
  }
  .date-range-selector-wrapper .rdrCalendarWrapper {
    background-color: #16182e;
  }
  .rdrCalendarWrapper {
    color: #000;
    font-size: 12px;
  }
  .react-dropdown-select-dropdown {
    background-color: #101124 !important;
    border: 1px solid #ffffff14 !important;
    position: absolute;
    top: 43px;
    right: 0;
    left: unset !important;
    border: 1px solid #ccc;
    width: 348px;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    overflow: auto;
    z-index: 9;
    max-height: 475px !important;
    width: fit-content !important;
  }
  .date-range-custom {
    color: #000;
    margin: 0.5rem;
    text-align: center;
  }
  .page-description {
    margin-bottom: 0;
    color: rgb(173, 171, 171);

    a {
      color: rgb(173, 171, 171);
    }
  }
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px #0003;
    gap: 4px;
    .item-group {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  &.theme--light {
    .recharts-cartesian-axis-ticks {
      tspan {
        fill: #0d1a16b2;
      }
    }
  }
`;

// const CustomTooltipWrapper = styled.div``;
