import React from "react";

function Icon({ ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="760" height="333" fill="none" viewBox="0 0 760 333">
      <g fill="#82ADEF" opacity="0.5">
        <path
          d="M486.919 710.1c-65.86 13.39-136.066 13.552-201.5 2.743C28.925 670.522-87.178 344.694 75.979 145.117 126.447 83.372 197.2 39.162 273.54 17.097 380.726-13.887 500.972-1.33 596.441 56.411c230.723 139.58 217.665 540.745-45.957 635.973-20.492 7.395-41.795 13.267-63.565 17.696v.02z"
          opacity="0.1"
        ></path>
        <path
          d="M594.836 580.801c-45.023 36.003-98.556 63.593-152.718 80.985C229.794 729.89 13.754 526.635 60.24 310.439c14.378-66.885 51.076-128.324 100.709-175.054 69.698-65.605 166.386-103.09 261.835-96.386 230.683 16.214 377.613 327.515 213.644 503.402-12.754 13.674-26.705 26.494-41.592 38.4z"
          opacity="0.1"
        ></path>
        <path
          d="M325.265 627.978c-48.883-14.222-96.059-38.847-136.147-69.14-157.167-118.734-120.388-378.207 59.3-454.62 55.584-23.63 118.621-28.383 177.576-16.295 82.797 16.965 159.055 67.738 202.779 140.108 105.664 174.912-44.293 439.423-254.647 410.451-16.348-2.255-32.696-5.811-48.861-10.504z"
          opacity="0.1"
        ></path>
        <path
          d="M330.016 602.764c-44.373-12.901-87.163-35.25-123.535-62.74-142.625-107.742-109.239-343.18 53.817-412.523 50.446-21.455 107.634-25.762 161.147-14.791 75.14 15.401 144.331 61.481 183.993 127.146 95.876 158.699-40.19 398.728-231.068 372.437-14.846-2.052-29.671-5.283-44.354-9.549v.02z"
          opacity="0.1"
        ></path>
        <path d="M443.885 576.006c-41.49 8.432-85.701 8.534-126.927 1.727-161.553-26.656-234.683-231.881-131.902-357.584 31.782-38.888 76.359-66.743 124.429-80.64 67.505-19.505 143.255-11.621 203.388 24.767 145.326 87.913 137.101 340.599-28.94 400.576-12.916 4.673-26.319 8.371-40.028 11.154h-.02z"></path>
      </g>
    </svg>
  );
}

export default Icon;
