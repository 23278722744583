import styled from "styled-components";
import React from "react";
import { ImagePerpetualTrading, ImageSpotTrading, ImageStakeBlue, ImageStakeStablecoins } from "assets";
import { motion } from "framer-motion";
import ExternalLink from "components/ExternalLink/ExternalLink";
const OnestopDexSection = () => {
  const ListOneStop = [
    {
      title: "Spot Trading",
      desc: "Seamlessly exchange tokens at industry-leading low fees.",
      btnText: "Trade now",
      image: ImageSpotTrading,
      link: "https://app.yummy.fi/#/spot-native",
    },
    {
      title: "Perpetual Trading",
      desc: "Experience the thrill of high-leverage trading with Crypto, Forex, and Commodities. Yummy Finance offers all the tools you need to succeed.",
      btnText: "Trade now",
      image: ImagePerpetualTrading,
      link: "https://app.yummy.fi/#/trade",
    },
    {
      title: "Stake Blue Chip Tokens",
      desc: "Generate impressive high-yield rewards by depositing top-tier crypto assets.",
      btnText: "Stake now",
      image: ImageStakeBlue,
      link: "https://app.yummy.fi/#/earn/mglp",
    },
    {
      title: "Stake Stablecoins",
      desc: "Enjoy exceptional interest rates by staking your stablecoins.",
      btnText: "Stake now",
      image: ImageStakeStablecoins,
      link: "https://app.yummy.fi/#/earn/gslp",
    },
  ];
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.2, type: "spring", stiffness: 100, damping: 15 } },
  };

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <Wrapper>
      <motion.div className="container wrapper" initial="hidden" whileInView="visible" variants={listVariants}>
        <motion.div className="title" variants={titleVariants} whileInView="visible" initial="hidden">
          Your One-Stop DEX
        </motion.div>
        <motion.div className="box-list">
          {ListOneStop.map((item, idx) => (
            <motion.div className="item-list" key={idx} variants={itemVariants}>
              <img className="img" src={item.image} alt="" />
              <div className="body-box">
                <div className="content">
                  <div className="title-card">{item.title}</div>
                  <div className="desc">{item.desc}</div>
                </div>
                <ExternalLink className="w-full" href={item.link}>
                  <button className="btn-outline w-full btn">{item.btnText}</button>
                </ExternalLink>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </Wrapper>
  );
};

export default OnestopDexSection;

const Wrapper = styled.div`
  margin-top: 160px;
  @media (max-width: 1024px) {
    margin: 0 24px;
    margin-top: 120px;
  }
  @media (max-width: 767px) {
    margin: 0 16px;
    margin-top: 120px;
  }
  .w-full {
    width: 100%;
  }
  .title {
    color: Text/Text_Primary;
    text-align: center;

    /* Title/Bold */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media (max-width: 767px) {
      color: Text/Text_Primary;
      text-align: center;

      /* Small_Title/Bold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
  .box-list {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin-top: 36px;
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      gap: 24px;
      align-self: stretch;
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
  }
  .item-list {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    flex: 1 0 0;
    align-self: stretch;
    position: relative;
    border-radius: 24px;
    background: #1c1e1d;
    @media (max-width: 767px) {
      display: flex;
      padding: 16px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
  }
  .img {
    @media (max-width: 767px) {
      position: absolute;
      width: 80px;
      height: 80px;
      right: 0;
      bottom: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    flex: 1 0 0;
    align-self: stretch;
    @media (max-width: 767px) {
      align-items: start;
    }
  }
  .title-card {
    color: Text/Text_Primary;
    text-align: center;

    /* 16/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    @media (max-width: 1024px) {
      color: Text/Text_Primary;
      text-align: center;

      /* Small_Title/Bold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
  .desc {
    color: var(--Text-Text_Secondary, #667180);
    text-align: center;

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media (max-width: 767px) {
      /* 14/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-align: start;
    }
  }
  .body-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    flex: 1 0 0;
    align-self: stretch;
  }
  .btn {
    @media (max-width: 767px) {
      width: fit-content;
    }
  }
`;
