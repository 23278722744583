import Checkbox from "components/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import { BigNumber, ethers } from "ethers";
import { callContract, contractFetcher } from "lib/contracts";
import { useEffect, useState, useMemo, useRef } from "react";
import "./Earn.scss";
import "./EarnTheme.scss";
import RewardRouter from "abis/RewardRouter.json";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useWeb3React } from "@web3-react/core";
import { FANTOM, getConstant, SUPPORTED_CHAIN_ID_GM, SUPPORTED_CHAIN_ID_VAULT } from "config/chains";
import { useChainId, useChainIdWithDefault } from "lib/chains";
import { getContract } from "config/contracts";
import { approveTokens } from "domain/tokens";
import "./OverviewV2.scss";
import active_dot from "img/earn/active_dot.svg";
import active_dotLight from "img/earn/active_dotLight.svg";
import useSWR from "swr";
import Token from "abis/Token.json";
import { omit } from "lodash";

import {
  ACTIVE_CHAIN_IDS_V2,
  ACTIVE_CHAIN_IDS_VGMY,
  GLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_DAY,
  USD_DECIMALS,
} from "lib/legacy";
import { expandDecimals, formatAmount, formatAmountFree, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useGmyPrice, useTotalEsGmyStaked, useTotalGmyStaked } from "domain/legacy";
import axios from "axios";
import NewVaultJson from "abis/NewVault.json";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import TooltipHTML from "components/Tooltip/TooltipHTML";
import GMYAprTooltip from "components/Stake/GMYAprTooltip";
import useUsdPrice from "hooks/useUsdPrice";
import useMspPrice from "hooks/useMspPrice";
import { useMedia } from "react-use";
import useGMYFarmContractMulticall from "hooks/contracts/useGMYFarmContractMulticall";
import { ClaimV2RewardModal } from "./modals/Stakes";

import glp_coin from "img/earn/glp_coin.svg";
import gmy_coin from "img/earn/gmy_coin.svg";
import msp_ic from "img/icons/ic_msp.svg";
import esgmy_coin from "img/earn/esgmy_coin.svg";
import vgmy_coin from "img/earn/vgmy_coin.svg";
import useGmContract from "hooks/contracts/useGmContract";
import useVGMYContract from "hooks/contracts/usevGMYContract";
import { BorderIcon } from "components/Icon";
import { caculateAPYHourly } from "lib/helper";
import Countdown from "react-countdown";
import usePoolRewardersVGMY from "hooks/contracts/usePoolRewardersVGMY";
import moment from "moment";
import { useThemeContext } from "contexts/ThemeProvider";
import useGmApr, { SYMBOL_BY_ADDRESS_REWARDS } from "hooks/useGmApr";
import useInfoGm from "hooks/useInfoGm";

const tooltipTexts = {
  vGMY: "vGMY from GM staking.",
  WFTM: "FTM (WFTM) from GM staking.",
  WETH: "ETH (WETH) from GM staking.",
};

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-gmy"],
    true
  );
  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-gmy"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy,
        false, // shouldStakeGmy
        shouldClaimEsGmy,
        false, // shouldStakeEsGmy
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Claim General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy}>
              <span>Claim GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy}>
              <span>Claim esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  // const shouldClaimGmy = false; // delete here
  // const shouldStakeGmy = false; // delete here

  // const [shouldClaimGmytest,setShouldClaimGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-claim-gmy"], true); // delete here
  // const [shouldStakeGmytest,setShouldStakeGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-stake-gmy"], true); // delete here

  // open here //

  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-gmy"],
    true
  );
  const [shouldStakeGmy, setShouldStakeGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-gmy"],
    true
  );

  // open here //

  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-gmy"],
    true
  );
  const [shouldStakeEsGmy, setShouldStakeEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-gmy"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const gmyAddress = getContract(chainId, "GMY");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, gmyAddress, "allowance", account, stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeGmy && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving GMY...`;
    }
    if (needApproval) {
      return `Approve GMY`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: gmyAddress,
        spender: stakedGmyTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy || shouldStakeGmy,
        shouldStakeGmy,
        shouldClaimEsGmy || shouldStakeEsGmy,
        shouldStakeEsGmy,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeGmy = (value) => {
    if (value) {
      setShouldClaimGmy(true);
    }
    setShouldStakeGmy(value);
  };

  const toggleShouldStakeEsGmy = (value) => {
    if (value) {
      setShouldClaimEsGmy(true);
    }
    setShouldStakeEsGmy(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Compound General Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <span>Stake Multiplier Points</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy} disabled={shouldStakeGmy}>
              <span>Claim GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeGmy} setIsChecked={toggleShouldStakeGmy}>
              <span>Stake GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy} disabled={shouldStakeEsGmy}>
              <span>Claim esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsGmy} setIsChecked={toggleShouldStakeEsGmy}>
              <span>Stake esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

const POOL_INDEX = ["GM", "GM"];
const symbolRender = {
  WFTM: "FTM (WFTM)",
};

const EarnOverviewV2 = (props) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { chainId: walletChainId } = useChainId();
  const isTablet = useMedia("(max-width: 1023px)");
  const {
    setPendingTxns,
    processedData,
    connectWallet,
    vestingData,
    totalGmySupply,
    stakedGmySupplyUsd,
    totalSupplyUsd,
    stakedEsGmySupplyUsd,
    esGmySupplyUsd,
    esGmySupply,
    rewardsPrice,
  } = props;
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);
  const [isClaimV2RewardVisible, setIsClaimV2RewardVisible] = useState(false);
  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const rewardRouterAddress = getContract(walletChainId, "RewardRouter");
  const { active, library, account } = useWeb3React();
  const wrappedTokenSymbol = getConstant(walletChainId, "wrappedTokenSymbol");
  const nativeTokenSymbol = getConstant(walletChainId, "nativeTokenSymbol");

  const { gmyPrice } = useGmyPrice(walletChainId, {}, active);
  const priceTokenGMYVault = Number(formatAmount(gmyPrice, USD_DECIMALS));
  const priceTokenGLPVault = Number(formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true));
  const [statsGMYWFTM, setStatsGMYWFTM] = useState({
    price: 0,
    apr: 1,
  });
  const GMY_WFTM_Address =
    walletChainId === FANTOM
      ? "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394"
      : "0x104d0312f95a1b3056435d19668f2272ab1e7df2";
  const priceStatsGMYWFTM = statsGMYWFTM?.price;

  const fetchStatsGMYWFTM = async () => {
    try {
      const res = await axios.get(
        walletChainId === FANTOM
          ? `https://eqapi-main-7cmme.ondigitalocean.app/api/v2/pairs`
          : "https://api.velodrome.finance/api/v1/pairs",
        {}
      );
      if (walletChainId === FANTOM) {
        if (res && res.data) {
          const { apr, tvl, totalSupply } = res.data.data.find(
            (item) => item.address.toLowerCase() === GMY_WFTM_Address
          );

          setStatsGMYWFTM({
            price: tvl / totalSupply,
            apr,
          });
        }
      } else {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.find((item) => item.address.toLowerCase() === GMY_WFTM_Address)
        ) {
          const { apr, tvl, totalSupply } = res.data.data.find(
            (item) => item.address.toLowerCase() === GMY_WFTM_Address
          );

          setStatsGMYWFTM({
            price: tvl / totalSupply,
            apr,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const vaultAddress = getContract(walletChainId, "Vault");
  const vaultGMYAddress = getContract(walletChainId, "VaultGMY");
  const vaultGLPAddress = getContract(walletChainId, "VaultGLP");
  const vaultGMY_WFTMAddress = getContract(walletChainId, walletChainId === FANTOM ? "VaultGMY_WFTM" : "VaultGMY_WETH");
  const { data: balanceOfGMY } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, walletChainId, vaultGMYAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );
  const { data: balanceOfGLP } = useSWR(
    [`StakeV2:balanceOf:${active}:${account}`, walletChainId, vaultGLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );
  const { data: balanceOfGMY_WFTM } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, walletChainId, vaultGMY_WFTMAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );
  const { data: pricePerFullShareGMY } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareGMY:${active}:${account}`,
      walletChainId,
      vaultGMYAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );
  const { data: pricePerFullShareGLP } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareGLP:${active}:${account}`,
      walletChainId,
      vaultGLPAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );
  const { data: pricePerFullShareGMY_WFTM } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareGMY_WFTM:${active}:${account}`,
      walletChainId,
      vaultGMY_WFTMAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const AmountMMMVaultDeposit = formatAmountFree(balanceOfGMY, 18);
  const AmountGLPVaultDeposit = formatAmountFree(balanceOfGLP, 18);
  const AmountGMY_WFTMVaultDeposit = formatAmountFree(balanceOfGMY_WFTM, 18);

  const rateWithdrawGMYVault = formatAmountFree(pricePerFullShareGMY, 18);
  const rateWithdrawGLPVault = formatAmountFree(pricePerFullShareGLP, 18);
  const rateWithdrawGMY_WFTMVault = formatAmountFree(pricePerFullShareGMY_WFTM, 18);

  let depositDollar = "0";
  const checkAvailableNumber = (number) => {
    if (!number) return 0;
    if (number === "...") return 0;
    return number;
  };
  if (AmountMMMVaultDeposit || AmountGLPVaultDeposit || AmountGMY_WFTMVaultDeposit) {
    depositDollar = formatNumber(
      checkAvailableNumber(AmountMMMVaultDeposit || 0) *
        checkAvailableNumber(priceTokenGMYVault || 0) *
        checkAvailableNumber(rateWithdrawGMYVault || 0) +
        checkAvailableNumber(AmountGLPVaultDeposit || 0) *
          checkAvailableNumber(priceTokenGLPVault || 0) *
          checkAvailableNumber(rateWithdrawGLPVault || 0) +
        checkAvailableNumber(AmountGMY_WFTMVaultDeposit || 0) *
          checkAvailableNumber(priceStatsGMYWFTM || 0) *
          checkAvailableNumber(rateWithdrawGMY_WFTMVault || 0),
      2
    );
  }
  // console.log("????", {
  //   AmountMMMVaultDeposit,
  //   priceTokenGMYVault, rateWithdrawGMYVault,
  //   AmountGLPVaultDeposit, priceTokenGLPVault, rateWithdrawGLPVault,
  //   AmountGMY_WFTMVaultDeposit, priceStatsGMYWFTM, rateWithdrawGMY_WFTMVault
  // });
  useEffect(() => {
    fetchStatsGMYWFTM();

    const interval = setInterval(async () => {
      fetchStatsGMYWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  //REWARDS V2
  const now = useRef(moment.utc().valueOf() / 1000);
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_GM,
    isUseDefaultChain: false,
  });

  const {
    pendingTokens,
    stakedGlp,
    glpPoolInfo: [totalStaked],
    claimable: [claimable],
    getTotalVested: [totalVested],
    vestingDuration: [vestingDuration],
    getUnlockedVestingAmount: [unlockedVestingAmount],
    lockedVestingAmounts: [lockedVestingAmount],
    lockedVestingAmounts: [lockedVestingAmounts],
    poolRewardsPerSec,
  } = useGMYFarmContractMulticall(chainId, active, account, library);
  const { poolInfo } = usePoolRewardersVGMY(chainId, active, account, library);
  const { price: gmPrice, totalSupply: totalSupplyGm } = useInfoGm(chainId);

  const [amountStaked] = stakedGlp;
  const amountStakedUsd = useUsdPrice(amountStaked, gmPrice, 30);
  const claimableUsd = useUsdPrice(claimable, gmyPrice, 30);

  const [, symbols, decimals, amounts] = pendingTokens;
  const rewards = useMemo(() => {
    if (!symbols || !symbols.length || !decimals || !decimals.length || !amounts || !amounts.length) {
      return [];
    }

    const _rewards = symbols.reduce((acc, cur, index) => {
      const inUsd = rewardsPrice?.[symbols[index]]?.price
        ? amounts[index]
            ?.mul(parseValue(rewardsPrice?.[symbols[index]]?.price, rewardsPrice?.[symbols[index]]?.decimal))
            ?.div(expandDecimals(1, rewardsPrice[symbols[index]]?.decimal))
        : undefined;
      return [
        ...acc,
        {
          symbol: symbolRender[cur] || cur,
          decimal: Number(decimals[index]),
          amount: amounts[index],
          inUsd: amounts[index].eq(0) ? 0 : inUsd,
          tooltip: tooltipTexts[cur] || "",
        },
      ];
    }, []);

    //Check if have duplicate reward
    const rewards = {};

    for (const reward of _rewards) {
      if (rewards.hasOwnProperty(reward.symbol)) {
        const inUsd =
          rewards[reward.symbol].inUsd !== undefined && reward.inUsd !== undefined
            ? BigNumber.from(rewards[reward.symbol].inUsd).add(reward.inUsd)
            : undefined;

        rewards[reward.symbol] = {
          amount: rewards[reward.symbol].amount.add(reward.amount),
          inUsd,
          symbol: reward.symbol,
          decimal: reward.decimal,
          tooltip: reward.tooltip,
        };
      } else {
        rewards[reward.symbol] = {
          amount: reward.amount,
          inUsd: reward.inUsd,
          symbol: reward.symbol,
          decimal: reward.decimal,
          tooltip: reward.tooltip,
        };
      }
    }

    return Object.values(rewards);
  }, [amounts, decimals, rewardsPrice, symbols]);

  const totalRewardUsd = !account
    ? 0
    : Object.values(rewards).reduce((acc, cur) => {
        return acc + parseFloat(formatAmount(cur.inUsd, Number(cur.decimal), Number(cur.decimal)));
      }, 0);
  //  + Number(formatAmount(claimableUsd, 18, 18, false));

  const totalStakedUsd = useUsdPrice(totalStaked, gmPrice, 30);
  // const apr = useMemo(() => {
  //   if (totalStakedUsd) {
  //     const [, rewardSymbols, RewardDecimals, rewardPerSecs] = poolRewardsPerSec;
  //     const _totalStakedInDollar = parseFloat(formatAmountFree(totalStakedUsd, Number(18), Number(18))) || 1;

  //     return rewardPerSecs.reduce((acc, cur, index) => {
  //       const rewardPrice = rewardsPrice[rewardSymbols[index]]?.price;

  //       const _rewardPerSec = parseFloat(
  //         formatAmountFree(cur, Number(RewardDecimals[index]), Number(RewardDecimals[index]))
  //       );
  //       const apr = ((+rewardPrice * _rewardPerSec * SECONDS_PER_YEAR) / _totalStakedInDollar) * 100;

  //       return acc + apr;
  //     }, 0);
  //   }
  // }, [poolRewardsPerSec, rewardsPrice, totalStakedUsd]);

  const apr = useGmApr({
    gmPrice: gmPrice,
    rewardsPrice,
    totalStaked,
    poolRewardsPerSec,
  });

  //Use Contracts
  const { balanceOf: balanceOfGm, decimals: gmDecimal } = useGmContract(chainId, active, account);
  const { totalSupply: totalSupplyVGMY } = useVGMYContract(chainId, active, account);

  const totalSupplyGmUsd = useUsdPrice(totalSupplyGm, gmPrice, 30);
  const totalSupplyVGMYUsd = useUsdPrice(totalSupplyVGMY, gmyPrice, 30);

  const vestingUsd = useUsdPrice(
    vestingData?.glpVesterVestedAmount && vestingData?.gmyVesterVestedAmount
      ? vestingData.glpVesterVestedAmount.add(vestingData.gmyVesterVestedAmount)
      : undefined,
    gmyPrice,
    30
  );

  let { total: totalGmyStaked } = useTotalGmyStaked();
  let { total: totalEsGmyStaked } = useTotalEsGmyStaked();

  let aprTotalGlp;
  let apyGlp;
  aprTotalGlp = parseFloat(formatAmountFree(processedData?.glpAprTotal, 2));
  apyGlp = aprTotalGlp ? formatNumber(caculateAPYHourly(aprTotalGlp, 4080), 2) : null;

  let aprTotalGmy;
  let apyGmy;
  aprTotalGmy = parseFloat(formatAmountFree(processedData?.gmyAprTotal, 2));
  apyGmy = aprTotalGmy ? formatNumber(caculateAPYHourly(aprTotalGmy, 4080), 2) : null;

  const isVestFinished =
    unlockedVestingAmount && totalVested && unlockedVestingAmount.eq(totalVested) && unlockedVestingAmount.gt(0);

  //dailyAvgReward = vGMY user vested / durationVesting (day unit)
  const dailyAvgReward = useMemo(() => {
    if (vestingDuration && lockedVestingAmount) {
      const vestingDurationByDayUnit = Number(vestingDuration) / SECONDS_PER_DAY;
      const result = vestingDurationByDayUnit
        ? lockedVestingAmount.mul(parseValue(1, 18)).div(parseValue(vestingDurationByDayUnit, 18))
        : undefined;
      return result;
    }
  }, [lockedVestingAmount, vestingDuration]);

  let vGMYvestingAmount;
  if (lockedVestingAmounts && unlockedVestingAmount) {
    vGMYvestingAmount = lockedVestingAmounts.sub(unlockedVestingAmount);
  }
  const vGMYvestingAmountUsd = useUsdPrice(vGMYvestingAmount, gmyPrice, 30);

  return (
    <div className={`earn-content-container OverviewV2 ${lightThemeClassName}`}>
      <div className="tab-content-title">Overview</div>
      <div className="tab-content-desc">
        By staking GMY, GLP or GM tokens on the Fantom, Optimism, Arbitrum and Base networks, you can earn protocol
        income and rewards. <ExternalLink href="https://docs.yummy.fi/rewards">Learn More</ExternalLink>.
      </div>
      <div className="tab-main-content overview">
        <div className="main-content left-content">
          <div className="left-box glp">
            <BorderIcon fill="#A593FD" />
            <div className="left-box-heading">
              <div className="left-box-token">
                <img src={glp_coin} alt="glp_coin" />
                <div className="left-box-token-info">
                  <div className="left-box-token-info-symbol">Yummy Liquidity Pool - GLP</div>
                  <div className="left-box-token-info-price">
                    Price:{" "}
                    {processedData.glpPrice ? (
                      <span className="value">
                        ${formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true)}
                      </span>
                    ) : (
                      <span className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
              </div>
              <Link className="view-btn border-btn" to="/earn-v2/glp">
                View details
              </Link>
            </div>
            <div className="left-box-body">
              <div className="left-box-body-left">
                <div className="left-box-body-left-box">
                  <div className="left-box-body-left-box-title">APR</div>
                  <div className="left-box-body-left-box-value">
                    {processedData.glpAprTotal ? (
                      <Tooltip
                        className="tooltip-apr"
                        position={isTablet ? "right-bottom" : undefined}
                        handle={
                          <div style={{ color: "#C1FF99" }}>
                            {formatKeyAmount(processedData, "glpAprTotal", 2, 2, true)}%
                          </div>
                        }
                        renderContent={() => {
                          return (
                            <>
                              <StatsTooltipRow
                                label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                                value={`${formatKeyAmount(processedData, "glpAprForNativeToken", 2, 2, true)}%`}
                                showDollar={false}
                              />
                              <StatsTooltipRow
                                label="Escrowed GMY APR"
                                value={`${formatKeyAmount(processedData, "glpAprForEsGmy", 2, 2, true)}%`}
                                showDollar={false}
                              />
                              <div style={{ marginTop: "8px", fontWeight: 400 }}>
                                <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                              </div>
                            </>
                          );
                        }}
                      />
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                {SUPPORTED_CHAIN_ID_VAULT.includes(walletChainId) && (
                  <div className="left-box-body-left-box">
                    <div className="left-box-body-left-box-title">Vault APY</div>
                    <div className="left-box-body-left-box-value">
                      {apyGlp ? (
                        `${apyGlp}%`
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="left-box-body-right">
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total supply</div>
                  <div className="left-box-body-right-row-value">
                    {processedData.glpSupply ? (
                      <div className="value">
                        {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} GLP{" "}
                        <span>${formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0), 2)}</span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total staked</div>
                  <div className="left-box-body-right-row-value">
                    {processedData.glpSupply ? (
                      <div className="value">
                        {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} GLP{" "}
                        <span>${formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0), 2)}</span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">
                    Your staked{" "}
                    {processedData.glpBalance && processedData.glpBalance.gt(0) && (
                      <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                    )}
                  </div>
                  <div className="left-box-body-right-row-value">
                    {processedData.glpBalance ? (
                      <div className="value">
                        {formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} GLP{" "}
                        <span>
                          ${formatNumber(formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2), 2)}
                        </span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Link className="view-btn-mobile border-btn full" to="/earn-v2/glp">
              View details
            </Link>
          </div>
          {ACTIVE_CHAIN_IDS_V2.includes(walletChainId) && (
            <div className="left-box gm">
              <BorderIcon fill="#22D7AC" />
              <div className="left-box-heading">
                <div className="left-box-token">
                  <img src={msp_ic} alt="msp_ic" />
                  <div className="left-box-token-info">
                    <div className="left-box-token-info-symbol">Yummy Stable Liquidity Pool - GM</div>
                    <div className="left-box-token-info-price">
                      Price:{" "}
                      <span>
                        {" "}
                        {gmPrice ? (
                          `$${formatAmount(gmPrice, 30, 3)}`
                        ) : (
                          <span className="skeleton-box" style={{ width: "60px" }} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <Link className="view-btn border-btn" to="/earn-v2/gm">
                  View details
                </Link>
              </div>
              <div className="left-box-body">
                <div className="left-box-body-left">
                  <div className="left-box-body-left-box">
                    <div className="left-box-body-left-box-title">APR</div>
                    <div className="left-box-body-left-box-value">
                      {apr && apr.total !== undefined && !isNaN(apr.total) ? (
                        <>
                          {/* {apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}% */}
                          {Object.keys(omit(apr, "total")).length === 1 ? (
                            <>{apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}%`}</>
                          ) : (
                            <Tooltip
                              handle={`${apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}%`}
                              position={isTablet && "right-bottom"}
                              renderContent={() =>
                                Object.entries(apr).map(
                                  ([key, value]) =>
                                    key !== "total" && (
                                      <StatsTooltipRow
                                        key={key}
                                        label={SYMBOL_BY_ADDRESS_REWARDS[chainId][key.toLowerCase()] + " APR"}
                                        showDollar={false}
                                        value={`${value === 0 ? "0.000" : `${formatNumber(value, 2)}`}%`}
                                      />
                                    )
                                )
                              }
                            />
                          )}
                        </>
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px" }} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="left-box-body-right">
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">Total supply</div>
                    <div className="left-box-body-right-row-value">
                      {totalSupplyGm ? (
                        <>
                          {formatAmount(totalSupplyGm, gmDecimal, 0, true)} GM{" "}
                          <span>${formatNumber(formatAmount(totalSupplyGmUsd, gmDecimal, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">Total staked</div>
                    <div className="left-box-body-right-row-value">
                      {totalStaked ? (
                        <>
                          {formatAmount(totalStaked, gmDecimal, 0, true)} GM{" "}
                          <span>${formatNumber(formatAmount(totalStakedUsd, gmDecimal, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">
                      Your staked{" "}
                      {amountStaked && amountStaked.gt(0) && (
                        <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                      )}
                    </div>
                    <div className="left-box-body-right-row-value">
                      {amountStaked ? (
                        <>
                          {formatAmount(amountStaked, gmDecimal, 2, true)} GM{" "}
                          <span>${formatNumber(formatAmount(amountStakedUsd, gmDecimal, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Link className="view-btn-mobile border-btn full" to="/earn-v2/gm">
                View details
              </Link>
            </div>
          )}
          <div className="left-box gmy">
            <BorderIcon fill="#A593FD" />
            <div className="left-box-heading">
              <div className="left-box-token">
                <img src={gmy_coin} alt="gmy_coin" />
                <div className="left-box-token-info">
                  <div className="left-box-token-info-symbol">Yummy Governance Token - GMY</div>
                  <div className="left-box-token-info-price">
                    Price:{" "}
                    <span>
                      {gmyPrice ? (
                        "$" + formatAmount(gmyPrice, USD_DECIMALS, 3, true)
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <Link className="view-btn border-btn" to="/earn-v2/gmy-esgmy">
                View details
              </Link>
            </div>
            <div className="left-box-body">
              <div className="left-box-body-left">
                <div className="left-box-body-left-box">
                  <div className="left-box-body-left-box-title">APR</div>
                  <div className="left-box-body-left-box-value">
                    {processedData.gmyAprTotalWithBoost ? (
                      <Tooltip
                        className="tooltip-apr"
                        position={isTablet ? "right-bottom" : undefined}
                        handle={
                          <div style={{ color: "#C1FF99" }}>
                            {" "}
                            {formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%
                          </div>
                        }
                        renderContent={() => (
                          <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                        )}
                      />
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                {SUPPORTED_CHAIN_ID_VAULT.includes(walletChainId) && (
                  <div className="left-box-body-left-box">
                    <div className="left-box-body-left-box-title">Vault APY</div>
                    <div className="left-box-body-left-box-value">
                      {apyGmy ? (
                        `${apyGmy}%`
                      ) : (
                        <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="left-box-body-right">
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total supply</div>
                  <div className="left-box-body-right-row-value">
                    {totalGmySupply ? (
                      <>
                        {formatAmount(totalGmySupply, 18, 0, true)} GMY{" "}
                        <span>${formatNumber(formatAmount(totalSupplyUsd, USD_DECIMALS, 0), 2)}</span>
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total staked</div>
                  <div className="left-box-body-right-row-value">
                    {totalGmyStaked ? (
                      <>
                        {formatAmount(totalGmyStaked, 18, 0, true)} GMY{" "}
                        <span>${formatNumber(formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 2), 2)}</span>
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">
                    Your staked{" "}
                    {processedData?.gmyInStakedGmy && processedData?.gmyInStakedGmy.gt(0) && (
                      <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                    )}
                  </div>
                  <div className="left-box-body-right-row-value">
                    {processedData?.gmyInStakedGmy ? (
                      <div className="value">
                        {formatKeyAmount(processedData, "gmyInStakedGmy", 18, 2, true)} GMY{" "}
                        <span>
                          ${formatNumber(formatKeyAmount(processedData, "gmyInStakedGmyUsd", USD_DECIMALS, 2), 2)}
                        </span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Link className="view-btn-mobile border-btn full" to="/earn-v2/gmy-esgmy">
              View details
            </Link>
          </div>
          <div className="left-box esgmy">
            <BorderIcon fill="#74E3CF" />
            <div className="left-box-heading">
              <div className="left-box-token">
                <img src={esgmy_coin} alt="esgmy_coin" />
                <div className="left-box-token-info">
                  <div className="left-box-token-info-symbol">Yummy Escrow Token - esGMY</div>
                  <div className="left-box-token-info-price">
                    Price:{" "}
                    <span>
                      {gmyPrice ? (
                        "$" + formatAmount(gmyPrice, USD_DECIMALS, 3, true)
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <Link className="view-btn border-btn" to="/earn-v2/gmy-esgmy">
                View details
              </Link>
            </div>
            <div className="left-box-body">
              <div className="left-box-body-left">
                <div className="left-box-body-left-box">
                  <div className="left-box-body-left-box-title">APR</div>
                  <div className="left-box-body-left-box-value">
                    {processedData.gmyAprTotalWithBoost ? (
                      <Tooltip
                        className="tooltip-apr"
                        position={isTablet ? "right-bottom" : undefined}
                        handle={
                          <div style={{ color: "#C1FF99" }}>
                            {" "}
                            {formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%
                          </div>
                        }
                        renderContent={() => (
                          <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                        )}
                      />
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="left-box-body-right">
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total supply</div>
                  <div className="left-box-body-right-row-value">
                    {esGmySupply ? (
                      <>
                        {formatAmount(esGmySupply, 18, 0, true)} esGMY{" "}
                        <span>${formatNumber(formatAmount(esGmySupplyUsd, USD_DECIMALS, 0), 2)}</span>
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">Total staked</div>
                  <div className="left-box-body-right-row-value">
                    {totalEsGmyStaked ? (
                      <>
                        {formatAmount(totalEsGmyStaked, 18, 0, true)} esGMY{" "}
                        <span>${formatNumber(formatAmount(stakedEsGmySupplyUsd, USD_DECIMALS, 0), 2)}</span>
                      </>
                    ) : (
                      <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">
                    Your staked{" "}
                    {processedData?.esGmyInStakedGmy && processedData?.esGmyInStakedGmy.gt(0) && (
                      <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                    )}
                  </div>
                  <div className="left-box-body-right-row-value">
                    {processedData?.esGmyInStakedGmy ? (
                      <div className="value">
                        {formatKeyAmount(processedData, "esGmyInStakedGmy", 18, 2, true)} esGMY{" "}
                        <span>
                          ${formatNumber(formatKeyAmount(processedData, "esGmyInStakedGmyUsd", USD_DECIMALS, 2), 2)}
                        </span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="left-box-body-right-row">
                  <div className="left-box-body-right-row-title">
                    Your vesting{" "}
                    {vestingData?.glpVesterVestedAmount &&
                      vestingData?.gmyVesterVestedAmount &&
                      vestingData?.glpVesterVestedAmount.add(vestingData?.gmyVesterVestedAmount).gt(0) && (
                        <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                      )}
                  </div>
                  <div className="left-box-body-right-row-value">
                    {vestingData?.glpVesterVestedAmount && vestingData?.gmyVesterVestedAmount ? (
                      <div className="value">
                        {formatAmount(
                          vestingData?.glpVesterVestedAmount.add(vestingData?.gmyVesterVestedAmount),
                          18,
                          2,
                          true
                        )}{" "}
                        esGMY <span>${formatAmount(vestingUsd, 18, 2, true)}</span>
                      </div>
                    ) : (
                      <div className="value">
                        <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Link className="view-btn-mobile border-btn full" to="/earn-v2/gmy-esgmy">
              View details
            </Link>
          </div>
          {ACTIVE_CHAIN_IDS_VGMY.includes(walletChainId) && (
            <div className="left-box vgmy">
              <BorderIcon fill="#FCD364" />
              <div className="left-box-heading">
                <div className="left-box-token">
                  <img src={vgmy_coin} alt="vgmy_coin" />
                  <div className="left-box-token-info">
                    <div className="left-box-token-info-symbol">Yummy Vesting Token - vGMY</div>
                    <div className="left-box-token-info-price">
                      Price:{" "}
                      <span>
                        {gmyPrice ? (
                          "$" + formatAmount(gmyPrice, USD_DECIMALS, 3, true)
                        ) : (
                          <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <Link className="view-btn border-btn" to="/earn-v2/gm">
                  View details
                </Link>
              </div>
              <div className="left-box-body">
                <div className="left-box-body-left">
                  <div className="left-box-body-left-box">
                    <div className="left-box-body-left-box-title">Daily Avg. Reward</div>
                    <div className="left-box-body-left-box-value">
                      {isVestFinished ? "0.00" : formatAmount(dailyAvgReward, 18, 2)} GMY
                    </div>
                  </div>
                </div>
                <div className="left-box-body-right">
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">Total supply</div>
                    <div className="left-box-body-right-row-value">
                      {totalSupplyVGMY ? (
                        <>
                          {formatAmount(totalSupplyVGMY, 18, 0, true)} vGMY{" "}
                          <span>${formatNumber(formatAmount(totalSupplyVGMYUsd, 18, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">
                      Your vesting{" "}
                      {vGMYvestingAmount && vGMYvestingAmount.gt(0) && (
                        <img src={isLightTheme ? active_dotLight : active_dot} alt="active_dot" className="dot" />
                      )}
                    </div>
                    <div className="left-box-body-right-row-value">
                      {vGMYvestingAmount ? (
                        <>
                          {formatAmount(vGMYvestingAmount, 18, 2)} vGMY{" "}
                          <span>${formatNumber(formatAmount(vGMYvestingAmountUsd, 18, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                  <div className="left-box-body-right-row">
                    <div className="left-box-body-right-row-title">Claimable amount</div>
                    <div className="left-box-body-right-row-value">
                      {claimable ? (
                        <>
                          {formatAmount(claimable, 18, 2)} GMY{" "}
                          <span>${formatNumber(formatAmount(claimableUsd, 18, 2), 2)}</span>
                        </>
                      ) : (
                        <span className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Link className="view-btn-mobile border-btn full" to="/earn-v2/gm">
                View details
              </Link>
            </div>
          )}
        </div>
        <div className="main-content right-content">
          <div className="right-box right-box-top">
            <div className="right-box-heading">
              <div className="right-box-heading-title">General Rewards</div>
              <div className="right-box-heading-reward">
                Pending rewards{" "}
                <div>
                  {processedData.totalRewardsUsd ? (
                    `$${formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, 2, true)}`
                  ) : (
                    <div
                      className="skeleton-box"
                      style={{
                        height: 33.6,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="right-box-rows">
              <div className="right-box-row">
                <div className="right-box-row-title">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                <div className="right-box-row-value">
                  {processedData.totalNativeTokenRewards ? (
                    <div className="value native-token-reward">
                      <TooltipHTML
                        position="right-bottom"
                        className="nowrap"
                        handle={`${formatKeyAmount(
                          processedData,
                          "totalNativeTokenRewards",
                          18,
                          4,
                          true
                        )} <span class="reward-dollar">$${formatKeyAmount(
                          processedData,
                          "totalNativeTokenRewardsUsd",
                          USD_DECIMALS,
                          2,
                          true
                        )}</span>`}
                        renderContent={() => (
                          <>
                            <StatsTooltipRow
                              label={`GMY & esGMY`}
                              showDollar={false}
                              value={`${formatKeyAmount(
                                processedData,
                                "feeGmyTrackerRewards",
                                18,
                                4,
                                true
                              )} ($${formatKeyAmount(
                                processedData,
                                "feeGmyTrackerRewardsUsd",
                                USD_DECIMALS,
                                2,
                                true
                              )})`}
                            />
                            <StatsTooltipRow
                              label={`GLP`}
                              showDollar={false}
                              value={`${formatKeyAmount(
                                processedData,
                                "feeGlpTrackerRewards",
                                18,
                                4,
                                true
                              )} ($${formatKeyAmount(
                                processedData,
                                "feeGlpTrackerRewardsUsd",
                                USD_DECIMALS,
                                2,
                                true
                              )})`}
                            />
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="right-box-row">
                <div className="right-box-row-title">GMY</div>
                <div className="right-box-row-value">
                  {processedData.totalVesterRewards ? (
                    <div className="value native-token-reward">
                      <TooltipHTML
                        position="right-bottom"
                        className="nowrap"
                        handle={`${formatKeyAmount(
                          processedData,
                          "totalVesterRewards",
                          18,
                          4,
                          true
                        )} <span class="reward-dollar">$${formatKeyAmount(
                          processedData,
                          "totalVesterRewardsUsd",
                          USD_DECIMALS,
                          2,
                          true
                        )}</span>`}
                        renderContent={() => (
                          <>
                            <StatsTooltipRow
                              label={`GMY Vault`}
                              showDollar={false}
                              value={`${formatKeyAmount(
                                processedData,
                                "gmyVesterRewards",
                                18,
                                4,
                                true
                              )} ($${formatKeyAmount(processedData, "gmyVesterRewardsUsd", USD_DECIMALS, 2, true)})`}
                            />
                            <StatsTooltipRow
                              label={`GLP Vault`}
                              showDollar={false}
                              value={`${formatKeyAmount(processedData, "glpVesterRewards", 18, 4)} ($${formatKeyAmount(
                                processedData,
                                "glpVesterRewardsUsd",
                                USD_DECIMALS,
                                2,
                                true
                              )})`}
                            />
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="right-box-row">
                <div className="right-box-row-title">esGMY</div>
                <div className="right-box-row-value">
                  {processedData.totalEsGmyRewards ? (
                    <div className="value native-token-reward">
                      <TooltipHTML
                        position="right-bottom"
                        className="nowrap"
                        handle={`${formatKeyAmount(
                          processedData,
                          "totalEsGmyRewards",
                          18,
                          4,
                          true
                        )} <span class="reward-dollar">$${formatKeyAmount(
                          processedData,
                          "totalEsGmyRewardsUsd",
                          USD_DECIMALS,
                          2,
                          true
                        )}</span>`}
                        renderContent={() => (
                          <>
                            <StatsTooltipRow
                              label={`GMY & esGMY`}
                              showDollar={false}
                              value={`${formatKeyAmount(
                                processedData,
                                "stakedGmyTrackerRewards",
                                18,
                                4,
                                true
                              )} ($${formatKeyAmount(
                                processedData,
                                "stakedGmyTrackerRewardsUsd",
                                USD_DECIMALS,
                                2,
                                true
                              )})`}
                            />
                            <StatsTooltipRow
                              label={`GLP`}
                              showDollar={false}
                              value={`${formatKeyAmount(
                                processedData,
                                "stakedGlpTrackerRewards",
                                18,
                                4
                              )} ($${formatKeyAmount(
                                processedData,
                                "stakedGlpTrackerRewardsUsd",
                                USD_DECIMALS,
                                2,
                                true
                              )})`}
                            />
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div className="value">
                      <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="right-box-row">
                <div className="right-box-row-title">Multiplier Points</div>
                <div className="right-box-row-value">
                  {processedData?.bonusGmyTrackerRewards ? (
                    `${formatKeyAmount(processedData, "bonusGmyTrackerRewards", 18, 4, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  )}
                </div>
              </div>
              <div className="right-box-row">
                <div className="right-box-row-title">Staked Multiplier Points</div>
                <div className="right-box-row-value">
                  {processedData?.bnGmyInFeeGmy ? (
                    `${formatKeyAmount(processedData, "bnGmyInFeeGmy", 18, 4, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  )}
                </div>
              </div>
            </div>
            {account && (
              <div className="actions">
                <button
                  className="default-btn"
                  disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                  onClick={() => setIsCompoundModalVisible(true)}
                >
                  Compound
                </button>
                <button
                  className="view-btn border-btn"
                  onClick={() => setIsClaimModalVisible(true)}
                  disabled={!processedData?.totalRewardsUsd || processedData.totalRewardsUsd.eq(0)}
                >
                  Claim
                </button>
              </div>
            )}
            {!account && (
              <button className="default-btn full" onClick={connectWallet}>
                Connect Wallet
              </button>
            )}
          </div>
          {ACTIVE_CHAIN_IDS_V2.includes(walletChainId) && (
            <div className="right-box right-box-bottom">
              <div className="right-box-heading">
                <div className="right-box-heading-title">GM Pool Rewards</div>
                <div className="right-box-heading-reward">
                  Pending rewards{" "}
                  <div>
                    {totalRewardUsd === undefined ? (
                      <div
                        className="skeleton-box"
                        style={{
                          height: 33.6,
                        }}
                      />
                    ) : (
                      <>${formatAmount(parseValue(totalRewardUsd * 1e18, 12), 30, 2, true)}</>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-box-rows">
                {/* {Object.entries(rewards).length > 0 && (
                  <>
                    {Object.entries(rewards).map(([address, rw]) => {
                      return (
                        <div className="right-box-row" key={address}>
                          <div className="right-box-row-title">{rw.symbol + " Rewards" || "..."}</div>
                          <div className="right-box-row-value">
                            <TooltipHTML
                              // position={isTablet ? "center-top" : "right-top"}
                              className="fit-content custom"
                              position="right-top"
                              handle={`${formatAmount(
                                rw.amount,
                                rw.decimal,
                                8,
                                true
                              )} <span class="reward-dollar">$${formatAmount(rw.inUsd, rw.decimal, 8, true)}</span>`}
                              renderContent={() => (
                                <>
                                  {Object.entries(rw.pools).map(([pool, [amount, amountInUsd]]) => {
                                    const poolName = pool.replaceAll("-", " ");
                                    return (
                                      <>
                                        {rw.symbol} from {poolName} staking.
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )} */}
                {rewards.length > 0 &&
                  rewards.map((rw) => {
                    return (
                      <div className="right-box-row" key={rw.symbol}>
                        <div className="right-box-row-title">{rw.symbol + " Rewards" || "..."}</div>
                        <div className="right-box-row-value">
                          <TooltipHTML
                            // position={isTablet ? "center-top" : "right-top"}
                            className="fit-content custom"
                            position="right-top"
                            handle={`${formatAmount(
                              rw.amount,
                              rw.decimal,
                              4,
                              true
                            )} <span class="reward-dollar">$${formatAmount(rw.inUsd, rw.decimal, 2, true)}</span>`}
                            renderContent={() => rw.tooltip}
                          />
                        </div>
                      </div>
                    );
                  })}
                {rewards.length === 0 &&
                  Array(2)
                    .fill(null)
                    .map((n, i) => (
                      <>
                        <div className="right-box-row" key={i}>
                          <div className="right-box-row-title">
                            <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                          </div>
                          <div className="right-box-row-value">
                            <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                          </div>
                        </div>
                      </>
                    ))}
              </div>
              {account && (
                <>
                  <div className="actions alone">
                    {poolInfo && poolInfo.startTimestamp && now.current < poolInfo.startTimestamp ? (
                      <button className="default-btn full" disabled={true}>
                        Rewards start in{" "}
                        <Countdown
                          key={poolInfo.startTimestamp}
                          date={new Date(poolInfo.startTimestamp * 1000)}
                          renderer={countdownRenderer}
                          daysInHours
                        />
                      </button>
                    ) : (
                      <button
                        className="default-btn full"
                        disabled={totalRewardUsd === 0 || !totalRewardUsd || !poolInfo}
                        onClick={() => setIsClaimV2RewardVisible(true)}
                      >
                        Claim
                      </button>
                    )}
                  </div>
                </>
              )}
              {!account && (
                <button className="default-btn full" onClick={connectWallet}>
                  Connect Wallet
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <ClaimV2RewardModal
        isVisible={isClaimV2RewardVisible}
        setIsVisible={setIsClaimV2RewardVisible}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        totalRewardUsd={totalRewardUsd}
        rewards={rewards}
        // claimable={claimable}
        // claimableUsd={claimableUsd}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData?.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={walletChainId}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={walletChainId}
      />
    </div>
  );
};

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};

export default EarnOverviewV2;
