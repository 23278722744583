import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import {
  CLOSED_POSITION,
  OPEN_ORDER,
  OPEN_POSITION,
  SECONDS_PER_DAY,
  TRADE_ACTIVITY,
  getCompletePosition,
  getMarketTokensInfo,
  getPageTitle,
} from "lib/legacy";
import { cloneElement, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useChainId } from "lib/chains";
import { formatAmount, formatAmountInDollar, parseValue } from "lib/numbers";

import SEO from "components/Common/SEO";

import FooterV2 from "components/Footer/Footer";
import AccountProfitLossIcon from "components/Icon/AccountProfitLossIcon";
import AccountRankingIcon from "components/Icon/AccountRankingIcon";
import AccountTradesIcon from "components/Icon/AccountTradesIcon";
import AccountVolumeIcon from "components/Icon/AccountVolumeIcon";
import { getContract } from "config/contracts";
import icClock from "img/clock-bg.svg";
import icClockLight from "img/ic_clockLight.svg";
import ranking from "img/portfolio/ranking.svg";
import rankingLight from "img/portfolio/rankingLight.svg";
import trade from "img/portfolio/trade.svg";
import tradeLight from "img/portfolio/tradeLight.svg";
import profitLoss from "img/portfolio/profitLoss.svg";
import profitLossLight from "img/portfolio/profitLossLight.svg";
import volume from "img/portfolio/volume.svg";
import volumeLight from "img/portfolio/volumeLight.svg";
import no_data_icon from "img/analytics/no-data-icon.svg";
import no_data_iconLight from "img/analytics/no-data-iconLight.svg";
import { contractFetcher } from "lib/contracts";

import useSWR from "swr";
import PositionsList from "components/ExchangeV2/PositionsList";
import useMarketAssets from "hooks/useMarketAssets";
import useAssetsPrices from "hooks/useAssetsPrices";

import ReaderMsp from "abis/ReaderMsp.json";
import Token from "abis/Token.json";

import useUserOrder from "hooks/useUserOrder";
import OrderListNew from "components/ExchangeV2/OrderListNew";
import ClosedPositionsList from "components/ExchangeV2/ClosedPositionsList";
import TradeActivityList from "components/ExchangeV2/TradeActivityList";
import useUserPosition from "hooks/useUserPosition";
import useUserStats from "hooks/useUserStats";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import "styles/AccountOverview.scss";
import { useThemeContext } from "contexts/ThemeProvider";
import { DataTable, SkeletonLoadingTable, TableRow, TokenHead } from "pages/Analytics/table";
import PortfolioChart from "./PortfolioChart";
import { Select } from "antd";
import downTriangleIc from "img/downTriangleIc.svg";
import downTriangleIcLight from "img/downTriangleIcLight.svg";
import LinkIcon from "components/Icon/LinkIcon";
import Tooltip from "components/Tooltip/Tooltip";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import TransactionDepositModal from "components/DepositModal";
import WithdrawModal from "components/WithdrawModal";
import { usePortfolio } from "hooks/usePortfolio";
import useUserStatsWithParams from "hooks/useUserStatsWithParams";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl } from "config/chains";

export const TAB_OPTIONS = [
  OPEN_POSITION,
  OPEN_ORDER,
  CLOSED_POSITION,
  TRADE_ACTIVITY,
  // DEPOSITS,
  // WITHDRAWS,
  // CASH_CASHOUT,
];

const FILTER_BY_OPTIONS = [
  {
    label: "All time",
    value: null,
  },
  {
    label: "24 Hours",
    value: moment().unix() - SECONDS_PER_DAY,
  },
  {
    label: "7 Days",
    value: moment().unix() - SECONDS_PER_DAY * 7,
  },
  {
    label: "1 Month",
    value: moment().unix() - SECONDS_PER_DAY * 30,
  },
];

let closePositionTempt = [];
export default function Portfolio({ setPendingTxns, connectWallet, savedSlippageAmount, savedSelectedDexes }) {
  const { active, library, account: _account } = useWeb3React();
  // const account = "0x31e40209cfcdda86baabfe93a428cc9143a9ed30";
  //FIX ME: USE REAL ACCOUNT
  //----------
  const [account, setAccount] = useState();

  useEffect(() => {
    if (!account) {
      setAccount(_account);
    }
  }, [_account, account]);
  //----------
  const { chainId } = useChainId();
  const { positions: myOrders, isLoading } = useUserOrder(account);
  const { data: dataUserStats, isLoading: isLoadingDataStats, setFrom } = useUserStatsWithParams(account, chainId);
  const { positions: positionsOnChain, loading } = useUserTradeInfo(account);
  const { positions: positionsApi } = useUserPosition(account);
  let positions = getCompletePosition(positionsOnChain, positionsApi);
  if (closePositionTempt && closePositionTempt.length > 0) {
    positions = positions.filter((x) => !closePositionTempt.find((y) => y.posId === x.posId));
  }
  const { isLightTheme, lightThemeClassName } = useThemeContext();

  const readerMspAddress = getContract(chainId, "ReaderMsp");

  const { data: userTokenBalances } = useSWR(
    active && [active, chainId, readerMspAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderMsp),
    }
  );

  // const { data: usdcBalance } = useSWR(
  //   [`User:usdcBalance:${chainId}`, chainId, getContract(chainId, "USDC"), "balanceOf", account],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  // const { data: gusdBalance } = useSWR(
  //   [`StakeV2:gusdBalance:${chainId}`, chainId, getContract(chainId, "GUSD"), "balanceOf", account],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  const TABS = [
    {
      label: "Open Positions V2",
      key: "openPositions",
      count: positions?.length || 0,
    },
    {
      label: "Open Orders V2",
      key: "orders",
      count: myOrders.length || 0,
    },
    {
      label: "Closed Positions V2",
      key: "closedPositions",
      count: dataUserStats?.closedCount || 0,
    },
    {
      label: "Trade History V2",
      key: "tradeHistories",
      count: dataUserStats?.trades || 0,
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [isWithdrawModal, setIsWithdrawModal] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [modalType, setModalType] = useState("withdraw");

  const [depositValue, setDepositValue] = useState("");
  const [withdrawValue, setWithdrawValue] = useState("");

  const marketAsssets = useMarketAssets();
  const marketAsssetList = Object.keys(marketAsssets).map((key) => marketAsssets[key]);

  const marketPrices = useAssetsPrices();
  const marketTokensInfo = getMarketTokensInfo(marketAsssetList, marketPrices);
  const { data: dataPortfolio, isLoading: isLoadingPortfolio } = usePortfolio(chainId, account);

  const [posOrders, setPosOrders] = useState();

  useEffect(() => {
    if (activeTab.key !== "orders") {
      setPosOrders(undefined);
    }
  }, [activeTab]);

  const handleShowModal = (modalType) => {
    if (modalType === "deposit") {
      setIsDepositModal(true);
      setModalType("deposit");
    }
    if (modalType === "withdraw") {
      setIsWithdrawModal(true);
      setModalType("withdraw");
    }
  };

  const renderTabSection = () => {
    return {
      openPositions: (
        <PositionsList
          setListSection={() => setActiveTab(TABS[1])}
          setPendingTxns={setPendingTxns}
          userTokenBalances={userTokenBalances}
          myOrders={myOrders}
          setPosOrders={setPosOrders}
          positions={positions}
          loading={loading}
          closePositionTempt={closePositionTempt}
        />
      ),
      orders: <OrderListNew myOrders={posOrders || myOrders} />,
      closedPositions: <ClosedPositionsList />,
      tradeHistories: <TradeActivityList />,
    }[activeTab.key];
  };

  let portfolioValue = 0;
  if (dataPortfolio.totalSize && dataPortfolio.marginUsage && dataPortfolio.freeCollateral) {
    if (dataPortfolio.marginUsage.eq(0)) {
      portfolioValue = dataPortfolio.freeCollateral;
    } else {
      portfolioValue = dataPortfolio.totalSize
        .mul(parseValue(1, 30))
        .div(dataPortfolio.marginUsage)
        .add(dataPortfolio.freeCollateral);
    }
  }

  // let leverage = 0;
  // if (dataPortfolio.totalSize && dataPortfolio.totalCollateral && !dataPortfolio.totalCollateral.eq(0)) {
  //   leverage = dataPortfolio.totalSize.mul(parseValue(1, 30)).div(dataPortfolio.totalCollateral);
  // }

  const styleTick = useMemo(() => {
    if (dataPortfolio.marginUsage) {
      const marginUsage = parseFloat(formatAmount(dataPortfolio.marginUsage, 30, 30));
      const goal = (marginUsage * 180) / 250;

      return {
        transform: `translateX(calc(-50% - 8px)) rotate(${Math.floor(goal)}deg)`,
      };
    }
    return {};
  }, [dataPortfolio.marginUsage]);

  return (
    <SEO title={getPageTitle("Portfolio")}>
      {isDepositModal && (
        <TransactionDepositModal
          chainId={chainId}
          isVisible={isDepositModal}
          setIsVisible={setIsDepositModal}
          library={library}
          isDepositModal={modalType === "deposit" ? true : false}
          savedSlippageAmount={savedSlippageAmount}
          savedSelectedDexes={savedSelectedDexes}
        />
      )}
      {isWithdrawModal && (
        <WithdrawModal
          chainId={chainId}
          isVisible={isWithdrawModal}
          setIsVisible={setIsWithdrawModal}
          library={library}
        />
      )}
      <Wrapper className={`${lightThemeClassName} Portfolio default-container page-layout`}>
        {/* <input type="text" onChange={(e) => setAccount(e.target.value)} placeholder="Custom Account" /> */}
        <div className="Portfolio-heading">
          <div className="Portfolio-heading-text">Portfolio</div>
          {active && (
            <ExternalLink className="Portfolio-heading-desc" href={`${getExplorerUrl(chainId)}address/${account}`}>
              {account} <LinkIcon width={24} height={24} />
            </ExternalLink>
          )}
        </div>
        <div className="Portfolio-summary">
          <div className="portfolio-card">
            <div className="portfolio-card-top">
              <div className="portfolio-card-box">
                <div className="portfolio-card-heading">
                  <Tooltip
                    renderContent={() => <div>Total deposited collateral + unrealized PnL after accrued fees</div>}
                    handle={"Portfolio Value"}
                    position="left-top"
                  />
                  <span className={active ? "portfolio-card-connected" : "portfolio-card-disconnected"}>
                    <div className="portfolio-card-dot" /> {active ? "Connected" : "Disconnected"}
                  </span>
                </div>
                <div className="portfolio-card-total">
                  {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 33.59, width: 100 }} />}
                  {!isLoadingPortfolio && formatAmountInDollar(portfolioValue, 30, 2, true)}
                </div>
              </div>
              <div className="portfolio-card-rows">
                <div className="portfolio-card-row">
                  <div className="portfolio-card-key">
                    <Tooltip
                      renderContent={() => <div>Total collateral value of all open positions</div>}
                      handle={"Your Collateral"}
                      position="left-bottom"
                      className="fit-content nowrap"
                    />
                  </div>
                  <div className="portfolio-card-value">
                    {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 19.59, width: 30 }} />}
                    {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.totalCollateral, 30, 2, true)}
                  </div>
                </div>
                <div className="portfolio-card-row">
                  <div className="portfolio-card-key">Free Collateral</div>
                  <div className="portfolio-card-value">
                    {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 19.59, width: 30 }} />}
                    {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.freeCollateral, 30, 2, true)}
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-card-middle">
              <div className="portfolio-card-heading">
                <Tooltip
                  renderContent={() => (
                    <div>Total open position size / Total collateral after unrealized PnL and accrued fees</div>
                  )}
                  handle={<>Margin Usage / Leverage</>}
                  position="left-top"
                  className="excep"
                />{" "}
              </div>
              <div className="portfolio-card-clock">
                <div className="clock">
                  <img src={isLightTheme ? icClockLight : icClock} alt="icClock" />
                  <div className="clock-tick" style={styleTick}>
                    <div className="clock-tick-main" />
                    <div className="clock-tick-dot" />
                  </div>
                </div>
                <div className="portfolio-card-clock-value">
                  {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 33.59, width: 160 }} />}
                  {!isLoadingPortfolio && (
                    <>
                      {formatAmount(dataPortfolio.marginUsage || 0, 30, 2, true)}% /{" "}
                      {formatAmount(dataPortfolio.marginUsage || 0, 30, 2, true)}x
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={`portfolio-card-bottom ${!active ? "inactive" : ""}`}>
              {active && (
                <>
                  <button className="default-btn bold" onClick={() => handleShowModal("deposit")}>
                    Deposit
                  </button>
                  <button className="border-btn bold" onClick={() => handleShowModal("withdraw")}>
                    Withdraw
                  </button>
                </>
              )}
              {!active && (
                <button className="default-btn bold" onClick={connectWallet}>
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
          <div className="portfolio-chart">
            {active && <PortfolioChart account={account} chainId={chainId} />}
            {!active && (
              <div className="no-record-chart">
                <img
                  src={isLightTheme ? no_data_iconLight : no_data_icon}
                  alt="no_data_icon"
                  className="no-record-icon-styled"
                />
                <span>No Data</span>
              </div>
            )}
          </div>
        </div>
        {/* <div className="Portfolio-collateral">
          <div className="Portfolio-collateral-title">Your Collateral</div>
          <DataTable $noIndex $autoHeight $rowHeightSkeleton="278.75px" className={lightThemeClassName}>
            <div className="head">
              <div className="col-name">Asset</div>
              <div className="col-name">Wallet</div>
              <div className="col-name">Your Collateral</div>
              <div className="col-name">Free Collateral</div>
              <div className="col-name" style={{ minWidth: 300 }}></div>
            </div>
            <div className="body">
              {!isLoading &&
                Array(2)
                  .fill(null)
                  ?.map((row, i) => {
                    return (
                      <TableRow noScaleOnHover key={i}>
                        <div d-label="Asset" className="col-body">
                          <TokenHead isComing={i === 2} />
                        </div>
                        <div d-label="Wallet" className="col-body">
                          <div className="col-body-content">
                            {formatAmount(usdcBalance || 0, 6, 2, true)}
                            <div className="muted not-bold">{formatAmountInDollar(usdcBalance || 0, 6, 2, true)}</div>
                          </div>
                        </div>
                        <div d-label="Your Collateral" className="col-body">
                          <div className="col-body-content">
                            {!isLoadingPortfolio && formatAmount(dataPortfolio.totalCollateral, 30, 2, true)}
                            <div className="muted not-bold">
                              {formatAmountInDollar(dataPortfolio.totalCollateral, 30, 2, true)}
                            </div>
                          </div>
                        </div>
                        <div d-label="Free Collateral" className="col-body">
                          <div className="col-body-content">
                            {!isLoadingPortfolio && formatAmount(dataPortfolio.freeCollateral, 30, 2, true)}
                            <div className="muted not-bold">
                              {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.freeCollateral, 30, 2, true)}
                            </div>
                          </div>
                        </div>
                        <div d-label="" className="col-body col-btns" style={{ minWidth: 300 }}>
                          {active && (
                            <div className="btns">
                              <button className="default-btn bold" onClick={() => handleShowModal("deposit")}>
                                Deposit
                              </button>
                              <button className="border-btn bold" onClick={() => handleShowModal("withdraw")}>
                                Withdraw
                              </button>
                            </div>
                          )}
                          {!active && (
                            <div className="btns">
                              <button className="default-btn bold" onClick={connectWallet}>
                                Connect Wallet
                              </button>
                            </div>
                          )}
                        </div>
                      </TableRow>
                    );
                  })}
            </div>
            {isLoading && <SkeletonLoadingTable rowNumber={1} noHeading />}
          </DataTable>
        </div> */}
        <div className="Portfolio-history">
          <div className="Portfolio-history-heading">
            <div className="Portfolio-history-title">History</div>
            <Select1
              showArrow
              suffixIcon={<img src={isLightTheme ? downTriangleIcLight : downTriangleIc} alt="" />}
              onChange={setFrom}
              defaultValue={FILTER_BY_OPTIONS[0]}
              bordered={false}
              options={FILTER_BY_OPTIONS}
              popupClassName={lightThemeClassName}
            />
          </div>
          <div className="Portfolio-history-container">
            <div className="Portfolio-history-item">
              <div className="Portfolio-history-item-icon">
                <img src={isLightTheme ? rankingLight : ranking} alt="Portfolio-history-item-img" />
              </div>
              <div className="Portfolio-history-item-info">
                <div className="Portfolio-history-item-title">Ranking</div>
                <div className="Portfolio-history-item-value">{dataUserStats?.top || "--"}</div>
              </div>
            </div>
            <div className="Portfolio-history-item">
              <div className="Portfolio-history-item-icon">
                <img src={isLightTheme ? tradeLight : trade} alt="Portfolio-history-item-img" />
              </div>
              <div className="Portfolio-history-item-info">
                <div className="Portfolio-history-item-title">Trades</div>
                <div className="Portfolio-history-item-value">{dataUserStats?.trades || "0"}</div>
              </div>
            </div>
            <div className="Portfolio-history-item">
              <div className="Portfolio-history-item-icon">
                <img src={isLightTheme ? profitLossLight : profitLoss} alt="Portfolio-history-item-img" />
              </div>
              <div className="Portfolio-history-item-info">
                <div className="Portfolio-history-item-title">Profit / Loss</div>
                <div className="Portfolio-history-item-value">
                  {dataUserStats?.profitLoss && dataUserStats?.profitLoss.includes("-") ? "-" : ""}$
                  {dataUserStats?.profitLoss
                    ? formatAmount(dataUserStats?.profitLoss, 30, 2, true).replace("-", "")
                    : "0.00"}
                </div>
              </div>
            </div>
            <div className="Portfolio-history-item">
              <div className="Portfolio-history-item-icon">
                <img src={isLightTheme ? volumeLight : volume} alt="Portfolio-history-item-img" />
              </div>
              <div className="Portfolio-history-item-info">
                <div className="Portfolio-history-item-title">Volume</div>
                <div className="Portfolio-history-item-value">
                  {dataUserStats?.volume
                    ? "$" + formatAmount(dataUserStats?.volume, 30, 2, true).replace("-")
                    : "$0.00"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`Portfolio-positions exchange-v2 ${lightThemeClassName}`}>
          <div className="Portfolio-tabs">
            {TABS.map((tab) => {
              return (
                <div
                  className={cx("tab", {
                    active: activeTab.key === tab.key,
                  })}
                  onClick={() => setActiveTab(tab)}
                  key={tab.key}
                >
                  {tab.label} {tab.count ? `(${tab.count})` : null}
                </div>
              );
            })}
          </div>
          <div className="Portfolio-tab-content">
            {cloneElement(renderTabSection(), {
              marketAsssets,
              account,
              chainId,
              marketTokensInfo,
              library,
              active,
            })}
          </div>
        </div>
      </Wrapper>
      <FooterV2 />
    </SEO>
  );
}

const Select1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 40px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: var(--Base-Unit-XS-8, 8px);
    border: 1px solid var(--Border, rgba(255, 255, 255, 0.1)) !important;
    background-color: var(--Nature-1, #191b2e) !important;
  }
`;

const Wrapper = styled.div`
  padding-top: 48px;

  .Portfolio-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 24px;

    .Portfolio-heading-text {
      font-size: 36px;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }

    .Portfolio-heading-desc {
      display: flex;
      align-items: center;
      text-decoration: none;

      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      cursor: pointer;

      &:hover {
        color: #c1ff99;

        svg {
          g {
            opacity: 1;
          }

          path {
            fill: #c1ff99;
          }
        }
      }
    }
  }

  .Portfolio-summary {
    display: grid;
    grid-template-columns: 423px 1fr;
    gap: 24px;

    .portfolio-card {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 16px;
      background: var(--Linear, linear-gradient(116deg, #18235d 1.03%, #191b2e 100%));

      & > * {
        display: flex;
        padding: 24px;
      }

      .portfolio-card-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        .Tooltip-handle {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .portfolio-card-connected,
        .portfolio-card-disconnected {
          color: var(--Primary, #c1ff99);
          font-size: 14px;
          font-weight: 500;
          line-height: 120%; /* 16.8px */
          display: flex;
          align-items: center;
          gap: var(--Base-Unit-XS-8, 8px);

          .portfolio-card-dot {
            width: var(--Base-Unit-XS-8, 8px);
            height: var(--Base-Unit-XS-8, 8px);
            background-color: #c1ff99;
            border-radius: 50%;
          }
        }

        .portfolio-card-disconnected {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));

          .portfolio-card-dot {
            background-color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
          }
        }
      }

      .portfolio-card-top {
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
        border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));

        .portfolio-card-box {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 8px;
        }

        .portfolio-card-total {
          color: var(--Primary, #c1ff99);
          font-size: 24px;
          font-weight: 700;
          line-height: 140%; /* 33.6px */
        }

        .portfolio-card-rows {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Base-Unit-S-12, 12px);
          align-self: stretch;

          .portfolio-card-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .portfolio-card-key {
              color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */

              .Tooltip-handle {
                color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
                font-size: 14px;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
              }
            }

            .portfolio-card-value {
              font-size: 14px;
              font-weight: 700;
              line-height: 140%; /* 19.6px */
            }
          }
        }
      }

      .portfolio-card-middle {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));

        .portfolio-card-clock {
          display: flex;
          align-items: center;
          gap: var(--Base-Unit-S-12, 12px);

          .clock {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 50%;
              transform: translateX(-50%);
              width: 30px;
              height: 19px;
              background-color: #172152;
              border-radius: 10rem 10rem 0 0;
            }

            .clock-tick {
              display: flex;
              align-items: center;
              transform-origin: right;

              width: fit-content;
              position: absolute;
              bottom: -2px;
              left: 50%;
              transform: translateX(calc(-50% - 8px)) rotate(0deg);
              z-index: 1;

              .clock-tick-main {
                height: 2px;
                width: 12px;
                background-color: white;
              }

              .clock-tick-dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: white;
              }
            }
          }

          .portfolio-card-clock-value {
            font-size: 24px;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
          }
        }
      }

      .portfolio-card-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        &.inactive {
          grid-template-columns: 1fr;
        }
      }
    }

    .portfolio-chart {
      .no-record-chart {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
        border-radius: 16px;

        @media screen and (max-width: 767px) {
          padding: 16px;
        }
      }
    }
  }

  .Portfolio-collateral {
    margin-top: 72px;

    .Portfolio-collateral-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      margin-bottom: 16px;
    }
  }

  .Portfolio-history {
    margin: 72px 0 24px;

    .Portfolio-history-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-bottom: 16px;

      .Portfolio-history-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
      }
    }

    .Portfolio-history-container {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .Portfolio-history-item {
        display: flex;
        padding: 24px;
        align-items: center;
        gap: var(--Base-Unit-S-12, 12px);
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--Nature-1, #191b2e);

        .Portfolio-history-item-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Base-Unit-XS-8, 8px);
          flex: 1 0 0;

          .Portfolio-history-item-title {
            color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          .Portfolio-history-item-value {
            font-size: 20px;
            font-weight: 700;
            line-height: 140%; /* 28px */
          }
        }
      }
    }
  }

  .Portfolio-positions {
    background: transparent;

    .Portfolio-tabs {
      display: flex;
      align-items: flex-start;
      margin: 0 0 16px;
      overflow-y: auto;

      position: relative;
      z-index: 1;

      .tab {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        color: var(--white-60, var(--text-text-secondary, hsla(0, 0%, 100%, 0.6)));
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        text-wrap: nowrap;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: #fff;
        }

        &.active {
          background: #212333;
          color: #fff;
          pointer-events: none;
        }
      }
    }

    .Portfolio-tab-content {
      position: relative;
      z-index: 1;

      .PositionsList table {
        margin-top: -16px;
        border-collapse: separate;

        .Exchange-list-header {
          top: 0;
        }

        .no-data-record {
          border-color: var(--Border, rgba(255, 255, 255, 0.1));
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .Portfolio-heading {
    }

    .Portfolio-summary {
      grid-template-columns: 1fr 1fr;
    }

    .Portfolio-collateral {
      margin-top: 64px;
    }

    .Portfolio-history {
      margin-top: 64px;

      .Portfolio-history-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .Portfolio-positions {
    }
  }

  @media screen and (max-width: 767px) {
    .Portfolio-heading {
      flex-direction: column;
      align-items: flex-start;

      .Portfolio-heading-text {
        font-size: 24px;
      }
    }

    .Portfolio-summary {
      grid-template-columns: 1fr;

      .portfolio-card {
        .portfolio-card-top,
        .portfolio-card-middle,
        .portfolio-card-bottom {
          padding: 16px;
          gap: 16px;
        }
      }
    }

    .Portfolio-collateral {
      margin-top: 40px;
    }

    .Portfolio-history {
      margin-top: 40px;

      .Portfolio-history-container {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }

    .Portfolio-positions {
    }
  }

  &.theme--light {
    .Portfolio-heading {
      .Portfolio-heading-desc {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

        svg {
          path {
            fill: rgba(13, 26, 22, 0.7);
          }
        }

        &:hover {
          color: #02b27f;

          svg {
            path {
              fill: #02b27f;
            }
          }
        }
      }
    }

    .Portfolio-summary {
      .portfolio-card {
        background: radial-gradient(285.88% 111.8% at 100% 0%, #f2fffb 0%, #daf2eb 100%);

        .portfolio-card-heading {
          .Tooltip-handle {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          .portfolio-card-connected {
            color: #02b27f;

            .portfolio-card-dot {
              background-color: #02b27f;
            }
          }
        }

        .portfolio-card-top {
          border-bottom-color: var(--Border, rgba(13, 26, 22, 0.07));

          .portfolio-card-box {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 8px;
          }

          .portfolio-card-total {
            color: var(--Primary, #02b27f);
          }

          .portfolio-card-rows {
            .portfolio-card-row {
              .portfolio-card-key {
                color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

                .Tooltip-handle {
                  color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
                }
              }
            }
          }
        }

        .portfolio-card-middle {
          border-bottom-color: var(--Border, rgba(13, 26, 22, 0.07));

          .portfolio-card-clock {
            .clock {
              &::after {
                content: "";
                background-color: #e1f6ef;
              }

              .clock-tick {
                .clock-tick-main {
                  background-color: #0d1a16;
                }

                .clock-tick-dot {
                  background-color: #0d1a16;
                }
              }
            }
            .portfolio-card-clock-value {
            }
          }
        }

        .portfolio-card-bottom {
        }
      }

      .portfolio-chart {
        .no-record-chart {
          border-color: rgba(13, 26, 22, 0.07);
        }
      }
    }

    .Portfolio-history {
      .Portfolio-history-container {
        .Portfolio-history-item {
          background: var(--Nature-1, #fff);

          .Portfolio-history-item-info {
            .Portfolio-history-item-title {
              color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
            }
          }
        }
      }
    }

    .Portfolio-positions {
      .Portfolio-tabs {
        .tab {
          color: rgba(13, 26, 22, 0.7);

          &:hover {
            background: var(--HighLight, rgba(2, 178, 127, 0.15));
          }

          &.active {
            color: #02b27f;
            background: var(--HighLight, rgba(2, 178, 127, 0.15));
          }
        }
      }
    }
  }
`;
