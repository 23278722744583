import React, { memo, useMemo, useState } from "react";
import styled from "styled-components";
import Stats from "./Stats/Stats";
import StatBarCharts from "./Stats/StatBarCharts";
import StatPieCharts from "./Stats/StatPieCharts";
import StatVolumeAndTrending from "./Stats/StatVolumeAndTrending";
import { useChainId } from "lib/chains";
import moment from "moment";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { FANTOM, OP, BASE, getExplorerUrl } from "config/chains";
import { useLastSubgraphBlock } from "dataProvider";
import useOpenInterestStats from "hooks/useOpenInterestStats";
import DateRangeSelect from "./DateRangeSelect";
import useDailyInfos from "hooks/useDailyInfos";
import useDailyInfoLast24hCharts from "hooks/useDailyInfoLast24h";
import useDailyVolumesStats from "hooks/useDailyVolumeStats";

const dateRangeOptions = [
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "Last 2 Months",
    id: 2,
    isDefault: true,
  },
  // {
  //   label: "Last 3 Months",
  //   id: 3,
  // },
  {
    label: "All time",
    id: 4,
  },
];

type OverviewProps = {};

const Overview = (props: OverviewProps) => {
  const { chainId } = useChainId();
  const isValidChain = chainId == FANTOM;

  const getCurrentChain = () => {
    return chainId === FANTOM ? "fantom" : chainId === OP ? "optimism" : chainId === BASE ? "base" : "arbitrum";
  };
  const [lastSubgraphBlock] = useLastSubgraphBlock(getCurrentChain());
  const localDate = new Date();
  //Dùng với range date v1
  const [dataRange, setDataRange] = useState({
    from: new Date(localDate.setMonth(localDate.getMonth() - 2)),
    to: new Date(),
  });
  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ from: start, to: end });
  };
  const DateRangeValue = useMemo(() => {
    let fromDate;
    let toDate;

    if (dataRange.from && dataRange.to) {
      fromDate = dataRange.from;
      toDate = dataRange.to;
    } else {
      // fromDate = new Date(localDate.setMonth(localDate.getMonth() - 2));
      fromDate = dataRange.from;
      toDate = new Date();
    }

    const utcOffset = localDate.getTimezoneOffset() / 60; // Chênh lệch đơn vị là giờ

    // Cộng thêm chênh lệch múi giờ vào giờ hiện tại
    let currentUtcFromDate = fromDate;
    currentUtcFromDate.setHours(fromDate.getHours() + Math.abs(utcOffset));

    let currentUtcToDate = toDate;
    currentUtcToDate.setHours(currentUtcToDate.getHours() + Math.abs(utcOffset));

    // Number(moment.utc(dataRange.to).format("X"))
    return {
      // from: moment(dataRange.fromValue).unix(),
      from: moment(currentUtcFromDate).unix(),
      to: moment(currentUtcToDate).unix(),
    };
  }, [dataRange]);

  const { data, originData } = useOpenInterestStats(chainId);

  const { data: dataDailyInfo, isLoading: isLoadingDailyInfoData } = useDailyInfos(chainId, DateRangeValue);
  const { data: dailyInfoChartData, isLoading: isLoadingDailyInfoChart } = useDailyInfoLast24hCharts(
    chainId,
    DateRangeValue
  );
  const { data: dailyVolumes } = useDailyVolumesStats(chainId, DateRangeValue);

  return (
    <Wrapper>
      <HeadWrapper>
        {isValidChain ? (
          <PageDes className="desc">
            Updated{" "}
            {lastSubgraphBlock ? moment((lastSubgraphBlock as any)?.timestamp * 1000).fromNow() : "a few seconds ago"}
            &nbsp;at block{" "}
            <ExternalLink
              className="link-styled"
              href={`${getExplorerUrl(chainId)}/block/${(lastSubgraphBlock as any)?.number}`}
            >
              {lastSubgraphBlock ? (lastSubgraphBlock as any)?.number : "72714579"}
            </ExternalLink>
          </PageDes>
        ) : (
          <div></div>
        )}
        {/* <div className="form">
              <DateRangeSelected
                onChangeDateSelected={(val) => handleChangeDateSelected(val)}
                rangeValue={rangeDateValue}
              />
            </div> */}
        <div className="form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.from}
            endDate={dataRange.to}
            onChange={onDateRangeChange}
          />
        </div>

      </HeadWrapper>
      <div className="chart-grid">
        <Stats
          chainId={chainId}
          dailyInfoData={dataDailyInfo}
          isLoadingDailyInfoData={isLoadingDailyInfoData}
          dailyInfoChartData={dailyInfoChartData}
          isLoadingDailyInfoChart={isLoadingDailyInfoChart}
        />
        <StatBarCharts chainId={chainId} dailyInfoData={dataDailyInfo} />
        <StatPieCharts data={data} originData={originData} chainId={chainId} />
        <StatVolumeAndTrending data={data} chainId={chainId} dailyVolumes={dailyVolumes} />
      </div>
    </Wrapper>
  );
};

export default memo(Overview);
const Wrapper = styled.div`
  width: 100%;
`;

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .form {
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: flex-end;
    }
    @media (max-width: 767px) {
      width: 100%;
      > div {
        width: inherit;
      }
    }
  }
  .date-range-selector-wrapper .rdrDayNumber span {
    color: #fff;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const PageDes = styled.div`
  color: var(--white-60, rgba(255, 255, 255, 0.6));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  span {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
  }
`;
