import React, { useEffect, useMemo } from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment";
import styled from "styled-components";
import { useMedia } from "react-use";
import { numberFormat, toKInChart } from "lib/numbers";
import { LiveDataTimeframeEnum } from "hooks/aggregator/useLiveChartData";

const p = "hh:mm A";

const format = (time: Date, formatter: string) => {
  return moment(time).format(formatter);
};

const AreaChartWrapper = styled(AreaChart)`
  svg {
    overflow-x: visible;
  }
`;
const getHoverDateFormat = (timeFrame: LiveDataTimeframeEnum | undefined) => {
  switch (timeFrame) {
    case LiveDataTimeframeEnum.HOUR:
    case LiveDataTimeframeEnum.FOUR_HOURS:
      return `${p} (Z)`;
    case LiveDataTimeframeEnum.DAY:
    case LiveDataTimeframeEnum.WEEK:
      return `${p} MMM D (Z)`;
    case LiveDataTimeframeEnum.MONTH:
      // case LiveDataTimeframeEnum.SIX_MONTHS:
      return "MMM D (Z)";
    default:
      return `${p} MMM D (Z)`;
  }
};

const getAxisDateFormat = (timeFrame: LiveDataTimeframeEnum | undefined) => {
  switch (timeFrame) {
    case LiveDataTimeframeEnum.HOUR:
    case LiveDataTimeframeEnum.FOUR_HOURS:
    case LiveDataTimeframeEnum.DAY:
      return p;
    case LiveDataTimeframeEnum.WEEK:
    case LiveDataTimeframeEnum.MONTH:
      // case LiveDataTimeframeEnum.SIX_MONTHS:
      return "MMM D";
    default:
      return `${p} MMM D`;
  }
};

const HoverUpdater = ({
  payload,
  setHoverValue,
}: {
  payload: any;
  setHoverValue: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  useEffect(() => {
    setHoverValue(payload.value);
  }, [payload.value, payload.time, setHoverValue]);

  return null;
};

const CustomizedCursor = (props: any) => {
  const { payload, points, timeFrame, width, unitYAsis, tooltipProps } = props;
  const { xAxisFormatter } = tooltipProps || {};
  const isTextAnchorStart = width - points[0].x > 100;
  if (payload) {
    const x = points[0].x + (isTextAnchorStart ? 5 : -5);
    const { time, value } = payload[0].payload;
    return (
      <>
        <text x={x} y={12} fill={"#999A9E"} fontSize={12} textAnchor={isTextAnchorStart ? "start" : "end"}>
          <tspan x={x} dy="0.8em">
            {xAxisFormatter ? xAxisFormatter(time, payload[0].payload) : format(time, getHoverDateFormat(timeFrame))}
          </tspan>

          {tooltipProps?.showValue ? (
            <tspan x={x} dy="1.6em">{`${unitYAsis ?? ""}${numberFormat(value, 0)}`}</tspan>
          ) : null}
        </text>
        <line x1={points[0].x} y1={0} x2={points[1].x} y2={points[1].y} stroke={"#999A9E"} width={2} />
      </>
    );
  } else {
    return <></>;
  }
};

const ONE_DAY_TIMESTAMP = 86400000;

const getFirstTimestamp = (timeFrame: LiveDataTimeframeEnum | undefined) => {
  const nowTimestamp = new Date().getTime();
  switch (timeFrame) {
    case LiveDataTimeframeEnum.HOUR:
      return nowTimestamp - 3600000;
    case LiveDataTimeframeEnum.FOUR_HOURS:
      return nowTimestamp - 1440000;
    case LiveDataTimeframeEnum.DAY:
      return nowTimestamp - ONE_DAY_TIMESTAMP;
    case LiveDataTimeframeEnum.WEEK:
      return nowTimestamp - 7 * ONE_DAY_TIMESTAMP;
    case LiveDataTimeframeEnum.MONTH:
      return nowTimestamp - 30 * ONE_DAY_TIMESTAMP;
    // case LiveDataTimeframeEnum.SIX_MONTHS:
    //   return nowTimestamp - 180 * ONE_DAY_TIMESTAMP;
    default:
      return nowTimestamp - 7 * ONE_DAY_TIMESTAMP;
  }
};

const addZeroData = (
  data: {
    time: number;
    value: string | number;
  }[],
  timeFrame: LiveDataTimeframeEnum | undefined
) => {
  let timestamp = getFirstTimestamp(timeFrame);
  const zeroData: any = [];

  while (data[0]?.time - timestamp > ONE_DAY_TIMESTAMP) {
    zeroData.push({ time: timestamp, value: "0" });
    timestamp += ONE_DAY_TIMESTAMP;
  }
  return [...zeroData, ...data];
};

interface LineChartProps {
  customizedData?: boolean;
  data: { time: number; value: string | number }[];
  setHoverValue?: React.Dispatch<React.SetStateAction<number | null>>;
  color?: string;
  timeFrame?: LiveDataTimeframeEnum;
  minHeight?: number;
  showYAsis?: boolean;
  unitYAsis?: string;
  xAxisProps?: {
    tickFormatter?: (time: number) => string;
  };
  tooltipProps?: {
    showValue?: boolean;
    xAxisFormatter?: (time: number, value: any) => React.ReactNode;
  };
}

const LineChart = ({
  customizedData = false,
  data,
  setHoverValue,
  color: overrideColor,
  timeFrame,
  minHeight = 292,
  showYAsis,
  unitYAsis = "",
  xAxisProps,
  tooltipProps,
}: LineChartProps) => {
  const color = useMemo(() => overrideColor ?? "#C1FF99", [overrideColor]);
  const isMobile = useMedia("(max-width: 700px)");
  const formattedData = useMemo(() => {
    const validData = data.filter((item) => !!item.value || item.value === 0);
    return customizedData ? validData : addZeroData(validData, timeFrame);
  }, [customizedData, data, timeFrame]);
  const dataMax = useMemo(() => Math.max(...formattedData.map((item) => parseFloat(item.value))), [formattedData]);
  const dataMin = useMemo(() => Math.min(...formattedData.map((item) => parseFloat(item.value))), [formattedData]);
  const ticks = useMemo(() => {
    if (formattedData && formattedData.length > 0) {
      const firstTime = formattedData[0].time;
      const lastTime = formattedData[formattedData.length - 1].time;
      const length = lastTime - firstTime;
      let padding = 0.06;
      let counts = 6;
      if (isMobile) {
        padding = 0.1;
        counts = 4;
      }
      const positions: any = [];
      for (let i = 0; i < counts; i++) {
        positions.push(padding + (i * (1 - 2 * padding)) / (counts - 1));
      }
      return positions.map((v) => parseInt(firstTime + length * v));
    }
    return [];
  }, [formattedData]);

  return (
    <ResponsiveContainer minHeight={isMobile ? 150 : minHeight} height="100%">
      {formattedData && formattedData.length > 0 ? (
        <AreaChartWrapper
          data={formattedData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          onMouseLeave={() => setHoverValue?.(null)}
        >
          <CartesianGrid
            horizontal={!!showYAsis}
            vertical={false}
            // stroke={theme.dark ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)'}
            stroke={"rgba(255,255,255,0.08)"}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.4} />
              <stop offset="100%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="time"
            fontSize="12px"
            axisLine={false}
            tickLine={false}
            domain={[formattedData[0]?.time || "auto", formattedData[formattedData.length - 1]?.time || "auto"]}
            ticks={ticks}
            tick={{ fill: "#999A9E", fontWeight: 400 }}
            type="number"
            textAnchor="middle"
            tickFormatter={(time) => {
              if (typeof time !== "number") {
                return "0";
              }
              if (xAxisProps?.tickFormatter) {
                return xAxisProps.tickFormatter(time);
              }
              return format(new Date(time), getAxisDateFormat(timeFrame));
            }}
            interval={0}
          />
          <YAxis
            width={dataMin >= 0.1 ? 69 : 105}
            dataKey="value"
            fontSize="12px"
            tickLine={false}
            axisLine={false}
            tick={{ fill: "#999A9E", fontWeight: 400 }}
            tickFormatter={(tick) => toKInChart(tick, unitYAsis)}
            ticks={[
              dataMin,
              dataMin + (1 * (dataMax - dataMin)) / 4,
              dataMin + (2 * (dataMax - dataMin)) / 4,
              dataMin + (3 * (dataMax - dataMin)) / 4,
              dataMin + (4 * (dataMax - dataMin)) / 4,
              dataMin + (5 * (dataMax - dataMin)) / 4,
            ]}
            orientation="left"
            domain={[dataMin, (5 * (dataMax - dataMin)) / 4]}
            hide={!showYAsis}
          />
          <Tooltip
            contentStyle={{ display: "none" }}
            formatter={(tooltipValue: any, name: string, props: any) =>
              // eslint-disable-next-line react/prop-types
              setHoverValue ? <HoverUpdater payload={props.payload} setHoverValue={setHoverValue} /> : undefined
            }
            cursor={<CustomizedCursor timeFrame={timeFrame} unitYAsis={unitYAsis} tooltipProps={tooltipProps} />}
          />
          <Area type="monotone" dataKey="value" stroke={color} fill="url(#colorUv)" strokeWidth={2} />
        </AreaChartWrapper>
      ) : (
        <></>
      )}
    </ResponsiveContainer>
  );
};

export default React.memo(LineChart);
