import Tooltip from "components/Tooltip/Tooltip";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  formatNumber,
  formatAddKeysAmount,
  parseValue,
} from "lib/numbers";

import gum_ic from "img/gum.svg";
import esgum_ic from "img/esgum.svg";
import { Link } from "react-router-dom";
import GMYAprTooltip from "components/Stake/GMYAprTooltip";
import { useTotalEsGmyStaked, useTotalGmyStaked } from "domain/legacy";
import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import styled from "styled-components";
import { ARBITRUM, BASE, getConstant } from "config/chains";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { getContract } from "config/contracts";
import { helperToast } from "lib/helperToast";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";

import Token from "abis/Token.json";
import RewardRouter from "abis/RewardRouter.json";

import { ethers } from "ethers";
import { approveTokens } from "domain/tokens";
import Modal from "components/Modal/Modal";

const { AddressZero } = ethers.constants;

const EarnSection = ({ processedData, gmyPrice, vestingData, setPendingTxns, totalRewardTokens }) => {
  let { total: totalGmyStaked } = useTotalGmyStaked();
  let { total: totalEsGmyStaked } = useTotalEsGmyStaked();
  const { chainId } = useChainId();

  let stakedGmySupplyUsd;
  if (!totalGmyStaked.isZero() && gmyPrice) {
    stakedGmySupplyUsd = totalGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let stakedEsGmySupplyUsd;
  if (!totalEsGmyStaked.isZero() && gmyPrice) {
    stakedEsGmySupplyUsd = totalEsGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let estGMYRewardsPerWeek = 0;
  if (processedData && processedData.gmyAprTotal && processedData.gmyBalanceUsd) {
    estGMYRewardsPerWeek = processedData.gmyAprTotal.mul(processedData.gmyBalanceUsd).div(365).mul(7).div(100);
  }

  let estEsGMYRewardsPerWeek = 0;
  if (processedData && processedData.gmyAprTotal && processedData.esGmyBalanceUsd) {
    estEsGMYRewardsPerWeek = processedData.gmyAprTotal.mul(processedData.esGmyBalanceUsd).div(365).mul(7).div(100);
  }

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const { account, active, library } = useWeb3React();
  const isGmyTransferEnabled = true;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const esGmyAddress = getContract(chainId, "ES_GMY");
  const gmyAddress = getContract(chainId, "GMY");

  const showStakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake GMY`);
    setStakeModalMaxAmount(processedData.gmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("GMY");
    setStakingTokenAddress(gmyAddress);
    setStakingFarmAddress(stakedGmyTrackerAddress);
    setStakeMethodName("stakeGmy");
  };

  const showStakeEsGmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esGMY`);
    setStakeModalMaxAmount(processedData.esGmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esGMY");
    setStakingTokenAddress(esGmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsGmy");
  };
  const showUnstakeEsGmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esGMY`);
    let maxAmount = processedData.esGmyInStakedGmy;
    if (
      processedData.esGmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.esGmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esGMY");
    setUnstakeMethodName("unstakeEsGmy");
  };

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");
  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusGmyTrackerRewards && processedData.bnGmyInFeeGmy) {
    multiplierPointsAmount = processedData.bonusGmyTrackerRewards.add(processedData.bnGmyInFeeGmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }

  let maxUnstakeableGmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.gmyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusGmyInFeeGmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.gmyVesterPairAmount);
    const stakedTokens = processedData.bonusGmyInFeeGmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableGmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const bonusGmyInFeeGmy = processedData ? processedData.bonusGmyInFeeGmy : undefined;

  const showUnstakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake GMY`);
    let maxAmount = processedData.gmyInStakedGmy;
    if (
      processedData.gmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.gmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("GMY");
    setUnstakeMethodName("unstakeGmy");
  };

  return (
    <section className="Earn-Section">
      <h1 className="Section-Title">Stake</h1>
      <p className="Section-Desc">Stake GUM or esGUM to earn rewards.</p>

      <div className="Section-DoublePaper">
        <div className="Section-Paper">
          <div className="Section-PaperHead">
            <img src={gum_ic} alt="gum_ic" />
            <div className="Section-PaperHeadDetails">
              <h3>Yummy Governance Token - GUM</h3>
              <p>
                Total Staked:{" "}
                {totalGmyStaked ? (
                  <>
                    <span>{formatAmount(totalGmyStaked, 18, 0, true)}</span> $
                    {formatNumber(formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 2), 2)}
                  </>
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                )}
              </p>
            </div>
            <Link className="Section-PaperHeadAction border-btn" to="/buy-gum">
              Buy GUM
            </Link>
          </div>
          <div className="Section-PaperBody">
            <div className="Section-PaperBodyTop">
              <div className="Section-PaperBodyTopItem">
                APR
                {processedData.gmyAprTotalWithBoost ? (
                  <Tooltip
                    className="tooltip-apr"
                    handle={
                      <div className="positive">
                        {formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%
                      </div>
                    }
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                )}
              </div>
              <div className="Section-PaperBodyTopItem">
                MPs APR
                <Tooltip
                  className="excep"
                  handle={`100.00%`}
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        Boost your rewards with Multiplier Points.&nbsp;
                        <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">
                          More info
                        </ExternalLink>
                        .
                      </span>
                    );
                  }}
                />
              </div>
              <div className="Section-PaperBodyTopItem">
                Boost
                {processedData.boostBasisPoints ? (
                  <Tooltip
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    // position="lef-bottom"
                    renderContent={() => {
                      return (
                        <div>
                          You are earning{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.boostBasisPoints, 2, 2, false)}%
                          </span>{" "}
                          more {nativeTokenSymbol} rewards using{" "}
                          <span style={{ fontWeight: 700, color: "white" }}>
                            {formatAmount(processedData.bnGmyInFeeGmy, 18, 4, 2, true)}
                          </span>{" "}
                          Staked Multiplier Points.
                          <br />
                          <div style={{ marginTop: "8px" }}>
                            <span>Use the "Compound" button to stake your Multiplier Points.</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "20px" }} />
                )}
              </div>
              <div className="Section-PaperBodyTopItem">
                Wallet balance
                <div>
                  {processedData?.gmyBalance ? (
                    <div className="value">
                      <span>{formatKeyAmountFixed(processedData, "gmyBalance", 18, 2, true)}</span> $
                      {formatKeyAmountFixed(processedData, "gmyBalanceUsd", USD_DECIMALS, 2, true)}
                    </div>
                  ) : (
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  )}
                </div>
              </div>
              <button className="default-btn" onClick={showStakeGmyModal}>
                Stake
              </button>
            </div>
            <div className="Section-PaperBodyBottom">
              <div className="Section-PaperBodyBottomItem">
                <span>Deposited</span>

                {processedData?.gmyInStakedGmy ? (
                  <>
                    <h5>{formatKeyAmount(processedData, "gmyInStakedGmy", 18, 2, true)} GUM</h5>
                    <span>${formatKeyAmount(processedData, "gmyInStakedGmyUsd", USD_DECIMALS, 2, true)}</span>
                  </>
                ) : (
                  <>
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </>
                )}

                <div className="Section-PaperBodyBottomWrap">
                  <div className="Section-PaperBodyBottomItemActions">
                    <button className="default-btn full" onClick={() => showUnstakeGmyModal()}>
                      Withdraw
                    </button>
                  </div>
                  <div className="Section-PaperBodyBottomItemRows">
                    <Tooltip
                      handle="Cooldown period"
                      renderContent={() => {
                        return "???";
                      }}
                    />
                    <h5>00:00:00</h5>
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottomItem">
                <span>Rewards</span>
                <h5>
                  $
                  {formatAddKeysAmount(
                    processedData,
                    "feeGmyTrackerRewardsUsd",
                    "stakedGmyTrackerRewardsUsd",
                    USD_DECIMALS,
                    2,
                    true
                  )}
                </h5>
                <span>
                  {processedData?.feeGmyTrackerRewards &&
                    `$${formatKeyAmount(
                      processedData,
                      "feeGmyTrackerRewardsUsd",
                      USD_DECIMALS,
                      2,
                      true
                    )} ${wrappedTokenSymbol}, `}
                  {processedData.stakedGmyTrackerRewards &&
                    `$${formatKeyAmount(processedData, "stakedGmyTrackerRewardsUsd", USD_DECIMALS, 2, true)} esGUM`}
                </span>
                <div className="Section-PaperBodyBottomWrap">
                  <div className="Section-PaperBodyBottomItemActions">
                    <button className="default-btn">Compound</button>
                    <button className="border-btn">Claim</button>
                  </div>
                  <div className="Section-PaperBodyBottomItemRows">
                    Est. rewards per week
                    <h5>${formatAmount(estGMYRewardsPerWeek, USD_DECIMALS, 2, true)}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Section-Paper">
          <div className="Section-PaperHead">
            <img src={esgum_ic} alt="esgum_ic" />
            <div className="Section-PaperHeadDetails">
              <h3>Escrowed GUM - esGUM</h3>
              <p>
                Total Staked:{" "}
                {totalEsGmyStaked ? (
                  <>
                    <span> {formatAmount(totalEsGmyStaked, 18, 0, true)}</span> $
                    {formatNumber(formatAmount(stakedEsGmySupplyUsd, USD_DECIMALS, 0), 2)}
                  </>
                ) : (
                  <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
                )}
              </p>
            </div>
          </div>
          <div className="Section-PaperBody">
            <div className="Section-PaperBodyTop">
              <div className="Section-PaperBodyTopItem">
                APR
                {processedData.gmyAprTotalWithBoost ? (
                  <Tooltip
                    className="tooltip-apr"
                    handle={
                      <h5 className="positive">
                        {formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%
                      </h5>
                    }
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                ) : (
                  <div className="skeleton-box" style={{ width: "60px", height: "19.6px" }} />
                )}
              </div>
              <div className="Section-PaperBodyTopItem">
                MPs APR
                <Tooltip
                  className="excep"
                  handle={`100.00%`}
                  position="center-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        Boost your rewards with Multiplier Points.&nbsp;
                        <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">
                          More info
                        </ExternalLink>
                        .
                      </span>
                    );
                  }}
                />
              </div>
              {/* <div className="Section-PaperBodyTopItem">
              Boost
              <Tooltip
                handle="5%"
                renderContent={() => {
                  return "???";
                }}
              />
            </div> */}
              <div className="Section-PaperBodyTopItem">
                Wallet balance
                <div>
                  {processedData.esGmyBalance ? (
                    <>
                      <span>{formatKeyAmountFixed(processedData, "esGmyBalance", 18, 2, true)}</span> $
                      {formatKeyAmountFixed(processedData, "esGmyBalanceUsd", USD_DECIMALS, 2, true)}
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  )}
                </div>
              </div>
              <button className="default-btn" onClick={showStakeEsGmyModal}>
                Stake
              </button>
            </div>
            <div className="Section-PaperBodyBottom">
              <div className="Section-PaperBodyBottomItem">
                <span>Deposited</span>

                {processedData?.esGmyInStakedGmy ? (
                  <>
                    <h5>{formatKeyAmount(processedData, "esGmyInStakedGmy", 18, 2, true)} esGUM</h5>
                    <span>${formatKeyAmount(processedData, "esGmyInStakedGmyUsd", USD_DECIMALS, 2, true)}</span>
                  </>
                ) : (
                  <>
                    <div className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                    <span className="skeleton-box" style={{ width: "65px", height: "20px" }} />
                  </>
                )}

                <div className="Section-PaperBodyBottomWrap">
                  <div className="Section-PaperBodyBottomItemActions">
                    <button className="default-btn full" onClick={() => showUnstakeEsGmyModal()}>
                      Withdraw
                    </button>
                  </div>
                  <div className="Section-PaperBodyBottomItemRows">
                    <Tooltip
                      handle="Cooldown period"
                      renderContent={() => {
                        return "???";
                      }}
                    />
                    <h5>00:00:00</h5>
                  </div>
                </div>
              </div>
              <div className="Section-PaperBodyBottomItem">
                <span>Rewards</span>
                <h5>$40.12</h5>
                <span>$20.05 BERA, $20.05 esGUM, 100 Multiplier points</span>
                <div className="Section-PaperBodyBottomWrap">
                  <div className="Section-PaperBodyBottomItemActions">
                    <button className="default-btn">Compound</button>
                    <button className="border-btn">Claim</button>
                  </div>
                  <div className="Section-PaperBodyBottomItemRows">
                    Est. rewards per month
                    <h5>${formatAmount(estEsGMYRewardsPerWeek, USD_DECIMALS, 2, true)}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusGmyInFeeGmy={bonusGmyInFeeGmy}
      />
    </section>
  );
};

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active &&
      stakingTokenAddress && [
        `allowance:${active}:${account}:${farmAddress}`,
        chainId,
        stakingTokenAddress,
        "allowance",
        account,
        farmAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );
  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{stakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {stakingTokenSymbol === "esGMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esGMY amount includes the esGMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esGMY to GMY later, you will be required to deposit a reserve amount of GMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusGmyInFeeGmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusGmyInFeeGmy &&
    bonusGmyInFeeGmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusGmyInFeeGmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusGmyInFeeGmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusGmyInFeeGmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <ModalWrapper className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <StyledSectionTop className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </StyledSectionTop>
          <div className="Exchange-swap-section-bottom">
            <div>
              <StyledInput
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <StyledTokenSymbol className="PositionEditor-token-symbol">{unstakingTokenSymbol}</StyledTokenSymbol>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}

const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19.6px;
`;

const StyledTokenSymbol = styled.div`
  font-size: 16px !important;
`;

const StyledInput = styled.input`
  font-weight: 700 !important;
`;
const StyledSectionTop = styled.div`
  > div {
    font-weight: 400 !important;
  }
`;
const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
`;

export default EarnSection;
