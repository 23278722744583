import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" fill="none" viewBox="0 0 40 41">
      <g clipPath="url(#clip0_18928_13865)">
        <g clipPath="url(#clip1_18928_13865)">
          <path fill="#53AE94" d="M20 .5c11.045 0 20 8.955 20 20s-8.955 20-20 20-20-8.952-20-20S8.954.5 20 .5z"></path>
          <path
            fill="#fff"
            d="M22.469 17.835V14.86h6.803v-4.533H10.746v4.533h6.804v2.973c-5.53.254-9.688 1.349-9.688 2.66 0 1.313 4.16 2.408 9.688 2.664v9.53h4.92v-9.531c5.52-.255 9.67-1.35 9.67-2.66s-4.15-2.405-9.67-2.66m0 4.513v-.003c-.139.01-.852.052-2.44.052-1.27 0-2.163-.036-2.477-.052v.003c-4.886-.216-8.533-1.067-8.533-2.085s3.648-1.867 8.533-2.084v3.322c.32.022 1.235.076 2.498.076 1.517 0 2.28-.063 2.42-.076V18.18c4.876.217 8.514 1.069 8.514 2.083 0 1.014-3.64 1.866-8.514 2.084"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_18928_13865">
          <path fill="#fff" d="M0 0H40V40H0z" transform="translate(0 .5)"></path>
        </clipPath>
        <clipPath id="clip1_18928_13865">
          <path fill="#fff" d="M0 0H40V40H0z" transform="translate(0 .5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
