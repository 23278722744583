import { IconDiscord, IconHalfCircle, IconLinkTree, IconMedium, IconStar, IconTelegram, IconX } from "assets";
import { linkSocial } from "constants/constants";
import { motion } from "framer-motion";
import { itemVariants, listVariants, titleVariants } from "lib/anim";
import styled from "styled-components";

const Social = () => {
  const socials = [
    {
      title: "X",
      link: linkSocial.X,
      icon: <IconX />,
    },
    {
      title: "Linktree",
      link: linkSocial.LINKTREE,
      icon: <IconLinkTree
        width={"32"}
        height={"32"}
      />,
    },
    {
      title: "Medium",
      link: linkSocial.MEDIUM,
      icon: <IconMedium />,
    },
    {
      title: "Telegram",
      link: linkSocial.TELEGRAM,
      icon: <IconTelegram />,
    },
  ];

  return (
    <Wrapper>
      <div className="container wrapper">
        <IconStar className="star-1" />
        <IconStar size="small" className="star-2" />
        <IconStar width={110} height={107} className="star-3" />
        <IconStar size="small" className="star-4" />
        <IconStar size="small" className="star-5" />

        <IconHalfCircle className="half-circle" />
        <motion.div className="box-content" initial="hidden" whileInView="visible" variants={listVariants}>
          <motion.div className="title" variants={titleVariants} whileInView="visible" initial="hidden">
            Join our community
          </motion.div>
          <div className="box-social">
            {socials.map((item, idx) => (
              <motion.div
                style={{
                  flex: "1 0 0",
                  width: "100%",
                }}
                key={idx}
                variants={itemVariants}
              >
                {item.link === "" ? (
                  <SocialItem $hasLink={!!item.link}>
                    {item.icon}
                    {item.title}
                  </SocialItem>
                ) : (
                  <SocialItem href={item.link} target="_blank" $hasLink={!!item.link}>
                    {item.icon}
                    {item.title}
                  </SocialItem>
                )}
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default Social;

const Wrapper = styled.div`
  margin-top: 160px;
  display: flex;
  padding: 120px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  align-self: stretch;
  background: linear-gradient(180deg, #0a1733 0%, #0f234d 100%);
  position: relative;

  @media (max-width: 1024px) {
    margin-top: 120px;
    padding: 120px 24px;
  }
  .wrapper {
    width: 100%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .title {
    color: Text/Text_Primary;
    text-align: center;

    /* Title/Bold */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media (max-width: 767px) {
      color: Text/Text_Primary;
      text-align: center;

      /* Small_Title/Bold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
  .half-circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .box-content {
    position: relative;
    z-index: 10;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .box-social {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    margin-top: 36px;
    @media (max-width: 1024px) {
      display: grid;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }
  }
  .item-social {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 16px;
    background: #152a16;
    :hover {
      background: #29502a;
    }
    text-decoration: none;
    color: #fff;
    text-align: center;

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .star-1 {
    position: absolute;
    top: 5%;
    left: 30%;
    @media (max-width: 1024px) {
      top: 22%;
      left: -7%;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
  .star-2 {
    position: absolute;
    top: 5px;
    left: 38%;
    @media (max-width: 1024px) {
      top: 12%;
      left: 6%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .star-3 {
    position: absolute;
    top: 5px;
    right: 33%;
    @media (max-width: 1024px) {
      top: 19%;
      right: 15%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .star-4 {
    position: absolute;
    top: 42%;
    right: 29%;
    @media (max-width: 1024px) {
      top: 53%;
      right: -1%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .star-5 {
    position: absolute;
    top: 38%;
    right: 17%;
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const SocialItem = styled.a`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 16px;
  background: #0f1826;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  ${({ $hasLink }) =>
    $hasLink
      ? `
    &:hover {
      background: #18273e;
    }
    cursor: pointer;
  `
      : `
    cursor: not-allowed;
  `}

  @media (max-width: 767px) {
    width: 100%;
  }
`;
