import { PoolFrame } from "pages/Earn/styles";

import mglp_ic from "img/mglp.svg";
import green_arrow_ic from "img/green-arrow.svg";

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Footer from "components/Footer/Footer";
import BuySellMglp from "pages/Earn/BuySellMglp";
import PoolCompositionMglp from "pages/Earn/PoolCompositionMglp";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber, parseValue } from "lib/numbers";
import {
  BASIS_POINTS_DIVISOR,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  GLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
} from "lib/legacy";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";
import { getContract } from "config/contracts";
import { getNativeToken, getTokens } from "config/tokens";

import RewardReader from "abis/RewardReader.json";
import ReaderV2 from "abis/ReaderV2.json";
import GlpManager from "abis/GlpManager.json";
import RewardTracker from "abis/RewardTracker.json";
import VaultV2 from "abis/VaultV2.json";
import Vault from "abis/Vault.json";
import Token from "abis/Token.json";

import { FANTOM } from "config/chains";
import { useGmyPrice } from "domain/legacy";

const { AddressZero } = ethers.constants;

const Mglp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const { active, library, account } = useWeb3React();
  const [glpValue, setGlpValue] = useState("");
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);

  const { gmyPrice } = useGmyPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdgAddress = getContract(chainId, "USDG");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");
  const vaultAddress = getContract(chainId, "Vault");
  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmyAddress = getContract(chainId, "GMY");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const gmAddress = getContract(chainId, "GM");
  const glpAddress = getContract(chainId, "GLP");

  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmVesterAddress = getContract(chainId, "GmVester");
  const stakedGmTrackerAddress = getContract(chainId, "StakedGmTracker");

  const tokensForBalanceAndSupplyQuery = [stakedGlpTrackerAddress, usdgAddress];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress, gmVesterAddress];
  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress, gmAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
    gmAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
    feeGmTrackerAddress,
  ];

  const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { data: tokenBalances } = useSWR(
    [`Mglp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
    }
  );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Mglp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Mglp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: glpBalance } = useSWR(
    [`Mglp:glpBalance:${active}`, chainId, feeGlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: aums } = useSWR([`Mglp:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Mglp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Mglp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Mglp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Mglp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [
      `Mglp:stakedGmySupply:${active}:${stakedGmyTrackerAddress}`,
      chainId,
      gmyAddress,
      "balanceOf",
      stakedGmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`BuySellMglp:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress, gmAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Mglp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let gmySupply = totalSupply;

  const usdgSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  const glpAmount = parseValue(glpValue, GLP_DECIMALS);

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const glpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);

  const glpPrice =
    aum && aum.gt(0) && glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
      : expandDecimals(1, USD_DECIMALS);

  const glpSupplyUsd = glpSupply.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  const nativeToken = getTokenInfo(infoTokens, AddressZero);

  let glpBalanceUsd;
  if (glpBalance) {
    glpBalanceUsd = glpBalance.mul(glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  }

  let totalApr = bigNumberify(0);

  let feeGlpTrackerAnnualRewardsUsd;
  let feeGlpTrackerApr;

  if (
    stakingData &&
    stakingData.feeGlpTracker &&
    stakingData.feeGlpTracker.tokensPerInterval &&
    nativeToken &&
    nativeToken.minPrice &&
    glpSupplyUsd &&
    glpSupplyUsd.gt(0)
  ) {
    feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeToken.minPrice)
      .div(expandDecimals(1, 18));
    feeGlpTrackerApr = feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(glpSupplyUsd);
    totalApr = totalApr.add(feeGlpTrackerApr);
  }

  let stakedGlpTrackerAnnualRewardsUsd;
  let stakedGlpTrackerApr;

  if (
    gmyPrice &&
    stakingData &&
    stakingData.stakedGlpTracker &&
    stakingData.stakedGlpTracker.tokensPerInterval &&
    glpSupplyUsd &&
    glpSupplyUsd.gt(0)
  ) {
    stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(gmyPrice)
      .div(expandDecimals(1, 18));
    stakedGlpTrackerApr = stakedGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(glpSupplyUsd);
    totalApr = totalApr.add(stakedGlpTrackerApr);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply
  );

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={mglp_ic} alt="mglp_ic" /> Majors pools - MGLP
          </h1>
          <p>Purchase MGLP tokens to earn BERA, esGUM fees from swaps and leveraged trading.</p>
        </div>
        <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div>
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {formatAmount(totalApr, 2, 2, true) !== "0.00" ? (
              <>
                <h5 className="positive">{formatAmount(totalApr, 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatAmount(feeGlpTrackerApr, 2, 2, false)}% <br className="br-mobile" />+ esGMY{" "}
                {formatAmount(stakedGlpTrackerApr, 2, 2, false)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(glpSupply, GLP_DECIMALS, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatAmount(glpSupply, GLP_DECIMALS, 0, true)} GLP</h5>$
                {formatAmount(glpSupplyUsd, USD_DECIMALS, 2, true)}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.glpSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "glpSupply", 18, 0, true)} GLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 2), 2)}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {glpBalance ? (
              <>
                <h5>{formatAmount(glpBalance, GLP_DECIMALS, 4, true)} GLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {glpBalanceUsd ? (
              <>${formatAmount(glpBalanceUsd, USD_DECIMALS, 2, true)}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {glpBalance ? (
              <>
                <h5>{formatAmount(glpBalance, GLP_DECIMALS, 4, true)} GLP</h5>$
                {formatAmount(glpBalanceUsd, USD_DECIMALS, 2, true)}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart">
            <h3>Token Distribution</h3>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="name" color="red" />
                <YAxis />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <PoolCompositionMglp
            isBuying={isBuying}
            glpAmount={glpAmount}
            glpPrice={glpPrice}
            usdgSupply={usdgSupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellMglp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            glpValue={glpValue}
            setGlpValue={setGlpValue}
            glpAmount={glpAmount}
            glpPrice={glpPrice}
            usdgSupply={usdgSupply}
            totalTokenWeights={totalTokenWeights}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Mglp;

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
