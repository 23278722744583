import React from "react";
import cx from "classnames";
import "./ExternalLink.scss";
import { useMedia } from "react-use";

type Props = {
  href: string;
  children: React.ReactNode;
  className?: string;
  target?: string;
  onClick?: () => void;
};

function ExternalLink({ href, children, className, target = "_blank", onClick }: Props) {
  const isMobile = useMedia("(max-width: 800px)");

  const classNames = cx("link-underline", className);
  return isMobile ? (
    <a
      href={href}
      style={{
        textDecoration: "none",
      }}
      className={classNames}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <a
      href={href}
      style={{
        textDecoration: "none",
      }}
      className={classNames}
      target={target}
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default ExternalLink;
