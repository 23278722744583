import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import { useChainId } from "lib/chains";

import Hero from "./components/Hero";

import { ImageBg } from "assets";
import { getCoinListYummy } from "services/api";
import CexcellentDexHero from "./components/CexcellentDexHero";
import FooterYummy from "./components/FooterYummy";
import LoadingPage from "./components/LoadingPage";
import OnestopDexSection from "./components/OnestopDexSection";
import Social from "./components/Social";
import TokenPotentialSection from "./components/TokenPotentialSection";

export default function HomeV3() {
  const { chainId } = useChainId();

  const [dataSpon, setDataSpon] = useState();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // const handleGetCoin = async () => {
  //   try {
  //     const res = await getCoinList();
  //     setDataSpon(res.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleGetCoinYummy = async () => {
    try {
      const res = await getCoinListYummy();
      const data = res.data.data
        .filter((i) => i.symbol !== "FET/USD")
        .sort((a, b) => parseFloat(b.openInterest.total) - parseFloat(a.openInterest.total));
      setDataSpon(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // handleGetCoin();
    handleGetCoinYummy();
  }, []);

  return (
    <>
      {isLoading ? (
        <PageLoader>
          <LoadingPage />
          {/* <img src={gmx40Icon} alt="gmxIcon" /> */}
          {/* TODO */}
        </PageLoader>
      ) : (
        <Wrapper
          style={{
            backgroundImage: `url(${ImageBg})`,
          }}
        >
          <Hero dataSpon={dataSpon} chainId={chainId} />
          <TokenPotentialSection dataSpon={dataSpon} />
          <OnestopDexSection />
          <CexcellentDexHero />
          {/* <CryptoSkills /> */}
          <Social />
          <FooterYummy />
        </Wrapper>
      )}
    </>
  );
}

const loading = keyframes`
  0% {

    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const PageLoader = styled.div`
  background-color: #000;
  position: fixed;
  inset: 0;
  z-index: 999;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 999px;
  border: 3px solid #34f5ae;
  border-top-color: transparent;
  animation: ${loading} 1s linear infinite forwards;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: 126px;

  @media (max-width: 1024px) {
    padding-top: 80px;
  }
  @media (max-width: 767px) {
    padding-top: 60px;
  }
  .container {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
  }
`;
