import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import styled from "styled-components";

import "./RouterTab.scss";

import Vest from "pages/Vest";

import glp from "img/routerTab/glp.svg";
import gmy_esgmy from "img/routerTab/gmy_esgmy.svg";
import overview from "img/routerTab/overview.svg";
import transfer_account from "img/routerTab/transfer_account.svg";
import msp from "img/routerTab/msp.svg";
import vest from "img/routerTab/vest.svg";
import glpLight from "img/routerTab/glpLight.svg";
import gmy_esgmyLight from "img/routerTab/gmy_esgmyLight.svg";
import overviewLight from "img/routerTab/overviewLight.svg";
import transfer_accountLight from "img/routerTab/transfer_accountLight.svg";
import mspLight from "img/routerTab/mspLight.svg";
import vestLight from "img/routerTab/vestLight.svg";

import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import Footer from "components/Footer/Footer";
import { getServerUrl } from "config/backend";
import { ARBITRUM, BASE, BERACHAIN_BARTIO, FANTOM, FANTOM_TESTNET, OP, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useGmyPrice, useTotalGmyStaked, useTotalEsGmyStaked } from "domain/legacy";
import { BigNumber, ethers } from "ethers";
import { contractFetcher } from "lib/contracts";
import {
  ESGMY_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  GMY_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import EarnGLP from "pages/Stake/EarnGLP";
import GMYandESGMY from "pages/Stake/GMYandESGMY";
import EarnOverview from "pages/Stake/Overview";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import bg from "img/earn/bg.svg";
import bgLight from "img/earn/bg-light.png";
import Gm from "pages/Stake/Gm";
import moment from "moment";
import useTokensPrice from "hooks/useTokensPrice";
import EarnOverviewV2 from "pages/Stake/OverviewV2";
import { useThemeContext } from "contexts/ThemeProvider";

const { AddressZero } = ethers.constants;
const EarnTabs = [
  {
    name: "Overview",
    params: null,
    img: overview,
    imgLight: overviewLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "GM",
    params: "gm",
    img: msp,
    imgLight: mspLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
    newTag: true,
  },
  {
    name: "GMY & esGMY",
    params: "gmy-esgmy",
    img: gmy_esgmy,
    imgLight: gmy_esgmyLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "GLP",
    params: "glp",
    img: glp,
    imgLight: glpLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "Vest",
    params: "vest",
    img: vest,
    imgLight: vestLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE],
  },
  // {
  //   name: "Vault",
  //   params: "vault",
  //   img: vault,
  //   chains: [FANTOM, ARBITRUM] //TODO legacy
  // },
  {
    name: "Transfer account",
    params: "transfer-account",
    img: transfer_account,
    imgLight: transfer_accountLight,
    chains: [FANTOM, BERACHAIN_BARTIO, ARBITRUM, BASE, FANTOM_TESTNET],
  },
];

const now = moment.utc().valueOf() / 1000;

export default function RouterTab({ setPendingTxns, connectWallet }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  let match = useRouteMatch();
  const location = useLocation();
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const gmAddress = getContract(chainId, "GM");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const stakedGmTrackerAddress = getContract(chainId, "StakedGmTracker");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const stakedGmyDistributorAddress = getContract(chainId, "StakedGmyDistributor");
  const stakedGlpDistributorAddress = getContract(chainId, "StakedGlpDistributor");
  const stakedGmDistributorAddress = getContract(chainId, "StakedGmDistributor");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const gmVesterAddress = getContract(chainId, "GmVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress, gmVesterAddress];

  const excludedEsGmyAccounts = [stakedGmyDistributorAddress, stakedGlpDistributorAddress, stakedGmDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress, gmAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
    gmAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
    feeGmTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [
      `StakeV2:stakedGmySupply:${active}:${stakedGmyTrackerAddress}`,
      chainId,
      gmyAddress,
      "balanceOf",
      stakedGmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: esGmySupply } = useSWR(
    [
      `StakeV2:esGmySupply:${active}:${excludedEsGmyAccounts}:${esGmyAddress}`,
      chainId,
      readerAddress,
      "getTokenSupply",
      esGmyAddress,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsGmyAccounts]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  const { gmyPrice, gmyPriceFromFantom } = useGmyPrice(
    chainId,
    { arbitrum: chainId === FANTOM ? library : undefined },
    active
  );

  // const { totalGmySupply, totalSupply } = useGMYInfo();
  let totalGmySupply, totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalGmySupply = totalSupplies[1];
    totalSupply = totalSupplies[1];
  }
  // const gmyPrice = BigNumber.from("1000000000000000000000000000000")

  // let { total: totalGmySupply } = useTotalGmySupply();
  // let totalGmySupply = BigNumber.from("10000000000000000000000000");

  let { op: opGmyStaked, fantom: fantomGmyStaked, total: totalGmyStaked } = useTotalGmyStaked();
  let { op: opEsGmyStaked, fantom: fantomEsGmyStaked, total: totalEsGmyStaked } = useTotalEsGmyStaked();

  // const gmySupplyUrl = getServerUrl(chainId, "/gmy_supply");
  // const { data: gmySupply } = useSWR([gmySupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  let gmySupply = totalSupply;

  // const isGmyTransferEnabled = true;

  let esGmySupplyUsd;
  if (esGmySupply && gmyPrice) {
    esGmySupplyUsd = esGmySupply.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusGmyTrackerRewards && processedData.bnGmyInFeeGmy) {
    multiplierPointsAmount = processedData.bonusGmyTrackerRewards.add(processedData.bnGmyInFeeGmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnGmyInFeeGmy && processedData.bonusGmyInFeeGmy) {
    totalRewardTokens = processedData.bnGmyInFeeGmy.add(processedData.bonusGmyInFeeGmy);
  }

  let totalRewardTokensAndGlp;
  if (totalRewardTokens && processedData && processedData.glpBalance) {
    totalRewardTokensAndGlp = totalRewardTokens.add(processedData.glpBalance);
  }

  const bonusGmyInFeeGmy = processedData ? processedData.bonusGmyInFeeGmy : undefined;

  let stakedGmySupplyUsd;
  if (!totalGmyStaked.isZero() && gmyPrice) {
    stakedGmySupplyUsd = totalGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let stakedEsGmySupplyUsd;
  if (!totalEsGmyStaked.isZero() && gmyPrice) {
    stakedEsGmySupplyUsd = totalEsGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalGmySupply && !totalGmySupply.isZero() && gmyPrice) {
    totalSupplyUsd = totalGmySupply.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableGmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.gmyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusGmyInFeeGmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.gmyVesterPairAmount);
    const stakedTokens = processedData.bonusGmyInFeeGmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableGmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }
  let earnMsg;
  if (totalRewardTokensAndGlp && totalRewardTokensAndGlp.gt(0)) {
    let gmyAmountStr;
    if (processedData.gmyInStakedGmy && processedData.gmyInStakedGmy.gt(0)) {
      gmyAmountStr = formatAmount(processedData.gmyInStakedGmy, 18, 2, true) + " GMY";
    }
    let esGmyAmountStr;
    if (processedData.esGmyInStakedGmy && processedData.esGmyInStakedGmy.gt(0)) {
      esGmyAmountStr = formatAmount(processedData.esGmyInStakedGmy, 18, 2, true) + " esGMY";
    }
    let mpAmountStr;
    if (processedData.bonusGmyInFeeGmy && processedData.bnGmyInFeeGmy.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnGmyInFeeGmy, 18, 2, true) + " MP";
    }
    let glpStr;
    if (processedData.glpBalance && processedData.glpBalance.gt(0)) {
      glpStr = formatAmount(processedData.glpBalance, 18, 2, true) + " GLP";
    }
    const amountStr = [gmyAmountStr, esGmyAmountStr, mpAmountStr, glpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <span>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndGlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </span>
      </div>
    );
  }

  //GM only for FANTOM
  const rewardsPrice = useTokensPrice({
    gmyPrice: gmyPriceFromFantom,
    chainId,
  });

  return (
    <Wrapper
      style={
        isLightTheme
          ? { backgroundImage: `url(${bgLight})`, backgroundSize: "contain", backgroundRepeat: "no-repeat" }
          : {}
      }
      className={`RouterTab ${lightThemeClassName}`}
    >
      <div className="total-wrapper-content">
        <div className="router">
          {EarnTabs.filter((x) => x.chains.includes(chainId)).map((item) => (
            <div
              key={item.params}
              className={`earn-tab ${
                (item.params && location.pathname.includes(item.params)) ||
                (!item.params && location.pathname === "/earn-v2/")
                  ? "earn-tab-active"
                  : ""
              } ${item.newTag ? "new-tag" : ""}`}
            >
              <Link to={`/earn-v2/${item.params || ""}`}>
                <img src={isLightTheme ? item.imgLight : item.img} alt={item.params} />
                <span>{item.name}</span>
              </Link>
            </div>
          ))}
        </div>
        <Switch>
          <Route path={`/earn-v2/gmy-esgmy`} exact>
            <GMYandESGMY
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              gmyPrice={gmyPrice}
              stakedGmySupplyUsd={stakedGmySupplyUsd}
              stakedEsGmySupplyUsd={stakedEsGmySupplyUsd}
              totalGmySupply={totalGmySupply}
              totalSupplyUsd={totalSupplyUsd}
              esGmySupplyUsd={esGmySupplyUsd}
              esGmySupply={esGmySupply}
              hasMultiplierPoints={hasMultiplierPoints}
              vestingData={vestingData}
              maxUnstakeableGmy={maxUnstakeableGmy}
              multiplierPointsAmount={multiplierPointsAmount}
              bonusGmyInFeeGmy={bonusGmyInFeeGmy}
            />
          </Route>
          <Route path={`/earn-v2/glp`} exact>
            <EarnGLP setPendingTxns={setPendingTxns} connectWallet={connectWallet} processedData={processedData} />
          </Route>
          <Route path={`/earn-v2/vest`} exact>
            <Vest
              totalRewardTokens={totalRewardTokens}
              vestingData={vestingData}
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
            />
          </Route>
          <Route path={match.path} exact>
            {/* <EarnOverview
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
            /> */}
            <EarnOverviewV2
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
              totalGmySupply={totalGmySupply}
              stakedGmySupplyUsd={stakedGmySupplyUsd}
              totalSupplyUsd={totalSupplyUsd}
              stakedEsGmySupplyUsd={stakedEsGmySupplyUsd}
              esGmySupplyUsd={esGmySupplyUsd}
              esGmySupply={esGmySupply}
              rewardsPrice={rewardsPrice}
            />
          </Route>
          <Route path={`/earn-v2/transfer-account`} exact>
            <BeginAccountTransfer setPendingTxns={setPendingTxns} connectWallet={connectWallet} />
          </Route>
          <Route path={`/earn-v2/gm`} exact>
            <Gm
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              // isBootstrapPhase={isBootstrapPhase}
              // endTimeBootstrapPhase={endTimeBootstrapPhase}
              rewardsPrice={rewardsPrice}
            />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  min-height: 900px;

  background-image: url(${bg});
  background-repeat: no-repeat;
  padding-bottom: 286px;
  background-size: contain;

  .earn-tab {
    opacity: 0.6 !important;

    a span {
      font-weight: 700;
    }

    &.new-tag {
      display: flex;
      align-items: center;
      gap: var(--base-unit-xs-8, 8px);
      position: relative;

      &::after {
        content: "New";
        display: flex;
        padding: 2px var(--Border_radius, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--base-unit-xs-8, 8px);
        border-radius: var(--Border_radius, 4px);
        border: 1px solid var(--Warning, #ffdf76);
        color: var(--Warning, #ffdf76);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%; /* 16.8px */

        @media screen and (max-width: 767px) {
          position: absolute;
          right: -0.953px;
          top: -4px;
          transform: rotate(29.671deg);
        }
      }
    }
  }
  .earn-tab-active {
    span {
      font-weight: 700;
    }
    opacity: 1 !important;
  }
  @media (max-width: 1024px) {
    /* min-height: 1200px; */
    background-size: initial;
    gap: 0;
  }

  @media (max-width: 600px) {
    gap: 0;
  }

  .total-wrapper-content {
    display: flex;

    gap: 24px;

    width: 1320px;
    /* height: fit-content; */

    /* padding: 0 24px; */

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 24px;
    }

    @media screen and (max-width: 767px) {
      gap: 40px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
    }

    .router {
      margin-top: 65px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 163px;

      a {
        cursor: pointer;
        display: flex;
        align-items: center;

        gap: 10px;

        font-weight: 700;
        font-size: 14px;

        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }

      .earn-tab-active {
        a {
          color: white !important;
        }
      }

      @media (max-width: 1023px) {
        margin-top: 64px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
        align-items: center;

        width: 100%;
        height: 95px;
        background: #191b2e;
        padding: 12px;
        margin-top: 0px;
        gap: unset;
        justify-content: space-between;
        .earn-tab {
          padding: 12px;
          opacity: 1 !important;
        }
        .earn-tab-active {
          span {
            font-weight: 700;
          }
          background: rgba(255, 255, 255, 0.1);
          border-radius: 12px;
        }
        div {
          flex: 1;
          a {
            display: flex;

            flex-direction: column;
            gap: 6px;
            opacity: 1;

            img {
              width: 32px;
              height: 32px;
            }

            font-weight: 500;
            font-size: 8px;

            color: #ffffff;

            &:nth-child(2) {
              span {
                max-width: 30px;
              }
            }

            span {
              max-width: 38px;

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 2048px) {
    height: 100%;
  }
`;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;
