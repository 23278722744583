import styled, { css } from "styled-components";
import Notice from "../Notice";
import Modal from "./Modal";
import {
  ARBITRUM,
  BERACHAIN_BARTIO,
  DEFAULT_CHAIN_ID,
  FANTOM,
  FANTOM_TESTNET,
  OP,
  SUPPORTED_CHAIN_IDS,
  getChainName,
} from "config/chains";
import { useCallback, useMemo } from "react";
import { clearWalletConnectData, clearWalletLinkData, switchNetwork } from "lib/wallets";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY } from "config/localStorage";
import { WalletConnectConnectorV2 } from "lib/wallets/WalletConnectV2";
import { useChainId } from "lib/chains";
import { useHistory } from "react-router-dom";

import wrong_network from "img/wrong-network.svg";
import wrong_networkLight from "img/wrong-networkLight.svg";
import { useThemeContext } from "contexts/ThemeProvider";

const listRoutes = ["#/earn-v2/gm", "#/analytic-v2"];
const listTradeRoutesV2 = ["#/trade", "#/trade?switch=true", "#/account-overview"];

const routesConfig = [
  { path: "#/vault", pageName: "Vault", chains: [FANTOM, ARBITRUM] },
  { path: "#/nft", pageName: "NFT Club", chains: [FANTOM, BERACHAIN_BARTIO] },
  { path: "#/swap", pageName: "Swap", chains: [FANTOM] },
  { path: "#/bridge/nft", pageName: "Bridge NFT", chains: [OP, ARBITRUM] },
  { path: "#/earn-v2/gm", pageName: "GM", chains: [FANTOM, BERACHAIN_BARTIO] },
  { path: "#/buy/mint", pageName: "Mint GM", chains: [FANTOM, BERACHAIN_BARTIO] },
  { path: "#/buy/redeem", pageName: "Redeem GM", chains: [FANTOM, BERACHAIN_BARTIO] },
  { path: "#/analytic-v2", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
  { path: "#/analytic-v2/overview", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
  { path: "#/analytic-v2/top-traders", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
  { path: "#/analytic-v2/top-positions", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
  { path: "#/analytic-v2/search", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
  { path: "#/analytic-v2/contest", pageName: "Analytics V2", chains: [FANTOM, BERACHAIN_BARTIO], isRoot: true },
];

const WrongChainModal = () => {
  const { error, deactivate, connector, account, active, chainId } = useWeb3React();
  const { isLightTheme, lightThemeClassName } = useThemeContext() as any;
  // const { chainId } = useChainId();
  const location = window.location;
  const history = useHistory();
  const isLanding = true;

  const pageName = [
    { route: "#/trade", name: "Trade V2" },
    { route: "#/v1", name: "Trade V1" },
    { route: "#/portfolio", name: "Account Overview" },
    { route: "#/earn-v2/gm", name: "GM" },
    { route: "#/earn-v2/", name: "Earn" },
    { route: "#/earn-v2/gmy-esgmy", name: "Earn" },
    { route: "#/earn-v2/glp", name: "Earn" },
    { route: "#/earn-v2/vest", name: "Earn" },
    { route: "#/earn-v2/transfer-account", name: "Earn" },
    { route: "#/dashboard", name: "Dashboard" },
    { route: "#/referrals", name: "Referrals" },
    { route: "#/bridge", name: "Bridge" },
    { route: "#/news", name: "News" },
    { route: "#/proof-of-locked-liquidity", name: "Proof of Locked Liquidity" },
    { route: "#/analytic-v2", name: "Analytics V2" },
    { route: "#/analytic", name: "Analytics" },
    { route: "#/buy/mint", name: "Mint/Redeem GM" },
    { route: "#/buy/redeem", name: "Mint/Redeem GM" },
    { route: "#/buy", name: "Buy" },
  ];

  const disconnect = () => {
    function disconnectAccount() {
      // only works with WalletConnect
      clearWalletConnectData();
      // force clear localStorage connection for MM/CB Wallet (Brave legacy)
      clearWalletLinkData();
      deactivate();
    }

    disconnectAccount();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
  };

  const isWrongNetwork = useMemo(() => {
    if (isLanding) return false;

    if (error instanceof UnsupportedChainIdError || (chainId && !SUPPORTED_CHAIN_IDS.includes(chainId))) {
      return true;
    }

    const currentRoute = routesConfig.find((route) =>
      route.isRoot ? location.hash.includes(route.path) : route.path === location.hash
    );

    if (currentRoute && chainId) {
      return !currentRoute.chains.includes(chainId);
    }

    if (listRoutes.includes(location.hash)) {
      if (chainId && chainId !== FANTOM) return true;
    }
    if (listTradeRoutesV2.includes(location.hash)) {
      if (chainId && chainId !== FANTOM && chainId !== BERACHAIN_BARTIO) return true;
    }
    if (!active) return false;
    return false;
  }, [chainId, error, isLanding, location.hash, active]);

  const handleSwitchChain = () => {
    if (routesConfig.find((route) => route.path === location.hash)) {
      const targetChain = routesConfig.find((route) => route.path === location.hash)?.chains[0] || DEFAULT_CHAIN_ID;
      switchNetwork(targetChain, true);

      return;
    }

    if (listRoutes.includes(location.hash)) {
      switchNetwork(FANTOM, true);
    } else switchNetwork(DEFAULT_CHAIN_ID, true);

    if (listTradeRoutesV2.includes(location.hash)) {
      switchNetwork(FANTOM, true);
    } else switchNetwork(DEFAULT_CHAIN_ID, true);
  };
  const handleRenderSuportedChain = () => {
    if (routesConfig.find((route) => route.path === location.hash)) {
      return routesConfig
        .find((route) => route.path === location.hash)
        ?.chains.map((chainId) => getChainName(chainId))
        .join(", ");
    }

    if (listRoutes.some((route) => location.hash.includes(route))) {
      return "Fantom";
    }

    if (listTradeRoutesV2.some((route) => location.hash.includes(route))) {
      return "Fantom";
    } else return SUPPORTED_CHAIN_IDS.map((chainId) => getChainName(chainId)).join(", ");
  };
  const renderAppPageTitle = () => {
    const page = pageName.find((x) => location.hash.includes(x.route));
    return page?.name || "this page";
  };
  return (
    <>
      <Modal label="Check your network" isVisible={isWrongNetwork} setIsVisible={null} className={`wrong-modal`}>
        <Text>
          Currently {renderAppPageTitle()} only supported in {handleRenderSuportedChain()}.
        </Text>
        <ActionWrapper>
          {/* <img src={isLightTheme ? wrong_networkLight : wrong_network} alt="wrong_network" /> */}
          <Notice content="Please switch your network to continue" />
          <div className="btns-wrapper">
            {connector instanceof WalletConnectConnectorV2 ? (
              <ButtonConnect className={lightThemeClassName} disabled>
                Can't switch here, please switch network in your wallet
              </ButtonConnect>
            ) : (
              <ButtonConnect className={lightThemeClassName} onClick={() => handleSwitchChain()}>
                Switch network in wallet
              </ButtonConnect>
            )}
            <button className="border-btn full" onClick={disconnect}>
              Disconnect
            </button>
          </div>
        </ActionWrapper>
      </Modal>
    </>
  );
};

const ActionWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 24px;

  .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;
  }

  & > img {
    height: 160px;
  }
`;

const ButtonConnect = styled.button<{ disabled?: boolean }>`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 8px;
  background: #34f5ae;
  border: none;
  outline: none;
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &.theme--light {
    background: var(--Primary, #02b27f);
    color: #fff !important;

    &:hover {
      opacity: 1;
      background: var(--Primary, #03805b);
    }
  }
`;

const Text = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 24px;
`;

export default WrongChainModal;
