import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : props.size === "small" ? "60" : "125"}
      height={props.height ? props.height : props.size === "small" ? "58" : "121"}
      fill="none"
      viewBox="0 0 125 121"
      {...props}
    >
      <path
        fill="#82ADEF"
        d="M22.472 17.19c8.631-6.685 18.846-11.744 29.163-14.873 40.393-12.251 80.685 26.88 71.221 67.657-2.924 12.617-10.052 24.137-19.597 32.833-13.383 12.21-31.803 19.017-49.857 17.452-43.622-3.758-70.49-63.146-38.931-95.938a76.71 76.71 0 017.981-7.152l.02.02z"
        opacity="0.1"
      ></path>
      <path
        fill="#82ADEF"
        d="M28.93 87.203c-4.285-6.725-7.31-14.507-8.895-22.227-6.255-30.232 24.878-57.01 53.96-47.441 8.996 2.966 16.916 8.92 22.603 16.437 8.001 10.565 11.738 24.421 9.423 37.485-5.626 31.533-50.609 47.197-72.4 22.024a53.8 53.8 0 01-4.67-6.298l-.02.02z"
        opacity="0.1"
      ></path>
      <path
        fill="#82ADEF"
        d="M57.2 91.734c-5.626-1.626-11.048-4.47-15.658-7.944-18.075-13.654-13.85-43.5 6.823-52.277 6.398-2.722 13.648-3.27 20.43-1.869 9.525 1.95 18.299 7.782 23.315 16.112 12.144 20.114-5.098 50.529-29.285 47.197a39.169 39.169 0 01-5.625-1.22z"
        opacity="0.1"
      ></path>
      <path
        fill="#82ADEF"
        d="M62.338 41.834a24.944 24.944 0 001.421-13.51c.813 5.404 5.402 12.109 8.794 16.253 5.22 6.38 14.277 15.056 23.558 13.674-7.758 1.138-15.008 5.465-19.801 11.662-4.285 5.526-10.175 13.755-9.2 21.11-1.564-11.784-6.234-22.532-18.257-26.575-5.869-1.971-12.165-2.235-18.338-2.5 5.686-.73 11.494-1.645 16.47-4.49 5.442-3.108 11.332-7.76 14.317-13.389.386-.731.731-1.483 1.036-2.255v.02z"
      ></path>
    </svg>
  );
}

export default Icon;
