import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <g clipPath="url(#clip0_18948_1449)">
        <g clipPath="url(#clip1_18948_1449)">
          <path fill="#F2F4F7" d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"></path>
          <path fill="#343434" d="M20.31 5.781l-.19.652V25.33l.19.19 8.772-5.184L20.31 5.78z"></path>
          <path fill="#8C8C8C" d="M20.309 5.781l-8.772 14.555 8.772 5.184V5.781z"></path>
          <path fill="#3C3C3B" d="M20.309 27.181l-.108.133v6.731l.108.316L29.087 22l-8.778 5.181z"></path>
          <path fill="#8C8C8C" d="M20.309 34.361v-7.18l-8.772-5.183 8.772 12.363z"></path>
          <path fill="#141414" d="M20.31 25.52l8.771-5.184-8.771-3.987v9.171z"></path>
          <path fill="#393939" d="M11.537 20.336l8.772 5.184V16.35l-8.772 3.987z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_18948_1449">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
        <clipPath id="clip1_18948_1449">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
