import { ImageHeadBanner } from "assets";
import { targetDate } from "constants/constants";
import SponsorBanner from "containers/Landing/SponsorBanner";
import Countdown from "react-countdown";
import { useMediaQuery } from "react-responsive";
import Fade from "react-reveal/Fade";
import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";
import { countdownVariants, descriptionVariants, titleVariants } from "lib/anim";
import pythlogo from "../images/pyth-logotype-light-10.svg";

const Hero = ({ chainId, dataSpon }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const countdownRenderer = (countdownProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const d = String(days);
    const h = String(hours);
    const m = String(minutes);
    return (
      <div className="time-countdown">
        <div className="time-left">
          <div className="box-time">
            <div className="number-time">{d.padStart(2, "0")}</div>
            <span>days</span>
          </div>
        </div>{" "}
        <div className="time-left">
          <div className="box-time">
            <div className="number-time">{h.padStart(2, "0")}</div>
            <span>hours</span>
          </div>
        </div>{" "}
        <div className="time-left">
          <div className="box-time">
            <div className="number-time"> {m.padStart(2, "0")}</div>
            <span>minutes</span>{" "}
          </div>
        </div>{" "}
      </div>
    );
  };
  return (
    <Wrapper>
      <Inner>
        <div className="banner">
          <div>
            <Heading>
              <LeftHead>
                <motion.div initial="hidden" whileInView="visible" variants={titleVariants}>
                  <Title>The All-in-One DEX for Berachain</Title>
                </motion.div>

                <motion.div initial="hidden" whileInView="visible" variants={descriptionVariants}>
                  <Description className="desc-head">Everything Everywhere All on Yummy Finance</Description>
                </motion.div>

                {/* <motion.div initial="hidden" whileInView="visible" variants={descriptionVariants}>
                  <div className="testnet">Testnet will be available in</div>
                </motion.div>

                <motion.div initial="hidden" whileInView="visible" variants={countdownVariants}>
                  <Countdown date={targetDate} renderer={countdownRenderer} />
                </motion.div> */}
                <motion.div initial="hidden" whileInView="visible" variants={titleVariants}>
                <Description>
                  <a className="launch-app-btn" href="https://app.yummy.fi/#/trade">
                    <button className="btn-fill">Launch app</button>
                  </a>
                  </Description>
                </motion.div>

                <motion.div initial="hidden" whileInView="visible" variants={titleVariants}>
                  <div class="frame-427321607">
                    <div class="powered-by">Powered by</div>
                    <a href={"https://www.pyth.network/"} target="_blank" className="microswap-icon" rel="noreferrer">
                      <img src={pythlogo} alt="pyth logo" />
                    </a>
                  </div>
                </motion.div>
              </LeftHead>

              <RightHead>
                <motion.img
                  src={ImageHeadBanner}
                  className="head-banner"
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    damping: 15,
                    duration: 0.7,
                  }}
                />
              </RightHead>
            </Heading>
          </div>
        </div>
        <SponsorBanner dataSpon={dataSpon} />
      </Inner>
    </Wrapper>
  );
};

function createCSS() {
  const altAttrNames = {
    bnbIc: [7, 0, 11, 20.93],
    daiIc: [21.42, 10.42, 14.67, 16.67],
    usdtIc: [10.17, 19.37, 26.66, 4.53],
    btcIc: [87.67, 20.83, 10.33, 12.5],
    ethIc: [84.26, 40.22, 26.92, 45.35],
    usdcIc: [8.42, 10.42, 50.07, 7.05],
    ftmIc: [89, 10.42, 47.07, 7.05],
  };

  let styles = "";

  Object.keys(altAttrNames).forEach((name) => {
    styles += `
      img[alt="${name}"] {
        left: ${altAttrNames[name][0]}%;
        right: ${altAttrNames[name][1]}%;
        top: ${altAttrNames[name][2]}%;
        bottom: ${altAttrNames[name][3]}%;

        @media (max-width: 1024px) {
          top: ${altAttrNames[name][2] / 2}%;
          scale: 0.6;
        }

        @media (max-width: 767px) {
          top: ${altAttrNames[name][2] / 2.5}%;
          scale: 0.4;
        }
      }
    `;
  });

  return css`
    ${styles}
  `;
}

const floating = keyframes`
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 5px); }
  100%   { transform: translate(0, -0px); }
`;

const FloatTokens = styled.div`
  position: absolute;
  inset: 0;
  z-index: 5;

  img {
    position: absolute;
    animation: ${floating} 2.5s ease-in-out 0.33s infinite normal none;
  }
  @media (max-width: 1840px) {
    img {
      opacity: 0.4;
    }
  }
  @media (max-width: 1024px) {
    img {
      opacity: 1;
    }
  }
  ${createCSS()}
`;

const GradientText = styled.div`
  height: 75px;

  @media (max-width: 1024px) {
    height: 50px;
  }

  @media (max-width: 767px) {
    height: 35px;
  }
`;

const Title = styled.div`
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0 auto;
  text-align: left;
  background: linear-gradient(90deg, #3f8ffd 0%, #f8fff8 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-bottom: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 67.2px */
    width: 80%;
  }

  @media (max-width: 767px) {
    text-align: center;

    /* Small_Title/Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fff !important;
  text-align: left;
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    color: #fff;
    text-align: center;

    /* Small_Title/Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
  @media (max-width: 767px) {
    color: #fff;
    text-align: center;

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    margin-bottom: 24px;
  }
`;

const StatItem = styled.div`
  flex: 1;
  background-color: #ffffff1a;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  img {
    width: 36px;
    height: 36px;
    filter: drop-shadow(rgb(0, 255, 200) 0px 4px 12px);
    /* box-shadow: 0px 4px 24px 0px #C1FF99; */
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    height: 100%;

    .value {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
    }

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #ffffff99;
      margin-top: auto;
    }
  }

  @media (max-width: 1024px) {
    min-width: calc(100% / 4);

    &:nth-child(4),
    &:nth-child(5) {
      width: 224px;
      flex: unset;
    }

    .content {
      .value {
        font-size: 16px;
      }

      .title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    min-width: unset;
    flex-direction: row;
    padding: 16px;

    &:nth-child(4),
    &:nth-child(5) {
      width: unset;
      flex: unset;
    }

    .content {
      gap: 8px;
      align-items: flex-start;

      .value {
        font-size: 16px;
      }
    }
  }
`;

const Stats = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  .value span {
    color: #fff !important;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    gap: 16px;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 0;
  }

  @media (max-width: 767px) {
    gap: 0;
  }

  .banner {
    margin-bottom: 200px;
    @media (max-width: 1024px) {
      margin-bottom: 120px;
    }
    @media (max-width: 767px) {
      margin-bottom: 64px;
    }
    .frame-427321607,
    .frame-427321607 * {
      box-sizing: border-box;
    }
    .frame-427321607 {
      border-radius: 12px;
      border-style: solid;
      border-color: var(--border, rgba(239, 236, 235, 0.08));
      border-width: 1px;
      padding: 8px 16px 8px 16px;
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      position: relative;
    }
    .powered-by {
      color: #667180;
      text-align: left;
      font-family: var(--16-bold-font-family, "Inter-Bold", sans-serif);
      font-size: var(--16-bold-font-size, 16px);
      line-height: var(--16-bold-line-height, 140%);
      font-weight: var(--16-bold-font-weight, 700);
      position: relative;
    }
    .pyth-logotype-light-1 {
      flex-shrink: 0;
      width: 93.86px;
      height: 32px;
      position: relative;
      overflow: visible;
    }
    .launch-app-btn {
      text-decoration: auto;
    }
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  @media (max-width: 1660px) {
    max-width: 1250px;
  }
  @media (max-width: 1024px) {
    gap: 24px;
    justify-content: center;
    flex-direction: column-reverse;
  }
  @media (max-width: 767px) {
    gap: 24px;
    flex-direction: column-reverse;
    padding: 0px 24px;
  }
`;
const LeftHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  @media (max-width: 1024px) {
    align-items: center;
  }
  .testnet {
    color: var(--Text-Text_Secondary, #999691);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    @media (max-width: 1024px) {
      color: var(--Text-Text_Secondary, #999691);
      text-align: center;

      /* 16/Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
    @media (max-width: 767px) {
      align-items: center;
      color: var(--Text-Text_Secondary, #999691);
      text-align: center;

      /* 14/Bold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  .time-countdown {
    display: flex;
    align-items: center;
    gap: var(--Base-Unit-S-12, 12px);
    margin-top: 12px;
  }
  .time-left {
    display: flex;
    padding: var(--Base-Unit-XS-8, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 30px;
    border: 2px solid var(--Functional-Orange, #50feb9);
    @media (max-width: 767px) {
      display: flex;

      padding: 4px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 20px;
      border: 1px solid var(--Functional-Orange, #50feb9);
    }
  }
  .box-time {
    display: flex;
    padding: 24px 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 24px;
    background: var(--Functional-Orange, #50feb9);
    width: 120px;
    span {
      margin-top: -4px;
    }
    @media (max-width: 1660px) {
    }
    @media (max-width: 1024px) {
      width: 94px;
      height: 94px;
    }
    @media (max-width: 767px) {
      width: 72px;
      height: 72px;
      display: flex;
      padding: 16px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: -4px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 16px;
      background: var(--Functional-Orange, #fed74c);
    }
    span {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      @media (max-width: 767px) {
        color: #000;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 14px */
      }
    }
  }
  .number-time {
    color: #000;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media (max-width: 1024px) {
      color: #000;

      /* Small_Title/Bold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
`;
const RightHead = styled.div`
  .head-banner {
    @media (max-width: 1024px) {
      width: 241.748px;
    }
    @media (max-width: 767px) {
      width: 193px;
    }
  }
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* img {
    width: 659px;
    height: 446px;
    @media (max-width: 1660px) {
      width: 620px;
      height: 415px;
    }
    @media (max-width: 1024px) {
      width: 600px;
      height: 406px;
    }
    @media (max-width: 767px) {
      width: 362px;
      height: 253px;
    }
  } */
`;

const TradeButton = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #080715;
  background-color: #c1ff99;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  outline: none;
  text-decoration: none;
  margin-bottom: 48px;
  &:hover {
    background: #b7e399;
    color: #080715 !important;
  }

  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 24px;
    border-radius: 8px;
  }
`;

const PoweredByGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PowerOwner = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  height: 100%;
  position: relative;
  .link-styled {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .line {
    height: 48px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.6);
  }
  img {
    width: 140px;
    height: 48px;
  }

  @media (max-width: 1024px) {
    img {
      width: 120px;
      height: 40px;
    }
    .line {
      height: 40px;
    }
  }
  @media (max-width: 767px) {
    gap: 12px;
    img {
      width: 100px;
      height: 32px;
    }
    .line {
      height: 32px;
    }
  }
`;
const Label = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  color: rgba(255, 255, 255, 0.6) !important;
  width: 100%;
  text-align: left;
  display: flex;
  svg {
    margin-right: 2px;
  }
  @media (max-width: 1024px) {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: relative;

  .videoTag {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .audit {
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #ffffff30;
    @media (max-width: 767px) {
      margin-right: 12px;
      padding-right: 12px;
    }
  }
  @media (max-width: 1024px) {
    padding: 60px 0 48px;
  }

  @media (max-width: 1024px) {
    padding: 24px 0 0px;
  }

  > video {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    object-fit: cover;
    background-color: #000000;
    filter: brightness(30%) blur(4px);
  }
`;

export default Hero;
