import { ImageFaucet } from "assets";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Token from "abis/Token.json";
import { callContract, contractFetcher } from "lib/contracts";
import { useChainId } from "lib/chains";
import { getWhitelistedTokens } from "config/tokens";

const Faucet = () => {
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped && !t.isNative);
  const handleSuscessCapcha = () => {
    setIsDisable(false);
  };

  const onFaucet = () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(tokenList[1].address, Token.abi, library.getSigner());
    const method = "mint"; //faucet";
    const params = [account,"1000000000000000000"];
    
    callContract(chainId, contract, method, params, {
      sentMsg: `Faucet submitted!`,
      failMsg: `Faucet failed.`,
      successMsg: `Faucet success!`,
    })
      .then(async () => { })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <Wrapper>
      <div className="wrapper-img">
        <img src={ImageFaucet} alt="" />
        <div className="connect"></div>
      </div>
      <div className="wrapper-box">
        <div className="head">
          <div className="title">Brachain Testnet Faucets</div>
          <div className="desc">Fast and reliable. 0.1 BERA/day.</div>
        </div>
        <ReCAPTCHA hl="en" sitekey="6LfMySkqAAAAAJ2S2Ui6qrJmLY1QDgMScEp9SKBj" onChange={handleSuscessCapcha} />
        <button onClick={onFaucet} className="default-btn full">
          Faucet now
        </button>
      </div>
    </Wrapper>
  );
};

export default Faucet;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 95px;
  gap: 79px;
  .wrapper-img {
    position: relative;
  }
  .connect {
    position: absolute;
    width: 78.152px;
    height: 22.881px;
    flex-shrink: 0;
    border: 2px solid #06002b;
    background: #29d0f0;
    top: 26%;
    right: -33%;
  }
  .wrapper-box {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 24px;
    border: 2px solid #29d0f0;
    min-width: 512px;
  }
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
  .desc {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
`;
