import React, { useEffect } from "react";

const LoadingPage = () => {
  useEffect(() => {
    // CSS
    const style = document.createElement("style");
    style.textContent = `
      @keyframes line-move {
        from,
        35%,
        to {
          stroke-dashoffset: -6.99px;
        }
        50%,
        85% {
          stroke-dashoffset: 4.99px;
        }
      }
      @keyframes line-wobble {
        from,
        to {
          stroke-width: 1px;
          transform: translate(0,6.5px);
        }
        25% {
          stroke-width: 1.5px;
          transform: translate(0,6.5px);
        }
        31.25% {
          stroke-width: 1px;
          transform: translate(0,7px);
        }
        37.5% {
          stroke-width: 1.5px;
          transform: translate(0,5px);
        }
        50% {
          stroke-width: 1px;
          transform: translate(0,6.5px);
        }
        75% {
          stroke-width: 1.5px;
          transform: translate(0,6.5px);
        }
        81.25% {
          stroke-width: 1px;
          transform: translate(0,6px);
        }
        87.5% {
          stroke-width: 1.5px;
          transform: translate(0,8px);
        }
      }
      :root {
        --hue: 223;
        --bg: hsl(var(--hue),90%,90%);
        --fg: hsl(var(--hue),90%,10%);
        --line1: hsl(193, 90%, 35%);
        --line2: hsl(203, 90%, 40%);
        --line3: hsl(213, 90%, 45%);
        --line4: hsl(var(--hue),90%,50%);
        --trans-dur: 0.3s;
        font-size: calc(14px + (30 - 14) * (100vw - 280px) / (3840 - 280));
      }
      body {
        background-color: var(--bg);
        color: var(--fg);
        display: flex;
        font: 1em/1.5 sans-serif;
        height: 100vh;
        transition:
          background-color var(--trans-dur),
          color var(--trans-dur);
      }
      .pl {
        --dur: 4s;
        display: block;
        margin: auto;
        width: 15em;
        height: auto;
      }
      .pl__line,
      .pl__line-wobble {
        animation-duration: var(--dur);
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.65,0,0.35,1);
      }
      .pl__line {
        animation-name: line-move;
        animation-timing-function: cubic-bezier(0.33,1,0.67,1);
        stroke: var(--line1);
        transition: stroke var(--trans-dur);
      }
      .pl__line:nth-child(2) { stroke: var(--line2); }
      .pl__line:nth-child(3) { stroke: var(--line3); }
      .pl__line:nth-child(4) { stroke: var(--line4); }
      .pl__line-wobble {
        animation-name: line-wobble;
      }
      .pl__lines:nth-child(8n) .pl__line { animation-delay: calc(var(--dur) * -0.01); }
      .pl__lines:nth-child(8n + 1) .pl__line { animation-delay: calc(var(--dur) * -0.13); }
      .pl__lines:nth-child(8n + 2) .pl__line { animation-delay: calc(var(--dur) * -0.25); }
      .pl__lines:nth-child(8n + 3) .pl__line { animation-delay: calc(var(--dur) * -0.37); }
      .pl__lines:nth-child(8n + 4) .pl__line { animation-delay: calc(var(--dur) * -0.49); }
      .pl__lines:nth-child(8n + 5) .pl__line { animation-delay: calc(var(--dur) * -0.61); }
      .pl__lines:nth-child(8n + 6) .pl__line { animation-delay: calc(var(--dur) * -0.73); }
      .pl__lines:nth-child(8n + 7) .pl__line { animation-delay: calc(var(--dur) * -0.85); }
      .pl__lines:nth-child(8n) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.01); }
      .pl__lines:nth-child(8n + 1) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.13); }
      .pl__lines:nth-child(8n + 2) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.25); }
      .pl__lines:nth-child(8n + 3) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.37); }
      .pl__lines:nth-child(8n + 4) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.49); }
      .pl__lines:nth-child(8n + 5) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.61); }
      .pl__lines:nth-child(8n + 6) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.73); }
      .pl__lines:nth-child(8n + 7) .pl__line-wobble { animation-delay: calc(var(--dur) * -0.85); }
      @media (prefers-color-scheme: dark) {
        :root {
          --bg: hsl(var(--hue),90%,10%);
          --fg: hsl(var(--hue),90%,90%);
        }
      }
    `;
    document.head.appendChild(style);

    // HTML
    const createSVG = () => {
      const sectors = 24;
      const lines = 4;
      const size = 32;
      const viewBox = `0 0 ${size} ${size}`;
      const sizePx = `${size}px`;
      const center = size / 2;
      const centerCoords = `${center},${center}`;

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("class", "pl");
      svg.setAttribute("viewBox", viewBox);
      svg.setAttribute("width", sizePx);
      svg.setAttribute("height", sizePx);

      const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
      g.setAttribute("stroke", "currentcolor");
      g.setAttribute("stroke-linecap", "round");
      g.setAttribute("stroke-width", "1");
      g.setAttribute("stroke-dasharray", "5 8");

      for (let sector = 0; sector < sectors; sector++) {
        const angle = (-360 / sectors) * sector;
        const rotate = `rotate(${angle},${centerCoords})`;

        const gLines = document.createElementNS("http://www.w3.org/2000/svg", "g");
        gLines.setAttribute("class", "pl__lines");
        gLines.setAttribute("transform", rotate);

        const gLineWobble = document.createElementNS("http://www.w3.org/2000/svg", "g");
        gLineWobble.setAttribute("class", "pl__line-wobble");
        gLineWobble.setAttribute("transform", "translate(0,6.5)");

        for (let ln = 0; ln < lines; ln++) {
          const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
          line.setAttribute("class", "pl__line");
          line.setAttribute("x1", "16");
          line.setAttribute("y1", "16");
          line.setAttribute("x2", "16");
          line.setAttribute("y2", "23");
          gLineWobble.appendChild(line);
        }

        gLines.appendChild(gLineWobble);
        g.appendChild(gLines);
      }

      svg.appendChild(g);
      return svg;
    };

    const loaderContainer = document.getElementById("loader-container");
    if (loaderContainer) {
      loaderContainer.appendChild(createSVG());
    }

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      const loaderContainer = document.getElementById("loader-container");
      if (loaderContainer) {
        loaderContainer.innerHTML = "";
      }
    };
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "scale(0.5)",
      }}
      id="loader-container"
    ></div>
  );
};

export default LoadingPage;
