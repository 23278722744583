import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "32px"}
      height={props.height ? props.height : "32px"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.fill ? props.fill : "#fff"}
        d="M27.766 7.586L30 5.464V5h-7.74l-5.516 13.626L10.47 5H2.354v.464l2.61 3.119c.254.23.387.567.353.906v12.254a1.39 1.39 0 01-.377 1.216L2 26.495v.459h8.336v-.465l-2.94-3.53a1.434 1.434 0 01-.405-1.216v-10.6l7.317 15.816h.85l6.293-15.815v12.599c0 .332 0 .4-.22.618l-2.263 2.174V27h10.98v-.464l-2.181-2.12a.642.642 0 01-.25-.619V8.204a.64.64 0 01.249-.618z"
      ></path>
    </svg>
  );
}

export default Icon;
