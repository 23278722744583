import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import Checkbox from "components/Checkbox/Checkbox";
import Footer from "components/Footer/Footer";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";

import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import RewardRouter from "abis/RewardRouter.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import Vester from "abis/Vester.json";
import { ethers } from "ethers";
import styled from "styled-components";

import { ARBITRUM, BASE, FANTOM, getChainName, getConstant } from "config/chains";
import { useGmyPrice, useTotalGmyStaked } from "domain/legacy";
import {
  BASIS_POINTS_DIVISOR,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getPageTitle,
  getProcessedData,
  getStakingData,
  getVestingData,
  GLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
} from "lib/legacy";

import useSWR from "swr";

import { getContract } from "config/contracts";

import SEO from "components/Common/SEO";
import ExternalLink from "components/ExternalLink/ExternalLink";
import GMYAprTooltip from "components/Stake/GMYAprTooltip";
import StatsTooltip from "components/StatsTooltip/StatsTooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getServerUrl } from "config/backend";
import { approveTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  formatKeyAmountFixed,
  parseValue,
} from "lib/numbers";
import "./StakeV2.css";
const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    farmAddress,
    rewardRouterAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, farmAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: `Stake submitted!`,
      failMsg: `Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return `Staking...`;
    }
    return `Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Stake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        {stakingTokenSymbol === "esGMY" && chainId !== ARBITRUM && chainId !== BASE ? (
          <Warning>
            Please note that your staked esGMY amount includes the esGMY bonus from minting NFTs. Keep in mind that if
            you decide to unstake and vest esGMY to GMY later, you will be required to deposit a reserve amount of GMY
            that corresponds to the rewards generated.
          </Warning>
        ) : null}

        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    rewardRouterAddress,
    unstakeMethodName,
    multiplierPointsAmount,
    reservedAmount,
    bonusGmyInFeeGmy,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);
  let burnAmount;

  if (
    multiplierPointsAmount &&
    multiplierPointsAmount.gt(0) &&
    amount &&
    amount.gt(0) &&
    bonusGmyInFeeGmy &&
    bonusGmyInFeeGmy.gt(0)
  ) {
    burnAmount = multiplierPointsAmount.mul(amount).div(bonusGmyInFeeGmy);
  }

  const shouldShowReductionAmount = true;
  let rewardReductionBasisPoints;
  if (burnAmount && bonusGmyInFeeGmy) {
    rewardReductionBasisPoints = burnAmount.mul(BASIS_POINTS_DIVISOR).div(bonusGmyInFeeGmy);
  }

  const getError = () => {
    if (!amount) {
      return `Enter an amount`;
    }
    if (amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: `Unstake submitted!`,
      failMsg: `Unstake failed.`,
      successMsg: `Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return `Unstaking...`;
    }
    return `Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <span>Unstake</span>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            Unstaking will burn&nbsp;
            <ExternalLink href="https://docs.yummy.fi/rewards">
              {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
            </ExternalLink>
            .&nbsp;
            {shouldShowReductionAmount && (
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            )}
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function VesterDepositModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    balance,
    vestedAmount,
    averageStakedAmount,
    maxVestableAmount,
    library,
    stakeTokenLabel,
    reserveAmount,
    maxReserveAmount,
    vesterAddress,
    setPendingTxns,
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);

  let amount = parseValue(value, 18);

  let nextReserveAmount = reserveAmount;

  let nextDepositAmount = vestedAmount;
  if (amount) {
    nextDepositAmount = vestedAmount.add(amount);
  }

  let additionalReserveAmount = bigNumberify(0);
  if (amount && averageStakedAmount && maxVestableAmount && maxVestableAmount.gt(0)) {
    nextReserveAmount = nextDepositAmount.mul(averageStakedAmount).div(maxVestableAmount);
    if (nextReserveAmount.gt(reserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(reserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return `Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return `Max amount exceeded`;
    }
    if (nextReserveAmount.gt(maxReserveAmount)) {
      return `Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: `Deposit submitted!`,
      failMsg: `Deposit failed!`,
      successMsg: `Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <span>Deposit</span>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}
              >
                <span>Max: {formatAmount(maxAmount, 18, 4, true)}</span>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esGMY</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Wallet</span>
              </div>
              <div className="align-right">{formatAmount(balance, 18, 2, true)} esGMY</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Vault Capacity</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    maxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div>
                        <p className="text-white">
                          <span>Vault Capacity for your Account:</span>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Deposited`}
                          value={`${formatAmount(vestedAmount, 18, 2, true)} esGMY`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={`Max Capacity`}
                          value={`${formatAmount(maxVestableAmount, 18, 2, true)} esGMY`}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <span>Reserve Amount</span>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    reserveAmount && reserveAmount.gte(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <>
                        <StatsTooltipRow
                          label={`Current Reserved`}
                          value={formatAmount(reserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={`Additional reserve required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            <span>
                              You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)}{" "}
                              {stakeTokenLabel} to vest {formatAmount(amount, 18, 2, true)} esGMY.
                            </span>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}

function VesterWithdrawModal(props) {
  const { isVisible, setIsVisible, chainId, title, library, vesterAddress, setPendingTxns } = props;
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const onClickPrimary = () => {
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: `Withdraw submitted.`,
      failMsg: `Withdraw failed.`,
      successMsg: `Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <span>
          <div>
            This will withdraw and unreserve all tokens as well as pause vesting.
            <br />
            <br />
            esGMY tokens that have been converted to GMY will remain as GMY tokens.
            <br />
            <br />
            To claim GMY tokens without withdrawing, use the "Claim" button under the Total Rewards section.
            <br />
            <br />
          </div>
        </span>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={isWithdrawing}>
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function CompoundModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    active,
    account,
    library,
    chainId,
    setPendingTxns,
    totalVesterRewards,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isCompounding, setIsCompounding] = useState(false);
  // const shouldClaimGmy = false; // delete here
  // const shouldStakeGmy = false; // delete here

  // const [shouldClaimGmytest,setShouldClaimGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-claim-gmy"], true); // delete here
  // const [shouldStakeGmytest,setShouldStakeGmy] = useLocalStorageSerializeKey([chainId, "StakeV2-compound-should-stake-gmy"], true); // delete here

  // open here //

  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-gmy"],
    true
  );
  const [shouldStakeGmy, setShouldStakeGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-gmy"],
    true
  );

  // open here //

  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-es-gmy"],
    true
  );
  const [shouldStakeEsGmy, setShouldStakeEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-stake-es-gmy"],
    true
  );
  const [shouldStakeMultiplierPoints, setShouldStakeMultiplierPoints] = useState(true);
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-compound-should-convert-weth"],
    true
  );

  const gmyAddress = getContract(chainId, "GMY");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");

  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, gmyAddress, "allowance", account, stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const needApproval = shouldStakeGmy && tokenAllowance && totalVesterRewards && totalVesterRewards.gt(tokenAllowance);

  const isPrimaryEnabled = () => {
    return !isCompounding && !isApproving && !isCompounding;
  };

  const getPrimaryText = () => {
    if (isApproving) {
      return `Approving GMY...`;
    }
    if (needApproval) {
      return `Approve GMY`;
    }
    if (isCompounding) {
      return `Compounding...`;
    }
    return `Compound`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: gmyAddress,
        spender: stakedGmyTrackerAddress,
        chainId,
      });
      return;
    }

    setIsCompounding(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy || shouldStakeGmy,
        shouldStakeGmy,
        shouldClaimEsGmy || shouldStakeEsGmy,
        shouldStakeEsGmy,
        shouldStakeMultiplierPoints,
        shouldClaimWeth || shouldConvertWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Compound submitted!`,
        failMsg: `Compound failed.`,
        successMsg: `Compound completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsCompounding(false);
      });
  };

  const toggleShouldStakeGmy = (value) => {
    if (value) {
      setShouldClaimGmy(true);
    }
    setShouldStakeGmy(value);
  };

  const toggleShouldStakeEsGmy = (value) => {
    if (value) {
      setShouldClaimEsGmy(true);
    }
    setShouldStakeEsGmy(value);
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Compound Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox
              isChecked={shouldStakeMultiplierPoints}
              setIsChecked={setShouldStakeMultiplierPoints}
              disabled={true}
            >
              <span>Stake Multiplier Points</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy} disabled={shouldStakeGmy}>
              <span>Claim GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeGmy} setIsChecked={toggleShouldStakeGmy}>
              <span>Stake GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy} disabled={shouldStakeEsGmy}>
              <span>Claim esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldStakeEsGmy} setIsChecked={toggleShouldStakeEsGmy}>
              <span>Stake esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimModal(props) {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    setPendingTxns,
    nativeTokenSymbol,
    wrappedTokenSymbol,
  } = props;
  const [isClaiming, setIsClaiming] = useState(false);
  const [shouldClaimGmy, setShouldClaimGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-gmy"],
    true
  );
  const [shouldClaimEsGmy, setShouldClaimEsGmy] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-es-gmy"],
    true
  );
  const [shouldClaimWeth, setShouldClaimWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-claim-weth"],
    true
  );
  const [shouldConvertWeth, setShouldConvertWeth] = useLocalStorageSerializeKey(
    [chainId, "StakeV2-claim-should-convert-weth"],
    true
  );

  const isPrimaryEnabled = () => {
    return !isClaiming;
  };

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`;
    }
    return `Claim`;
  };

  const onClickPrimary = () => {
    setIsClaiming(true);

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      "handleRewards",
      [
        shouldClaimGmy,
        false, // shouldStakeGmy
        shouldClaimEsGmy,
        false, // shouldStakeEsGmy
        false, // shouldStakeMultiplierPoints
        shouldClaimWeth,
        shouldConvertWeth,
      ],
      {
        sentMsg: `Claim submitted.`,
        failMsg: `Claim failed.`,
        successMsg: `Claim completed!`,
        setPendingTxns,
      }
    )
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsClaiming(false);
      });
  };

  const toggleConvertWeth = (value) => {
    if (value) {
      setShouldClaimWeth(true);
    }
    setShouldConvertWeth(value);
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={`Claim Rewards`}>
        <div className="CompoundModal-menu">
          <div>
            <Checkbox isChecked={shouldClaimGmy} setIsChecked={setShouldClaimGmy}>
              <span>Claim GMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimEsGmy} setIsChecked={setShouldClaimEsGmy}>
              <span>Claim esGMY Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldClaimWeth} setIsChecked={setShouldClaimWeth} disabled={shouldConvertWeth}>
              <span>Claim {wrappedTokenSymbol} Rewards</span>
            </Checkbox>
          </div>
          <div>
            <Checkbox isChecked={shouldConvertWeth} setIsChecked={toggleConvertWeth}>
              <span>
                Convert {wrappedTokenSymbol} to {nativeTokenSymbol}
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default function StakeV2({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  const hasInsurance = false;

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakingFarmAddress, setStakingFarmAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const [isVesterDepositModalVisible, setIsVesterDepositModalVisible] = useState(false);
  const [vesterDepositTitle, setVesterDepositTitle] = useState("");
  const [vesterDepositStakeTokenLabel, setVesterDepositStakeTokenLabel] = useState("");
  const [vesterDepositMaxAmount, setVesterDepositMaxAmount] = useState("");
  const [vesterDepositBalance, setVesterDepositBalance] = useState("");
  const [vesterDepositEscrowedBalance, setVesterDepositEscrowedBalance] = useState("");
  const [vesterDepositVestedAmount, setVesterDepositVestedAmount] = useState("");
  const [vesterDepositAverageStakedAmount, setVesterDepositAverageStakedAmount] = useState("");
  const [vesterDepositMaxVestableAmount, setVesterDepositMaxVestableAmount] = useState("");
  const [vesterDepositValue, setVesterDepositValue] = useState("");
  const [vesterDepositReserveAmount, setVesterDepositReserveAmount] = useState("");
  const [vesterDepositMaxReserveAmount, setVesterDepositMaxReserveAmount] = useState("");
  const [vesterDepositAddress, setVesterDepositAddress] = useState("");

  const [isVesterWithdrawModalVisible, setIsVesterWithdrawModalVisible] = useState(false);
  const [vesterWithdrawTitle, setVesterWithdrawTitle] = useState(false);
  const [vesterWithdrawAddress, setVesterWithdrawAddress] = useState("");

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const stakedGmyDistributorAddress = getContract(chainId, "StakedGmyDistributor");
  const stakedGlpDistributorAddress = getContract(chainId, "StakedGlpDistributor");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress];

  const excludedEsGmyAccounts = [stakedGmyDistributorAddress, stakedGlpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [`StakeV2:stakedGmySupply:${active}`, chainId, gmyAddress, "balanceOf", stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
    }
  );

  const { data: esGmySupply } = useSWR(
    [`StakeV2:esGmySupply:${active}`, chainId, readerAddress, "getTokenSupply", esGmyAddress],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsGmyAccounts]),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );
  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { gmyPrice } = useGmyPrice(chainId, { arbitrum: chainId === FANTOM ? library : undefined }, active);

  // const { totalGmySupply, totalSupply } = useGMYInfo();
  let totalGmySupply, totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalGmySupply = totalSupplies[1];
    totalSupply = totalSupplies[1];
  }
  // const gmyPrice = BigNumber.from("1000000000000000000000000000000")

  // let { total: totalGmySupply } = useTotalGmySupply();
  // let totalGmySupply = BigNumber.from("10000000000000000000000000");

  let { op: opGmyStaked, fantom: fantomGmyStaked, total: totalGmyStaked } = useTotalGmyStaked();

  const gmySupplyUrl = getServerUrl(chainId, "/gmy_supply");
  // const { data: gmySupply } = useSWR([gmySupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  let gmySupply = totalSupply;

  const isGmyTransferEnabled = true;

  let esGmySupplyUsd;
  if (esGmySupply && gmyPrice) {
    esGmySupplyUsd = esGmySupply.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusGmyTrackerRewards && processedData.bnGmyInFeeGmy) {
    multiplierPointsAmount = processedData.bonusGmyTrackerRewards.add(processedData.bnGmyInFeeGmy);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnGmyInFeeGmy && processedData.bonusGmyInFeeGmy) {
    totalRewardTokens = processedData.bnGmyInFeeGmy.add(processedData.bonusGmyInFeeGmy);
  }

  let totalRewardTokensAndGlp;
  if (totalRewardTokens && processedData && processedData.glpBalance) {
    totalRewardTokensAndGlp = totalRewardTokens.add(processedData.glpBalance);
  }

  const bonusGmyInFeeGmy = processedData ? processedData.bonusGmyInFeeGmy : undefined;

  let stakedGmySupplyUsd;
  if (!totalGmyStaked.isZero() && gmyPrice) {
    stakedGmySupplyUsd = totalGmyStaked.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalGmySupply && !totalGmySupply.isZero() && gmyPrice) {
    totalSupplyUsd = totalGmySupply.mul(gmyPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableGmy = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.gmyVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusGmyInFeeGmy
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.gmyVesterPairAmount);
    const stakedTokens = processedData.bonusGmyInFeeGmy;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableGmy = availableTokens.mul(stakedTokens).div(divisor);
    }
  }

  const showStakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }

    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake GMY`);
    setStakeModalMaxAmount(processedData.gmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("GMY");
    setStakingTokenAddress(gmyAddress);
    setStakingFarmAddress(stakedGmyTrackerAddress);
    setStakeMethodName("stakeGmy");
  };

  const showStakeEsGmyModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle(`Stake esGMY`);
    setStakeModalMaxAmount(processedData.esGmyBalance);
    setStakeValue("");
    setStakingTokenSymbol("esGMY");
    setStakingTokenAddress(esGmyAddress);
    setStakingFarmAddress(AddressZero);
    setStakeMethodName("stakeEsGmy");
  };

  const showGmyVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.gmyVester.maxVestableAmount.sub(vestingData.gmyVester.vestedAmount);
    if (processedData.esGmyBalance && processedData.esGmyBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esGmyBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(`GMY Vault`);
    setVesterDepositStakeTokenLabel("staked GMY + esGMY + Multiplier Points");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esGmyBalance);
    setVesterDepositEscrowedBalance(vestingData.gmyVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.gmyVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.gmyVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.gmyVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.gmyVester.pairAmount);
    setVesterDepositMaxReserveAmount(totalRewardTokens);
    setVesterDepositValue("");
    setVesterDepositAddress(gmyVesterAddress);
  };

  const showGlpVesterDepositModal = () => {
    let remainingVestableAmount = vestingData.glpVester.maxVestableAmount.sub(vestingData.glpVester.vestedAmount);
    if (processedData.esGmyBalance && processedData.esGmyBalance.lt(remainingVestableAmount)) {
      remainingVestableAmount = processedData.esGmyBalance;
    }

    setIsVesterDepositModalVisible(true);
    setVesterDepositTitle(`GLP Vault`);
    setVesterDepositStakeTokenLabel("staked GLP");
    setVesterDepositMaxAmount(remainingVestableAmount);
    setVesterDepositBalance(processedData.esGmyBalance);
    setVesterDepositEscrowedBalance(vestingData.glpVester.escrowedBalance);
    setVesterDepositVestedAmount(vestingData.glpVester.vestedAmount);
    setVesterDepositMaxVestableAmount(vestingData.glpVester.maxVestableAmount);
    setVesterDepositAverageStakedAmount(vestingData.glpVester.averageStakedAmount);
    setVesterDepositReserveAmount(vestingData.glpVester.pairAmount);
    setVesterDepositMaxReserveAmount(processedData.glpBalance);
    setVesterDepositValue("");
    setVesterDepositAddress(glpVesterAddress);
  };

  const showGmyVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.gmyVesterVestedAmount || vestingData.gmyVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from GMY Vault`);
    setVesterWithdrawAddress(gmyVesterAddress);
  };

  const showGlpVesterWithdrawModal = () => {
    if (!vestingData || !vestingData.glpVesterVestedAmount || vestingData.glpVesterVestedAmount.eq(0)) {
      helperToast.error(`You have not deposited any tokens for vesting.`);
      return;
    }

    setIsVesterWithdrawModalVisible(true);
    setVesterWithdrawTitle(`Withdraw from GLP Vault`);
    setVesterWithdrawAddress(glpVesterAddress);
  };

  const showUnstakeGmyModal = () => {
    if (!isGmyTransferEnabled) {
      helperToast.error(`GMY transfers not yet enabled`);
      return;
    }
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake GMY`);
    let maxAmount = processedData.gmyInStakedGmy;
    if (
      processedData.gmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.gmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("GMY");
    setUnstakeMethodName("unstakeGmy");
  };

  const showUnstakeEsGmyModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle(`Unstake esGMY`);
    let maxAmount = processedData.esGmyInStakedGmy;
    if (
      processedData.esGmyInStakedGmy &&
      vestingData &&
      vestingData.gmyVesterPairAmount.gt(0) &&
      maxUnstakeableGmy &&
      maxUnstakeableGmy.lt(processedData.esGmyInStakedGmy)
    ) {
      maxAmount = maxUnstakeableGmy;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.gmyVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("esGMY");
    setUnstakeMethodName("unstakeEsGmy");
  };

  const renderMultiplierPointsLabel = useCallback(() => {
    return `Multiplier Points APR`;
  }, []);

  const renderMultiplierPointsValue = useCallback(() => {
    return (
      <Tooltip
        handle={`100.00%`}
        position="right-bottom"
        renderContent={() => {
          return (
            <span>
              Boost your rewards with Multiplier Points.&nbsp;
              <ExternalLink href="https://docs.yummy.fi/rewards#multiplier-points">More info</ExternalLink>.
            </span>
          );
        }}
      />
    );
  }, []);

  let earnMsg;
  if (totalRewardTokensAndGlp && totalRewardTokensAndGlp.gt(0)) {
    let gmyAmountStr;
    if (processedData.gmyInStakedGmy && processedData.gmyInStakedGmy.gt(0)) {
      gmyAmountStr = formatAmount(processedData.gmyInStakedGmy, 18, 2, true) + " GMY";
    }
    let esGmyAmountStr;
    if (processedData.esGmyInStakedGmy && processedData.esGmyInStakedGmy.gt(0)) {
      esGmyAmountStr = formatAmount(processedData.esGmyInStakedGmy, 18, 2, true) + " esGMY";
    }
    let mpAmountStr;
    if (processedData.bonusGmyInFeeGmy && processedData.bnGmyInFeeGmy.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnGmyInFeeGmy, 18, 2, true) + " MP";
    }
    let glpStr;
    if (processedData.glpBalance && processedData.glpBalance.gt(0)) {
      glpStr = formatAmount(processedData.glpBalance, 18, 2, true) + " GLP";
    }
    const amountStr = [gmyAmountStr, esGmyAmountStr, mpAmountStr, glpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <span>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndGlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </span>
      </div>
    );
  }

  return (
    <div className="default-container page-layout">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        farmAddress={stakingFarmAddress}
        rewardRouterAddress={rewardRouterAddress}
        stakeMethodName={stakeMethodName}
        hasMultiplierPoints={hasMultiplierPoints}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={unstakeModalMaxAmount}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        rewardRouterAddress={rewardRouterAddress}
        unstakeMethodName={unstakeMethodName}
        multiplierPointsAmount={multiplierPointsAmount}
        bonusGmyInFeeGmy={bonusGmyInFeeGmy}
      />
      <VesterDepositModal
        isVisible={isVesterDepositModalVisible}
        setIsVisible={setIsVesterDepositModalVisible}
        chainId={chainId}
        title={vesterDepositTitle}
        stakeTokenLabel={vesterDepositStakeTokenLabel}
        maxAmount={vesterDepositMaxAmount}
        balance={vesterDepositBalance}
        escrowedBalance={vesterDepositEscrowedBalance}
        vestedAmount={vesterDepositVestedAmount}
        averageStakedAmount={vesterDepositAverageStakedAmount}
        maxVestableAmount={vesterDepositMaxVestableAmount}
        reserveAmount={vesterDepositReserveAmount}
        maxReserveAmount={vesterDepositMaxReserveAmount}
        value={vesterDepositValue}
        setValue={setVesterDepositValue}
        library={library}
        vesterAddress={vesterDepositAddress}
        setPendingTxns={setPendingTxns}
      />
      <VesterWithdrawModal
        isVisible={isVesterWithdrawModalVisible}
        setIsVisible={setIsVesterWithdrawModalVisible}
        vesterAddress={vesterWithdrawAddress}
        chainId={chainId}
        title={vesterWithdrawTitle}
        library={library}
        setPendingTxns={setPendingTxns}
      />
      <CompoundModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <ClaimModal
        active={active}
        account={account}
        setPendingTxns={setPendingTxns}
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        totalVesterRewards={processedData.totalVesterRewards}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />
      <div className="section-title-block">
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div className="Page-title">
            <span>Earn</span>
          </div>
          <div className="Page-description">
            <span>
              Stake <ExternalLink href="https://docs.yummy.fi/tokenomics">GMY</ExternalLink> and{" "}
              <ExternalLink href="https://docs.yummy.fi/glp">GLP</ExternalLink> to earn rewards.
            </span>
          </div>
          {earnMsg && <div className="Page-description">{earnMsg}</div>}
        </div>
      </div>
      <div className="StakeV2-content">
        <div className="StakeV2-cards">
          <div className="App-card StakeV2-gmy-card">
            <div className="App-card-title">GMY</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <span>Price</span>
                </div>
                <div>
                  {!gmyPrice && "..."}
                  {
                    gmyPrice && "$" + formatAmount(gmyPrice, USD_DECIMALS, 3, true)
                    // <Tooltip
                    //   position="right-bottom"
                    //   className="nowrap"
                    //   handle={"$" + formatAmount(gmyPrice, USD_DECIMALS, 2, true)}
                    //   renderContent={() => (
                    //     <>
                    //       <StatsTooltipRow
                    //         label={`Price on Avalanche`}
                    //         value={formatAmount(gmyPriceFromAvalanche, USD_DECIMALS, 2, true)}
                    //       />
                    //       <StatsTooltipRow
                    //         label={`Price on Arbitrum`}
                    //         value={formatAmount(gmyPriceFromArbitrum, USD_DECIMALS, 2, true)}
                    //       />
                    //     </>
                    //   )}
                    // />
                  }
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Wallet</span>
                </div>
                <div>
                  {/* {formatKeyAmount(processedData, "gmyBalance", 18, 2, true)} GMY ($
                  {formatKeyAmount(processedData, "gmyBalanceUsd", USD_DECIMALS, 2, true)}) */}
                  {formatKeyAmountFixed(processedData, "gmyBalance", 18, 2, true)} GMY ($
                  {formatKeyAmountFixed(processedData, "gmyBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Staked</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "gmyInStakedGmy", 18, 2, true)} GMY ($
                  {formatKeyAmount(processedData, "gmyInStakedGmyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>APR</span>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Rewards</span>
                </div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalGmyRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol})`}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeGmyTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(processedData, "feeGmyTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label="Escrowed GMY"
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedGmyTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(
                              processedData,
                              "stakedGmyTrackerRewardsUsd",
                              USD_DECIMALS,
                              2,
                              true
                            )})`}
                            showDollar={false}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Boost Percentage</span>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatAmount(processedData.boostBasisPoints, 2, 2, false)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <div>
                          <span>
                            You are earning {formatAmount(processedData.boostBasisPoints, 2, 2, false)}% more{" "}
                            {nativeTokenSymbol} rewards using{" "}
                            {formatAmount(processedData.bnGmyInFeeGmy, 18, 4, 2, true)} Staked Multiplier Points.
                          </span>
                          <div style={{ marginTop: "8px" }}>
                            <span>Use the "Compound" button to stake your Multiplier Points.</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Staked</span>
                </div>
                <div>
                  {!totalGmyStaked && "..."}
                  {totalGmyStaked && (
                    // formatAmount(totalGmyStaked, 18, 0, true) +
                    // " GMY" +
                    // ` ($${formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 2, true)})`
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        formatAmount(totalGmyStaked, 18, 0, true) +
                        " GMY" +
                        ` ($${formatAmount(stakedGmySupplyUsd, USD_DECIMALS, 0, true)})`
                      }
                      renderContent={() => (
                        <StatsTooltip
                          showDollar={false}
                          title={`Staked`}
                          opValue={opGmyStaked}
                          fantomValue={fantomGmyStaked}
                          total={totalGmyStaked}
                          decimalsForConversion={18}
                          symbol="GMY"
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Supply</span>
                </div>
                <div>
                  {!totalGmySupply && "..."}
                  {totalGmySupply && (
                    <>
                      {formatAmount(totalGmySupply, 18, 0, true)} GMY ($
                      {formatAmount(totalSupplyUsd, USD_DECIMALS, 0, true)})
                    </>
                  )}
                </div>
              </div>
              <div className="App-card-divider" />
              <div className="App-card-options">
                <Link className="App-button-option App-card-option" to="/buy_gmy">
                  <span>Buy GMY</span>
                </Link>
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showStakeGmyModal()}>
                    <span>Stake</span>
                  </button>
                )}
                {active && (
                  <button
                    className="App-button-option App-card-option border-btn"
                    onClick={() => showUnstakeGmyModal()}
                  >
                    <span>Unstake</span>
                  </button>
                )}
                {active && (
                  <Link className="App-button-option App-card-option border-btn" to="/begin_account_transfer">
                    <span>Transfer Account</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="App-card primary StakeV2-total-rewards-card">
            <div className="App-card-title">
              <span>Total Rewards</span>
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  {nativeTokenSymbol} ({wrappedTokenSymbol})
                </div>
                <div>
                  {formatKeyAmount(processedData, "totalNativeTokenRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalNativeTokenRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">GMY</div>
                <div>
                  {formatKeyAmount(processedData, "totalVesterRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalVesterRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Escrowed GMY</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "totalEsGmyRewards", 18, 4, true)} ($
                  {formatKeyAmount(processedData, "totalEsGmyRewardsUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Multiplier Points</span>
                </div>
                <div>{formatKeyAmount(processedData, "bonusGmyTrackerRewards", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Staked Multiplier Points</span>
                </div>
                <div>{formatKeyAmount(processedData, "bnGmyInFeeGmy", 18, 4, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total</span>
                </div>
                <div>${formatKeyAmount(processedData, "totalRewardsUsd", USD_DECIMALS, 2, true)}</div>
              </div>
              <div className="App-card-bottom-placeholder">
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {active && (
                    <button className="App-button-option App-card-option">
                      <span>Compound</span>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option">
                      <span>Claim</span>
                    </button>
                  )}
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
              <div className="App-card-bottom">
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {active && (
                    <button
                      className="App-button-option App-card-option"
                      onClick={() => setIsCompoundModalVisible(true)}
                    >
                      <span>Compound</span>
                    </button>
                  )}
                  {active && (
                    <button
                      // style={{
                      //   background: "none",
                      //   color: "#ffffff",
                      //   border: "1px solid rgba(255, 255, 255, 0.1)",
                      // }}
                      // disabled
                      className="App-button-option App-card-option"
                      onClick={() => setIsClaimModalVisible(true)}
                    >
                      <span>Claim</span>
                    </button>
                  )}
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      <span>Connect Wallet</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">GLP ({chainName})</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <span>Price</span>
                </div>
                <div>${formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Wallet</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} GLP ($
                  {formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Staked</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "glpBalance", GLP_DECIMALS, 2, true)} GLP ($
                  {formatKeyAmount(processedData, "glpBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>APR</span>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "glpAprTotal", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol}) APR`}
                            value={`${formatKeyAmount(processedData, "glpAprForNativeToken", 2, 2, true)}%`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label="Escrowed GMY APR"
                            value={`${formatKeyAmount(processedData, "glpAprForEsGmy", 2, 2, true)}%`}
                            showDollar={false}
                          />
                          <br />

                          <span>APRs are updated weekly and will depend on the fees collected for the week.</span>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Rewards</span>
                </div>
                <div>
                  <Tooltip
                    handle={`$${formatKeyAmount(processedData, "totalGlpRewardsUsd", USD_DECIMALS, 2, true)}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <StatsTooltipRow
                            label={`${nativeTokenSymbol} (${wrappedTokenSymbol})`}
                            value={`${formatKeyAmount(
                              processedData,
                              "feeGlpTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(processedData, "feeGlpTrackerRewardsUsd", USD_DECIMALS, 2, true)})`}
                            showDollar={false}
                          />
                          <StatsTooltipRow
                            label="Escrowed GMY"
                            value={`${formatKeyAmount(
                              processedData,
                              "stakedGlpTrackerRewards",
                              18,
                              4
                            )} ($${formatKeyAmount(
                              processedData,
                              "stakedGlpTrackerRewardsUsd",
                              USD_DECIMALS,
                              2,
                              true
                            )})`}
                            showDollar={false}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Staked</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} GLP ($
                  {formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Supply</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "glpSupply", 18, 0, true)} GLP ($
                  {formatKeyAmount(processedData, "glpSupplyUsd", USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                <Link className="App-button-option App-card-option" to="/buy_glp">
                  <span>Buy GLP</span>
                </Link>
                <Link className="App-button-option App-card-option" to="/buy_glp#redeem">
                  <span>Sell GLP</span>
                </Link>
                {hasInsurance && (
                  <a
                    className="App-button-option App-card-option"
                    href="https://app.insurace.io/Insurance/Cart?id=124&referrer=545066382753150189457177837072918687520318754040"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Purchase Insurance</span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="App-card">
            <div className="App-card-title">
              <span>Escrowed GMY</span>
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <span>Price</span>
                </div>
                <div>${formatAmount(gmyPrice, USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Wallet</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "esGmyBalance", 18, 2, true)} esGMY ($
                  {formatKeyAmount(processedData, "esGmyBalanceUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Staked</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "esGmyInStakedGmy", 18, 2, true)} esGMY ($
                  {formatKeyAmount(processedData, "esGmyInStakedGmyUsd", USD_DECIMALS, 2, true)})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>APR</span>
                </div>
                <div>
                  <Tooltip
                    handle={`${formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true)}%`}
                    position="right-bottom"
                    renderContent={() => (
                      <GMYAprTooltip processedData={processedData} nativeTokenSymbol={nativeTokenSymbol} />
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">{renderMultiplierPointsLabel()}</div>
                <div>{renderMultiplierPointsValue()}</div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Staked</span>
                </div>
                <div>
                  {formatKeyAmount(processedData, "stakedEsGmySupply", 18, 0, true)} esGMY ($
                  {formatKeyAmount(processedData, "stakedEsGmySupplyUsd", USD_DECIMALS, 0, true)})
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Total Supply</span>
                </div>
                <div>
                  {formatAmount(esGmySupply, 18, 0, true)} esGMY (${formatAmount(esGmySupplyUsd, USD_DECIMALS, 0, true)}
                  )
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showStakeEsGmyModal()}>
                    <span>Stake</span>
                  </button>
                )}
                {active && (
                  <button
                    className="App-button-option App-card-option border-btn"
                    onClick={() => showUnstakeEsGmyModal()}
                  >
                    <span>Unstake</span>
                  </button>
                )}
                {!active && (
                  <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                    <span>Connect Wallet</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="Tab-title-section">
          <div className="Page-title">
            <span>Vest</span>
          </div>
          <div className="Page-description">
            <span>
              Convert esGMY tokens to GMY tokens.
              <br />
              Please read the{" "}
              <ExternalLink href="https://docs.yummy.fi/rewards#vesting">vesting details</ExternalLink> before
              using the vaults.
            </span>
          </div>
        </div>
        <div>
          <div className="StakeV2-cards">
            <div className="App-card StakeV2-gmy-card">
              <div className="App-card-title">
                <span>GMY Vault</span>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <span>Staked Tokens</span>
                  </div>
                  <div>
                    <Tooltip
                      handle={formatAmount(totalRewardTokens, 18, 2, true)}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <>
                            <StatsTooltipRow
                              showDollar={false}
                              label="GMY"
                              value={formatAmount(processedData.gmyInStakedGmy, 18, 2, true)}
                            />

                            <StatsTooltipRow
                              showDollar={false}
                              label="esGMY"
                              value={formatAmount(processedData.esGmyInStakedGmy, 18, 2, true)}
                            />
                            <StatsTooltipRow
                              showDollar={false}
                              label="Multiplier Points"
                              value={formatAmount(processedData.bnGmyInFeeGmy, 18, 2, true)}
                            />
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Reserved for Vesting</span>
                  </div>
                  <div>
                    {formatKeyAmount(vestingData, "gmyVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(totalRewardTokens, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Vesting Status</span>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "gmyVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "gmyVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span>
                              {formatKeyAmount(vestingData, "gmyVesterClaimSum", 18, 4, true)} tokens have been
                              converted to GMY from the{" "}
                              {formatKeyAmount(vestingData, "gmyVesterVestedAmount", 18, 4, true)} esGMY deposited for
                              vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Claimable</span>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "gmyVesterClaimable", 18, 4, true)} GMY`}
                      position="right-bottom"
                      renderContent={() => (
                        <span>
                          {formatKeyAmount(vestingData, "gmyVesterClaimable", 18, 4, true)} GMY tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      <span>Connect Wallet</span>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showGmyVesterDepositModal()}>
                      <span>Deposit</span>
                    </button>
                  )}
                  {active && (
                    <button
                      className="App-button-option App-card-option border-btn"
                      onClick={() => showGmyVesterWithdrawModal()}
                    >
                      <span>Withdraw</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="App-card StakeV2-gmy-card">
              <div className="App-card-title">
                <span>GLP Vault</span>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">
                    <span>Staked Tokens</span>
                  </div>
                  <div>{formatAmount(processedData.glpBalance, 18, 2, true)} GLP</div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Reserved for Vesting</span>
                  </div>
                  <div>
                    {formatKeyAmount(vestingData, "glpVesterPairAmount", 18, 2, true)} /{" "}
                    {formatAmount(processedData.glpBalance, 18, 2, true)}
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Vesting Status</span>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "glpVesterClaimSum", 18, 4, true)} / ${formatKeyAmount(
                        vestingData,
                        "glpVesterVestedAmount",
                        18,
                        4,
                        true
                      )}`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            <span>
                              {formatKeyAmount(vestingData, "glpVesterClaimSum", 18, 4, true)} tokens have been
                              converted to GMY from the{" "}
                              {formatKeyAmount(vestingData, "glpVesterVestedAmount", 18, 4, true)} esGMY deposited for
                              vesting.
                            </span>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">
                    <span>Claimable</span>
                  </div>
                  <div>
                    <Tooltip
                      handle={`${formatKeyAmount(vestingData, "glpVesterClaimable", 18, 4, true)} GMY`}
                      position="right-bottom"
                      renderContent={() => (
                        <span>
                          {formatKeyAmount(vestingData, "glpVesterClaimable", 18, 4, true)} GMY tokens can be claimed,
                          use the options under the Total Rewards section to claim them.
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  {!active && (
                    <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                      <span>Connect Wallet</span>
                    </button>
                  )}
                  {active && (
                    <button className="App-button-option App-card-option" onClick={() => showGlpVesterDepositModal()}>
                      <span>Deposit</span>
                    </button>
                  )}
                  {active && (
                    <button
                      className="App-button-option App-card-option border-btn"
                      onClick={() => showGlpVesterWithdrawModal()}
                    >
                      <span>Withdraw</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;
