import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Token as UniToken } from "@uniswap/sdk-core";
import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";

import VaultMsp from "abis/VaultMsp.json";
import OrderVaultMsp from "abis/OrderVaultMsp.json";
import OrderBook from "abis/OrderBook.json";
import PositionManager from "abis/PositionManager.json";
import Vault from "abis/Vault.json";
import Router from "abis/Router.json";
import UniPool from "abis/UniPool.json";
import UniswapV2 from "abis/UniswapV2.json";
import LiquidityLocker from "abis/LiquidityLocker.json";

import Token from "abis/Token.json";
import PositionRouter from "abis/PositionRouter.json";
import ReaderV2 from "abis/ReaderV2.json";

import { getContract } from "config/contracts";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  BASE,
  BERACHAIN_BARTIO,
  FANTOM,
  OP,
  getConstant,
  getHighExecutionFee,
} from "config/chains";
import { DECREASE, getOrderKey, INCREASE, SWAP, USD_DECIMALS } from "lib/legacy";

import { groupBy } from "lodash";
import { UI_VERSION } from "config/ui";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { getGmyGraphClient, nissohGraphClient } from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { replaceNativeTokenAddress } from "./tokens";
import { getUsd } from "./tokens/utils";
import { getProvider } from "lib/rpc";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { t } from "@lingui/macro";

export * from "./prices";

const { AddressZero } = ethers.constants;

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    const graphClient = getGmyGraphClient(chainId);
    if (graphClient) {
      // eslint-disable-next-line no-console
      graphClient.query({ query }).then(setRes).catch(console.warn);
    }
  }, [setRes, query, chainId]);

  return res ? res.data.orderStat : null;
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    getGmyGraphClient(chainId)?.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getGmyGraphClient(chainId);
      if (!graphClient) {
        return;
      }

      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        // eslint-disable-next-line no-console
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, library) {
  const count = 1000;
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    nissohGraphClient.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query]);

  const key = res ? `allPositions${count}__` : null;

  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
          const position: any = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          };
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate);
          position.marginFee = position.size.div(1000);
          position.fee = position.fundingFee.add(position.marginFee);

          const THRESHOLD = 5000;
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
          position.danger = collateralDiffPercent.gt(THRESHOLD);

          return position;
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );

    return ret.filter(Boolean);
  });

  return positions;
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    getGmyGraphClient(chainId)?.query({ query }).then(setRes);
  }, [setRes, query, chainId]);

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret: any = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );
  });

  return orders.filter(Boolean);
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTrades(chainId, account, forSingleAccount, afterId) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/trades?address=${account.toLowerCase()}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/trades`;

  if (afterId && afterId.length > 0 && url) {
    const urlItem = new URL(url as string);
    urlItem.searchParams.append("after", afterId);
    url = urlItem.toString();
  }

  const { data: trades, mutate: updateTrades } = useSWR(url ? url : null, {
    dedupingInterval: 10000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (trades && !trades.error) {
    trades.sort((item0, item1) => {
      const data0 = item0;
      const data1 = item1;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useMinExecutionFee(library, active, chainId, infoTokens) {
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const { data: minExecutionFee } = useSWR<BigNumber>([active, chainId, positionRouterAddress, "minExecutionFee"], {
    fetcher: contractFetcher(library, PositionRouter),
  });

  const { data: gasPrice } = useSWR<BigNumber | undefined>(["gasPrice", chainId], {
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(library, chainId);
        if (!provider) {
          resolve(undefined);
          return;
        }

        try {
          const gasPrice = await provider.getGasPrice();
          resolve(gasPrice);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
    },
  });

  let multiplier;

  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  if (chainId === ARBITRUM || chainId === ARBITRUM_TESTNET) {
    multiplier = 65000;
  }

  // multiplier for Avalanche is just the average gas usage
  if (chainId === AVALANCHE) {
    multiplier = 700000;
  }
  if (chainId === FANTOM) {
    multiplier = 700000;
  }
  if (chainId === BERACHAIN_BARTIO) {
    multiplier = 700000;
  }
  if (chainId === BASE) {
    multiplier = 700000;
  }

  let finalExecutionFee = minExecutionFee;

  if (gasPrice && minExecutionFee) {
    const estimatedExecutionFee = gasPrice.mul(multiplier);
    if (estimatedExecutionFee.gt(minExecutionFee)) {
      finalExecutionFee = estimatedExecutionFee;
    }
  }

  const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens);
  const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS));
  const errorMessage =
    isFeeHigh &&
    `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

  return {
    minExecutionFee: finalExecutionFee,
    minExecutionFeeUSD: finalExecutionFeeUSD,
    minExecutionFeeErrorMessage: errorMessage,
  };
}

export function useStakedGmySupply(library, active) {
  const gmyAddressArb = getContract(ARBITRUM, "GMY");
  const stakedGmyTrackerAddressArb = getContract(ARBITRUM, "StakedGmyTracker");

  const { data: arbData, mutate: arbMutate } = useSWR<any>(
    [`StakeV2:stakedGmySupply:${active}`, ARBITRUM, gmyAddressArb, "balanceOf", stakedGmyTrackerAddressArb],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const gmyAddressAvax = getContract(AVALANCHE, "GMY");
  const stakedGmyTrackerAddressAvax = getContract(AVALANCHE, "StakedGmyTracker");

  const { data: avaxData, mutate: avaxMutate } = useSWR(
    [`StakeV2:stakedGmySupply:${active}`, AVALANCHE, gmyAddressAvax, "balanceOf", stakedGmyTrackerAddressAvax],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  let data;
  if (arbData && avaxData) {
    data = arbData.add(avaxData);
  }

  const mutate = () => {
    arbMutate();
    avaxMutate();
  };

  return { data, mutate };
}

export function useHasOutdatedUi() {
  // const url = getServerUrl(ARBITRUM, "/ui_version");
  // const { data, mutate } = useSWR([url], {
  //   // @ts-ignore
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });

  let hasOutdatedUi = false;

  // if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
  //   hasOutdatedUi = true;
  // }

  return { data: hasOutdatedUi };
}
export function useGMYInfo() {
  const url = "https://api.gummy.exchange/bartio/api/tokens/info?symbols=GMY&chain=BARTIO";
  const { data: data } = useSWR([url], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  return {
    // totalSupply: parseValue(data?.GMY?.totalSupply, 18) || 0,
    // totalGmySupply: parseValue(data?.GMY?.circulatingSupply, 18) || 0,
    gmyPrice: parseValue(data?.GMY?.price, USD_DECIMALS) || 0,
  };
}
export function useGmyPrice(chainId, libraries, active) {
  const arbitrumLibrary = libraries && libraries.arbitrum ? libraries.arbitrum : undefined;
  const opLibrary = libraries && libraries.op ? libraries.op : undefined;

  const { data: gmyPriceFromArbitrum, mutate: mutateFromArbitrum } = useGmyPriceFromArbitrum(arbitrumLibrary, active);
  // const { data: gmyPriceFromOP, mutate: mutateFromOP } = useGmyPriceFromOP();
  const { data: gmyPriceFromOP, mutate: mutateFromOP } = useGmyPriceFromOP2(opLibrary, active);
  const { data: gmyPriceFromFantom, mutate: mutateFromFantom } = useGmyPriceFromFantomV2();
  const { data: gmyPriceFromBase, mutate: mutateFromBase } = useGmyPriceFromOP2(opLibrary, active);

  let gmyPrice = gmyPriceFromFantom;
  if (chainId === OP) {
    gmyPrice = gmyPriceFromOP;
  }
  if (chainId === ARBITRUM) {
    gmyPrice = gmyPriceFromArbitrum;
  }
  if (chainId === BASE) {
    gmyPrice = gmyPriceFromBase;
  }
  const mutate = useCallback(() => {
    mutateFromFantom();
    mutateFromOP();
    mutateFromArbitrum();
    mutateFromBase();
  }, [mutateFromFantom, mutateFromOP, mutateFromArbitrum, mutateFromBase]);

  return {
    gmyPrice,
    gmyPriceFromArbitrum,
    gmyPriceFromOP,
    gmyPriceFromFantom,
    gmyPriceFromBase,
    mutate,
  };
}
export function useBridgeApi(account, currentTab) {
  const url = `https://api.gummy.exchange/layerzero/bridge/histories/${account}${
    currentTab === "GMY" ? "" : "?type=NFT"
  }`;
  const data = useSWR([url], {
    dedupingInterval: 30000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (data && data?.data) return data?.data;
  else return null;
}
export function useMigrateApi(account, chainId) {
  const url = `https://api.gummy.exchange/layerzero/migration/histories/${account}?chainId=${chainId}`;
  const data = useSWR([url], {
    dedupingInterval: 30000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (data && data?.data) return data?.data;
  else return null;
}
// use only the supply endpoint on arbitrum, it includes the supply on avalanche
export function useTotalGmySupply() {
  const gmySupplyUrlArbitrum = getServerUrl(ARBITRUM, "/gmy_supply");

  const { data: gmySupply, mutate: updateGmySupply } = useSWR([gmySupplyUrlArbitrum], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  return {
    total: gmySupply ? bigNumberify(gmySupply) : undefined,
    mutate: updateGmySupply,
  };
}

export function useTotalGmySupplyAll() {
  const gmyAddressBARTIO = getContract(BERACHAIN_BARTIO, "GMY");
  const glpAddressBARTIO = getContract(BERACHAIN_BARTIO, "GLP");
  const usdgAddressBARTIO = getContract(BERACHAIN_BARTIO, "USDG");
  const readerAddressBARTIO = getContract(BERACHAIN_BARTIO, "Reader");
  const tokensForSupplyQueryBARTIO = [gmyAddressBARTIO, glpAddressBARTIO, usdgAddressBARTIO];

  // const gmyAddressOP = getContract(OP, "GMY");
  // const glpAddressOP = getContract(OP, "GLP");
  // const usdgAddressOP = getContract(OP, "USDG");
  // const readerAddressOP = getContract(OP, "Reader");
  // const tokensForSupplyQueryOP = [gmyAddressOP, glpAddressOP, usdgAddressOP];

  // const gmyAddress = getContract(FANTOM, "GMY");
  // const glpAddress = getContract(FANTOM, "GLP");
  // const usdgAddress = getContract(FANTOM, "USDG");
  // const readerAddressFtm = getContract(FANTOM, "Reader");
  // const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  // const gmyAddressArb = getContract(ARBITRUM, "GMY");
  // const glpAddressArb = getContract(ARBITRUM, "GLP");
  // const usdgAddressArb = getContract(ARBITRUM, "USDG");
  // const readerAddressArb = getContract(ARBITRUM, "Reader");
  // const tokensForSupplyQueryArb = [gmyAddressArb, glpAddressArb, usdgAddressArb];

  // const gmyAddressBase = getContract(BASE, "GMY");
  // const glpAddressBase = getContract(BASE, "GLP");
  // const usdgAddressBase = getContract(BASE, "USDG");
  // const readerAddressBase = getContract(BASE, "Reader");
  // const tokensForSupplyQueryBase = [gmyAddressBase, glpAddressBase, usdgAddressBase];

  let totalSupplies = useRef(bigNumberify(0));


  const { data: totalSuppliesBARTIO, mutate: updateTotalSuppliesBARTIO } = useSWR<any>(
    [
      `Dashboard:totalSuppliesBARTIO:${[BERACHAIN_BARTIO, tokensForSupplyQueryBARTIO]}`,
      BERACHAIN_BARTIO,
      readerAddressBARTIO,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryBARTIO]),
      refreshInterval: 10000,
    }
  );

  // const { data: totalSuppliesBASE, mutate: updateTotalSuppliesBASE } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesBASE:${[BASE, tokensForSupplyQueryBase]}`,
  //     BASE,
  //     readerAddressBase,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryBase]),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: totalSuppliesOP, mutate: updateTotalSuppliesOP } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesOP:${[OP, tokensForSupplyQueryOP]}`,
  //     OP,
  //     readerAddressOP,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOP]),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: totalSuppliesFtm, mutate: updateTotalSuppliesFtm } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesOP:${[FANTOM, tokensForSupplyQuery]}`,
  //     FANTOM,
  //     readerAddressFtm,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: mulSuppliesFtm } = useSWR(
  //   [
  //     `Bridge:mulSuppliesFtm:${FANTOM}`,
  //     FANTOM,
  //     "0x01e77288b38b416F972428d562454fb329350bAc",
  //     "balanceOf",
  //     "0x23a2d47Af72A9d18B756DfDDEF790cdc1d0e9005",
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: totalSuppliesArb, mutate: updateTotalSuppliesArb } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesArb:${[ARBITRUM, tokensForSupplyQueryArb]}`,
  //     ARBITRUM,
  //     readerAddressArb,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryArb]),
  //     refreshInterval: 10000,
  //   }
  // );
  const mutate = useCallback(() => {
    // updateTotalSuppliesOP();
    // updateTotalSuppliesFtm();
    // updateTotalSuppliesArb();
    // updateTotalSuppliesBASE();
    updateTotalSuppliesBARTIO();
  }, [updateTotalSuppliesBARTIO]);

  if (
    // totalSuppliesOP &&
    // totalSuppliesFtm &&
    // totalSuppliesOP[1] &&
    // totalSuppliesFtm[1] &&
    // totalSuppliesArb &&
    // totalSuppliesArb[1] &&
    // totalSuppliesBASE &&
    // totalSuppliesBASE[1] &&
    // mulSuppliesFtm &&
    totalSuppliesBARTIO &&
    totalSuppliesBARTIO[1]
  ) {
    let total = totalSuppliesBARTIO[1];
    totalSupplies.current = total;
  }

  return {
    // op: totalSuppliesOP && totalSuppliesOP[1] ? totalSuppliesOP[1] : bigNumberify(0),
    // fantom:
    //   mulSuppliesFtm && totalSuppliesFtm && totalSuppliesFtm[1]
    //     ? totalSuppliesFtm[1].sub(mulSuppliesFtm)
    //     : bigNumberify(0),
    // arb: totalSuppliesArb && totalSuppliesArb[1] ? totalSuppliesArb[1] : bigNumberify(0),
    bartio: totalSuppliesBARTIO && totalSuppliesBARTIO[1] ? totalSuppliesBARTIO[1] : bigNumberify(0),
    total: totalSupplies.current,
    mutate,
  };
}

export function useTotalGmyStaked() {
  // const stakedGmyTrackerAddressArbitrum = getContract(ARBITRUM, "StakedGmyTracker");
  // const stakedGmyTrackerAddressBase = getContract(BASE, "StakedGmyTracker");
  // const stakedGmyTrackerAddressOP = getContract(OP, "StakedGmyTracker");
  // const stakedGmyTrackerAddressFtm = getContract(FANTOM, "StakedGmyTracker");
  const stakedGmyTrackerAddressBARTIO = getContract(BERACHAIN_BARTIO, "StakedGmyTracker");
  let totalStakedGmy = useRef(bigNumberify(0));

  // const { data: stakedGmySupplyBase, mutate: updateStakedGmySupplyBase } = useSWR<BigNumber>(
  //   [`StakeV2:stakedGmySupplyBase:${BASE}`, BASE, getContract(BASE, "GMY"), "balanceOf", stakedGmyTrackerAddressBase],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedGmySupplyArbitrum, mutate: updateStakedGmySupplyArbitrum } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedGmySupply:${ARBITRUM}`,
  //     ARBITRUM,
  //     getContract(ARBITRUM, "GMY"),
  //     "balanceOf",
  //     stakedGmyTrackerAddressArbitrum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedGmySupplyOP, mutate: updateStakedGmySupplyOP } = useSWR<BigNumber>(
  //   [`StakeV2:stakedGmySupplyOP:${OP}`, OP, getContract(OP, "GMY"), "balanceOf", stakedGmyTrackerAddressOP],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: stakedGmySupplyFtm, mutate: updateStakedGmySupplyFtm } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedGmySupplyFtm:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "GMY"),
  //     "balanceOf",
  //     stakedGmyTrackerAddressFtm,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  const { data: stakedGmySupplyBARTIO, mutate: updateStakedGmySupplyBARTIO } = useSWR<BigNumber>(
    [
      `StakeV2:stakedGmySupplyBARTIO:${BERACHAIN_BARTIO}`,
      BERACHAIN_BARTIO,
      getContract(BERACHAIN_BARTIO, "GMY"),
      "balanceOf",
      stakedGmyTrackerAddressBARTIO,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  const mutate = useCallback(() => {
    // updateStakedGmySupplyArbitrum();
    // updateStakedGmySupplyOP();
    // updateStakedGmySupplyOPLegacy();
    // updateStakedGmySupplyFtm();
    // updateStakedGmySupplyFtmLegacy();
    // updateStakedGmySupplyBase();
    updateStakedGmySupplyBARTIO();
  }, [
    // updateStakedGmySupplyFtmLegacy,
    // updateStakedGmySupplyFtm,
    // updateStakedGmySupplyOP,
    // updateStakedGmySupplyOPLegacy,
    // updateStakedGmySupplyArbitrum,
    // updateStakedGmySupplyBase,
    updateStakedGmySupplyBARTIO
  ]);

  if (
    // stakedGmySupplyFtm &&
    // stakedGmySupplyOP &&
    // stakedGmySupplyOPLegacy &&
    // stakedGmySupplyArbitrum &&
    // stakedGmySupplyFtmLegacy &&
    // stakedGmySupplyBase &&
    stakedGmySupplyBARTIO
  ) {
    let total = stakedGmySupplyBARTIO
      // .add(stakedGmySupplyOP)
      // .add(stakedGmySupplyOPLegacy)
      // .add(stakedGmySupplyArbitrum)
      // .add(stakedGmySupplyFtmLegacy)
      // .add(stakedGmySupplyBase);
    totalStakedGmy.current = total;
  }

  return {
    // op: stakedGmySupplyOP,
    // oplegacy: stakedGmySupplyOPLegacy,
    // fantom: stakedGmySupplyFtm,
    // fantomlegacy: stakedGmySupplyFtmLegacy,
    // arbitrum: stakedGmySupplyArbitrum,
    // base: stakedGmySupplyBase,
    total: totalStakedGmy.current,
    bartio: stakedGmySupplyBARTIO,
    mutate,
  };
}

export function useTotalEsGmyStaked() {
  // const stakedGmyTrackerAddressOP = getContract(OP, "StakedGmyTracker");
  // const stakedGmyTrackerAddressFtm = getContract(FANTOM, "StakedGmyTracker");
  // const stakedGmyTrackerAddressArbitrum = getContract(ARBITRUM, "StakedGmyTracker");
  // const stakedGmyTrackerAddressBase = getContract(BASE, "StakedGmyTracker");
  const stakedGmyTrackerAddressBARTIO = getContract(BERACHAIN_BARTIO, "StakedGmyTracker");
  let totalStakedGmy = useRef(bigNumberify(0));

  const { data: stakedGmySupplyBARTIO, mutate: updateStakedGmySupplyBARTIO } = useSWR<BigNumber>(
    [`StakeV2:stakedEsGmySupplyBARTIO:${BERACHAIN_BARTIO}`, BERACHAIN_BARTIO, getContract(BERACHAIN_BARTIO, "ES_GMY"), "balanceOf", stakedGmyTrackerAddressBARTIO],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  // const { data: stakedGmySupplyOP, mutate: updateStakedGmySupplyOP } = useSWR<BigNumber>(
  //   [`StakeV2:stakedEsGmySupplyOP:${OP}`, OP, getContract(OP, "ES_GMY"), "balanceOf", stakedGmyTrackerAddressOP],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedGmySupplyFtm, mutate: updateStakedGmySupplyFtm } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsGmySupplyFtm:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "ES_GMY"),
  //     "balanceOf",
  //     stakedGmyTrackerAddressFtm,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedGmySupplyFtmLegacy, mutate: updateStakedGmySupplyFtmLegacy } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsGmySupplyFtmLegacy:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "ES_GMY"),
  //     "balanceOf",
  //     "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: stakedGmySupplyArbitrum, mutate: updateStakedGmySupplyArbitrum } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsGmySupplyArbitrum:${ARBITRUM}`,
  //     ARBITRUM,
  //     getContract(ARBITRUM, "ES_GMY"),
  //     "balanceOf",
  //     stakedGmyTrackerAddressArbitrum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: stakedGmySupplyBase, mutate: updateStakedGmySupplyBase } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsGmySupplyBase:${BASE}`,
  //     BASE,
  //     getContract(BASE, "ES_GMY"),
  //     "balanceOf",
  //     stakedGmyTrackerAddressBase,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  const mutate = useCallback(() => {
    // updateStakedGmySupplyArbitrum();
    // updateStakedGmySupplyOP();
    // updateStakedGmySupplyFtm();
    // updateStakedGmySupplyFtmLegacy();
    // updateStakedGmySupplyBase();
    updateStakedGmySupplyBARTIO();
  }, [
    // updateStakedGmySupplyArbitrum,
    // updateStakedGmySupplyFtm,
    // updateStakedGmySupplyOP,
    // updateStakedGmySupplyFtmLegacy,
    // updateStakedGmySupplyBase,
    updateStakedGmySupplyBARTIO,
  ]);

  if (stakedGmySupplyBARTIO) {
    let total = stakedGmySupplyBARTIO;
    totalStakedGmy.current = total;
  }
  
  return {
    // op: stakedGmySupplyOP,
    // fantom: stakedGmySupplyFtm,
    // fantomlegacy: stakedGmySupplyFtmLegacy,
    // arbitrum: stakedGmySupplyArbitrum,
    // base: stakedGmySupplyBase,
    bartio: stakedGmySupplyBARTIO,
    total: totalStakedGmy.current,
    mutate,
  };
}
export function useTotalGmyInLiquidity() {
  // let poolAddressArbitrum = getContract(ARBITRUM, "UniswapGmyEthPool");
  // let poolAddressAvax = getContract(AVALANCHE, "TraderJoeGmyAvaxPool");
  let poolAddressFtm = getContract(FANTOM, "UniswapGmyEthPool");
  let poolAddressOP = getContract(OP, "UniswapGmyEthPool");

  let totalGMY = useRef(bigNumberify(0));

  // const { data: gmyInLiquidityOnArbitrum, mutate: mutateGMYInLiquidityOnArbitrum } = useSWR<any>(
  //   [`StakeV2:gmyInLiquidity:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "GMY"), "balanceOf", poolAddressArbitrum],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: gmyInLiquidityOnAvax, mutate: mutateGMYInLiquidityOnAvax } = useSWR<any>(
  //   [`StakeV2:gmyInLiquidity:${AVALANCHE}`, AVALANCHE, getContract(AVALANCHE, "GMY"), "balanceOf", poolAddressAvax],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  const { data: gmyInLiquidityOnFtm, mutate: mutateGMYInLiquidityOnFtm } = useSWR<any>(
    [`StakeV2:gmyInLiquidity:${FANTOM}`, FANTOM, getContract(FANTOM, "GMY"), "balanceOf", poolAddressFtm],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );
  const { data: gmyInLiquidityOnOP, mutate: mutateGMYInLiquidityOnOP } = useSWR<any>(
    [`StakeV2:gmyInLiquidity:${OP}`, OP, getContract(OP, "GMY"), "balanceOf", poolAddressOP],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    mutateGMYInLiquidityOnFtm();
    mutateGMYInLiquidityOnOP();
  }, [mutateGMYInLiquidityOnFtm, mutateGMYInLiquidityOnOP]);

  if (gmyInLiquidityOnFtm && gmyInLiquidityOnOP) {
    let total = bigNumberify(gmyInLiquidityOnFtm)?.add(gmyInLiquidityOnOP);
    totalGMY.current = total;
  }
  return {
    op: gmyInLiquidityOnOP,
    fantom: gmyInLiquidityOnFtm,
    total: totalGMY.current,
    mutate,
  };
}
export function useLqLocked() {
  const poolAddress = getContract(FANTOM, "UniswapGmyEthPool");
  const liquidityLockerAddress = getContract(FANTOM, "LiquidityLocker");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeGmyFantomReserves", FANTOM, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: gmyReserve, _reserve1: usdcReserve }: any = data || {};
  const { data: totalSupply, mutate: updateTotalSupply } = useSWR(["totalSupply", FANTOM, poolAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });

  const { data: balance } = useSWR(["balance", FANTOM, poolAddress, "balanceOf"], {
    fetcher: contractFetcher(undefined, UniswapV2, [liquidityLockerAddress]),
  });

  const { data: lockUntil } = useSWR(["lockUntil", FANTOM, liquidityLockerAddress, "lockUntil"], {
    fetcher: contractFetcher(undefined, LiquidityLocker),
  });

  if (balance && usdcReserve && gmyReserve && totalSupply && lockUntil) {
    const pooledUSDC = usdcReserve.mul(balance).div(totalSupply);
    const pooledGMY = gmyReserve.mul(balance).div(totalSupply);
    const gmyPrice = usdcReserve.mul(expandDecimals(1, 18)).div(gmyReserve);

    const pooledGMYDolar = gmyReserve.mul(balance).div(totalSupply).mul(gmyPrice).div(expandDecimals(1, 6));
    const tvl = pooledUSDC.mul(2).div(expandDecimals(1, 6));
    return {
      tvl,
      pooledUSDC,
      pooledGMY,
      pooledGMYDolar,
      lockUntil,
      lockTokenddress: "0x2a6538a456650cd454dcd8f0b4665183dba0bb27",
      timeLockddress: "0x8ac2cc7af1517e6f58a31a43773ca6c80bf3edb9",
      lpTokenAddress: poolAddress,
    };
  }
}

function useGmyPriceFromOP2(library, active) {
  const poolAddress = getContract(OP, "UniswapGmyEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR<any>(
    [`StakeV2:uniPoolSlot0:${active}:${poolAddress}`, OP, poolAddress, "slot0"],
    {
      fetcher: contractFetcher(library, UniPool),
      refreshInterval: 10000,
    }
  );

  const vaultAddress = getContract(OP, "Vault");
  const ethAddress = getTokenBySymbol(OP, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR<BigNumber>(
    [`StakeV2:ethPrice:${active}:${ethAddress}`, OP, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const gmyPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(OP, ethAddress, 18, "SYMBOL", "NAME");

      const gmyAddress = getContract(OP, "GMY");
      const tokenB = new UniToken(OP, gmyAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount?.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: gmyPrice, mutate };
}

function useGmyPriceFromFantom() {
  const poolAddress = getContract(FANTOM, "UniswapGmyEthPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeGmyFantomReserves", FANTOM, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: gmyReserve, _reserve1: avaxReserve }: any = data || {};

  const vaultAddress = getContract(FANTOM, "Vault");
  const usdcAddress = getTokenBySymbol(FANTOM, "USDC").address;
  // const { data: usdcPrice, mutate: updateUsdcPrice } = useSWR(
  //   [`StakeV2:usdcPrice`, FANTOM, vaultAddress, "getMinPrice", usdcAddress],
  //   {
  //     fetcher: contractFetcher(undefined, Vault),
  //   }
  // );

  const apiPriceUSDC = `https://api.dexscreener.com/latest/dex/pairs/fantom/0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c`;

  const { data: usdcData, mutate: updateUsdcPrice } = useSWR([apiPriceUSDC], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  let usdcPrice = usdcData ? bigNumberify(usdcData.pair.priceUsd * 1000000) : undefined;
  usdcPrice = usdcPrice?.mul(bigNumberify(10)!.pow(30 - 6));
  const PRECISION = bigNumberify(10)!.pow(18);
  const PRECISION_USDC = bigNumberify(10)!.pow(6);
  let gmyPrice;
  if (avaxReserve && gmyReserve && usdcPrice) {
    gmyPrice = avaxReserve.mul(PRECISION).div(gmyReserve).mul(usdcPrice)!.div(PRECISION_USDC);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateUsdcPrice(undefined, true);
  }, [updateReserves, updateUsdcPrice]);

  return { data: gmyPrice, mutate };
}

function useGmyPriceFromFantomV2() {
  const poolAddress = getContract(FANTOM, "TraderJoeGmyAvaxPool");

  const { data, mutate: updateReserves } = useSWR(
    [`TraderJoeGmyAvaxReserves:${poolAddress}`, FANTOM, poolAddress, "getReserves"],
    {
      fetcher: contractFetcher(undefined, UniswapV2),
      refreshInterval: 10000,
    }
  );
  const { _reserve0: avaxReserve, _reserve1: gmyReserve }: any = data || {};
  const vaultAddress = getContract(FANTOM, "Vault");
  const ethAddress = getTokenBySymbol(FANTOM, "WFTM").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice:${ethAddress}`, FANTOM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
      refreshInterval: 10000,
    }
  );

  const PRECISION = bigNumberify(10)!.pow(30);
  let gmyPrice;
  if (avaxReserve && gmyReserve && ethPrice) {
    gmyPrice = gmyReserve.mul(PRECISION).div(avaxReserve).mul(ethPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateReserves, updateEthPrice]);

  return { data: gmyPrice, mutate };
}

function useGmyPriceFromOP() {
  const poolAddress = getContract(OP, "TraderJoeGmyAvaxPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeGmyAvaxReserves", OP, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: avaxReserve, _reserve1: gmyReserve }: any = data || {};
  const vaultAddress = getContract(OP, "Vault");
  const ethAddress = getTokenBySymbol(OP, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice`, OP, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const PRECISION = bigNumberify(10)!.pow(18);
  let gmyPrice;
  if (avaxReserve && gmyReserve && ethPrice) {
    gmyPrice = avaxReserve.mul(PRECISION).div(gmyReserve).mul(ethPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateReserves, updateEthPrice]);

  return { data: gmyPrice, mutate };
}

function useGmyPriceFromArbitrum(library, active) {
  const poolAddress = getContract(ARBITRUM, "UniswapGmyEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR<any>(
    [`StakeV2:uniPoolSlot0:${active}:${poolAddress}`, ARBITRUM, poolAddress, "slot0"],
    {
      fetcher: contractFetcher(library, UniPool),
      refreshInterval: 10000,
    }
  );

  const vaultAddress = getContract(ARBITRUM, "Vault");
  const ethAddress = getTokenBySymbol(ARBITRUM, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR<BigNumber>(
    [`StakeV2:ethPrice:${active}:${ethAddress}`, ARBITRUM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const gmyPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(ARBITRUM, ethAddress, 18, "SYMBOL", "NAME");

      const gmyAddress = getContract(ARBITRUM, "GMY");
      const tokenB = new UniToken(ARBITRUM, gmyAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount?.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: gmyPrice, mutate };
}

export async function approvePlugin(chainId, pluginAddress, { library, setPendingTxns, sentMsg, failMsg }) {
  const routerAddress = getContract(chainId, "Router");
  const contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner());
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    setPendingTxns,
  });
}

export async function createSwapOrder(
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts: any = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts: any = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts: any = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export function handleCancelOrder(chainId, library, order, opts) {
  let func;
  if (order.type === SWAP) {
    func = cancelSwapOrder;
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder;
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder;
  }

  return func(chainId, library, order.index, {
    successMsg: `Order cancelled.`,
    failMsg: `Cancel failed.`,
    sentMsg: `Cancel submitted.`,
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
  });
}

export async function cancelMultipleOrders(chainId, library, allIndexes = [], opts) {
  const ordersWithTypes = groupBy(allIndexes, (v: any) => v.split("-")[0]);
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return;
    return ordersWithTypes[key].map((d) => d.split("-")[1]);
  }
  // params order => swap, increase, decrease
  const params = ["Swap", "Increase", "Decrease"].map((key) => getIndexes(key) || []);
  const method = "cancelMultiple";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(chainId, library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

export async function newPositionOrder(
  chainId,
  library,
  tokenId,
  isLong,
  orderType,
  price,
  slippage,
  collateral,
  size,
  refer,
  opts: any = {}
) {
  const params = [
    tokenId,
    isLong,
    orderType,
    // 0 -> market order
    // 1 -> limit order
    // 2 -> stop-market order
    // 3 -> stop-limit order
    [price, slippage, collateral, size], // paramMsp
    // for market order:  _params[0] -> allowed price (revert if exceeded)
    // for limit order: _params[0] -> limit price
    //_params[1] -> slippage || 0
    // In stop-market order: _params[1] -> stop price,
    // In stop-limit order: _params[0] -> limit price, _params[1] -> stop price
    // for all orders: _params[2] -> collateral
    // for all orders: _params[3] -> size
    refer,
  ];
  const method = "newPositionOrder";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  // console.log("?????", { params, opts });

  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function newPositionOrderWithTPSL(
  chainId,
  library,
  tokenId,
  isLong,
  orderType,
  allowedPrice,
  slippage,
  collateral,
  size, // paramMsp
  refer,
  isTPs,
  prices,
  amountPercents,
  opts: any = {}
) {
  const params = [
    tokenId,
    isLong,
    orderType,
    [allowedPrice, slippage, collateral, size], // paramMsp
    refer,
    isTPs,
    prices,
    amountPercents,
  ];
  const method = "newPositionOrderWithTPSL";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export async function addTPSL(chainId, library, _posId, _isTPs, _prices, _amountPercents, opts) {
  const params = [_posId, _isTPs, _prices, _amountPercents];
  const method = "addTPSL";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function addOrRemoveCollateral(chainId, library, isPlus, _posId, _amount, opts) {
  const params = [_posId, _amount];
  const method = isPlus ? "addCollateral" : "removeCollateral";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function addTrailingStop(chainId, library, _posId, _param, opts) {
  const params = [_posId, _param];
  const method = "addTrailingStop";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function addPosition(chainId, library, _posId, _collateralDelta, _sizeDelta, _allowPrice, opts) {
  const params = [_posId, _collateralDelta, _sizeDelta, _allowPrice];
  const method = "addPosition";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function decreasePosition(chainId, library, _sizeDelta, _allowedPrice, _posId, opts) {
  const params = [_sizeDelta, _allowedPrice, _posId];
  const method = "decreasePosition";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelPendingOrder(chainId, library, posId, opts) {
  const params = [posId];
  const method = "cancelPendingOrder";
  const orderBookAddress = getContract(chainId, "VaultMsp");
  const contract = new ethers.Contract(orderBookAddress, VaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function cancelTriggerOrder(chainId, library, _posId, _orderId, opts) {
  const params = [_posId, _orderId];
  const method = "cancelTriggerOrder";
  const orderBookAddress = getContract(chainId, "OrderVaultMsp");
  const contract = new ethers.Contract(orderBookAddress, OrderVaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function editTriggerOrder(chainId, library, _posId, _orderId, _isTP, _price, _amountPercent, opts) {
  const params = [_posId, _orderId, _isTP, _price, _amountPercent];
  const method = "editTriggerOrder";
  const orderBookAddress = getContract(chainId, "OrderVaultMsp");
  const contract = new ethers.Contract(orderBookAddress, OrderVaultMsp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
