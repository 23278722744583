function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 40}
      height={props.height ? props.height : 40}
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_18905_16785)">
        <g clipPath="url(#clip1_18905_16785)">
          <path
            fill="#F7931A"
            d="M39.4 24.838c-2.672 10.715-13.524 17.235-24.24 14.563C4.45 36.731-2.07 25.877.6 15.164 3.27 4.448 14.124-2.073 24.836.598 35.55 3.27 42.07 14.123 39.4 24.838z"
          ></path>
          <path
            fill="#fff"
            d="M28.819 17.15c.398-2.661-1.628-4.092-4.4-5.046l.9-3.605-2.195-.547-.875 3.51c-.577-.144-1.17-.28-1.759-.414l.882-3.533-2.194-.547-.899 3.604a72.485 72.485 0 01-1.401-.33l.002-.01-3.026-.757-.584 2.345s1.628.373 1.594.396c.889.222 1.05.81 1.023 1.276L14.863 17.6c.061.016.14.038.228.074l-.232-.058-1.435 5.754c-.109.27-.384.675-1.006.521.022.032-1.595-.398-1.595-.398l-1.09 2.512 2.856.712c.532.134 1.052.273 1.565.404l-.908 3.647 2.192.547.9-3.608c.598.162 1.18.312 1.748.454l-.896 3.59 2.195.547.908-3.64c3.742.709 6.556.423 7.74-2.962.955-2.725-.047-4.297-2.016-5.322 1.434-.33 2.515-1.274 2.803-3.222h-.001zm-5.015 7.032c-.678 2.725-5.266 1.252-6.754.883l1.205-4.832c1.488.372 6.258 1.107 5.55 3.949zm.68-7.07c-.62 2.478-4.439 1.219-5.678.91L19.9 13.64c1.239.309 5.229.885 4.584 3.471z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_18905_16785">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
        <clipPath id="clip1_18905_16785">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
