import { IconLogo } from "assets";
import React from "react";
import styled from "styled-components";

const FooterYummy = () => {
  const listFooter = [
    {
      title: "Product",
      children: [
        {
          name: "Perp",
          link: "https://app.yummy.fi/#/trade",
        },
        {
          name: "Swap",
          link: "https://app.yummy.fi/#/spot-native",
        },
        {
          name: "Earn",
          link: "https://app.yummy.fi/#/earn",
        },
      ],
    },
    {
      title: "Resources",
      children: [
        {
          name: "White paper",
          link: "#",
        },
        {
          name: "Audit report",
          link: "#",
        },
        {
          name: "Docs",
          link: "https://docs.yummy.fi/",
        },
      ],
    },
    {
      title: "Other",
      children: [
        {
          name: "Privacy policy",
          link: "#",
        },
        {
          name: "Term of service",
          link: "#",
        },
        {
          name: "Cookies notice",
          link: "#",
        },
        {
          name: "FAQs",
          link: "#",
        },
      ],
    },
  ];
  return (
    <Wrapper>
      <div className="head">
        <div className="container content-head">
          <IconLogo />
          <div className="box-link">
            {listFooter.map((item, idx) => (
              <div className="item-link" key={idx}>
                <div className="title-link">{item.title}</div>
                <div className="child-link">
                  {item.children.map((i, t) => (
                    <a href={i.link} target="_blank" className="link-child" key={t}>
                      {i.name}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer">Copyrights © 2024 Yummy Finance. All rights reserved.</div>
    </Wrapper>
  );
};

export default FooterYummy;

const Wrapper = styled.div`
  background: #10141f;
  .head {
    display: flex;
    padding: 72px 0px 36px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    @media (max-width: 1024px) {
      display: flex;
      padding: 72px 24px 36px 24px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
    @media (max-width: 767px) {
      display: flex;
      padding: 72px 16px 36px 16px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
  }
  .content-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 1024px) {
      display: flex;
      gap: 120px;
      align-self: stretch;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      align-self: stretch;
    }
  }
  .box-link {
    display: flex;
    width: 648px;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    @media (max-width: 1024px) {
      display: flex;
      align-items: flex-start;
      gap: 36px;
      flex: 1 0 0;
    }
    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      width: 100%;
    }
  }
  .item-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
  }
  .title-link {
    color: #fff;

    /* 16/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  .child-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .link-child {
    color: var(--Text-Text_Secondary, #667180);

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    text-decoration: none;
    /* :hover {
      text-decoration: underline;
    } */
  }
  .footer {
    display: flex;
    padding: 24px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 2px solid var(--Border, rgba(239, 236, 235, 0.08));
    background: #10141f;
    color: var(--Text-Text_Secondary, #667180);
    text-align: center;

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */

    @media (max-width: 767px) {
      display: flex;
      padding: 24px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
  }
`;
