import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "33px"}
      height={props.height ? props.height : "32px"}
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill={props.fill ? props.fill : "#fff"}
        d="M4.929 3.84l9.099 13.265L4.486 28.16H6.52l8.409-9.742 6.682 9.742h6.41l-9.522-13.88L27.51 3.84h-2.032l-7.88 9.128-6.26-9.128h-6.41z"
      ></path>
    </svg>
  );
}

export default Icon;
