import "rc-slider/assets/index.css";
import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./SwapBox.css";
import useSWR from "swr";

import { IoMdSwap } from "react-icons/io";

import { FANTOM, IS_NETWORK_DISABLED, getChainName, getConstant, isSupportedChain } from "config/chains";
import { getContract } from "config/contracts";
import * as Api from "domain/legacy";
import { BASIS_POINTS_DIVISOR, DEFAULT_HIGHER_SLIPPAGE_AMOUNT, DUST_BNB, SHORT, SWAP, USD_DECIMALS } from "lib/legacy";

import ConfirmationBox from "./ConfirmationAggregatorBox";
import ExchangeInfoRow from "./ExchangeInfoRow";

import Token from "abis/Token.json";
import WETH from "abis/WETH.json";
import RouterAggregatpr from "abis/RouterAggregatorMicroSwap.json";

import ReloadIcon from "components/Icon/TradeIcon/ReloadIcon";

import SettingIcon from "components/Icon/TradeIcon/SettingIcon";
import TimerIcon from "components/Icon/TradeIcon/TimerIcon";
import { getMicroSwapToken, getNativeToken, getTokenBySymbol, getWrappedToken } from "config/tokens";
import { useUserReferralCode } from "domain/referrals";
import { approveRouterToken } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import SwapIcon from "img/trade/swap_icon.svg";
import WriteLinkIcon from "img/trade/write-link-icon.svg";
import MicroSwapIc from "img/microswap/logo-white.svg";
import MicroSwapLightIc from "img/microswap/logo-black.svg";
import { callContract, contractFetcher } from "lib/contracts";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import {
  bigNumberify,
  convertSmallENumber,
  formatAmount,
  formatAmountFree,
  formatNumber,
  parseValue,
} from "lib/numbers";
import RecentTransactionFireBirdModal from "pages/SpotThroughArregator/components/recentTransactionsModalAggreator";
import NoLiquidityErrorModal from "./NoLiquidityErrorModal";
import { BigNumber, ethers } from "ethers";
import { apiKeyMicroswap } from "hooks/useAggregator";
import cx from "classnames";
import { useThemeContext } from "contexts/ThemeProvider";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";
import TokenSelectorV3 from "./TokenSelectorV3";
import WarningIcon from "img/analytics/warning_icon.svg";
import { Link, useHistory } from "react-router-dom";
const { AddressZero } = ethers.constants;

export default function SpotArregatorBoxSwap(props) {
  const {
    active,
    library,
    account,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    setIsSettingsSlippageVisible,
    onSelectFromTokenChanged,
    onSelectToTokenChanged,
    onHandleReloadState,
    dataAggregator,
    microSwapTokens,
  } = props;
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const history = useHistory();

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account);
  const [recentTransactionModalState, setRecentTransactionModalState] = useState(false);
  const [swapHistory, setSwapHistory] = useLocalStorageSerializeKey(
    [`${chainId}-${account}`, "micro-swap-history"],
    []
  );

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  // TODO hack with useLocalStorageSerializeKey
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Swap-Short-Collateral-Address-V2",
    getTokenBySymbol(chainId, defaultCollateralSymbol).address
  );
  const isSwap = swapOption === SWAP;

  let routerContract = getContract(chainId, "MicroSwapRouter");

  const routerAddress = routerContract || getContract(chainId, "MicroSwapRouter");

  const [rateTypeNormal, setRateTypeNormal] = useState(true);
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, tokenAllowanceAddress, "allowance", account, routerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const fromToken = getMicroSwapToken(microSwapTokens, fromTokenAddress);
  const toToken = getMicroSwapToken(microSwapTokens, toTokenAddress);

  useEffect(() => {
    if ((fromToken.isNative && toToken.isWrapped) || (toToken.isNative && fromToken.isWrapped)) {
      if (fromValue) {
        setToValue(fromValue);
        onSelectToTokenChanged && onSelectToTokenChanged(fromValue);
      }
    }
  }, [fromToken, toToken]);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const isPotentialWrap = (fromToken?.isNative && toToken?.isWrapped) || (fromToken?.isWrapped && toToken?.isNative);
  const isWrapOrUnwrap = Boolean(isSwap && isPotentialWrap);
  const needApproval =
    fromTokenAddress !== AddressZero &&
    tokenAllowance &&
    fromAmount &&
    fromAmount.gt(tokenAllowance) &&
    !isWrapOrUnwrap;

  const priceImpact = useMemo(() => {
    return 0;
  }, []);

  const getPriceImpactStyle = () => {
    let color = isLightTheme ? "#0D1A16" : "white";

    if ((priceImpact >= 5) & (priceImpact < 10)) {
      color = isLightTheme ? "#D49007" : "#FFA722";
    }

    if (priceImpact >= 10) {
      color = isLightTheme ? "#D62F44" : "#F6475D";
    }

    return {
      color,
    };
  };

  useEffect(() => {
    onSelectFromTokenChanged && onSelectFromTokenChanged(fromValue);
    if ((fromToken.isNative && toToken.isWrapped) || (toToken.isNative && fromToken.isWrapped)) {
      setToValue(fromValue);

      onSelectToTokenChanged && onSelectToTokenChanged(fromValue);
    }
    if (!fromValue || !Number(fromValue)) setToValue("");
  }, [fromValue]);

  useEffect(() => {
    if (!(fromToken.isNative && toToken.isWrapped) && !(toToken.isNative && fromToken.isWrapped)) {
      let valueTo = BigNumber.from(0);
      if (fromValue && dataAggregator) {
        if (dataAggregator?.quoteData?.maxReturn?.totalTo) {
          valueTo = BigNumber.from(dataAggregator?.quoteData?.maxReturn?.totalTo);
          setToValue(formatAmount(valueTo, toToken.decimals, toToken.decimals));
        } else {
          setToValue("0");
        }
      }
      onSelectToTokenChanged && onSelectToTokenChanged(formatAmount(valueTo, toToken.decimals, toToken.decimals));
    }
  }, [dataAggregator]);

  useEffect(() => {
    if (chainId && chainId !== FANTOM) {
      history.push("/spot-native");
    }
  }, [chainId, history]);
  const getSwapError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [`Swaps disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (fromTokenAddress === toTokenAddress) {
      return [`Select different tokens`];
    }

    if (fromValue && dataAggregator.length === 0) return "Insufficient liquidity on Swap";
    if (!fromAmount) {
      return [`Enter an amount`];
    }
    if (fromToken && fromToken?.balance && fromAmount && fromAmount.gt(fromToken.balance)) {
      return [`Insufficient ${fromToken.symbol} balance`];
    }
    return [false];
  };

  const getToLabel = () => {
    if (isSwap) {
      return `To (estimated)`;
    }
  };

  const getError = () => {
    if (isSwap) {
      return getSwapError();
    }
    return false;
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!active) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }

    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (fromToken && fromToken.balance && fromAmount && fromAmount.gt(fromToken.balance)) {
      return false;
    }
    if (!Number(fromAmount) || !Number(toAmount)) return false;
    if (priceImpact >= 50) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    if (!active) {
      return `Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return `Incorrect Network`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (fromTokenAddress === AddressZero && toTokenAddress?.toLowerCase() === nativeTokenAddress?.toLowerCase()) {
      if (isSubmitting) {
        return `Wrapping...`;
      }
      return "Wrap";
    }
    if (fromTokenAddress?.toLowerCase() === nativeTokenAddress?.toLowerCase() && toTokenAddress === AddressZero) {
      if (isSubmitting) {
        return `Unwrapping...`;
      }
      return "Unwrap";
    }
    if (fromValue && fromAmount.gt(0) && dataAggregator.length === 0) return "Insufficient liquidity on Swap";
    if (fromToken && fromToken.balance && fromAmount && fromAmount.gt(fromToken.balance)) {
      return [`Insufficient ${fromToken.symbol} balance`];
    }
    if (needApproval && isWaitingForApproval) {
      return `Waiting for Approval`;
    }
    if (isApproving) {
      return `Approving ${fromToken.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${fromToken.symbol}`;
    }

    if (isSwap) {
      if (priceImpact >= 50) {
        return `Price Impact Too High`;
      }
      if (priceImpact >= 5) {
        return `High Price Impact, Swap Anyway`;
      }
      if (!isWrapOrUnwrap && Number(savedSlippageAmount) > 200) {
        return `High Slippage, Swap Anyway`;
      }

      if (isPendingConfirmation) {
        return `Swapping...`;
      }
      return `Swap`;
    }
  };

  const onSelectFromToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
  };
  const onSelectToToken = (token) => {
    setToTokenAddress(swapOption, token.address);
  };

  const onFromValueChange = (e) => {
    setAnchorOnFromAmount(true);
    setFromValue(e.target.value);
  };

  const onToValueChange = (e) => {
    setAnchorOnFromAmount(false);
  };

  const switchTokens = () => {
    setToValue("");
    setIsWaitingForApproval(false);

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    updatedTokenSelection[swapOption] = {
      from: toToken.address,
      to: fromToken.address,
    };
    setTokenSelection(updatedTokenSelection);
  };

  const switchRate = () => {
    setRateTypeNormal(!rateTypeNormal);
  };

  const wrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, library.getSigner());
    const successMessage = `Wrapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
      fromToken.symbol
    } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`;
    callContract(chainId, contract, "deposit", {
      value: fromAmount,
      sentMsg: `Wrap submitted.`,
      successMsg: successMessage,
      failMsg: `Swap failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        const trans = await res.wait();
        const his = [...swapHistory, { message: successMessage.replace("!", ""), hash: trans.transactionHash }];
        setSwapHistory(his);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const unwrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, library.getSigner());
    const successMessage = `Unwrapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
      fromToken.symbol
    } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`;

    callContract(chainId, contract, "withdraw", [fromAmount], {
      sentMsg: `Unwrap submitted!`,
      failMsg: `Unwrap failed.`,
      successMsg: successMessage,
      setPendingTxns,
    })
      .then(async (res) => {
        const trans = await res.wait();
        const his = [...swapHistory, { message: successMessage.replace("!", ""), hash: trans.transactionHash }];
        setSwapHistory(his);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    setIsPendingConfirmation(true);

    if (isSwap) {
      SwapsAction();
      return;
    }
  };

  const onClickPrimary = () => {
    if (!active) {
      props.connectWallet();
      return;
    }
    if (needApproval) {
      approveFromToken();
      return;
    }

    if (isSwap) {
      if (fromTokenAddress === AddressZero && toTokenAddress?.toLowerCase() === nativeTokenAddress?.toLowerCase()) {
        wrap();
        return;
      }

      if (fromTokenAddress?.toLowerCase() === nativeTokenAddress?.toLowerCase() && toTokenAddress === AddressZero) {
        unwrap();
        return;
      }
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromToken?.balance) {
      return;
    }

    const maxAvailableAmount = fromToken?.isNative
      ? fromToken.balance.sub(bigNumberify(DUST_BNB).mul(2))
      : fromToken?.balance;
    if (maxAvailableAmount.gt(0))
      setFromValue(formatAmount(maxAvailableAmount, fromToken.decimals, fromToken.decimals, false));
    else setFromValue("0");

    setAnchorOnFromAmount(true);
  }

  function shouldShowMaxButton() {
    if (!fromToken || !fromToken?.balance) {
      return false;
    }
    const maxAvailableAmount = fromToken.isNative
      ? fromToken.balance.sub(bigNumberify(DUST_BNB).mul(2))
      : fromToken.balance;
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  }

  const handleReload = () => {
    if (fromValue && toValue) {
      onHandleReloadState();
    }
  };

  const ROUTER_API = `https://api.microswap.org/aggregator/v2/encode`;

  async function sendTransaction(contract, method, params, value) {
    const funcData = await contract.interface.decodeFunctionData("swap", params.data);
    const successMessage = `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
      fromToken.symbol
    } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`;
    callContract(chainId, contract, method, funcData, {
      value,
      sentMsg: `Swap submitted!`,
      successMsg: successMessage,
      failMsg: `Swap failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsConfirming(false);
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
        const trans = await res.wait();
        const historyMessage = `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
          fromToken.symbol
        } for ${formatAmount(trans?.events[trans?.events?.length - 1]?.args?.amountOut, toToken.decimals, 4, true)} ${
          toToken.symbol
        }`;
        const his = [...swapHistory, { message: historyMessage, hash: trans.transactionHash }];
        setSwapHistory(his);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  }

  async function SwapsAction() {
    let method = "swap";
    let value;
    // fetch encoded swap transaction data
    const { encodedData, maxReturn } = await fetch(`${ROUTER_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": `${apiKeyMicroswap}`,
      },
      body: JSON.stringify(dataAggregator),
    }).then((r) => r.json());

    if (encodedData && maxReturn) {
      const tokenIn = dataAggregator.from;
      routerContract = encodedData.router; // router contract address
      const currencyAIsEth = tokenIn.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
      const amountIn = dataAggregator.amount;
      value = bigNumberify(0);

      if (fromTokenAddress === AddressZero) {
        value = bigNumberify(amountIn.toString());
      }

      const contract = new ethers.Contract(routerContract, RouterAggregatpr.abi, library.getSigner());
      const tx = {
        from: account,
        to: routerContract,
        gas: "1000000",
        data: encodedData.data, // encoded contract data
        value: currencyAIsEth ? bigNumberify(amountIn.toString()) : undefined,
      };
      sendTransaction(contract, method, tx, value);
    } else {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
    }
  }

  function approveFromToken() {
    approveRouterToken({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      token: fromToken,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      pendingTxns,
      setPendingTxns,
    });
  }
  const fromUsd = useMemo(() => {
    if (dataAggregator?.quoteData?.maxReturn && fromAmount) {
      if (!fromToken.isNative) {
        const { tokens } = dataAggregator?.quoteData?.maxReturn;
        const token = tokens[fromTokenAddress?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, fromToken.decimals)
          : token?.price;
        return fromAmount.mul(parseValue(tokenPrice || 0, fromToken.decimals)).div(parseValue(1, fromToken.decimals));
      } else {
        const wrappedToken = getWrappedToken(chainId || FANTOM);
        const { tokens } = dataAggregator?.quoteData?.maxReturn;
        const token = tokens[wrappedToken?.address?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, fromToken.decimals)
          : token?.price;
        return fromAmount.mul(parseValue(tokenPrice || 0, fromToken.decimals)).div(parseValue(1, fromToken.decimals));
      }
    }
    return parseValue(0, fromToken.decimals);
  }, [dataAggregator?.quoteData?.maxReturn, fromAmount]);

  const toUsd = useMemo(() => {
    if (dataAggregator?.quoteData?.maxReturn && toAmount) {
      if (!toToken.isNative) {
        const { tokens } = dataAggregator?.quoteData?.maxReturn;
        const token = tokens[toTokenAddress?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, toToken.decimals)
          : token?.price;
        return toAmount.mul(parseValue(tokenPrice || 0, toToken.decimals)).div(parseValue(1, toToken.decimals));
      } else {
        const wrappedToken = getWrappedToken(chainId || FANTOM);
        const { tokens } = dataAggregator?.quoteData?.maxReturn;
        const token = tokens[wrappedToken?.address?.toLowerCase()];
        const tokenPrice = token?.price?.toString()?.includes("e-")
          ? convertSmallENumber(token?.price, toToken.decimals)
          : token?.price;
        return toAmount.mul(parseValue(tokenPrice || 0, toToken.decimals)).div(parseValue(1, toToken.decimals));
      }
    }
    return parseValue(0, fromToken.decimals);
  }, [dataAggregator?.quoteData?.maxReturn, toAmount]);

  const calcRate = useMemo(() => {
    if (dataAggregator?.quoteData?.maxReturn && fromValue && toValue) {
      if (Number(fromValue) && Number(toValue)) {
        const rate = rateTypeNormal ? Number(toValue) / Number(fromValue) : Number(fromValue) / Number(toValue);
        return parseValue(rate.toFixed(6), 6);
      }
      return null;
    }
  }, [dataAggregator?.quoteData?.maxReturn, rateTypeNormal, fromValue, toValue]);
  const isLowLiquidity = useMemo(() => {
    if (dataAggregator?.quoteData?.maxReturn && fromValue && toValue) {
      if (Number(fromValue) && Number(toValue)) {
        const rate = Number(toValue) / Number(fromValue);
        const { tokens } = dataAggregator?.quoteData?.maxReturn;
        const calcFromToken = fromToken.isNative
          ? tokens?.[getWrappedToken(chainId)?.address.toLowerCase()]
          : tokens?.[fromTokenAddress.toLowerCase()];
        const calcToToken = toToken.isNative
          ? tokens?.[getWrappedToken(chainId)?.address.toLowerCase()]
          : tokens?.[toTokenAddress.toLowerCase()];
        if (calcFromToken && calcToToken) {
          const oracalPrice = calcFromToken.price / calcToToken.price;
          const rateDiff = ((oracalPrice - rate) / oracalPrice) * 100;
          if (rateDiff > 3) return true;
        }
        return false;
      }
    }
    return false;
  }, [dataAggregator?.quoteData?.maxReturn, toValue]);
  return (
    <div className={`Exchange-swap-box box-trade-swap`}>
      <div className="swap-arregator-box">
        <div className="label-box-container">
          <div className="label-box-title label-box-title--active">Aggregator</div>
          <Link to={"/spot-native"} className="label-box-title">
            Native
          </Link>
        </div>
        <div className="content-box">
          <div
            className="content-icon-styled"
            onClick={handleReload}
            style={{ cursor: Boolean(fromValue && toValue) ? "pointer" : "not-allowed" }}
          >
            <ReloadIcon
              placeHolderColor={isLightTheme ? "#F2ae00" : "rgba(255,255,255,.1)"}
              color={isLightTheme ? "#02B27F" : "#fff"}
              validData={Boolean(fromValue && toValue)}
              timeReload={Boolean(fromValue && toValue) ? "15s" : "0s"}
            />
          </div>
          <div
            className="content-icon-styled"
            onClick={() => account && setRecentTransactionModalState(!recentTransactionModalState)}
            style={{ cursor: account ? "pointer" : "not-allowed" }}
          >
            <TimerIcon stopColor={isLightTheme ? "#F2AE00" : "#C1FF99"} color={isLightTheme ? "#02B27F" : "#fff"} />
          </div>
          <div className="content-icon-styled" onClick={setIsSettingsSlippageVisible}>
            <SettingIcon color={isLightTheme ? "#02B27F" : "#fff"} stopColor={isLightTheme ? "#F2AE00" : "#C1FF99"} />
          </div>
        </div>
      </div>
      <div
        className={`Exchange-swap-box-inner App-box-highlight box-trade-v2
        `}
      >
        {
          <React.Fragment>
            <div className="Exchange-swap-section exchange-section-first">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {dataAggregator?.quoteData?.maxReturn && (
                    <div className="Exchange-swap-usd">
                      <span>
                        From: {formatNumber(formatAmount(fromUsd, fromToken?.decimals || USD_DECIMALS, 2, false), 2)}{" "}
                        USD
                      </span>
                    </div>
                  )}
                  {!dataAggregator?.quoteData?.maxReturn && `From`}
                </div>
                {fromToken && (
                  <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                    <span>Balance: {formatAmount(fromToken?.balance, fromToken.decimals, 4, true)}</span>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                  />
                  {shouldShowMaxButton() && (
                    <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                      <span>MAX</span>
                    </div>
                  )}
                </div>
                <div>
                  <TokenSelectorV3
                    label={`From`}
                    chainId={chainId}
                    onSelectToken={onSelectFromToken}
                    tokens={microSwapTokens}
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                    selectedToken={fromToken}
                  />
                </div>
              </div>
            </div>
            <div className="Exchange-swap-ball-container">
              <div
                className={`Exchange-swap-ball ${swapOption === SHORT ? "Exchange-swap-ball-red" : ""}`}
                onClick={switchTokens}
              >
                <IoMdSwap className="Exchange-swap-ball-icon" />
              </div>
            </div>
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {dataAggregator?.quoteData?.maxReturn && (
                    <div className="Exchange-swap-usd">
                      <Tooltip
                        handle={getToLabel()}
                        position="left-top"
                        renderContent={() => {
                          return (
                            <>
                              <span className="text-desc">
                                We find the best price from the top liquidity sources every time. Fees of 0.05%
                                (non-stable tokens) and 0.005% (stable tokens) are automatically factored into this
                                quote by MicroSwap.
                              </span>
                            </>
                          );
                        }}
                      ></Tooltip>
                      : {formatNumber(formatAmount(toUsd || 0, toToken?.decimals || USD_DECIMALS, 2, false))} USD
                    </div>
                  )}
                  {!dataAggregator?.quoteData?.maxReturn && (
                    <Tooltip
                      handle={getToLabel()}
                      position="left-top"
                      renderContent={() => {
                        return (
                          <>
                            <span className="text-desc">
                              We find the best price from the top liquidity sources every time. Fees of 0.05%
                              (non-stable tokens) and 0.005% (stable tokens) are automatically factored into this quote
                              by MicroSwap.
                            </span>
                          </>
                        );
                      }}
                    ></Tooltip>
                  )}
                </div>
                {toToken && isSwap && (
                  <div className="muted align-right">
                    <span>Balance</span>: {formatAmount(toToken?.balance, toToken.decimals, 4, true)}
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div>
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={toValue}
                    onChange={onToValueChange}
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                </div>
                <div>
                  <TokenSelectorV3
                    label={`To`}
                    chainId={chainId}
                    onSelectToken={onSelectToToken}
                    tokens={microSwapTokens}
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                    selectedToken={toToken}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        }
        {isSwap && !isWrapOrUnwrap && (
          <div className="Exchange-swap-box-info">
            {fromAmount && (dataAggregator?.length > 0 || dataAggregator?.quoteData) && (
              <ExchangeInfoRow label={`Rate`}>
                <div className="rate-exchange-group">
                  <div className="price-token-styled">
                    <div className="exchange-token-value">
                      <span className="price-styled">1</span>
                      <span className="symbol-styled">{rateTypeNormal ? fromToken?.symbol : toToken?.symbol}</span> =
                      <span className="price-styled">
                        {!calcRate && <div className="skeleton-box" style={{ width: "60px" }} />}
                        {calcRate && <SmallTextNumber val={formatAmount(calcRate, 6, 6, false) || "--"} decimal={4} />}
                      </span>
                      <span className="symbol-styled">{rateTypeNormal ? toToken?.symbol : fromToken?.symbol}</span>
                    </div>
                    <div className="icon-styled" onClick={switchRate}>
                      <img src={SwapIcon} alt="swap icon" />
                    </div>
                  </div>
                  {/* {fromValue ? <div className="desc-rate-value">{`<0.1% cheaper than current market price`}</div> : <></>} */}
                </div>
              </ExchangeInfoRow>
            )}
            <ExchangeInfoRow label={`Slippage`}>
              <div className="price-token-styled">
                <div className="percent-slippage">{parseFloat(savedSlippageAmount / 100.0).toFixed(2)}%</div>
                <div className="icon-styled" onClick={setIsSettingsSlippageVisible}>
                  <img src={WriteLinkIcon} alt="swap icon" />
                </div>
              </div>
            </ExchangeInfoRow>
            {/* {Number(savedSlippageAmount) > 200 && (
              <div className="warning-box-wrapper">
                <div>
                  <img src={WarningIcon} className="warning-icon" />
                  <span className="text-desc">High slippage! You might get sandwiched</span>
                </div>
              </div>
            )} */}

            <div className={cx("Exchange-info-row", { "top-line": false })}>
              <Tooltip
                handle={<div className="Exchange-info-label">Minimum received</div>}
                position="left-top"
                renderContent={() => {
                  return (
                    <>
                      <span className="text-desc">
                        Your transaction will revert if there is a large, unfavorable price movement before it is
                        confirmed.
                      </span>
                    </>
                  );
                }}
              ></Tooltip>
              <div className={`align-right`} style={{ fontWeight: 700 }}>
                <SmallTextNumber
                  val={
                    fromAmount && toAmount
                      ? formatAmount(
                          !(fromToken?.isNative && toToken?.isWrapped) && !(fromToken?.isWrapped && toToken?.isNative)
                            ? toAmount.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)
                            : toAmount,
                          toToken?.decimals,
                          toToken?.decimals,
                          false
                        )
                      : "--"
                  }
                  decimal={4}
                />{" "}
                <span className="muted-color">{toToken.symbol || "USDC"}</span>
              </div>
            </div>
            {isLowLiquidity && (
              <div className="warning-box-wrapper">
                <div>
                  <img src={WarningIcon} className="warning-icon" />
                  <span className="text-desc">Warning: potential low liquidity trade</span>
                </div>
              </div>
            )}
            {/* {
              <div className={cx("Exchange-info-row", { "top-line": false })}>
                <Tooltip
                  handle={<div className="Exchange-info-label">Price impact</div>}
                  position="left-top"
                  renderContent={() => {
                    return (
                      <span className="text-desc">Estimated change in price due to the size of your transaction.</span>
                    );
                  }}
                ></Tooltip>
                <div className={`align-right`} style={{ fontWeight: 700, ...getPriceImpactStyle() }}>
                  {!priceImpact ? "--" : priceImpact < 0 ? "< 0.01" : priceImpact.toFixed(2)}%
                </div>
              </div>
            } */}
          </div>
        )}
        {isSwap && (
          <div className="Exchange-swap-button-container">
            <button className="default-btn full" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        )}

        {/* <div className="powered-wrapper">
          <div className="powered-label">Powered by</div>
          <a href={"https://app.microswap.org/swap"} target="_blank" className="microswap-icon" rel="noreferrer">
            <img src={isLightTheme ? MicroSwapLightIc : MicroSwapIc} style={{ height: "24px", objectFit: "contain" }} />
          </a>
        </div> */}
      </div>

      <RecentTransactionFireBirdModal
        isOpen={recentTransactionModalState}
        setIsOpen={setRecentTransactionModalState}
        account={account}
        transactions={swapHistory}
        forSingleAccount={true}
        getTokenInfo={getTokenInfo}
        chainId={chainId}
        nativeTokenAddress={nativeTokenAddress}
        shouldShowPaginationButtons={true}
        onClearAll={() => setSwapHistory([])}
      />
      {/* <NoLiquidityErrorModal
        chainId={chainId}
        fromToken={fromToken}
        toToken={toToken}
        modalError={modalError}
        setModalError={setModalError}
      /> */}
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          isSwap={isSwap}
          fromToken={fromToken}
          toToken={toToken}
          toAmount={toAmount}
          fromAmount={fromAmount}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          shortCollateralAddress={shortCollateralAddress}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          rateTypeNormal={rateTypeNormal}
          setRateTypeNormal={() => setRateTypeNormal(!rateTypeNormal)}
          isLightTheme={isLightTheme}
          toUsd={toUsd}
          fromUsd={fromUsd}
          calcRate={calcRate}
        />
      )}
    </div>
  );
}
