import { ARBITRUM, AVALANCHE, BASE, BERACHAIN_BARTIO, FANTOM, OP } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmy-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/yummy-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/yummy-fantom-referrals/version/latest",
  },
  [BERACHAIN_BARTIO]: {
    stats: "https://api.goldsky.com/api/public/project_clzqf9vmq8wix01vt0kn8aovf/subgraphs/yummy-bartio-stats/0.0.1/gn",
    referrals: "https://api.goldsky.com/api/public/project_clzqf9vmq8wix01vt0kn8aovf/subgraphs/yummy-bartio-referrals/0.0.1/gn",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
