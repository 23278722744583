import React from "react";

function Icon({ ...props }) {
  return (
    <svg 
    width={props.width ? props.width : "33px"}
      height={props.height ? props.height : "32px"}
       viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_20046_5197)">
        <path d="M16 0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32C24.8367 32 32 24.8367 32 16C32 7.16333 24.8367 0 16 0ZM17.254 24.6673H14.746V19.004H17.254V24.6673ZM23.0587 15.586H18.7707L21.8247 18.5527L20.146 20.238L16 16.0713L11.8533 20.238L10.1747 18.5593L13.2287 15.5927H8.94067V13.1993H13.2087L10.1747 10.3067L11.8533 8.58733L14.746 11.5607V7.33333H17.254V11.5607L20.146 8.58733L21.8247 10.3067L18.7907 13.1993H23.0587V15.586Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_20046_5197">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
