import { Menu } from "@headlessui/react";
import IcDown from "img/ic_down_white.svg";
import IcDownLight from "img/ic_down_whiteLight.svg";
import "./Bridge.scss";
import "./BridgeTheme.scss";
import styled from "styled-components";
import LayerZeroIcon from "img/layer0.svg";
import LayerZeroIconLight from "img/layer0Light.svg";
import icFantom from "img/ic_ftm_24.svg";
import icOp from "img/ic_op_24.svg";
import icArb from "img/ic_arbitrum_24.svg";
import icBase from "img/ic_base_24.svg";
import icScan from "img/ic_scan.svg";
import icScanLight from "img/ic_scanLight.svg";
import icScanWhite from "img/ic_scan_white.svg";
import icScanWhiteLight from "img/ic_scan_whiteLight.svg";
import icGMY from "img/bridge/gmy.svg";
import icNFT from "img/bridge/nft.svg";
import icPlus from "img/bridge/ic-plus.svg";
import icPlusLight from "img/bridge/ic-plusLight.svg";
import icCheckbox from "img/bridge/new_checkbox.svg";
import icCheckboxLight from "img/bridge/new_checkboxLight.svg";
import icCheckboxChecked from "img/bridge/new_checkbox_check.svg";
import icCheckboxCheckedLight from "img/bridge/new_checkbox_checkLight.svg";
import icNFTItem from "img/bridge/nft-item.svg";
import icBolt from "img/bridge/bolt.svg";
import icBoltLight from "img/bridge/boltLight.svg";
import icEmpty from "img/bridge/empty.png";
import { IoMdSwap } from "react-icons/io";
import { BASE, FANTOM, getConstant, getLinkCDN } from "config/chains";
import { OP } from "config/chains";
import { ARBITRUM, getExplorerUrl } from "config/chains";
import { useEffect, useState } from "react";
import Footer from "components/Footer/Footer";
import bg from "img/earn/bg.svg";
import Tooltip from "components/Tooltip/Tooltip";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { getContract } from "config/contracts";
import { useChainId } from "lib/chains";
import BridgedReader from "abis/Bridge.json";
import BridgedNFTReader from "abis/BridgeNFT.json";
import { expandDecimals, formatAmount, formatNumber } from "lib/numbers";
import { BigNumber, ethers } from "ethers";
import { numberWithCommas } from "lib/numbers";
import { getBalanceAndSupplyData } from "lib/legacy";
import ReaderV2 from "abis/ReaderV2.json";
import { useBridgeApi } from "domain/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Token from "abis/Token.json";
import { approveTokens } from "domain/tokens";
import { parseValue } from "graphql";
import { switchNetwork } from "lib/wallets";
import Pagination from "pages/Analytics/components/Pagination";
import Modal from "components/Modal/Modal";
import SelectedNftItem from "./SelectedNftItem";
import { Link } from "react-router-dom";
import useGetListStakedNFT from "hooks/useGetListStakedNFT";
import NFTClub from "abis/NFTClub.json";
import { useThemeContext } from "contexts/ThemeProvider";

const FANTOMBRIDGE = 112;
const ARBITRUMBRIDGE = 110;
const BASEBRIDGE = 184;
const TABS = [
  {
    name: "GMY",
    icon: icGMY,
  },
  {
    name: "NFT",
    icon: icNFT,
  },
];
const OPBRIDGE = 111;

function getChainL0(chainId) {
  switch (chainId) {
    case FANTOM:
      return FANTOMBRIDGE;
    case OP:
      return OPBRIDGE;
    case ARBITRUM:
      return ARBITRUMBRIDGE;
    case BASE:
      return BASEBRIDGE;

    default:
      return FANTOMBRIDGE;
  }
}
function getChainId(chainLzId) {
  switch (chainLzId) {
    case FANTOMBRIDGE:
      return FANTOM;
    case OPBRIDGE:
      return OP;
    case ARBITRUMBRIDGE:
      return ARBITRUM;
    case BASEBRIDGE:
      return BASE;
    default:
      return FANTOM;
  }
}
const Bridge = ({ setPendingTxns, pendingTxns, connectWallet }) => {
  const [isBridging, setIsBridging] = useState(false);
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { active, library, account, chainId: waletChainId } = useWeb3React();
  const [currentTab, setCurrentTab] = useState("GMY");
  const chains =
    currentTab === "GMY"
      ? [
          { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
          { chainId: OPBRIDGE, name: "Optimism", img: icOp, id: OP },
          { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb, id: ARBITRUM },
          { chainId: BASEBRIDGE, name: "Base", img: icBase, id: BASE },
        ]
      : [
          // { chainId: FANTOMBRIDGE, name: "Fantom", img: icFantom, id: FANTOM },
          { chainId: OPBRIDGE, name: "Optimism", img: icOp, id: OP },
          { chainId: ARBITRUMBRIDGE, name: "Arbitrum", img: icArb, id: ARBITRUM },
        ];
  // const account = "0xaa305f47225f9c49a51786012448f9928e94b936";
  const { chainId } = useChainId();
  const [isApproving, setIsApproving] = useState(false);
  const [isApprovingNFT, setIsApprovingNFT] = useState(false);
  const [fromChain, setFromChain] = useState(getChainL0(chainId));
  const [toChain, setToChain] = useState(
    chainId === OP ? FANTOMBRIDGE : chainId === ARBITRUM ? OPBRIDGE : ARBITRUMBRIDGE
  );
  const [historyData, setHistoryData] = useState([]);
  const [amount, setAmount] = useState("");
  const selectedFromChain = chains.find((x) => x.chainId === fromChain) || chains[0];
  const selectedToChain = chains.find((x) => x.chainId === toChain) || currentTab === chains[1];
  const nftClubAddress = getContract(chainId, "NFTClub");
  const bridgeReaderAddress = getContract(chainId, "Bridge");
  const bridgeNFTReaderAddress = getContract(chainId, "BridgeNFT");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [row, setRows] = useState(100);
  const MAX_NUMBER_PAGE = 70;

  const [isVisible, setIsVisible] = useState(false);
  const [nftList, setNftList] = useState([]);
  const [modalNftList, setModaltNftList] = useState([]);
  const histories = useBridgeApi(account, "GMY");
  const historiesNFT = useBridgeApi(account, "NFT");
  const { listMyNFT } = useGetListStakedNFT();
  // console.log("????", listMyNFT);
  const toChainList = chains.filter((item) => item.chainId !== fromChain);

  useEffect(() => {
    setHistoryData([]);
  }, [currentTab]);
  useEffect(() => {
    setTimeout(() => {
      if (currentTab === "GMY") {
        if (histories) {
          setHistoryData([...histories?.data?.rows]);
        } else setHistoryData([]);
      } else {
        if (historiesNFT) {
          setHistoryData([...historiesNFT?.data?.rows]);
        } else setHistoryData([]);
      }
    }, 300);
  }, [currentTab, histories, historiesNFT]);
  // useEffect(() => {
  //   if (listMyNFT.length !== nftList.length && nftList.length > 0 && listMyNFT.length > 0) {
  //     setNftList([]);
  //     setModaltNftList([]);
  //   }
  // }, [listMyNFT]);
  useEffect(() => {
    let length = historyData.length;
    setRows(length);
  }, [historyData]);
  useEffect(() => {
    const oldFrom = fromChain;
    setFromChain(getChainL0(chainId));
    setToChain(
      oldFrom === getChainL0(chainId) ? (toChain === getChainL0(chainId) ? toChainList[0].chainId : toChain) : oldFrom
    );
  }, [chainId, fromChain]);
  useEffect(() => {
    if (currentTab !== "GMY")
      if (toChain !== toChainList[0].chainId) {
        setToChain(toChainList[0].chainId);
      }
  }, [currentTab, toChainList]);
  useEffect(() => {
    if (listMyNFT && listMyNFT.length > 0) {
      const availableNFTs = listMyNFT.filter((x) => !x[2]);
      if (availableNFTs.length !== nftList.length) {
        let arrNft = [];
        for (let i = 0; i < listMyNFT.length; i++) {
          if (!listMyNFT[i][2]) arrNft.push({ id: listMyNFT[i][0], selected: false, power: listMyNFT[i][1] });
        }
        setNftList([...arrNft]);
      }
    } else {
      setNftList([]);
      setModaltNftList([]);
    }
  }, [listMyNFT]);
  useEffect(() => {
    if (currentTab !== "GMY") {
      setNftList([]);
    }
  }, [fromChain]);
  useEffect(() => {
    if (isVisible) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible && nftList.length > 0 && modalNftList.length === 0) {
      let arrModalNft = [];
      for (let i = 0; i < nftList.length; i++) {
        arrModalNft.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
      }
      setModaltNftList([...arrModalNft]);
    }
  }, [nftList]);
  const handleChangeTab = (tab) => {
    if (tab !== "GMY") {
      if (chainId === FANTOM) switchNetwork(toChain === OPBRIDGE ? ARBITRUM : OP, active);
    }
    setCurrentTab(tab);
  };
  const handleSelectModalNft = (nft) => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      if (arrModal[i].id === nft.id) {
        arrModal[i].selected = !nft.selected;
      } else {
        arrModal[i].selected = false;
      }
    }
    setModaltNftList(arrModal);
  };
  const handleSelectAllModalNft = () => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      arrModal[i].selected = true;
    }
    setModaltNftList(arrModal);
  };
  const handleDeselectAllModalNft = () => {
    let arrModal = [...modalNftList];
    for (let i = 0; i < arrModal.length; i++) {
      arrModal[i].selected = false;
    }
    setModaltNftList(arrModal);
  };
  const handleContinueNftModal = () => {
    let arrModal = [];
    for (let i = 0; i < modalNftList.length; i++) {
      arrModal.push({ id: modalNftList[i].id, selected: modalNftList[i].selected, power: modalNftList[i].power });
    }
    setNftList(arrModal);
    setIsVisible(false);
  };
  const handleRemoveNft = (nft) => {
    let arrSelected = [];
    for (let i = 0; i < nftList.length; i++) {
      arrSelected.push({ id: nftList[i].id, selected: nftList[i].selected, power: nftList[i].power });
    }
    for (let i = 0; i < arrSelected.length; i++) {
      if (arrSelected[i].id === nft.id) {
        arrSelected[i].selected = false;
        break;
      }
    }
    setNftList(arrSelected);
  };
  const selectedNft = nftList.filter((x) => x.selected === true);
  const selectedModalNft = modalNftList.filter((x) => x.selected === true);
  const getAmountValue = () => {
    if (!amount) return expandDecimals(0, 18);
    else {
      if (!amount.includes(".")) return expandDecimals(amount, 18);
      else {
        let after = amount.split(".")[1];
        return expandDecimals(amount.replace(".", ""), 18 - after.length);
      }
    }
  };
  const { data: estimateFee } = useSWR(
    [`Bridge:estSendFee:${[active, toChain]}`, chainId, bridgeReaderAddress, "estimateSendFee"],
    {
      fetcher: contractFetcher(library, BridgedReader, [toChain, account, getAmountValue(), false, "0x"]),
    }
  );

  const readerAddress = getContract(chainId, "Reader");
  const gmyAddress = getContract(chainId, "GMY");
  const walletTokens = [gmyAddress];
  const { data: walletBalances } = useSWR(
    [
      `Bridge:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );
  const { data: lzGMYBalances } = useSWR(
    [`Bridge:lzGMYBalances:${active}`, chainId, bridgeReaderAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, Token, []),
    }
  );
  const { balanceData } = getBalanceAndSupplyData(walletBalances);
  let gmyBalance = balanceData?.gmy;
  if (chainId === OP) {
    gmyBalance = lzGMYBalances;
  }

  const switchChains = () => {
    switchNetwork(getChainId(toChain), active);
  };
  const onInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(".")) {
      let after = value.split(".")[1];
      if (after.length <= 18) setAmount(value);
    } else {
      setAmount(value);
    }
  };
  const { data: tokenAllowance } = useSWR(
    active &&
      gmyAddress && [
        active,
        chainId,
        chainId === OP ? bridgeReaderAddress : gmyAddress,
        "allowance",
        account,
        bridgeReaderAddress,
      ],
    {
      fetcher: contractFetcher(library, Token),
    }
  );
  const { AddressZero } = ethers.constants;

  let checkAmount = getAmountValue();
  const needApproval = gmyAddress !== AddressZero && tokenAllowance && checkAmount && checkAmount.gt(tokenAllowance);
  const ApproveTokens = () => {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: chainId === OP ? bridgeReaderAddress : gmyAddress,
      spender: bridgeReaderAddress,
      chainId,
    });
  };

  const { data: isApprovedNFT } = useSWR(
    account && [`StakeNFT:isApprovedForAll:${active && account}`, chainId, nftClubAddress, "isApprovedForAll"],
    {
      fetcher: contractFetcher(library, NFTClub, [account, bridgeNFTReaderAddress]),
    }
  );
  // let adapterParams = ethers.utils.solidityPack(["uint16", "uint256"], [1, 2000000]);

  let adapterParams = ethers.utils.solidityPack(["uint16", "uint", "uint", "address"], [2, 200000, 0, account || "0x"]);
  // let adapterParams = "0x"
  // console.log("????", adapterParams);
  const listIds = selectedNft.map((x) => Number(x.id));
  const { data: estimateFeeNFT } = useSWR(
    [`Bridge:estimateSendBatchFee:${[active, toChain]}`, chainId, bridgeNFTReaderAddress, "estimateSendFee"],
    {
      fetcher: contractFetcher(library, BridgedNFTReader, [
        toChain,
        account,
        listIds.length > 0 ? listIds[0] : 1,
        false,
        adapterParams,
      ]),
    }
  );
  // const estimateFeeNFT = { nativeFee: chainId === OP ? expandDecimals(29, 14) : expandDecimals(29, 14) };
  const BridgeToken = () => {
    if (currentTab === "GMY") {
      const contract = new ethers.Contract(bridgeReaderAddress, BridgedReader.abi, library.getSigner());
      // console.log("????", [account, toChain, account, getAmountValue(), AddressZero, account, "0x"]);
      setIsBridging(true);
      callContract(
        chainId,
        contract,
        "sendFrom",
        [account, toChain, account, getAmountValue(), account, AddressZero, "0x"],
        {
          value: estimateFee?.nativeFee,
          successMsg: `Bridge completed`,
          sentMsg: `Bridge submitted!`,
          failMsg: `Bridge failed.`,
          setPendingTxns,
          pendingTxns,
        }
      ).finally(() => {
        setIsBridging(false);
      });
    } else {
      const contract = new ethers.Contract(bridgeNFTReaderAddress, BridgedNFTReader.abi, library.getSigner());
      // console.log("?????", { fee: estimateFeeNFT?.nativeFee });
      setIsBridging(true);
      callContract(
        chainId,
        contract,
        "sendFrom",
        [account, toChain, account, listIds[0], account, AddressZero, adapterParams],
        {
          value: estimateFeeNFT?.nativeFee,
          successMsg: `Bridge completed`,
          sentMsg: `Bridge submitted!`,
          failMsg: `Bridge failed.`,
          setPendingTxns,
          pendingTxns,
        }
      ).finally(() => {
        setIsBridging(false);
      });
    }
  };
  const handleSelectDropDownFrom = (chainId) => {
    if (fromChain !== chainId) switchNetwork(getChainId(chainId), active);
  };
  const handleSelectDropDownTo = (chainId) => {
    setToChain(chainId);
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const getBridgeContract = (selectedChainId) => {
    if (currentTab === "GMY") return getContract(chains.find((x) => x.chainId === selectedChainId || 112).id, "Bridge");
    else if (chainId === BASE) return "";
    else return getContract(chains.find((x) => x.chainId === selectedChainId || 112).id, "BridgeNFT");
  };
  const isBridgeDisabled = () => {
    if (currentTab === "GMY") {
      if (!amount || !account) return true;
      const valueAmount = getAmountValue();
      if (valueAmount.gt(gmyBalance || 0)) return true;
    } else if (selectedNft.length <= 0) return true;
    return false;
  };
  const handleMaxBalance = () => {
    if (gmyBalance && gmyBalance.gt(0)) setAmount(formatAmount(gmyBalance || 0, 18, 18, false));
    else setAmount("0.0000");
  };
  const getRecieveToken = () => {
    let result = amount;
    if (!amount) return "--";
    if (amount.includes(".")) {
      const after = amount.split(".")[1];
      if (after.length <= 4) {
        const gap = 4 - after.length;
        for (let i = 0; i < gap; i++) {
          result += "0";
        }
        return numberWithCommas(result);
      } else {
        const result = after.slice(0, 4);
        return numberWithCommas(amount.split(".")[0] + "." + result);
      }
    }
    return numberWithCommas(amount + ".0000");
  };

  const showData =
    historyData.length > 0
      ? historyData.slice((currentPage - 1) * rowsPerPage, Math.min(currentPage * rowsPerPage, historyData.length))
      : [];
  const onApproveNFT = () => {
    setIsApprovingNFT(true);
    const contract = new ethers.Contract(nftClubAddress, NFTClub.abi, library.getSigner());
    callContract(chainId, contract, "setApprovalForAll", [bridgeNFTReaderAddress, true], {
      sentMsg: `Approve NFT submitted!`,
      failMsg: `Approve NFT failed.`,
      successMsg: `Approve NFT completed!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsApprovingNFT(false);
      });
  };
  const renderNFTTransaction = (item) => {
    const listIds = item?.amount.split(",");
    if (listIds.length === 1) {
      return `Yummy #${listIds[0]}`;
    } else {
      return (
        <div className="nft-tool-tip">
          <Tooltip
            handle={`${listIds.length} NFTs`}
            position="right-bottom"
            renderContent={() => {
              return (
                <div className="nft-tooltip-container">
                  {listIds.map((id) => (
                    <div key={id} className="nft-line">
                      Yummy #{id}
                    </div>
                  ))}
                  <div />
                </div>
              );
            }}
          />
        </div>
      );
    }
  };
  // console.log("????11111", showData);

  useEffect(() => {
    if (String(window.location.hash).includes("nft")) {
      handleChangeTab("NFT");
    }
  }, []);

  return (
    <Wrapper className={lightThemeClassName}>
      <div className={`bridge-wrapper ${lightThemeClassName}`}>
        <div className="page-title">Bridge</div>
        <div className="page-desc">Bridge $GMY and NFTs seamlessly and securely across blockchains.</div>
        <div className="power-by">
          Powered by <img alt="" src={isLightTheme ? LayerZeroIconLight : LayerZeroIcon} />
        </div>
        <div className="tabs-container">
          {TABS.map((item) => (
            <Link
              className={`tab-content ${currentTab === item.name ? "tab-content-active" : ""}`}
              key={item.name}
              onClick={() => handleChangeTab(item.name)}
              to={`/bridge/${item.name.toLowerCase()}`}
            >
              <img alt="" src={item.icon} />
              <span>{item.name}</span>
            </Link>
          ))}
        </div>
        <div className="form-bridge">
          <div className="chains">
            <div className="chain-select-dropdown">
              <div className="dropdown-name">From</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedFromChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedFromChain.name}</div>
                    </div>
                    <img alt="" src={isLightTheme ? IcDownLight : IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {chains.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownFrom(item.chainId)}>
                        <div className={`menu-item ${item.chainId === fromChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
            <div className={`swap-ball`} onClick={switchChains}>
              <IoMdSwap className="swap-ball-icon" />
            </div>
            <div className="chain-select-dropdown">
              <div className="dropdown-name">To</div>
              <Menu>
                <Menu.Button as="div">
                  <div className="selected-chain">
                    <div className="chain-info">
                      <img alt="" src={selectedToChain.img} className="token-chain" />{" "}
                      <div className="chain-name">{selectedToChain.name}</div>
                    </div>
                    <img alt="" src={isLightTheme ? IcDownLight : IcDown} className="ic_down" />
                  </div>
                </Menu.Button>
                <div>
                  <Menu.Items as="div" className="menu-items">
                    {toChainList.map((item) => (
                      <Menu.Item key={item.chainId} onClick={() => handleSelectDropDownTo(item.chainId)}>
                        <div className={`menu-item ${item.chainId === toChain ? "menu-item--active" : ""}`}>
                          <img alt="" src={item.img} /> <div className="chain-name">{item.name}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
          </div>
          <div className="transfer-amount">
            <div className="balance-info">
              <div>
                {currentTab === "GMY"
                  ? "I want to transfer"
                  : "Select your NFT to bridge (Currently, we only support bridging one NFT at a time)"}
              </div>
              {currentTab === "GMY" ? (
                <div className="balance" onClick={handleMaxBalance}>
                  Balance: <span>{formatAmount(gmyBalance, 18, 4, true)}</span>
                </div>
              ) : (
                <div>
                  {selectedNft.length > 0 && (
                    <div className="cusor-pointer edit-text green-text" onClick={() => setIsVisible(true)}>
                      Edit
                    </div>
                  )}
                </div>
              )}
            </div>
            {currentTab === "GMY" ? (
              <input placeholder="0.00" className="amount" type="number" value={amount} onChange={onInputChange} />
            ) : (
              <div className="nft-selected-container">
                {selectedNft.map((item) => (
                  <SelectedNftItem item={item} onRemove={handleRemoveNft} />
                ))}
                {selectedNft.length < 1 && (
                  <div className="add-nft-item" onClick={() => setIsVisible(true)}>
                    <img alt="" src={isLightTheme ? icPlusLight : icPlus} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="recieve-info">
            <div>
              You will receive:{" "}
              {currentTab === "GMY" ? (
                <span className="white-text">{getRecieveToken()} GMY</span>
              ) : (
                <span className="white-text">
                  {selectedNft.length} NFT{selectedNft.length > 1 ? "(s)" : ""}
                </span>
              )}
            </div>
            <div>
              <Tooltip
                handle="Bridge fee:"
                position="left-bottom"
                renderContent={() => <div>Transaction Fees charged by LayerZero</div>}
              />{" "}
              {currentTab === "GMY" ? (
                <span className="white-text">
                  {estimateFee ? formatAmount(estimateFee?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
                </span>
              ) : (
                <span className="white-text">
                  {estimateFeeNFT ? formatAmount(estimateFeeNFT?.nativeFee, 18, 4, true) : "--"} {nativeTokenSymbol}
                </span>
              )}
            </div>
            {account && (
              <div className="reciever">
                <Tooltip
                  handle="Receiver:"
                  position="left-bottom"
                  renderContent={() => <div>This is the destination address of the network</div>}
                />{" "}
                <span>
                  <img alt="" src={selectedToChain.img} className="reciver-chain-name" style={{ marginRight: "4px" }} />
                  <ExternalLink href={getExplorerUrl(getChainId(toChain)) + "address/" + account}>
                    <span className="address-text">
                      {account} <img alt="" src={isLightTheme ? icScanLight : icScan} />
                    </span>
                  </ExternalLink>
                </span>
              </div>
            )}
          </div>
          {currentTab === "GMY" ? (
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : chainId !== FANTOM && chainId !== ARBITRUM && chainId !== OP && chainId !== BASE ? (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => switchNetwork(getChainId(selectedFromChain.chainId), active)}
                >
                  Switch to {selectedFromChain.name}
                </button>
              ) : needApproval ? (
                <button className="App-button-option App-card-option" onClick={() => ApproveTokens()}>
                  {isApproving ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  disabled={isBridgeDisabled()}
                  onClick={() => BridgeToken()}
                >
                  {isBridging ? "Bridging..." : "Bridge"}
                </button>
              )}
            </div>
          ) : (
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : chainId !== ARBITRUM && chainId !== OP ? (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => switchNetwork(getChainId(selectedFromChain.chainId), active)}
                >
                  Switch to {selectedFromChain.name}
                </button>
              ) : !isApprovedNFT ? (
                <button className="App-button-option App-card-option" onClick={() => onApproveNFT()}>
                  {isApprovingNFT ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  disabled={isBridgeDisabled()}
                  onClick={() => BridgeToken()}
                >
                  {isBridging ? "Bridging..." : "Bridge"}
                </button>
              )}
            </div>
          )}
        </div>
        <div className="down-content">
          <div className="page-title">Transaction History</div>
          <div className="transaction-table">
            <div className="transaction-line transaction-header">
              <div className="table-column">From</div>
              <div className="table-column">To</div>
              <div className="table-column">{currentTab === "GMY" ? "Amount" : "NFT"}</div>
              <div className="table-column">Status</div>
              <div className="table-column column-view">View</div>
            </div>
            <div className="transaction-content">
              {(!historyData || historyData?.length === 0) && <div className="no-data">No transactions data</div>}
              {historyData &&
                showData.map((item) => (
                  <div className="transaction-line transaction-row" key={item.fromTx}>
                    <div className="table-column">
                      {/* <img alt="" src={item?.fromChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.fromChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.fromChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.fromChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.fromChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                      {item?.fromChainId === BASEBRIDGE && (
                        <>
                          <img alt="" src={icBase} />
                          <div>{"Base"}</div>
                        </>
                      )}
                    </div>
                    <div className="table-column">
                      {item?.toChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.toChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.toChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                      {item?.toChainId === BASEBRIDGE && (
                        <>
                          <img alt="" src={icBase} />
                          <div>{"Base"}</div>
                        </>
                      )}
                    </div>
                    {currentTab === "GMY" ? (
                      <div className="table-column">
                        {formatAmount(Number(item?.amount) ? item?.amount : BigNumber.from(0), 18, 4, true)} GMY
                      </div>
                    ) : (
                      <div className="table-column">{renderNFTTransaction(item)}</div>
                    )}
                    <div className={`table-column ${item?.status.toLowerCase()}`}>{item?.status.toLowerCase()}</div>
                    <div className="table-column column-view">
                      <ExternalLink
                        href={`https://layerzeroscan.com/${item.fromChainId}/address/${getBridgeContract(
                          item.fromChainId
                        ).toLowerCase()}/message/${item.toChainId}/address/${getBridgeContract(
                          item.toChainId
                        ).toLowerCase()}/nonce/${item?.nonce}`}
                      >
                        <img alt="" src={isLightTheme ? icScanWhiteLight : icScanWhite} className="icon-view" />
                      </ExternalLink>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="transaction-table-mobile">
            {!historyData || (historyData.length === 0 && <div className="no-data">No transactions data</div>)}
            {historyData &&
              showData.map((item) => (
                <div className="table-mobile-content" key={item.fromTx}>
                  <div className="content-line">
                    <div className="title">From</div>
                    <div className="value">
                      {/* <img alt="" src={item?.fromChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.fromChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.fromChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.fromChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.fromChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                      {item?.fromChainId === BASEBRIDGE && (
                        <>
                          <img alt="" src={icBase} />
                          <div>{"Base"}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">To</div>
                    <div className="value">
                      {/* <img alt="" src={item?.toChainId === FANTOMBRIDGE ? icFantom : icArb} />
                      <div>{item?.toChainId === FANTOMBRIDGE ? "Fantom" : "Arbitrum"}</div> */}
                      {item?.toChainId === FANTOMBRIDGE && (
                        <>
                          <img alt="" src={icFantom} />
                          <div>{"Fantom"}</div>
                        </>
                      )}
                      {item?.toChainId === OPBRIDGE && (
                        <>
                          <img alt="" src={icOp} />
                          <div>{"Optimism"}</div>
                        </>
                      )}
                      {item?.toChainId === ARBITRUMBRIDGE && (
                        <>
                          <img alt="" src={icArb} />
                          <div>{"Arbitrum"}</div>
                        </>
                      )}
                      {item?.toChainId === BASEBRIDGE && (
                        <>
                          <img alt="" src={icBase} />
                          <div>{"Base"}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="content-line">
                    <div className="title">{currentTab === "GMY" ? "Amount" : "NFT"}</div>
                    {currentTab === "GMY" ? (
                      <div className="value">
                        {formatAmount(Number(item?.amount) ? item?.amount : BigNumber.from(0), 18, 4, true)} GMY
                      </div>
                    ) : (
                      <div className="value">{renderNFTTransaction(item)}</div>
                    )}
                  </div>
                  <div className="content-line">
                    <div className="title">Status</div>
                    <div className={`value ${item?.status.toLowerCase()}`}>{item?.status.toLowerCase()}</div>
                  </div>
                  <div className="content-line">
                    <div className="title">View</div>
                    <div className="value">
                      <ExternalLink
                        href={`https://layerzeroscan.com/${item.fromChainId}/address/${getBridgeContract(
                          item.fromChainId
                        ).toLowerCase()}/message/${item.toChainId}/address/${getBridgeContract(
                          item.toChainId
                        ).toLowerCase()}/nonce/${item?.nonce}`}
                      >
                        <img alt="" src={isLightTheme ? icScanWhiteLight : icScanWhite} className="icon-view" />
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {historyData && historyData.length > 0 && (
            <div className="pagination">
              <Pagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                maxNumberOfPage={MAX_NUMBER_PAGE}
                row={row}
              />
            </div>
          )}
        </div>
        <ModalWrapper className="StakeModal">
          <Modal isVisible={isVisible} className="secondary" setIsVisible={setIsVisible} label="Select NFT">
            {modalNftList.length > 0 && (
              <div>
                <div className="nft-select-container">
                  <div className="selected-info">
                    <div className="available">
                      Available: <span>{modalNftList.length}</span>
                    </div>
                  </div>
                  {/* <div className="info">
                    <div className="selected">
                      Selected: <span>{selectedModalNft.length}</span>
                    </div>
                    <div className="select-btn" onClick={() => handleSelectAllModalNft()}>
                      Select All
                    </div>
                    <div className="select-btn" onClick={() => handleDeselectAllModalNft()}>
                      Deselect All
                    </div>
                  </div> */}
                </div>
                <div className="nft-list">
                  {modalNftList.map((item) => (
                    <div
                      className={`list-item ${item.selected ? "list-item-selected" : ""}`}
                      key={item.id}
                      onClick={() => handleSelectModalNft(item)}
                    >
                      <div className="checkbox">
                        {isLightTheme ? (
                          <img alt="" src={item?.selected ? icCheckboxCheckedLight : icCheckboxLight} />
                        ) : (
                          <img alt="" src={item?.selected ? icCheckboxChecked : icCheckbox} />
                        )}
                      </div>
                      <img alt="" src={`${getLinkCDN(chainId)}${item.id}.png`} className="main-nft-modal" />
                      <div className="nft-info">
                        <div className="title">Yummy #{item.id}</div>
                        <div className="info">
                          <img alt="" src={isLightTheme ? icBoltLight : icBolt} />
                          <span>{formatNumber(item.power)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {modalNftList.length === 0 && (
              <div className="empty-state">
                <img alt="" src={icEmpty} className="empty-img" />
                <div>You don't have any Yummy NFT.</div>
                <Link className="get-your-nft" to="/nft">
                  Get your Yummy NFT
                  <img alt="" src={isLightTheme ? icScanLight : icScan} className="scan-img" />
                </Link>
              </div>
            )}
            <div className="actions">
              {!active ? (
                <button className="App-button-option App-card-option" onClick={() => connectWallet()}>
                  Connect Wallet
                </button>
              ) : chainId !== OP && chainId !== ARBITRUM ? (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => switchNetwork(selectedFromChain.chainId === FANTOMBRIDGE ? FANTOM : ARBITRUM, active)}
                >
                  Switch to {selectedFromChain.name}
                </button>
              ) : !isApprovedNFT ? (
                <button className="App-button-option App-card-option" onClick={() => onApproveNFT()}>
                  {isApprovingNFT ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  className="App-button-option App-card-option"
                  onClick={() => handleContinueNftModal()}
                  disabled={selectedModalNft.length === 0 && selectedNft.length === 0}
                >
                  {selectedModalNft.length === 0 && selectedNft.length === 0 ? "Select NFT" : "Continue"}
                </button>
              )}
            </div>
          </Modal>
        </ModalWrapper>
        <Footer />
      </div>
    </Wrapper>
  );
};
export default Bridge;

const ModalWrapper = styled.div`
  .Modal-body {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 767px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 16px !important;
    }
  }
  .Modal-content {
    width: 592px;
  }
`;

const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;

  &.theme--light {
    .edit-text {
      color: #02b27f !important;

      &:hover {
        color: #03805b !important;
      }
    }
  }
`;
