import { t, Trans } from "@lingui/macro";
import { getContract } from "config/contracts";
import { handleCancelOrder } from "domain/legacy";
import {
  DECREASE,
  getExchangeRate,
  getExchangeRateDisplay,
  getOrderError,
  getPositionForOrder,
  INCREASE,
  SWAP,
  USD_DECIMALS,
} from "lib/legacy";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import Tooltip from "../Tooltip/Tooltip";
import OrderEditor from "./OrderEditor";

import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import Wrapper from "pages/Analytics/components/Account.styled";
import OrderItemTradeTab from "pages/Analytics/components/OrderItemTradeTab";
import { useMediaQuery } from "react-responsive";
import Checkbox from "../Checkbox/Checkbox";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import "./OrdersList.css";
export default function OrdersListV2(props) {
  const {
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    totalTokenWeights,
    usdgSupply,
    orders,
    hideActions,
    chainId,
    savedShouldDisableValidationForTesting,
    cancelOrderIdList,
    setCancelOrderIdList,
  } = props;

  const [editingOrder, setEditingOrder] = useState(null);

  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  const renderHead = useCallback(() => {
    const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length;
    return (
      <tr className="Exchange-list-header">
        {orders && orders.length > 0 && (
          <th>
            <div className="checkbox-inline ">
              <Checkbox
                isChecked={isAllOrdersSelected}
                setIsChecked={() => {
                  if (isAllOrdersSelected) {
                    setCancelOrderIdList([]);
                  } else {
                    const allOrderIds = orders.map((o) => `${o.type}-${o.index}`);
                    setCancelOrderIdList(allOrderIds);
                  }
                }}
              />
            </div>
          </th>
        )}

        <th>
          <div>
            <span>Type</span>
          </div>
        </th>
        <th>
          <div>
            <span>Order</span>
          </div>
        </th>
        <th>
          <div>
            <span>Price</span>
          </div>
        </th>
        <th>
          <div>
            <span>Mark Price</span>
          </div>
        </th>
      </tr>
    );
  }, [cancelOrderIdList, orders, setCancelOrderIdList]);

  const renderEmptyRow = useCallback(() => {
    if (orders && orders.length) {
      return null;
    }

    return (
      <tr>
        <td colSpan="5" className="no-data-card">
          <span>No open orders</span>
        </td>
      </tr>
    );
  }, [orders]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td>
            <button className="Exchange-list-action" onClick={() => onEditClick(order)}>
              <span>Edit</span>
            </button>
          </td>
          <td>
            <button className="Exchange-list-action" onClick={() => onCancelClick(order)}>
              <span>Cancel</span>
            </button>
          </td>
        </>
      );
    },
    [onEditClick, onCancelClick]
  );

  const renderLargeList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }
    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);
        const orderId = `${order.type}-${order.index}`;

        return (
          <tr className="Exchange-list-item" key={orderId}>
            <td>
              <div className="checkbox-inline ">
                <Checkbox
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId);
                      } else {
                        return prevState.concat(orderId);
                      }
                    });
                  }}
                />
              </div>
            </td>
            <td className="Exchange-list-item-type">
              <span>Limit</span>
            </td>
            <td>
              <span>Swap</span>{" "}
              {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {toTokenInfo.symbol}
            </td>
            <td>
              <Tooltip
                handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                renderContent={() => `
                  You will receive at least ${formatAmount(
                    order.minOut,
                    toTokenInfo.decimals,
                    toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                    true
                  )} ${
                  toTokenInfo.symbol
                } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                `}
              />
            </td>
            <td>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)}</td>
            {!hideActions && renderActions(order)}
          </tr>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);

      // Longs Increase: max price
      // Longs Decrease: min price
      // Short Increase: min price
      // Short Decrease: max price
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);

      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const error = getOrderError(account, order, positionsMap);
      const orderId = `${order.type}-${order.index}`;
      const orderText = (
        <>
          {order.type === INCREASE ? `Increase` : `Decrease`} {indexTokenSymbol} {order.isLong ? `Long` : `Short`}
          &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
          {error && <div className="Exchange-list-item-error">{error}</div>}
        </>
      );

      return (
        <tr className="Exchange-list-item" key={`${order.isLong}-${order.type}-${order.index}`}>
          <td className="Exchange-list-item-type">
            <div>
              <Checkbox
                isChecked={cancelOrderIdList?.includes(orderId)}
                setIsChecked={() => {
                  setCancelOrderIdList((prevState) => {
                    if (prevState.includes(orderId)) {
                      return prevState.filter((i) => i !== orderId);
                    } else {
                      return prevState.concat(orderId);
                    }
                  });
                }}
              />
            </div>
          </td>
          <td className="Exchange-list-item-type">{order.type === INCREASE ? `Limit` : `Trigger`}</td>
          <td>
            {order.type === DECREASE ? (
              orderText
            ) : (
              <Tooltip
                handle={orderText}
                position="right-bottom"
                renderContent={() => {
                  const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
                  const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);
                  return (
                    <StatsTooltipRow
                      label={`Collateral`}
                      value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                        order.purchaseTokenAmount,
                        collateralTokenInfo.decimals,
                        4,
                        true
                      )}
                      ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})`}
                    />
                  );
                }}
              />
            )}
          </td>
          <td>
            {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, indexToken.displayDecimals || 2, true)}
          </td>
          <td>
            <Tooltip
              handle={formatAmount(markPrice, USD_DECIMALS, indexToken.displayDecimals || 2, true)}
              position="right-bottom"
              renderContent={() => {
                return (
                  <span>
                    The price that orders can be executed at may differ slightly from the chart price, as market orders
                    update oracle prices, while limit/trigger orders do not.
                  </span>
                );
              }}
            />
          </td>
          {!hideActions && renderActions(order)}
        </tr>
      );
    });
  }, [
    orders,
    renderActions,
    infoTokens,
    positionsMap,
    hideActions,
    chainId,
    account,
    cancelOrderIdList,
    setCancelOrderIdList,
  ]);

  const renderSmallList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );
        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);

        return (
          <div key={`${order.type}-${order.index}`} className="App-card">
            <div className="App-card-title-small">
              Swap {formatAmount(order.amountIn, fromTokenInfo.decimals, fromTokenInfo.isStable ? 2 : 4, true)}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(order.minOut, toTokenInfo.decimals, toTokenInfo.isStable ? 2 : 4, true)}{" "}
              {toTokenInfo.symbol}
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <span>Price</span>
                </div>
                <div>
                  <Tooltip
                    position="right-bottom"
                    handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                    renderContent={() => `
                    You will receive at least ${formatAmount(
                      order.minOut,
                      toTokenInfo.decimals,
                      toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                      true
                    )} ${
                      toTokenInfo.symbol
                    } if this order is executed. The exact execution price may vary depending on fees at the time the order is executed.
                  `}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <span>Mark Price</span>
                </div>
                <div>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo)}</div>
              </div>
              {!hideActions && (
                <>
                  <div className="App-card-divider"></div>
                  <div className="App-card-options">
                    <button className="App-button-option App-card-option" onClick={() => onEditClick(order)}>
                      <span>Edit</span>
                    </button>
                    <button className="App-button-option App-card-option" onClick={() => onCancelClick(order)}>
                      <span>Cancel</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, true, infoTokens);

      const error = getOrderError(account, order, positionsMap);

      return (
        <div key={`${order.isLong}-${order.type}-${order.index}`} className="App-card">
          <div className="App-card-title-small">
            {order.type === INCREASE ? `Increase` : `Decrease`} {indexTokenSymbol} {order.isLong ? `Long` : `Short`}
            &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
            {error && <div className="Exchange-list-item-error">{error}</div>}
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <span>Price</span>
              </div>
              <div>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <span>Mark Price</span>
              </div>
              <div>
                <Tooltip
                  handle={formatAmount(markPrice, USD_DECIMALS, indexToken.displayDecimals || 2, true)}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <span>
                        The price that the order can be executed at may differ slightly from the chart price as market
                        orders can change the price while limit / trigger orders cannot.
                      </span>
                    );
                  }}
                />
              </div>
            </div>
            {order.type === INCREASE && (
              <div className="App-card-row">
                <div className="label">
                  <span>Collateral</span>
                </div>
                <div>
                  ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                  {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{" "}
                  {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                </div>
              </div>
            )}
            {!hideActions && (
              <>
                <div className="App-card-divider"></div>
                <div className="App-card-options">
                  <button className="App-button-option App-card-option" onClick={() => onEditClick(order)}>
                    <span>Edit</span>
                  </button>
                  <button className="App-button-option App-card-option" onClick={() => onCancelClick(order)}>
                    <span>Cancel</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  }, [orders, onEditClick, onCancelClick, infoTokens, positionsMap, hideActions, chainId, account]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  return (
    <React.Fragment>
      <WrapperContainer>
        <Wrapper className="main-wrapper">
          <TableOrder>
            <div className={isSmallScreen ? "" : "table"}>
              {orders && orders.length > 0 && (
                <div className="header" style={{ display: isSmallScreen ? "none" : "flex" }}>
                  <div className="market child" style={{ width: "15%", flex: "unset" }}>
                    Order Type
                  </div>
                  <div className="size child" style={{ width: "10%", flex: "unset" }}>
                    Type
                  </div>
                  <div className="order child" style={{ width: "25%", flex: "unset" }}>
                    Order
                  </div>
                  <div className="collateral child" style={{ width: "20%", flex: "unset" }}>
                    Trigger Condition
                  </div>
                  <div className="child mark" style={{ width: "15%", flex: "unset" }}>
                    Mark Price
                  </div>
                  <div className="child action" style={{ width: "10%", flex: "unset" }}></div>
                </div>
              )}

              {orders &&
                orders.length > 0 &&
                orders.map((order) => (
                  <OrderItemTradeTab
                    key={order.id}
                    // setVisibleModal={() => {
                    //   setIsVisible(true);
                    //   setModalData(order);
                    // }}
                    infoTokens={infoTokens}
                    data={order}
                    isSmallScreen={isSmallScreen}
                    onEditClick={onEditClick}
                    onCancelClick={onCancelClick}
                    chainId={chainId}
                    hideTag={true}
                  />
                ))}
            </div>
            <div className="Exchange-list Orders">
              {orders && orders.length === 0 && (
                <div className="Exchange-empty-positions-list-note App-card no-data-card">
                  <span>No open orders</span>
                </div>
              )}
              {!orders && <div className="skeleton-box" style={{ height: "25px" }} />}
            </div>
          </TableOrder>
        </Wrapper>
      </WrapperContainer>

      {/* <table className="Exchange-list Orders App-box large">
        <tbody>
          {renderHead()}
          {renderEmptyRow()}
          {renderLargeList()}
        </tbody>
      </table> */}

      {editingOrder && (
        <OrderEditor
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        />
      )}
    </React.Fragment>
  );
}
const WrapperContainer = styled.div`
  .main-wrapper {
    @media screen and (max-width: 1023px) {
      padding: 0 !important;
    }
  }
`;
const TableOrder = styled.div`
  width: 100%;
  .table {
    width: 100% !important;
    border-radius: 12px;
    /* background: #212333; */
    color: rgb(185, 185, 185);
    border-collapse: collapse;
    .header {
      padding: 0px 11px;
      /* background: rgb(33, 29, 77); */
      > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #999691;
      }
      .collateral {
        flex: 1;
      }
      .mark {
        flex: 1;
      }
    }
    .action {
      display: flex;
      width: 200px;
      width: fit-content;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      .action-child {
        cursor: pointer;
        color: #7ee55c;

        &:hover {
          color: #b7e399;

          path {
            fill: #b7e399;
          }
        }
      }
    }
  }
`;
