import { useChainId } from "lib/chains";
import { FC } from "react";
import styled from "styled-components";
import { TXT } from "../multichainTxt";
import { useThemeContext } from "contexts/ThemeProvider";

const StartBlock: FC = () => {
  // <img alt="mint" src={icLink.src} />;
  const { lightThemeClassName } = useThemeContext() as any;
  const { chainId } = useChainId();
  return (
    <StyledHowTo className={lightThemeClassName}>
      <div className="container">
        <Wrap>
          <img alt="mint" className="mum1" src={TXT[chainId]?.logo1} />
          <img alt="mint" className="mum2" src={TXT[chainId]?.logo2} />
          <SectionTitle>Start in seconds</SectionTitle>
          <Desc>
            {" "}
            <div>Connect your crypto wallet to start using the app in seconds.</div>No registration needed.
          </Desc>
          <a href="https://docs.yummy.fi/trading#adding-a-wallet" className="btn-primary" target="_bank">
            Learn how to start
          </a>
        </Wrap>
      </div>
    </StyledHowTo>
  );
};

export default StartBlock;

const Wrap = styled.section`
  position: relative;
  top: -32px;
  padding: 41px 0;
  background: #20233b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;

  .mum1 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .mum2 {
    position: absolute;
    right: 24px;
    bottom: 0;
  }
  .btn-primary {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: 40px;
    padding: 8px 16px;
  }
  @media screen and (max-width: 991px) {
    padding: 24px 0;
    .mum1 {
      width: 144px;
    }
    .mum2 {
      width: 141px;
      right: 6px;
    }
  }
  @media screen and (max-width: 700px) {
    .mum1 {
      width: 96px;
    }
    .mum2 {
      width: 96px;
      right: 0px;
    }
  }
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 32px;
    font-size: 24px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 8px 0 24px 0;
  text-align: center;
  width: 430px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

const StyledHowTo = styled.section`
  color: #ffffff;
  background: #191b2e;
  position: relative;
  .container {
    width: 1368px !important;
    max-width: 1368px !important;
    @media screen and (max-width: 1368px) {
      max-width: 100% !important;
    }
    a {
      text-decoration: none;
    }
  }
  /* top: -32px; */

  &.theme--light {
    background: #fff;

    ${Wrap} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-2, #f2f5f7);
    }
  }
`;
