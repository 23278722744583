import React, { useState, useEffect, useMemo, useCallback } from "react";
import useSWR from "swr";
import { BigNumber, ethers } from "ethers";
import { getContract } from "config/contracts";
import styled from "styled-components";
import arrowIcon from "img/ic_arrow.svg";
import arrowIconLight from "img/ic_arrowLight.svg";
import patternImg from "img/dashboard/pattern.svg";
import mintRedeemIcon from "img/earn/mint_redeem.svg";
import ReaderV2 from "abis/ReaderV2.json";
import icLink from "img/icons/redirect.svg";
import StakeHelper from "abis/StakeHelper.json";
import icLinkLight from "img/icons/redirectLight.svg";
import icBlueInfo from "img/blue-info.svg";
import ArrowRoute from "img/arrow-route.svg";
import VaultGmy from "abis/VaultGmy.json";
import PriceManager from "abis/PriceManager.json";
import Token from "abis/Token.json";

import { omit } from "lodash";
import RouterAggregatpr from "abis/RouterAggregatorMicroSwap.json";
import "./MintRedeem.scss";
import { callContract, contractFetcher } from "lib/contracts";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatAmountInDollar,
  formatNumber,
  parseValue,
} from "lib/numbers";
import Tab from "components/Tab/Tab";
import {
  DEFAULT_CHAIN_ID,
  FANTOM,
  MICROSWAP_SUPPORTED_CHAIN_IDS,
  OP,
  SUPPORTED_CHAIN_ID_GM,
  SUPPORTED_V2_CHAINS,
} from "config/chains";
import { useWeb3React } from "@web3-react/core";
import useTokenContract from "hooks/contracts/useTokenContract";
import useSettingsManagerContract from "hooks/contracts/useSettingsManagerContract";
import useUsdPrice from "hooks/useUsdPrice";
import useGmContract from "hooks/contracts/useGmContract";
import useValidateAction from "hooks/useValidateAction";
import TokenInput from "components/TokenInput";
import usdcIcon from "img/ic_usdc_40.svg";
import Footer from "components/Footer/Footer";
import { ACTIVE_CHAIN_IDS_V2, BASIS_POINTS_DIVISOR, PLACEHOLDER_ACCOUNT, importImage } from "lib/legacy";
import useTokensPrice from "hooks/useTokensPrice";
import { Link, useHistory, useLocation } from "react-router-dom";
import useGMYFarmContractMulticall from "hooks/contracts/useGMYFarmContractMulticall";
import { switchNetwork } from "lib/wallets";
import { useChainIdWithDefault } from "lib/chains";
import useMspPrice from "hooks/useMspPrice";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useThemeContext } from "contexts/ThemeProvider";
import useGmApr, { SYMBOL_BY_ADDRESS_REWARDS } from "hooks/useGmApr";
import { useGmyPrice } from "domain/legacy";
import { useLocalStorageByChainId } from "lib/localStorage";
import { DEFAULT_FROM_V2_TOKEN, getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import TokenSelector from "components/Exchange/TokenSelector";
import useGMAggregator from "hooks/useGMAggregator";
import useMintGm from "hooks/useMintGm";
import useRedeemGm from "hooks/useRedeemGm";
import useVaultGmyContract from "hooks/contracts/useVaultGmyContract";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
import useInfoGm from "hooks/useInfoGm";

export const MINT_MSP = "Mint GM";
export const REDEEM_MSP = "Redeem GM";
const { AddressZero } = ethers.constants;

const apiKeyFireBird = "yummyfinance";

export default function MintRedeem({ setPendingTxns, connectWallet, savedSlippageAmount, savedSelectedDexes }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_GM,
    isUseDefaultChain: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const vaultGmyAddress = getContract(chainId, "VaultMsp");
  const usdcAddress = getContract(chainId, "USDC");
  const StakeHelperAddress = getContract(chainId, "StakeHelper");
  const gmAddress = getContract(chainId, "GM");
  const [destAddress, setDestAddress] = useLocalStorageByChainId(
    chainId,
    `redeem-default-dest-token-address`,
    DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || usdcAddress
  );
  const [destMintAddress, setDestMinAddress] = useLocalStorageByChainId(
    chainId,
    `mint-default-dest-token-address`,
    DEFAULT_FROM_V2_TOKEN[chainId]?.[0] || usdcAddress
  );
  const { data: balanceOfDestToken } = useSWR(
    [`StakeV2:balanceOfDestToken:${vaultGmyAddress}:${destAddress}`, chainId || FANTOM, destAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, Token, [vaultGmyAddress]),
    }
  );
  const [aggregatorParams, setAggregatorParams] = useState({
    from: AddressZero,
    amount: 0,
    dest: destMintAddress,
    slippage: parseFloat(savedSlippageAmount / BASIS_POINTS_DIVISOR) || "0.005",
  });
  const ROUTER_API = `https://api.microswap.org/aggregator/v2/encode`;
  const BlpAddress = getContract(chainId, "GM");

  const { pathname } = useLocation();

  const priceManagerAddress = getContract(chainId, "PriceManager");

  //Use Contracts
  const {
    // totalSupply: totalSupplyGm,
    balanceOf: balanceOfGm,
    decimals: gmDecimal,
  } = useGmContract(chainId, active, account);

  const { totalSupply: totalSupplyGm } = useInfoGm(chainId);
  const {
    stakedGlp,
    glpPoolInfo: [totalStaked],
    poolRewardsPerSec,
    poolRewarders: [poolRewardersAddresses],
    // cooldownDuration: [cooldownDuration],
  } = useGMYFarmContractMulticall(chainId, active, account, library);

  const { gmyPrice } = useGmyPrice(chainId, {}, active);
  const rewardsPrice = useTokensPrice({
    gmyPrice,
    chainId,
  });

  const [amountStaked, startTimestamp] = stakedGlp;

  const { price: gmPrice } = useInfoGm(chainId);

  const amountStakedUsd = useUsdPrice(amountStaked, gmPrice, 30);
  const balanceOfGmUsd = useUsdPrice(balanceOfGm, gmPrice, 30);
  const totalStakedUsd = useUsdPrice(totalStaked, gmPrice, 30);
  const totalSupplyGmUsd = useUsdPrice(totalSupplyGm, gmPrice, 30);
  const dataAggregator = useGMAggregator(chainId, aggregatorParams, savedSelectedDexes);

  const readerAddress = getContract(chainId, "Reader");
  const tokens = getTokens(chainId);
  const destTokens = SUPPORTED_V2_CHAINS.includes(chainId)
    ? tokens.filter((x) => DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(x.address))
    : [usdcAddress];
  const tokenAddresses = tokens.map((token) => token.address);

  const { data: tokenBalances } = useSWR(
    [`GlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
      refreshInterval: 5000,
    }
  );
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const poolTokenBalances = usePoolTokenBalance(chainId, vaultGmyAddress);
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `mint-gm-token-address`,
    usdcAddress
  );
  const swapToken = getToken(chainId, swapTokenAddress);
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress);
  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0);
  const apr = useGmApr({
    gmPrice: gmPrice,
    rewardsPrice,
    totalStaked,
    poolRewardsPerSec,
  });
  const [activeTab, setActiveTab] = useState(pathname && pathname.includes("mint") ? MINT_MSP : REDEEM_MSP);

  const TAB_OPTIONS = [MINT_MSP, REDEEM_MSP];
  const TAB_OPTION_LABELS = {
    [MINT_MSP]: `Mint GM`,
    [REDEEM_MSP]: `Redeem GM`,
  };

  // INPUT:
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const onFromValueChange = (e) => {
    const value = e.target.value;
    const maxDecimals = gmDecimal;
    if (value && value.includes(".")) {
      const decimalsAfter = value.split(".")[1];
      if (decimalsAfter.length <= maxDecimals) setFromValue(value);
    } else {
      setFromValue(value);
    }
  };

  const onToValueChange = (e) => {
    setToValue(e.target.value);
  };
  // const poolTokens = useMemo(() => {
  //   return DEFAULT_FROM_V2_TOKEN[chainId || FANTOM].map((token) => {
  //     return getToken(chainId, token);
  //   });
  // }, [chainId]);

  // DATAS:
  const usdcToken = useTokenContract(chainId, active, account, destAddress, vaultGmyAddress);
  const usdcMintToken = useTokenContract(chainId, active, account, destMintAddress, vaultGmyAddress);
  const { stakingFee, unstakingFee, maxTotalGm, isStakingEnabled, isUnstakingEnabled } = useSettingsManagerContract(
    chainId,
    active,
    account
  );

  const { data: usdcTokenToUsd } = useSWR([`PriceManager:tokenToUsd`, chainId, priceManagerAddress, "tokenToUsd"], {
    fetcher: contractFetcher(undefined, PriceManager, [destAddress, expandDecimals(1, usdcToken.decimals || 18)]),
  });
  const depositTokens =
    chainId && SUPPORTED_V2_CHAINS.includes(chainId)
      ? DEFAULT_FROM_V2_TOKEN[chainId].map((item) => {
          return getToken(chainId, item);
        })
      : [];
  const whitelistedTokens = MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId)
    ? getWhitelistedTokens(chainId)
    : depositTokens;
  const { data: totalSupplyBlp } = useSWR([`Blp:getTotalSupply`, chainId, BlpAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, Token),
    refreshInterval: 10000,
  });
  const { totalUSD } = useVaultGmyContract(chainId);
  const { outputMint, stakingFee: stakingFeeMint } = useMintGm({
    chainId,
    fromValue:
      SUPPORTED_V2_CHAINS.includes(chainId) && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)
        ? fromValue
        : dataAggregator?.data?.quoteData
        ? formatAmount(dataAggregator?.data?.quoteData?.maxReturn?.totalTo, 6, 6, false)
        : "",
    gmPrice: gmPrice,
    stakingFee,
    isMint: activeTab === MINT_MSP,
    totalUSD,
    totalSupplyBlp,
    fromAddress: usdcToken?.address,
  });

  const { outputMint: minOutputMin } = useMintGm({
    chainId,
    fromValue:
      SUPPORTED_V2_CHAINS.includes(chainId) && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)
        ? fromValue
        : dataAggregator?.data?.quoteData
        ? formatAmount(
            BigNumber.from(dataAggregator?.data?.quoteData?.maxReturn?.totalTo)
              .mul(BASIS_POINTS_DIVISOR - savedSlippageAmount)
              .div(BASIS_POINTS_DIVISOR),
            6,
            6,
            false
          )
        : "",
    gmPrice: gmPrice,
    stakingFee,
    isMint: activeTab === MINT_MSP,
    totalUSD,
    totalSupplyBlp,
    fromAddress: usdcToken?.address,
  });
  const { outputRedeem, unstakingFee: unstakingFeeRedeem } = useRedeemGm({
    chainId,
    fromValue,
    gmPrice: gmPrice,
    unstakingFee,
    isRedeem: activeTab === REDEEM_MSP,
    totalUSD,
    totalSupplyBlp,
    destToken: usdcToken?.symbol,
    destAddress: usdcToken.address,
    decimals: usdcToken.decimals,
  });

  //Swap fee = (usdAmount * settingsManager.stakingFee(_token)) / BASIS_POINTS_DIVISOR;
  const usdcSwapFeeInAmount = useMemo(() => {
    const BASIS_POINTS_DIVISOR = 100000;
    const fromTokenDecimal = activeTab === MINT_MSP ? usdcToken.decimals : gmDecimal;
    if (SUPPORTED_V2_CHAINS.includes(chainId) && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)) {
      if (fromValue && fromValue !== "0" && stakingFee) {
        const fromAmount = parseValue(fromValue, fromTokenDecimal);
        return fromAmount.mul(stakingFee).div(BASIS_POINTS_DIVISOR);
      }
    } else {
      if (fromValue && fromValue !== "0" && stakingFee && dataAggregator?.data?.quoteData) {
        const totalUSDC = formatAmount(dataAggregator?.data?.quoteData?.maxReturn?.totalTo, 6, 6, false);

        const fromAmount = parseValue(totalUSDC, fromTokenDecimal);
        return fromAmount.mul(stakingFee).div(BASIS_POINTS_DIVISOR);
      }
    }
  }, [activeTab, fromValue, stakingFee, usdcToken.decimals, gmDecimal, dataAggregator]);

  const usdcSwapFeeUnstakeInAmount = useMemo(() => {
    const BASIS_POINTS_DIVISOR = 100000;
    const fromTokenDecimal = gmDecimal;
    if (fromValue && fromValue !== "0" && unstakingFee && gmPrice) {
      const fromAmount = parseValue(fromValue, fromTokenDecimal);
      return fromAmount.mul(unstakingFee).div(BASIS_POINTS_DIVISOR);
    }
  }, [fromValue, gmDecimal, gmPrice, unstakingFee]);
  useEffect(() => {
    if (activeTab === MINT_MSP) {
      if (
        MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
        !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)
      ) {
        setAggregatorParams({
          ...aggregatorParams,
          dest: destMintAddress,
          from: swapTokenAddress,
          amount: fromValue ? parseValue(fromValue, swapToken.decimals) : 0,
        });
      } else {
        if (aggregatorParams.amount !== 0) {
          setAggregatorParams({
            ...aggregatorParams,
            dest: destMintAddress,
            from: swapTokenAddress,
            amount: 0,
          });
        }
      }
    }
  }, [fromValue, destMintAddress]);

  useEffect(() => {
    setFromValue("");
    setToValue("");
  }, [swapTokenAddress]);

  useEffect(() => {
    //THIS CALCULATE OUTPUT AMOUNT
    if (activeTab === MINT_MSP && outputMint !== null) {
      setToValue(formatAmount(outputMint, 30, 18));
    }
  }, [activeTab, outputMint, dataAggregator]);

  useEffect(() => {
    const destToken = destTokens?.find((token) => token?.address?.toLowerCase() === destAddress?.toLowerCase());
    if (activeTab === REDEEM_MSP && outputRedeem !== null) {
      setToValue(formatAmount(outputRedeem, 30, destToken.decimals || 6));
    }
  }, [activeTab, outputRedeem, usdcToken]);

  useEffect(() => {
    //THIS RESET FORM WHILE CHANGE TAB
    (() => {
      setFromValue("");
      setToValue("");
      setAggregatorParams({
        ...aggregatorParams,
        from: AddressZero,
        dest: destMintAddress,
        amount: 0,
      });
    })();
  }, [activeTab]);

  const swapFees = useMemo(() => {
    if (swapTokenInfo && swapTokenInfo?.minPrice && fromValue) {
      const fromValueParsed = parseValue(fromValue, 30)
        .mul(swapToken.isStable ? 5 : 50)
        .div(100000);
      return fromValueParsed.mul(swapTokenInfo?.minPrice).div(expandDecimals(1, 30));
    }
  }, [fromValue, swapTokenInfo]);
  const gmAvailableToMint = useMemo(() => {
    if (maxTotalGm && totalSupplyGm) {
      return maxTotalGm.sub(totalSupplyGm);
    }
  }, [maxTotalGm, totalSupplyGm]);

  //If balance > maxUsdcCanUse ==> use maxUsdcCanUse
  const handledMaxUsdcCanUse = useMemo(() => {
    if (gmAvailableToMint && gmPrice && usdcTokenToUsd) {
      //maxUsdcCanUse: (maxTotalGm - totalSupplyGm) * gmPrice / usdcPrice
      const parsedUsdcTokenToUsd = usdcTokenToUsd.div(parseValue(1, 12));
      const parsedGmPrice = gmPrice.div(parseValue(1, 12));

      const maxUsdcCanUse = gmAvailableToMint.mul(parsedGmPrice).div(parsedUsdcTokenToUsd).div(parseValue(1, 12));

      return maxUsdcCanUse && usdcToken.balance.lt(maxUsdcCanUse) ? usdcToken.balance : maxUsdcCanUse;
    }
  }, [gmAvailableToMint, gmPrice, usdcToken.balance, usdcTokenToUsd]);

  //actions: mint & redeem

  const { approve, getButtonText, isButtonEnabled, isNeedApproval } = useValidateAction({
    tokenAddress: swapToken?.address,
    spender:
      SUPPORTED_V2_CHAINS.includes(chainId) && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)
        ? vaultGmyAddress
        : StakeHelperAddress,
    fromValue,
    maxAmount:
      SUPPORTED_V2_CHAINS.includes(chainId) && DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)
        ? // ? pools.find((pool) => pool.address === swapToken.address).balance
          swapTokenBalance
        : swapTokenBalance,
    isSubmitting,
    actionText: ["Mint", "Minting..."],
    //uncomment heres
    disabled: [
      // [!gmPrice || gmPrice.eq(0) || !usdcTokenToUsd, "Fetching price..."],
      [!gmAvailableToMint || !!parseValue(toValue, gmDecimal)?.gt(gmAvailableToMint), "Limit minted amount exceeded"],
      // [!isStakingEnabled, "Token not enabled"],
    ],
    fromValueDecimal: swapToken?.decimals,
  });

  const {
    approve: approveRedeem,
    getButtonText: getButtonTextRedeem,
    isButtonEnabled: isButtonEnabledRedeem,
    isNeedApproval: isNeedApprovalRedeem,
  } = useValidateAction({
    tokenAddress: gmAddress,
    spender: vaultGmyAddress,
    fromValue,
    maxAmount: balanceOfGm,
    isSubmitting,
    actionText: ["Redeem", "Redeeming..."],
    fromValueDecimal: gmDecimal,
    disabled: [
      [!gmPrice || gmPrice.eq(0) || !usdcTokenToUsd, "Fetching price..."],
      [!isUnstakingEnabled, "Token not enabled"],
      [
        balanceOfDestToken &&
          toValue &&
          toValue !== "--" &&
          parseValue(toValue || 0, usdcToken.decimals)?.gt(balanceOfDestToken || 0),
        "Vault balance exceeded",
      ],
    ],
  });
  async function sendTransaction(contract, method, params, value) {
    let customMethod = "swap";
    let funcData;
    try {
      funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    } catch (e) {
      // customMethod = "swapSimpleMode";
      // funcData = await contract.interface.decodeFunctionData(customMethod, params.data);
    }
    callContract(chainId, contract, "swapAndStake", funcData, {
      value,
      sentMsg: `Mint submitted!`,
      failMsg: `Mint failed.`,
      successMsg: `Minted successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setFromValue("");
      });
  }
  const onSelectDestToken = (token) => {
    setDestAddress(token.address);
    // setIsWaitingForApproval(false);
  };
  const onSelectMintDestToken = (token) => {
    setDestMinAddress(token.address);
    // setIsWaitingForApproval(false);
  };

  const mint = useCallback(async () => {
    if (isNeedApproval) {
      approve();
      return;
    }
    setIsSubmitting(true);
    if (DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address)) {
      const contract = new ethers.Contract(vaultGmyAddress, VaultGmy.abi, library.getSigner());
      callContract(
        chainId,
        contract,
        "stake",
        [account, swapToken.address, parseValue(fromValue, swapToken.decimals)],
        {
          sentMsg: `Mint submitted!`,
          failMsg: `Mint failed.`,
          successMsg: `Minted successfully!`,
          setPendingTxns,
        }
      )
        .then(async (res) => {})
        .finally(() => {
          setIsSubmitting(false);
          setFromValue("");
        });
    } else {
      // console.log("??????", dataAggregator);
      const { encodedData, maxReturn } = await fetch(`${ROUTER_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": `${apiKeyFireBird}`,
          // "X-Client-Id": `${apiKeyFireBird}`,
        },
        body: JSON.stringify(dataAggregator?.data),
      }).then((r) => r.json());

      if (encodedData && maxReturn) {
        const amountIn = dataAggregator?.data.amount;
        const contract = new ethers.Contract(StakeHelperAddress, RouterAggregatpr.abi, library.getSigner());
        const tokenIn = dataAggregator?.data.from;
        const currencyAIsEth = tokenIn.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
        // routerContract = encodedData.router;
        const tx = {
          // caller: "0x24b5C2325c93eE0edf3a3E1a25632e4c0ED1d5fd",
          from: account,
          to: StakeHelperAddress,
          // maxFeePerGas: 1000000000000,
          // maxPriorityFeePerGas: 1000000000000  ,
          data: encodedData.data, // encoded contract data
          value: currencyAIsEth ? bigNumberify(amountIn.toString()) : undefined,
        };
        let value = bigNumberify(0);

        if (swapToken?.isNative) {
          value = bigNumberify(amountIn.toString());
        }
        // console.log("????", value);
        sendTransaction(contract, "swapAndStake", tx, value);
      } else {
        setIsSubmitting(true);
      }
    }
  }, [
    isNeedApproval,
    vaultGmyAddress,
    library,
    chainId,
    account,
    fromValue,
    usdcToken.decimals,
    setPendingTxns,
    approve,
  ]);

  const redeem = useCallback(async () => {
    if (isNeedApprovalRedeem) {
      approveRedeem();
      return;
    }
    setIsSubmitting(true);

    const contract = new ethers.Contract(vaultGmyAddress, VaultGmy.abi, library.getSigner());
    callContract(chainId, contract, "unstake", [destAddress, parseValue(fromValue, gmDecimal)], {
      sentMsg: `Redeem submitted!`,
      failMsg: `Redeem failed.`,
      successMsg: `Redeemed successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setFromValue("");
      });
  }, [
    isNeedApprovalRedeem,
    vaultGmyAddress,
    library,
    chainId,
    destAddress,
    fromValue,
    gmDecimal,
    setPendingTxns,
    approveRedeem,
  ]);

  const history = useHistory();
  useEffect(() => {
    history.push(`/buy/${activeTab === MINT_MSP ? "mint" : "redeem"}`);
  }, [activeTab, history]);
  const onSwapValueChange = (e) => {
    const value = e.target.value;
    const maxDecimals = swapToken.decimals;
    if (value && value.includes(".")) {
      const decimalsAfter = value.split(".")[1];
      if (decimalsAfter.length <= maxDecimals) setFromValue(value);
    } else {
      setFromValue(value);
    }
    // setAnchorOnSwapAmount(true);
  };
  const fillMaxAmount = () => {
    // setAnchorOnSwapAmount(true);
    setFromValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals));
    return;
  };
  const onSelectSwapToken = (token) => {
    setSwapTokenAddress(token.address);
    // setIsWaitingForApproval(false);
  };
  const mapPoolComposition = useMemo(() => {
    const origin = [...poolTokenBalances];
    if (poolTokenBalances && poolTokenBalances.length > 0) {
      let total = BigNumber.from(0);
      for (let i = 0; i < poolTokenBalances.length; i++) {
        total = total.add(
          poolTokenBalances[i].balance.div(
            poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
          )
        );
      }
      for (let i = 0; i < origin.length; i++) {
        const curTokenInfo = getTokenInfo(infoTokens, origin[i].address);
        const amountBalance = poolTokenBalances[i].balance.div(
          poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
        );
        origin[i] = {
          ...poolTokenBalances[i],
          amountPool: amountBalance,
          amountPoolUSD:
            curTokenInfo?.minPrice && curTokenInfo?.maxPrice && amountBalance
              ? amountBalance.mul(curTokenInfo?.minPrice.add(curTokenInfo?.maxPrice).div(2) || parseValue(1, 30))
              : amountBalance,
          percentPool: total.gt(0) ? amountBalance.mul(100).mul(parseValue(1, 6)).div(total) : 0,
        };
      }
    }
    return origin;
  }, [poolTokenBalances]);
  const handleClickMaxRedeem = () => {
    // console.log("????", {totalSupplyBlp, balanceOfDestToken, unstakingFee, totalUSD});
    if (balanceOfDestToken && balanceOfGm) {
      const maxFromAmount = totalSupplyBlp
        .mul(parseValue(1, 29))
        .mul(balanceOfDestToken?.div(usdcToken.decimals !== 6 ? parseValue(1, usdcToken.decimals - 6) : 1))
        .div(totalUSD.mul(parseValue(1, 5).sub(unstakingFee)));
      const parseFromValue = formatAmount(maxFromAmount, 18, 18, false);
      if (balanceOfGm.gt(maxFromAmount)) {
        setFromValue(parseFromValue);
      } else {
        setFromValue(formatAmount(balanceOfGm, 18, 18, false));
      }
    }
  };
  return (
    <Wrapper className={lightThemeClassName}>
      <div className="MintRedeemPage default-container page-layout">
        <div className="heading">
          <div className="heading-title">
            <img src={mintRedeemIcon} alt="tokenIcon" /> <span>Mint/Redeem GM</span>
          </div>
          <div className="heading-desc">
            <div>Purchase GM tokens and stake to earn protocol fees and bonus vGMY.</div>
          </div>
        </div>
        <div className="MintRedeemPage-content">
          <TokenStatsContainer>
            <StatCard>
              <StatRow>
                <div className="label">
                  <span>GM Price</span>
                </div>
                <div className="value green">
                  {gmPrice ? (
                    `$${formatAmount(gmPrice, 30, 3)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
              <StatRow>
                <div className="label">
                  <span>APR</span>
                </div>

                <div className="value green">
                  {/* {apr !== undefined && !isNaN(apr) ? (
                    <>{apr === 0 ? "0.000" : `${formatNumber(apr, 2)}`}%</>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )} */}

                  {apr && apr.total !== undefined && !isNaN(apr.total) ? (
                    <>
                      {/* {apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}% */}
                      {Object.keys(omit(apr, "total")).length === 1 ? (
                        <>{apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}%`}</>
                      ) : (
                        <Tooltip
                          className="tooltip-apr"
                          position="right-bottom"
                          handle={
                            <div style={{ color: isLightTheme ? "#02b27f" : "#C1FF99" }}>
                              {apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}%
                            </div>
                          }
                          renderContent={() =>
                            Object.entries(apr).map(
                              ([key, value]) =>
                                key !== "total" && (
                                  <StatsTooltipRow
                                    key={key}
                                    label={SYMBOL_BY_ADDRESS_REWARDS[chainId][key.toLowerCase()] + " APR"}
                                    showDollar={false}
                                    value={`${value === 0 ? "0.000" : `${formatNumber(value, 2)}`}%`}
                                  />
                                )
                            )
                          }
                        />
                      )}
                    </>
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
            </StatCard>
            <StatCard>
              <StatRow>
                <div className="label">
                  <span>Total supply</span>
                </div>
                <div className="value">
                  {totalSupplyGm ? (
                    `${formatAmount(totalSupplyGm, gmDecimal, 0, true)} GM`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
                <div className="desc">
                  {totalSupplyGmUsd ? (
                    `$${formatAmount(totalSupplyGmUsd, gmDecimal, 2, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
              <StatRow>
                <div className="label">
                  <span>Total staked</span>
                  <Link to="/earn-v2/gm" className="stake-now">
                    Stake now <img src={isLightTheme ? icLinkLight : icLink} alt="icLink" />
                  </Link>
                </div>
                <div className="value">
                  {totalStaked ? (
                    `${formatAmount(totalStaked, gmDecimal, 0, true)} GM`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
                <div className="desc">
                  {totalStakedUsd ? (
                    `$${formatAmount(totalStakedUsd, gmDecimal, 2, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
            </StatCard>
            <StatCard>
              <StatRow>
                <div className="label">
                  <span>Your wallet</span>
                </div>
                <div className="value">
                  {balanceOfGm ? (
                    `${formatAmount(balanceOfGm, gmDecimal, 2, true)} GM`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
                <div className="desc">
                  {balanceOfGmUsd ? (
                    `$${formatAmount(balanceOfGmUsd, gmDecimal, 2, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
              <StatRow>
                <div className="label">
                  <span>Your staked</span>
                </div>
                <div className="value">
                  {amountStaked ? (
                    `${formatAmount(amountStaked, gmDecimal, 2, true)} GM`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
                <div className="desc">
                  {amountStakedUsd ? (
                    `$${formatAmount(amountStakedUsd, gmDecimal, 2, true)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: "60px" }} />
                  )}
                </div>
              </StatRow>
            </StatCard>
          </TokenStatsContainer>
          <div className="MintRedeemPage-box App-box">
            <Tab
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTION_LABELS}
              option={activeTab}
              setOption={setActiveTab}
              onChange={setActiveTab}
              className="Exchange-swap-option-tabs"
            />
            {activeTab === MINT_MSP && (
              <>
                <BuyInputSection
                  topLeftLabel={`From`}
                  topRightLabel={`Balance:`}
                  tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
                  inputValue={fromValue}
                  onInputValueChange={onSwapValueChange}
                  showMaxButton={
                    fromValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)
                  }
                  onClickTopRightLabel={fillMaxAmount}
                  onClickMax={fillMaxAmount}
                  selectedToken={swapToken}
                  // balance={payBalance}
                  onlyNumber
                >
                  <TokenSelector
                    label={`Pay`}
                    chainId={chainId}
                    tokenAddress={swapTokenAddress}
                    onSelectToken={onSelectSwapToken}
                    tokens={whitelistedTokens}
                    infoTokens={infoTokens}
                    className="GlpSwap-from-token"
                    showSymbolImage={true}
                    showTokenImgInDropdown={true}
                  />
                </BuyInputSection>
                <div className="AppOrder-ball-container">
                  <div className="AppOrder-ball">
                    <img src={isLightTheme ? arrowIconLight : arrowIcon} alt="arrowIcon" />
                  </div>
                </div>
                {MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
                  !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapTokenAddress) && (
                    <div className="set-colateral">
                      <div className="title">Underlying token</div>
                      <div className="value">
                        <TokenSelector
                          label={`To`}
                          chainId={chainId}
                          tokenAddress={destMintAddress}
                          onSelectToken={onSelectMintDestToken}
                          tokens={destTokens}
                          infoTokens={infoTokens}
                          className="GlpSwap-from-token"
                          showMintingCap={false}
                          showSymbolImage={true}
                          showTokenImgInDropdown={true}
                          showBalances={false}
                        />
                      </div>
                    </div>
                  )}
                <TokenInput
                  defaultTokenSymbol={"GM"}
                  leftLabel="To"
                  value={toValue}
                  onChange={onToValueChange}
                  tokenDecimal={gmDecimal}
                  rightLabel={`Balance: ${formatAmount(balanceOfGm, gmDecimal, 2, true)}`}
                  disabled
                  tokenLogo={mintRedeemIcon}
                  style={{
                    marginTop: 0,
                  }}
                />
              </>
            )}
            {activeTab === REDEEM_MSP && (
              <>
                <TokenInput
                  defaultTokenSymbol={"GM"}
                  leftLabel="From"
                  value={fromValue}
                  setValue={setFromValue}
                  onChange={onFromValueChange}
                  balance={account ? balanceOfGm : undefined}
                  tokenDecimal={gmDecimal}
                  rightLabel={`Balance: ${formatAmount(balanceOfGm, gmDecimal, 2, true)}`}
                  tokenLogo={mintRedeemIcon}
                  // getBalanceOnClickRightLabel
                  onClickRightLabel={handleClickMaxRedeem}
                />
                <div className="AppOrder-ball-container">
                  <div className="AppOrder-ball">
                    <img src={isLightTheme ? arrowIconLight : arrowIcon} alt="arrowIcon" />
                  </div>
                </div>
                {/* <TokenInput
                  token={usdcToken}
                  leftLabel="To"
                  value={toValue}
                  onChange={onToValueChange}
                  rightLabel={`Balance: ${formatAmount(usdcToken.balance, usdcToken.decimals, 2, true)}`}
                  disabled
                  tokenLogo={usdcIcon}
                  style={{
                    marginTop: 0,
                  }}
                /> */}
                <div className="Exchange-swap-section exchange-section-first redeem-value-box">
                  <div className="Exchange-swap-section-top">
                    <div className="muted">To</div>
                    <div className="muted align-right">
                      <span>Balance: {formatAmount(usdcToken.balance, usdcToken.decimals, 2, true)}</span>
                    </div>
                  </div>
                  <div className="Exchange-swap-section-bottom">
                    <div className="Exchange-swap-input-container">
                      <input
                        type="number"
                        min="0"
                        placeholder="0.0"
                        className="Exchange-swap-input"
                        value={toValue}
                        disabled
                        onChange={onToValueChange}
                      />
                    </div>
                    <div>
                      <TokenSelector
                        label={`To`}
                        chainId={chainId}
                        tokenAddress={destAddress}
                        onSelectToken={onSelectDestToken}
                        tokens={destTokens}
                        infoTokens={infoTokens}
                        showMintingCap={false}
                        showSymbolImage={true}
                        className="GlpSwap-from-token"
                        showTokenImgInDropdown={true}
                        showBalances={false}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="stats">
              {activeTab === MINT_MSP &&
                MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
                !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapTokenAddress) && (
                  // <div className="row">
                  //   <div className="title">Route</div>
                  //   <div className={`value route-tokens`}>
                  //     <span>{swapToken.symbol} </span>
                  //     <img alt="icon" src={ArrowRoute} />
                  //     <span>{usdcMintToken.symbol} </span>
                  //     <img alt="icon" src={ArrowRoute} />
                  //     <span>GM</span>
                  //   </div>
                  // </div>
                  <div className="route-underlying-info">
                    <img alt="info" src={icBlueInfo} />
                    <div>
                      Your {swapToken.symbol} will be swapped to {usdcMintToken.symbol} then deposited into the pool.
                    </div>
                  </div>
                )}
              <div className="row">
                <div className="title">
                  <Tooltip
                    handle={activeTab === MINT_MSP ? "Mint Fees" : "Redeem Fees"}
                    className="fit-content nowrap"
                    renderContent={() => (
                      <>{activeTab === MINT_MSP ? "0.05% of mint amount" : "0.15% of redeem amount"}</>
                    )}
                  />
                </div>
                <div className="value">
                  {activeTab === MINT_MSP
                    ? formatAmountInDollar(stakingFeeMint, 30, 4, true)
                    : formatAmountInDollar(unstakingFeeRedeem, 30, 4, true)}
                </div>
              </div>
              {MICROSWAP_SUPPORTED_CHAIN_IDS.includes(chainId) &&
                !DEFAULT_FROM_V2_TOKEN?.[chainId]?.includes(swapToken?.address) &&
                activeTab === MINT_MSP && (
                  <div className="row">
                    <Tooltip
                      className="title"
                      handle={`Swap Fees`}
                      renderContent={() =>
                        "A swap fee of 0.05% (non-stable tokens) and 0.005% (stable tokens) charged by MicroSwap"
                      }
                    />
                    <div className="value">${swapFees ? formatAmount(swapFees, 30, 4, true) : "--"}</div>
                  </div>
                )}
              {activeTab === MINT_MSP && chainId !== OP && (
                <div className="row">
                  <div className="title">
                    <Tooltip
                      handle={`Minimum Received`}
                      position="left-top"
                      renderContent={() => {
                        return (
                          <>
                            <span className="text-desc">
                              You will receive at least this amount or your transaction will revert
                            </span>
                          </>
                        );
                      }}
                    ></Tooltip>
                  </div>
                  <div className="value">{minOutputMin ? formatAmount(minOutputMin, 30, 4, true) : "--"} GM</div>
                </div>
              )}
              {activeTab === MINT_MSP && (
                <div className="row">
                  <div className="title">Remaining Mintable Amount</div>
                  <div className="value">
                    {gmAvailableToMint ? formatNumber(formatAmount(gmAvailableToMint, gmDecimal, 0, false), 2) : "--"}{" "}
                    GM
                  </div>
                </div>
              )}
            </div>
            {!account && (
              <button className="default-btn full" style={{ marginTop: 24 }} onClick={() => connectWallet()}>
                Connect Wallet
              </button>
            )}
            {account && (
              <>
                {!ACTIVE_CHAIN_IDS_V2.includes(chainId) && (
                  <button
                    onClick={() => switchNetwork(DEFAULT_CHAIN_ID, active)}
                    className="default-btn action-btn full"
                  >
                    Switch to FANTOM
                  </button>
                )}
                {ACTIVE_CHAIN_IDS_V2.includes(chainId) && activeTab === MINT_MSP && (
                  <button onClick={mint} className="default-btn action-btn full" disabled={!isButtonEnabled()}>
                    {getButtonText()}
                  </button>
                )}
                {ACTIVE_CHAIN_IDS_V2.includes(chainId) && activeTab === REDEEM_MSP && (
                  <button onClick={redeem} className="default-btn action-btn full" disabled={!isButtonEnabledRedeem()}>
                    {getButtonTextRedeem()}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <div className="pool-composition">
          <div className="title">Pool Composition</div>
          <div className="table-content">
            <div className="td-content td-header">
              <div className="content">Token</div>
              <div className="content">Amount</div>
              <div className="content">Value</div>
              <div className="content">Weight</div>
            </div>
            {mapPoolComposition.map((token, index) => {
              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");
              return (
                <div className="td-content" key={index}>
                  <div className="content">
                    <div className="token-symbol-wrapper">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt="usdc" width="40px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-subtitle">
                          {token.symbol}{" "}
                          <div className="dropdown-clickable">
                            <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                          </div>
                        </div>
                        <div className="App-card-info-title">{token.name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {token?.amountPool ? formatAmount(token?.amountPool, 6, 2, true) : "--"}
                  </div>
                  <div className="content">
                    ${token?.amountPool ? formatAmount(token?.amountPoolUSD, 36, 2, true) : "--"}
                  </div>
                  <div className="content">
                    {token?.percentPool ? formatAmount(token?.percentPool, 6, 2, true) : "--"}%
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </Wrapper>
  );
}

const StatRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  &:not():first-child {
    padding-left: 24px;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .value {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;

    &.green {
      color: #c1ff99;
    }
    .tooltip-apr {
      .Tooltip-handle {
        color: #02b27f !important;
      }
    }
  }

  .desc {
    color: #ffffff99;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
`;

const StatCard = styled.div`
  padding: 24px;
  border-radius: 16px;
  background-image: linear-gradient(to left, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 50%);
  display: flex;

  &:has(.stake-now) {
    position: relative;

    .stake-now {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--main-primary, #c1ff99);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      text-decoration-line: underline;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      .label {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;
      }

      .stake-now {
        position: static;
      }
    }
  }

  &.hasReserved {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  > div {
    flex: 1;
  }

  @media (max-width: 767px) {
    padding: 16px;
    gap: 16px;
  }
`;

const TokenStatsContainer = styled.div`
  .Tooltip-popup {
    width: fit-content !important;
  }

  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 24px;
  background-color: transparent !important;
  position: relative;
  padding: 0 !important;

  @media (max-width: 767px) {
    gap: 16px;
  }

  .App-card-title {
    position: absolute;
    top: 0;

    .App-card-title-mark-icon {
      max-height: 36px;
    }

    .App-card-title-mark-info {
      display: flex;
      align-items: center;
      gap: 4px;

      .value {
        color: #34f5ae;
      }
    }
  }

  .App-card-content {
    padding: 24px;
    border-radius: 16px;
    background-image: linear-gradient(to left, #002e58cc, #191b2ecc);
    display: flex;

    > div {
      flex: 1;
    }

    .App-card-row {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > * {
        text-align: left;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #ffffff99;
      }
    }

    @media (max-width: 767px) {
      gap: 16px;
    }
  }
`;

const Wrapper = styled.div`
  background-image: url(${patternImg});
  background-repeat: no-repeat;
  background-size: contain;

  .page-layout {
    @media (max-width: 1023px) {
      padding-top: 72px;
    }

    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .heading {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .heading-title {
      display: flex;
      align-items: center;
      gap: 12px;

      > span {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
      }
    }

    .heading-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff99;

      a {
        color: #ffffff99;
      }
    }
  }

  &.theme--light {
    .heading-desc div {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }

    ${StatCard} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(to left, #f2fffb 0%, #daf2eb 100%);
    }

    ${StatRow} {
      .label span,
      .desc {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }

      .value.green,
      .stake-now {
        color: var(--Primary, #02b27f);
      }
    }

    .MintRedeemPage-box {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      background: var(--Nature-1, #fff);

      .Exchange-swap-option-tabs {
        background-color: transparent;
      }

      .stats {
        .row {
          .title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }

          .value {
            color: var(--Text-Text_Primary, #0d1a16);
          }
        }
      }
    }
  }

  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);
  }
`;
