import React, { useState } from "react";
import styled from "styled-components";
import searchImg from "img/analytics/ic-search-wallet.svg";
import { Input } from "antd";
import { ethers } from "ethers";

import { useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";

export default function SearchAddress() {
  const { lightThemeClassName } = useThemeContext() as any;
  const history = useHistory();
  const isMobile = useMedia("(max-width: 676px)");
  const [searchValue, setSearchValue] = useState("");

  const handleCheckAddress = (searchValue) => {
    return ethers.utils.isAddress(searchValue);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearch = async (value) => {
    if (handleCheckAddress(searchValue) && searchValue && value) {
      history.push(`/analytic/account/${searchValue}`);
    }
  };

  return (
    <Wrapper className={`analytic-container ${lightThemeClassName}`}>
      <div className="search-container">
        <img src={searchImg} className="search-img" alt="img" />
        <div className="search-title">Search by Address</div>
        <div className="search-input">
          {isMobile ? (
            <StyledSearch
              value={searchValue}
              onChange={handleChange}
              placeholder="Enter or paste address..."
              allowClear
              size="large"
              className={`${!handleCheckAddress(searchValue) || !searchValue ? "search-btn--disabled" : ""}`}
              onSearch={onSearch}
            />
          ) : (
            <StyledSearch
              value={searchValue}
              onChange={handleChange}
              placeholder="Enter or paste address..."
              allowClear
              enterButton={"Search"}
              size="large"
              className={`${!handleCheckAddress(searchValue) || !searchValue ? "search-btn--disabled" : ""}`}
              onSearch={onSearch}
            />
          )}

          {!handleCheckAddress(searchValue) && searchValue && <div className="txt-err">Invalid address</div>}
        </div>
      </div>
    </Wrapper>
  );
}

const StyledSearch = styled(Input.Search)`
  input {
    font-family: "Relative", sans-serif;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 50vh;
  .search-btn--disabled {
    .ant-input-search-button {
      cursor: not-allowed;
    }
  }
  width: 80%;
  .search-container {
    width: 100%;
    text-align: center;
  }

  .search-img {
    height: 96px;
  }
  .search-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 30px 0px 25px;
    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
  .search-input {
    /* padding-bottom: 300px; */
    input {
      color: black !important;
    }
  }
  .txt-err {
    text-align: left;
    margin: 10px 0px;
    color: rgb(246, 71, 93);
  }
  .css-dev-only-do-not-override-ixblex.ant-btn-primary,
  .ant-input-search-button {
    background: #c1ff99;
    color: #080715;
  }

  &.theme--light {
    .anticon.anticon-close-circle {
      svg path {
        color: #0d1a16b2;
      }

      &:hover {
        svg path {
          color: #0d1a16;
        }
      }
    }

    .search-input {
      /* padding-bottom: 300px; */
      .ant-input-affix-wrapper {
        &:focus-within,
        &:hover {
          border-color: #02b27f !important;
        }
      }
    }

    .search-container {
      .ant-input-group-wrapper.ant-input-search.search-btn--disabled {
        .ant-input-group-addon {
          button {
            box-shadow: none;
            background: #02b27f;

            span {
              color: #fff;
            }
          }
        }
      }

      .ant-input-group-addon {
        button {
          box-shadow: none;
          background: #02b27f;

          span {
            color: #fff;
          }

          &:hover {
            background: #03805b;
          }
        }
      }
    }

    .txt-err {
      color: #d62f44;
    }
  }
`;
