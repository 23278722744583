import { IconArrow, IconBnb, IconBtc, IconChart, IconEth, IconLink, IconOp, IconSnx } from "assets";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";
import { importImage, USD_DECIMALS } from "lib/legacy";
import { formatCryptoPrice } from "lib/numbers";
import { useEffect, useState } from "react";

import Marquee from "react-fast-marquee";
import { getCoinList } from "services/api";
import styled from "styled-components";

const SponsorBanner = ({ dataSpon }) => {
  return (
    <Marquee>
      {dataSpon &&
        dataSpon.map((sponsor, index) => {
          const url = `https://app.yummy.fi/#/trade?symbol=${encodeURIComponent(sponsor.symbol).replace(/%2F/g, "/")}`;
          return (
            <ExternalLink href={url}>
              <Item key={index} className="">
                <div className="chart">
                  <IconChart fill={sponsor.price24HChanged > 0 ? "#7EE55C" : "#F6475D"} />
                </div>
                <div className="left">
                  <img
                    src={importImage("ic_" + sponsor.symbol.split("/")[0]?.toLowerCase() + "_40.svg")}
                    alt="symbol"
                  />
                  <div className="name-coin">{sponsor.symbol}</div>
                </div>
                <div className="right">
                  <div className="price">
                    $<SmallTextNumber val={sponsor.price} decimal={2} />
                  </div>

                  <div className="percent">
                    <IconArrow
                      status={sponsor.price24HChanged > 0 ? "up" : "down"}
                      fill={sponsor.price24HChanged > 0 ? "#7EE55C" : "#F6475D"}
                    />
                    <div
                      style={{
                        color: sponsor.price24HChanged > 0 ? "#7EE55C" : "#F6475D",
                      }}
                      className="percent-number"
                    >
                      {Math.abs(sponsor.price24HChanged.toFixed(2))}%
                    </div>
                  </div>
                </div>
              </Item>
            </ExternalLink>
          );
        })}
    </Marquee>
  );
};

export default SponsorBanner;

const Item = styled.div`
  display: flex;
  position: relative;
  width: 349px;
  padding: 32px 24px;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  border-right: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
  background: var(--Nature-1, #171a1f);
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 16px 24px;
  }
  .chart {
    position: absolute;
    left: -1px;
    top: 0;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .name-coin {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .right {
    display: flex;
    width: 81px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    flex-shrink: 0;
  }
  .price {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .percent {
    display: flex;
    align-items: center;
  }
  .percent-number {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .img-coin {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;
