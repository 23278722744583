import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useGmyPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  GLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import gmyIcon from "img/ic_gmy_custom.svg";
import GMY_FTM from "img/GMY-FTM.svg";
import GMY_ETH from "img/GMY-ETH.svg";
import tokensIcon from "img/tokens.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import GlpManager from "abis/GlpManager.json";
import VaultStrategyGMY from "abis/VaultStrategyGMY.json";
import VaultStrategyGLP from "abis/VaultStrategyGLP.json";
import VaultStrategyGMY_WFTM from "abis/VaultStrategyGMY_WFTM.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useChainId } from "lib/chains";
import { FANTOM } from "config/chains";
import { useMedia } from "react-use";

export const GMY_VAULT = "GMY vault";
export const GLP_VAULT = "GLP vault";
export const GMY_WFTM_VAULT = "GMY_WFTM vault";
export const GMY_WETH_VAULT = "GMY_WETH vault";

const TAB_ICONS = {
  [GMY_VAULT]: gmyIcon,
  [GLP_VAULT]: tokensIcon,
  [GMY_WFTM_VAULT]: GMY_FTM,
  [GMY_WETH_VAULT]: GMY_ETH,
};

const StyledTab = styled(Tab)`
  height: 45px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 22.5px;

  width: fit-content;
  display: inline-flex;

  > div {
    height: 45px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    &:nth-child(2) {
      img {
        width: 36px;
      }
    }
  }
`;

const GMY_WFTM_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

export default function Vault(props) {
  const { chainId } = useChainId();

  const [activeTab, setActiveTab] = useState(chainId === FANTOM ? GMY_VAULT : GMY_WETH_VAULT);
  const TAB_OPTION_LABELS = {
    [GMY_VAULT]: `GMY`,
    [GLP_VAULT]: `GLP`,
    [GMY_WFTM_VAULT]: `GMY-WFTM`,
    [GMY_WETH_VAULT]: `GMY-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsGMYWFTM, setStatsGMYWFTM] = useState({
    price: 0,
    apr: 1,
  });

  // const fetchStatsGMYWFTM = async () => {
  //   const res = await axios.get(`https://api.velodrome.finance/api/v1/pairs`, {});

  //   if (
  //     res &&
  //     res.data &&
  //     res.data.data &&
  //     res.data.data.find((item) => item.address.toLowerCase() === GMY_WFTM_Address)
  //   ) {
  //     const { apr, tvl, totalSupply } = res.data.data.find((item) => item.address.toLowerCase() === GMY_WFTM_Address);

  //     setStatsGMYWFTM({
  //       price: tvl / totalSupply,
  //       apr,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   fetchStatsGMYWFTM();

  //   const interval = setInterval(async () => {
  //     fetchStatsGMYWFTM();
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  const TAB_OPTIONS = chainId === FANTOM ? [GMY_VAULT, GLP_VAULT, GMY_WFTM_VAULT] : [GMY_WETH_VAULT];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultGMYAddress = getContract(chainId, "VaultGMY");
  const vaultGLPAddress = getContract(chainId, "VaultGLP");
  const vaultGMY_WFTMAddress = getContract(chainId, "VaultGMY_WETH");
  const vaultGMY_WETHAddress = getContract(chainId, "VaultGMY_WETH");

  const currentVaultAddress =
    activeTab === GMY_VAULT
      ? vaultGMYAddress
      : activeTab === GLP_VAULT
      ? vaultGLPAddress
      : activeTab === GMY_WFTM_VAULT
      ? vaultGMY_WFTMAddress
      : vaultGMY_WETHAddress;

  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress];

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress, GMY_WFTM_Address];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesESGMY } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x8a478CB61A7f6E38AC09487e808B3E048709966E" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesGLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0xceaE93Bc099E386353B652b7e254DeFe52555883" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [`StakeV2:stakedGmySupply:${active}`, chainId, gmyAddress, "balanceOf", stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  //GMY
  const { data: strategyAddressGMY } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultGMYAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestGMY } = useSWR(
    [`StakeV2:lastHarvestGMY:${active}`, chainId, strategyAddressGMY, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyGMY),
    }
  );
  const { data: balanceOfGMY } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultGMYAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareGMY } = useSWR(
    () => [`StakeV2:getPricePerFullShareGMY:${active}:${account}`, chainId, vaultGMYAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyGMY } = useSWR([`StakeV2:totalSupply:${active}`, chainId, vaultGMYAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  //GLP
  const { data: strategyAddressGLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultGLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestGLP } = useSWR(
    [`StakeV2:lastHarvestGLP:${active}`, chainId, strategyAddressGLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyGLP),
    }
  );
  const { data: balanceOfGLP } = useSWR(
    [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultGLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareGLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareGLP:${active}:${account}`, chainId, vaultGLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyGLP } = useSWR([`StakeV2:totalSupply:${active}`, chainId, vaultGLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  // GMY_WFTM

  const { data: strategyAddressGMY_WFTM } = useSWR(
    [`StakeV2:strategy:${active}`, chainId, vaultGMY_WFTMAddress, "strategy"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: lastHarvestGMY_WFTM } = useSWR(
    [`StakeV2:lastHarvestGMY:${active}`, chainId, strategyAddressGMY_WFTM, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyGMY_WFTM),
    }
  );

  const { data: balanceOfGMY_WFTM } = useSWR(
    () => [`StakeV2:balanceOf:${active}:${account}`, chainId, vaultGMY_WFTMAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareGMY_WFTM } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareGMY_WFTM:${active}:${account}`,
      chainId,
      vaultGMY_WFTMAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyGMY_WFTM } = useSWR(
    [`StakeV2:totalSupply:${active}`, chainId, vaultGMY_WFTMAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { gmyPrice } = useGmyPrice(chainId, {}, active);

  let gmySupply;
  if (totalSupplies && totalSupplies[1]) {
    gmySupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);

  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESGMY = getDepositBalanceData(depositBalancesESGMY);
  const depositBalanceDataGLP = getDepositBalanceData(depositBalancesGLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const amountTokenDeposit =
    activeTab === GMY_VAULT
      ? formatAmountFree(balanceOfGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmountFree(balanceOfGLP, 18)
      : formatAmountFree(balanceOfGMY_WFTM, 18); // 3 vault
  const rateWithdraw =
    activeTab === GMY_VAULT
      ? formatAmountFree(pricePerFullShareGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmountFree(pricePerFullShareGLP, 18)
      : formatAmountFree(pricePerFullShareGMY_WFTM, 18); // 3 vault
  const amountTVL =
    activeTab === GMY_VAULT
      ? formatAmount(totalSupplyGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmount(totalSupplyGLP, 18)
      : formatAmount(totalSupplyGMY_WFTM, 18); // 3 vault

  const amountLastHarvest =
    activeTab === GMY_VAULT
      ? moment(Number(lastHarvestGMY?.toString() || "") * 1000).fromNow()
      : activeTab === GLP_VAULT
      ? moment(Number(lastHarvestGLP?.toString() || "") * 1000).fromNow()
      : moment(Number(lastHarvestGMY_WFTM?.toString() || "") * 1000).fromNow(); // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 4080) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply
  );

  let nameToken = "gmyBalance";

  switch (activeTab) {
    case GMY_VAULT:
      nameToken = "gmyBalance";
      break;

    case GLP_VAULT:
      nameToken = "glpBalance";
      break;

    case GMY_WFTM_VAULT:
      nameToken = "gmy_wftmBalance";
      break;

    case GMY_WETH_VAULT:
      nameToken = "gmy_wftmBalance"; // fix me later = gmy_wethBalance
      break;

    default:
      nameToken = "gmyBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);

  if (processedData && vestingData && processedData?.glpBalance) {
    if (activeTab === GLP_VAULT) {
      balance = formatAmountFree(processedData?.glpBalance.sub(vestingData?.glpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const apr =
    activeTab === GMY_WETH_VAULT
      ? statsGMYWFTM.apr
      : formatKeyAmount(processedData, activeTab === GMY_VAULT ? "gmyAprTotal" : "glpAprTotal", 2, 2, true); // 3 vault

  const apy = !isNaN(apr) ? formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2) : "--";
  const priceToken =
    activeTab === GMY_VAULT
      ? Number(formatAmount(gmyPrice, USD_DECIMALS))
      : activeTab === GLP_VAULT
      ? Number(formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true))
      : statsGMYWFTM.price;

  const [daily, setDaily] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(apr)) {
      setDaily(formatNumber(caculateAPYDaily(Number(apr), 1), 4));
    }
  }, [apy, apr]);

  const esGmyInStakedESGMY =
    depositBalanceDataESGMY &&
    gmyPrice &&
    depositBalanceDataESGMY["esGmyInStakedGmy"].mul(gmyPrice).div(expandDecimals(1, GLP_DECIMALS));

  const esGmyInStakedGLP =
    depositBalanceDataGLP &&
    gmyPrice &&
    depositBalanceDataGLP["esGmyInStakedGmy"].mul(gmyPrice).div(expandDecimals(1, GLP_DECIMALS));
  // console.log("???", daily);
  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div>TVL</div>
            {activeTab === GMY_WETH_VAULT ? (
              <span className="APY-value">
                {priceToken === 0 ? (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                ) : (
                  "$" + formatNumber(amountTVL * priceToken, 2)
                )}
              </span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken
                    ? formatNumber(
                        amountTVL * priceToken +
                          Number(formatAmount(activeTab === GMY_VAULT ? esGmyInStakedESGMY : esGmyInStakedGLP, 30)),
                        2
                      )
                    : "--"
                }`}
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === GMY_VAULT ? "GMY" : "GLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked esGMY`}
                        value={formatNumber(
                          Number(formatAmount(activeTab === GMY_VAULT ? esGmyInStakedESGMY : esGmyInStakedGLP, 30, 2)),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            APY
            {apr !== 1 ? (
              <span className="APY-value">{apy}%</span>
            ) : (
              <span className="APY-value">
                <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
              </span>
            )}
          </div>
          <div className="Daily">
            Daily
            {daily !== "0.0027" ? (
              <span className="Daily-value">{daily}%</span>
            ) : (
              <span className="APY-value">
                <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
              </span>
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <Wrapper className="Vault default-container page-layout">
      <div
        style={{
          maxWidth: "100%",
        }}
        className="section-title-block"
      >
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div
            style={{
              marginBottom: 8,
            }}
            className="Page-title"
          >
            <div>
              Vault <img alt="" src={chainId === FANTOM ? ftmIcon : opIcon} />
            </div>
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.6)",
            }}
            className="description"
          >
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </div>
        </div>
      </div>

      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />
      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    $
                    {amountTokenDeposit !== "--" && priceToken
                      ? formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                      : "--"}
                  </span>
                ) : (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    $
                    {amountTokenDeposit !== "--" && priceToken
                      ? formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                      : "--"}
                  </span>
                ) : (
                  <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                )}
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}

      <Footer />
    </Wrapper>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  }
`;

const Wrapper = styled.div`
  max-width: unset;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0 16px 0 16px;
  }
`;
