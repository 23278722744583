import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      style={{
        transform: props.status === "down" ? "rotate(180deg)" : "rotate(0deg)",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "16"}
      height={props.height ? props.height : "16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path fill={props.fill ? props.fill : "#fff"} d="M8 4.667l-4.62 6h9.238L8 4.666z"></path>
    </svg>
  );
}

export default Icon;
