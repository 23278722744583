import React from "react";

function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "32px"}
      height={props.height ? props.height : "32px"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.fill ? props.fill : "#fff"}
        d="M12.843 19.818l-.485 6.7c.694 0 .995-.292 1.355-.644l3.255-3.054 6.745 4.85c1.237.676 2.108.32 2.442-1.118l4.427-20.366v-.002c.393-1.795-.66-2.497-1.866-2.056l-26.021 9.78c-1.776.678-1.75 1.65-.302 2.09l6.653 2.032 15.453-9.494c.727-.472 1.388-.21.844.262l-12.5 11.02z"
      ></path>
    </svg>
  );
}

export default Icon;
