import Modal from "components/Modal/Modal";
import { useMemo } from "react";
import styled from "styled-components";
import { getContract } from "config/contracts";
import usePoolTokenBalance from "hooks/usePoolTokenBalance";
import { BigNumber } from "ethers";
import { formatAmount, parseValue } from "lib/numbers";
import { getTokenInfo } from "domain/tokens";
import { importImage } from "lib/legacy";

import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_GM } from "config/chains";
import { Link } from "react-router-dom";

export const PoolCompositionGslpModal = ({ isVisible, setIsVisible, infoTokens }) => {
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_GM,
    isUseDefaultChain: false,
  });

  const vaultGmyAddress = getContract(chainId, "VaultMsp");

  const poolTokenBalances = usePoolTokenBalance(chainId, vaultGmyAddress);

  const mapPoolComposition = useMemo(() => {
    const origin = [...poolTokenBalances];
    if (poolTokenBalances && poolTokenBalances.length > 0) {
      let total = BigNumber.from(0);
      for (let i = 0; i < poolTokenBalances.length; i++) {
        total = total.add(
          poolTokenBalances[i].balance.div(
            poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
          )
        );
      }
      for (let i = 0; i < origin.length; i++) {
        const curTokenInfo = getTokenInfo(infoTokens, origin[i].address);
        const amountBalance = poolTokenBalances[i].balance.div(
          poolTokenBalances[i].decimals !== 6 ? parseValue(1, poolTokenBalances[i].decimals - 6) : 1
        );
        origin[i] = {
          ...poolTokenBalances[i],
          amountPool: amountBalance,
          amountPoolUSD:
            curTokenInfo?.minPrice && curTokenInfo?.maxPrice && amountBalance
              ? amountBalance.mul(curTokenInfo?.minPrice.add(curTokenInfo?.maxPrice).div(2) || parseValue(1, 30))
              : amountBalance,
          percentPool: total.gt(0) ? amountBalance.mul(100).mul(parseValue(1, 6)).div(total) : 0,
        };
      }
    }
    return origin;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolTokenBalances]);

  return (
    <Modal1
      className="medium-modal Bootstrap-Modal hasDivider"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      label="Pool Composition"
    >
      <div className="PoolModal-Table">
        <div className="PoolModal-TableHead">
          <span>Token</span>
          <span>Weight</span>
          <span>TVL</span>
        </div>
        {mapPoolComposition.map((token, index) => {
          const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

          return (
            <div className="PoolModal-TableRow" key={index}>
              <span>
                <img src={tokenImage} alt="usdc" width="40px" /> {token.symbol}
              </span>
              <span> {token?.percentPool ? formatAmount(token?.percentPool, 6, 2, true) : "--"}%</span>
              <span>${token?.amountPool ? formatAmount(token?.amountPoolUSD, 36, 2, true) : "--"}</span>
            </div>
          );
        })}
      </div>
      <div className="PoolModal-Actions">
        <Link to="/earn/gslp" className="default-btn">
          Get & Stake
        </Link>
      </div>
    </Modal1>
  );
};

const Modal1 = styled(Modal)`
  .PoolModal-Table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Base-Unit-S-12, 12px);
    align-self: stretch;
  }

  .PoolModal-TableHead {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    align-self: stretch;

    span {
      width: calc(100% / 3);

      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  .PoolModal-TableRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      width: calc(100% / 3);

      &:first-child {
        display: flex;
        align-items: center;
        gap: var(--Base-Unit-XS-8, 8px);
        flex: 1 0 0;
      }
    }
  }

  .PoolModal-Actions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 24px;
  }
`;
