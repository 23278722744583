import { PoolFrame } from "pages/Earn/styles";

import gslp_ic from "img/gslp.svg";
import green_arrow_ic from "img/green-arrow.svg";

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Footer from "components/Footer/Footer";
import BuySellGslp from "pages/Earn/BuySellGslp";
import PoolCompositionGslp from "pages/Earn/PoolCompositionGslp";
import useVaultGmyContract from "hooks/contracts/useVaultGmyContract";

import { bigNumberify, expandDecimals, formatAmount, formatKeyAmount, formatNumber } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import { useChainIdWithDefault } from "lib/chains";
import { SUPPORTED_CHAIN_ID_GM } from "config/chains";
import { useGmyPrice } from "domain/legacy";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BASIS_POINTS_DIVISOR,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  GLP_DECIMALS,
  GM_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
} from "lib/legacy";
import { getNativeToken, getTokens } from "config/tokens";
import { getContract } from "config/contracts";
import useSWR from "swr";
import { contractFetcher } from "lib/contracts";

import RewardReader from "abis/RewardReader.json";
import ReaderV2 from "abis/ReaderV2.json";
import GlpManager from "abis/GlpManager.json";
import Vault from "abis/Vault.json";
import Token from "abis/Token.json";
import RewardTracker from "abis/RewardTracker.json";

import { ethers } from "ethers";

export const MINT_MSP = "Mint GM";
export const REDEEM_MSP = "Redeem GM";

const { AddressZero } = ethers.constants;

const Gslp = ({
  setPendingTxns,
  connectWallet,
  savedSelectedDexes,
  savedSlippageAmount,
  savedShouldDisableValidationForTesting,
}) => {
  const [isBuying, setIsBuying] = useState(true);
  const history = useHistory();

  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_GM,
    isUseDefaultChain: false,
  });

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  const { gmyPrice } = useGmyPrice(chainId, {}, active);

  const nativeTokenSymbol = getNativeToken(chainId).symbol;
  // const tokens = getTokens(chainId);
  const readerAddress = getContract(chainId, "Reader");
  const stakedGmTrackerAddress = getContract(chainId, "StakedGmTracker");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const usdgAddress = getContract(chainId, "USDG");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const vaultAddress = getContract(chainId, "Vault");
  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmyAddress = getContract(chainId, "GMY");
  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const gmAddress = getContract(chainId, "GM");

  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");
  const feeGmTrackerAddress = getContract(chainId, "FeeGmTracker");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const gmVesterAddress = getContract(chainId, "GmVester");

  const tokensForBalanceAndSupplyQuery = [stakedGmTrackerAddress, usdgAddress];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
    stakedGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  const vesterAddresses = [gmyVesterAddress, gmVesterAddress, gmVesterAddress];
  const walletTokens = [gmyAddress, esGmyAddress, gmAddress, stakedGmyTrackerAddress, gmAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    gmAddress,
    gmAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmTrackerAddress,
    feeGmTrackerAddress,
  ];

  // const tokenAddresses = tokens.map((token) => token.address);

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash !== "redeem";
    setIsBuying(buying);
  }, [history.location.hash]);

  // const { data: tokenBalances } = useSWR(
  //   [`Gslp:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
  //   }
  // );

  const { data: balancesAndSupplies } = useSWR(
    [
      `Gslp:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `Gslp:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`Gslp:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`Gslp:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: walletBalances } = useSWR(
    [
      `Gslp:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `Gslp:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `Gslp:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );

  const { data: gmBalance } = useSWR(
    [`Gslp:gmBalance:${active}`, chainId, feeGmTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [
      `Gslp:stakedGmySupply:${active}:${stakedGmyTrackerAddress}`,
      chainId,
      gmyAddress,
      "balanceOf",
      stakedGmyTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const tokensForSupplyQuery = [gmyAddress, gmAddress, usdgAddress, gmAddress];
  const { data: totalSupplies } = useSWR(
    [
      `Gslp:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );

  let totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalSupply = totalSupplies[1];
  }

  let gmySupply = totalSupply;

  let aum;
  if (aums && aums.length > 0) {
    aum = isBuying ? aums[0] : aums[1];
  }

  // const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);
  const gmSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const { gmPrice } = useVaultGmyContract(chainId);

  // const gmPrice =
  //   aum && aum.gt(0) && gmSupply.gt(0)
  //     ? aum.mul(expandDecimals(1, GM_DECIMALS)).div(gmSupply)
  //     : expandDecimals(1, USD_DECIMALS);

  // const gmSupplyUsd = gmSupply.mul(gmPrice).div(expandDecimals(1, GM_DECIMALS));
  const stakingData = getStakingData(stakingInfo);
  // const nativeToken = getTokenInfo(infoTokens, AddressZero);

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply,
    gmPrice
  );

  return (
    <PoolFrame>
      <div className="Pool-Head">
        <div className="Pool-HeadDetails">
          <h1>
            <img src={gslp_ic} alt="gslp_ic" />
            Stable pool - GSLP
          </h1>
          <p>Purchase GSLP tokens to earn BERA fees from swaps and leveraged trading.</p>
        </div>
        <div className="Pool-Links">
          <Link to="/earn" className="Pool-Link">
            Staking page
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Pool-Link">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div>
      </div>
      <div className="Pool-Papers">
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            APR
            {processedData.gmAprTotal ? (
              <>
                <h5 className="positive">{formatKeyAmount(processedData, "gmAprTotal", 2, 2, true)}%</h5>
                {nativeTokenSymbol} {formatKeyAmount(processedData, "gmAprForNativeToken", 2, 2, true)}%{" "}
                <br className="br-mobile" />+ esGMY {formatKeyAmount(processedData, "gmAprForEsGmy", 2, 2, true)}%
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Staked
            {processedData.gmSupply ? (
              <>
                <h5>{formatKeyAmount(processedData, "gmSupply", 18, 0, true)} GSLP</h5> $
                {formatNumber(formatKeyAmount(processedData, "gmSupplyUsd", USD_DECIMALS, 0), 2)}
              </>
            ) : (
              <div className="skeleton-box" style={{ width: "80px", height: "19.6px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Total Supply
            {formatAmount(gmSupply, 18, 4, true) !== "0.0000" ? (
              <>
                <h5>{formatKeyAmount(processedData, "gmSupply", 18, 0, true)} GSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "gmSupplyUsd", USD_DECIMALS, 0), 2)}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Paper Pool-PaperStats">
          <div className="Pool-PaperStat">
            Your Staked
            {gmBalance ? (
              <>
                <h5>{formatAmount(gmBalance, GLP_DECIMALS, 4, true)} GSLP</h5>
              </>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
            {processedData?.gmBalanceUsd ? (
              <>${formatNumber(formatKeyAmount(processedData, "gmBalanceUsd", USD_DECIMALS, 0), 2)}</>
            ) : (
              <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
            )}
          </div>
          <div className="Pool-PaperStat">
            Your Wallet
            {gmBalance ? (
              <>
                <h5>{formatAmount(gmBalance, GLP_DECIMALS, 4, true)} GSLP</h5>$
                {formatNumber(formatKeyAmount(processedData, "gmBalanceUsd", USD_DECIMALS, 0), 2)}
              </>
            ) : (
              <>
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
                <span className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "3px" }} />
              </>
            )}
          </div>
        </div>
        <div className="Pool-Aggregate">
          <div className="Pool-Paper Pool-Chart">
            <h3>Token Distribution</h3>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid opacity={0.1} />
                <XAxis dataKey="name" color="red" />
                <YAxis />
                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <PoolCompositionGslp />
        </div>
        <div className="Pool-Paper Pool-Form">
          <BuySellGslp
            isBuying={isBuying}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
            savedSelectedDexes={savedSelectedDexes}
            savedSlippageAmount={savedSlippageAmount}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          />
        </div>
      </div>
      <Footer />
    </PoolFrame>
  );
};

export default Gslp;

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
