import React from "react";
import styled from "styled-components";
import { IconArrow, IconBtc, IconEth, IconLink, IconUsdt, ImageBgToken } from "assets";
import { CoinData } from "interface";
import { motion } from "framer-motion";
import { importImage } from "lib/legacy";
import { formatCryptoPrice, formatKeyAmount, formatNumber } from "lib/numbers";
import SmallTextNumber from "components/Tooltip/SmallTextNumber";
import useProcessedData from "hooks/useProcessedData";
import { BERACHAIN_BARTIO } from "config/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

const contentVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.8,
    },
  },
};

const cardVariants = {
  hidden: (direction) => ({ opacity: 0, x: direction === "left" ? -50 : 50 }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      duration: 0.8,
    },
  },
};

const TokenPotentialSection = ({ dataSpon }) => {
  const processedData = useProcessedData(BERACHAIN_BARTIO);

  const majorApr = parseFloat(formatKeyAmount(processedData, "glpAprTotal", 2, 2, true).replace(/,/g, ""));
  const stableApr = parseFloat(formatKeyAmount(processedData, "gmAprTotal", 2, 2, true).replace(/,/g, ""));
  const yummyApr = parseFloat(formatKeyAmount(processedData, "gmyAprTotalWithBoost", 2, 2, true).replace(/,/g, ""));

  const apr = Math.max(majorApr, stableApr, yummyApr);
  return (
    <Wrapper>
      <div className="container wrapper">
        <motion.div className="content" initial="hidden" whileInView="visible">
          <motion.div variants={contentVariants}>
            <motion.div className="title" variants={contentVariants}>
              Unlock your token's full potential
            </motion.div>
            <motion.div className="desc" variants={contentVariants}>
              Convert your tokens into trading power while simultaneously generating passive income through staking.
            </motion.div>
          </motion.div>
          <div className="box-main">
            <motion.div className="card" variants={cardVariants} custom="left">
              <div className="head-card">
                <div className="title-card">Earn High Yield 🤑</div>
                <div className="coin-group">
                  <IconEth />
                  <IconBtc width={48} height={48} />
                  <IconUsdt />
                </div>
                <div className="content-title">Up to {formatNumber(apr)}% APR</div>
                <div className="content-desc">Provide liquidity in the Major pool and the Stable pool</div>
              </div>
              <ExternalLink className="link-btn" href="https://app.yummy.fi/#/earn">
                <button className="btn-fill">Earn now</button>
              </ExternalLink>
            </motion.div>
            <motion.div className="card" variants={cardVariants} custom="right">
              <div className="head-card">
                <div className="title-card">Trade the Hottest Markets🔥</div>
                <div className="box-trade">
                  {dataSpon &&
                    dataSpon.slice(0, 3).map((item, idx) => {
                      const url = `https://app.yummy.fi/#/trade?symbol=${encodeURIComponent(item.symbol).replace(
                        /%2F/g,
                        "/"
                      )}`;

                      return (
                        <ExternalLink href={url} className="item-trade" key={idx}>
                          <div className="left">
                            <div className="img-left">
                              <img
                                src={importImage("ic_" + item.symbol.split("/")[0]?.toLowerCase() + "_40.svg")}
                                alt="symbol"
                              />
                            </div>

                            <div className="name">{item.symbol}</div>
                          </div>
                          <div className="right">
                            <div className="price">
                              {" "}
                              $<SmallTextNumber val={item.price} decimal={2} />
                            </div>
                            <div className="box-percent">
                              <IconArrow
                                status={item.price24HChanged > 0 ? "up" : "down"}
                                fill={item.price24HChanged > 0 ? "#7EE55C" : "#F6475D"}
                              />
                              <div
                                style={{
                                  color: item.price24HChanged > 0 ? "#7EE55C" : "#F6475D",
                                }}
                                className="percent-number"
                              >
                                {Math.abs(item.price24HChanged.toFixed(2))}%
                              </div>
                            </div>
                          </div>
                        </ExternalLink>
                      );
                    })}
                </div>
              </div>
              <ExternalLink className="link-btn" href="https://app.yummy.fi/#/trade">
                <button className="btn-fill">Trade now</button>
              </ExternalLink>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default TokenPotentialSection;

const Wrapper = styled.section`
  margin-top: 160px;
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
  .link-btn {
    text-decoration: none;
  }
  .img-coin {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  .wrapper {
    background: url(${ImageBgToken}) lightgray 50% / cover no-repeat;
    display: flex;
    height: 647px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 36px;
    border: 2px solid #202540;
    @media (max-width: 1024px) {
      border-radius: 0;
      border: none;
      padding: 75px 24px;
      height: fit-content;
    }
    @media (max-width: 767px) {
      border-radius: 0;
      border: none;
      padding: 62px 24px;
      height: fit-content;
    }
  }
  .content {
    display: flex;
    width: 712px;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    @media (max-width: 767px) {
      width: fit-content;
    }
  }
  .title {
    color: #ffffff;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media (max-width: 767px) {
      color: Text/Text_Primary;
      text-align: center;

      /* Small_Title/Bold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
    }
  }
  .desc {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    margin-top: 12px;
    @media (max-width: 767px) {
      color: #fff;
      text-align: center;

      /* 14/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }
  .box-main {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
    }
  }
  .card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(12px);
  }
  .head-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
  }
  .title-card {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
  .coin-group {
    display: flex;
    align-items: center;
    > * + * {
      margin-left: -12px;
    }
  }
  .content-title {
    color: var(--Functional-Green, #40ffb7);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
  .content-desc {
    color: #fff;
    text-align: center;

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  .box-trade {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
  .item-trade {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Border-Radius-Medium, 12px);
    background: rgba(0, 0, 0, 0.3);
  }
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .img-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      height: 40px;
      width: 40px;
    }
  }
  .name {
    color: #fff;

    /* 14/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
  }
  .price {
    color: #fff;

    /* 14/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .box-percent {
    display: flex;
    align-items: center;
  }
  .percent-number {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
`;
