import styled from "styled-components";

export const PoolFrame = styled.div`
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 283.59px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .Pool-Head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin: 48px 0;
  }

  .Pool-HeadDetails {
    flex: 1;

    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: center;
    gap: 16px;

    img {
      width: 48px;
      height: 48px;
    }

    /* & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Border_radius, 4px);

    } */
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 150%; /* 36px */

      grid-column: 1/3;

      display: flex;
      align-items: center;
      gap: 8px;
    }

    p {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      grid-column: 1/3;
    }

    @media screen and (max-width: 767px) {
      gap: 8px;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .Pool-Links {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .Pool-Link {
    color: var(--Functional-Green, #7ee55c);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    text-decoration: none;

    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 4px;

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: #b7e399;
    }
  }

  .Pool-Papers {
    display: grid;
    grid-template-columns: auto 424px;
    gap: 24px;
  }

  .Pool-Paper {
    border-radius: 16px;
    background: var(--Nature-1, #1a1919);

    @media screen and (max-width: 767px) {
      padding: 16px !important;
    }
  }

  .Pool-PaperStats {
    display: flex;
    padding: 24px;
    align-items: stretch;
    gap: 24px;

    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      align-items: flex-start;
    }
  }

  .Pool-PaperStat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Border_radius, 4px);
    flex: 1 0 0;

    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    h5 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
  }

  .Pool-Aggregate {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }

  .Pool-Chart {
    flex: 1;
    border-radius: 16px;
    padding: 24px;
    height: fit-content;

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      margin-bottom: 16px;
    }

    .recharts-responsive-container {
      height: 270px !important;
    }

    .recharts-layer text {
      fill: #828899;
      font-size: 12px;
    }
  }

  .Pool-Composition {
    padding: 24px;

    .buy-tooltip.excep {
      @media screen and (max-width: 767px) {
        .Tooltip-popup {
          width: 290px !important;

          .text-white {
            text-wrap: balance;
          }
        }
      }
    }

    .App-card-info-title {
      font-weight: 500;
      color: #807e7c;
    }

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      margin-bottom: 16px;
    }

    .head .col-name:first-child,
    .body .col-body:first-child {
      min-width: 140px !important;
    }
  }

  .Pool-Form {
    height: fit-content;
    padding: 24px;

    .MintRedeemPage-box {
      padding: 0 !important
      ;
    }

    .Tab {
      background-color: #242323;
      margin-bottom: 16px;
    }

    .Tab-option {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      border: 2px solid transparent;

      &.active {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
        border-color: #4d4544;
      }
    }

    .Exchange-swap-section {
      background: #050505;
      gap: 12px;
      min-height: unset;
    }

    .Exchange-swap-input-container {
      margin: 0;
    }

    .AppOrder-ball {
      width: 36px;
      height: 36px;
      top: -3rem;
    }

    .PositionEditor-token-symbol {
      img {
        display: inline-block;
        margin-right: 4px;
      }
    }

    .selected-token,
    .TokenSelector-box {
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }

    .Exchange-swap-button-container {
      margin-top: 16px;
    }

    .TokenSelector {
      .Modal {
        justify-content: flex-end;

        @media screen and (max-width: 1023px) {
          justify-content: center;
        }
      }

      .Modal-content {
        width: 41.85rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        & > div:last-child {
          flex: 1;
          overflow-y: auto;
        }

        .Modal-body.DiableScroll-touch-move-container {
          max-height: unset;
          height: 100%;
        }
      }
    }

    .Exchange-swap-balance,
    .Exchange-swap-label {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }

  .App-card-title-info-icon {
    img {
      width: 32px;
      height: 32px;
    }
  }

  .token-symbol-wrapper {
    display: flex;
    align-items: center;
  }

  .App-card-info-subtitle {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1023px) {
    .Pool-Head {
      flex-direction: column;
      align-items: stretch;
      gap: 24px;
    }

    .Pool-Papers {
      grid-template-columns: 1fr;
    }

    .Pool-Form {
      order: -1;
    }
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const EarnFrame = styled.div`
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 283.59px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .border-btn {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Border-Radius-Medium, 12px) !important;
    border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));

    color: var(--Button-Text-Link, #7ee55c) !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }

  .Earn-Section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 48px;
  }

  .Section-Title {
    font-size: 36px;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
  }

  .Section-Desc {
    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin-top: 8px;
  }

  .Section-DoublePaper {
    display: flex;
    align-items: stretch;
    gap: 24px;
    align-self: stretch;
    margin-top: 24px;
  }

  .Section-Paper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    flex: 1 0 0;

    border-radius: 16px;
    background: var(--Nature-1, #1a1919);
  }

  .Section-PaperHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .Section-PaperHeadDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    margin-right: auto;
    margin-left: 12px;

    img {
      cursor: pointer;
      pointer-events: all;
    }

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */

      display: flex;
      align-items: center;
      gap: 8px;
    }

    p {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 12px;
      font-weight: 700;
      line-height: 140%; /* 16.8px */

      span {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }

  .Section-PaperHeadAction {
  }

  .Section-PaperBody {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    align-self: stretch;
  }

  .Section-PaperBodyTop {
    display: flex;
    padding: 16px;
    align-items: stretch;
    align-self: stretch;
    gap: 16px;

    border-radius: 16px;
    border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
  }

  .Section-PaperBodyTopItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    .Tooltip-handle,
    h5 {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }

    & > div {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
      }
    }
  }

  .Section-PaperBodyBottom {
    display: flex;
    align-items: stretch;
    gap: 12px;
    align-self: stretch;
  }

  .Section-PaperBodyBottomItem {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex: 1 0 0;

    border-radius: 16px;
    border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));

    span {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-align: center;
    }

    & > h5 {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      margin: 8px 0;

      /* & + span {
        margin-bottom: auto;
      } */
    }
  }

  .Section-PaperBodyBottomWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: auto;
  }

  .Section-PaperBodyBottomItemActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    gap: 8px;
    align-self: stretch;
    margin-top: 24px;

    &:has(.default-btn.full) {
      display: flex;
      grid-template-columns: unset;
    }
  }

  .Section-PaperBodyBottomItemRows {
    height: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 12px;
    font-weight: 500;
    line-height: 140%; /* 16.8px */

    .Tooltip-handle {
      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 12px;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
    }

    h5 {
      font-size: 12px;
      font-weight: 700;
      line-height: 140%; /* 16.8px */

      color: #fff;
      font-size: 12px;
      font-weight: 700;
      line-height: 140%; /* 16.8px */
    }
  }

  .Section-TriplePaper {
    display: flex;
    align-items: stretch;
    gap: 24px;
    align-self: stretch;
    margin-top: 24px;
  }

  .Section-SmallPaper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    border-radius: 16px;
    background: var(--Nature-1, #1a1919);
  }

  .Section-SmallPaperHead {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-bottom: 1px solid var(--Border, rgba(239, 236, 235, 0.08));

    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; /* 22.4px */

    img {
      width: 24px;
      height: 24px;
    }
  }

  .Section-SmallPaperBody {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    align-self: stretch;
  }

  .Section-SmallPaperBodyTop {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 16px;
    border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
  }

  .Section-SmallPaperBodyTopPair {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    img {
      width: 24px;
      height: 24px;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      color: var(--Text-Text_Secondary, #807e7c);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      h5 {
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */

        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
      }
    }
  }

  .Section-SmallPaperBodyBottom {
  }

  .Section-SmallPaperBodyBottomItemRows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .Section-SmallPaperBodyBottomItemRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    color: var(--Text-Text_Secondary, #807e7c);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */

    h5 {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }

  .Section-SmallPaperBodyBottomActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    gap: 12px;
    align-self: stretch;
    margin-top: 24px;
  }

  @media (max-width: 1024px) {
    .Section-DoublePaper {
      flex-direction: column;
    }

    .Section-TriplePaper {
      flex-direction: column;
    }
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;

    .Section-PaperHead {
      flex-wrap: wrap;
    }

    .Section-PaperHeadDetails {
      h3 {
        font-size: 14px;
      }
    }

    .Section-PaperBodyTop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      .default-btn {
        grid-column: 1/3;
      }
    }

    .Section-PaperHeadAction {
      min-width: 50%;
      flex: 1;
      margin-top: 12px;
    }

    .Section-PaperBodyBottom {
      flex-direction: column;
    }
  }

  .Buy-Head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin: 48px 0;
  }

  .Buy-HeadDetails {
    display: flex;
    align-items: center;
    gap: 16px;

    & > img {
      width: 48px;
      height: 48px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Border_radius, 4px);

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 36px */
      }

      p {
        color: var(--Text-Text_Secondary, #807e7c);
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
  }

  .Buy-Links {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .Buy-Link {
    color: var(--Functional-Green, #7ee55c);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */

    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: #b7e399;
    }
  }
`;
