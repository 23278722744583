import axios from "axios";

export const getCoinList = async () => {
  return await axios.get(
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,solana,ripple,the-open-network,dogecoin,chainlink,sui,cardano,arbitrum"
  );
};

export const getCoinListYummy = async () => {
  return await axios.get("https://api.gummy.exchange/v2/bartio/assets");
};
