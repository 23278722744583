import React, { memo, useCallback, useEffect, useMemo } from "react";
import Modal from "components/Modal/Modal";
import styled from "styled-components";
import LinkIcon from "components/Icon/TradeIcon/LinkIcon";
import SuccessIcon from "components/Icon/TradeIcon/SuccessIcon";
import FailIcon from "components/Icon/TradeIcon/FailIcon";
import { useMedia } from "react-use";
import { useLiquidationsData, useTrades } from "domain/legacy";
import {
  BASIS_POINTS_DIVISOR,
  CHAIN_ID_LOCAL,
  LIQUIDATION_FEE,
  MAX_LEVERAGE,
  SWAP,
  USD_DECIMALS,
  deserialize,
  getExchangeRateDisplay,
} from "lib/legacy";
import { select } from "@lingui/macro";
import { getContract } from "config/contracts";
import { getNormalizedTokenSymbol, getTokenLowerCase, getTokenSymbol } from "config/tokens";
import { formatAmount, bigNumberify } from "lib/numbers";
import Tooltip from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { ethers } from "ethers";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getExplorerUrl } from "config/chains";

const { AddressZero } = ethers.constants;

const RecentTransactionFireBirdModal = (props) => {
  const { chainId, transactions, isOpen, setIsOpen, className, onClearAll } = props;
  const isMobile = useMedia("(max-width: 1110px)");

  // console.log("???", transactions);

  return (
    <Modal
      label="Recent Transactions"
      isVisible={isOpen}
      setIsVisible={setIsOpen}
      className={className}
      children={
        <ChildrenModalWrapper className="children-modal-wrapper">
          {transactions.length > 0 && (
            <div className="btn-clear-all">
              <div
                onClick={() => {
                  onClearAll && onClearAll();
                }}
              >
                Clear all
              </div>
            </div>
          )}
          {transactions.length > 0 &&
            transactions.map((trade) => {
              const item = trade;
              return (
                <div className="transaction-item-group">
                  <div className="transaction-content">
                    <span>{item.message}</span>
                    <ExternalLink href={getExplorerUrl(chainId) + "tx/" + item.hash} className="link-icon">
                      <LinkIcon />
                    </ExternalLink>
                  </div>
                  <div className="transaction-status">
                    <SuccessIcon />
                  </div>
                </div>
              );
            })}
          {transactions.length === 0 && <div className="no-data-card">No recent transactions</div>}
        </ChildrenModalWrapper>
      }
    />
  );
};

export default memo(RecentTransactionFireBirdModal);

const ChildrenModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .btn-clear-all {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: end;
    div {
      color: #c1ff99 !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  .transaction-item-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
  }
  .transaction-content {
    display: flex;
    align-items: center;
  }
  .link-icon {
    cursor: pointer;
    &:hover {
      color: #c1ff99 !important;
      transform: scale(1.1);
    }
  }
`;
