import { ImageMoonBera } from "assets";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
const CexcellentDexHero = () => {
  const listHero = [
    {
      title: "Incredible Speed",
      desc: "Enjoy rapid trade execution and instant access to your earnings with Berachain's lightning-fast finality.",
    },
    {
      title: "Deep Liquidity",
      desc: "Experience optimal trade execution for any order size, guaranteed near-zero slippage, and minimal fees.",
    },
    {
      title: "Alignment system",
      desc: "Utilize a diverse range of tokens as collateral for trading and passive income generation.",
    },
    {
      title: "Best-in-class risk control",
      desc: "Enjoy peace of mind while maximizing profits with our CEX-like features designed to safeguard your position.",
    },
  ];
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100, damping: 15, duration: 0.8 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100, damping: 15, duration: 0.7 },
    },
  };
  return (
    <Wrapper>
      <div className="container wrapper">
        <div className="box-left">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 15, duration: 1 }}
          >
            <MoonWrapper>
              <img className="moon" src={ImageMoonBera} alt="Bera Moon" />
              <GradientOverlay />
            </MoonWrapper>
          </motion.div>
          <motion.div className="box-text-left" variants={titleVariants} initial="hidden" whileInView="visible">
            <div className="title">The CEX-cellent DEX on Berachain</div>
            <div className="desc">Experience the speed and security of a CEX, on-chain.</div>
          </motion.div>
        </div>
        <div className="box-right">
          <div className="column">
            {listHero.slice(0, 2).map((item, index) => (
              <motion.div
                key={index}
                className="hero-item"
                variants={itemVariants}
                initial="hidden"
                whileInView="visible"
              >
                <div>
                  <div className="title-hero">{item.title}</div>
                  <div className="desc-hero">{item.desc}</div>
                </div>
                <button
                  style={{
                    width: "fit-content",
                  }}
                  disabled
                  className="btn-fill"
                >
                  Learn more
                </button>
              </motion.div>
            ))}
          </div>
          <div className="column offset">
            {listHero.slice(2, 4).map((item, index) => (
              <motion.div
                key={index}
                className="hero-item"
                variants={itemVariants}
                initial="hidden"
                whileInView="visible"
              >
                <div>
                  <div className="title-hero">{item.title}</div>
                  <div className="desc-hero">{item.desc}</div>
                </div>
                <button
                  style={{
                    width: "fit-content",
                  }}
                  disabled
                  className="btn-fill"
                >
                  Learn more
                </button>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CexcellentDexHero;

const Wrapper = styled.div`
  margin-top: 160px;
  @media (max-width: 1024px) {
    margin-top: 120px;
    padding: 0 24px;
  }
  @media (max-width: 767px) {
    margin-top: 120px;
    padding: 0 16px;
  }
  .wrapper {
    display: grid;
    align-items: center;
    gap: 26px;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    @media (max-width: 1024px) {
      row-gap: 96px;
      column-gap: 26px;
    }
  }
  .box-left {
    position: relative;
    grid-column: span 5 / span 5;
    @media (max-width: 1024px) {
      grid-column: span 12 / span 12;
    }
  }
  .box-text-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: absolute;
    bottom: -16%;
  }
  .title {
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 78.4px */
    background: linear-gradient(90deg, #3f8ffd 0%, #f8fff8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 767px) {
      /* Title/Bold */
      font-family: Inter;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }
    .cex-text {
      color: var(--Primary, #72cc37);
      background: none;
      -webkit-text-fill-color: currentColor;
    }
  }
  .desc {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
    @media (max-width: 767px) {
      color: #fff;

      /* 16/Medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
  }
  .box-right {
    grid-column: span 7 / span 7;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    @media (max-width: 1024px) {
      grid-column: span 12 / span 12;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1 0%;
  }
  .column.offset {
    margin-top: 36px;
  }
  .hero-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    align-self: stretch;
    border-radius: 24px;
    border: 2px solid rgba(70, 202, 253, 0.2);

    @media (max-width: 767px) {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 26px;
      align-self: stretch;
      border-radius: 16px;
    }
  }
  .title-hero {
    color: Text/Text_Primary;

    /* 16/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    margin-bottom: 11px;
  }
  .desc-hero {
    color: var(--Text-Text_Secondary, #667180);

    /* 16/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media (max-width: 767px) {
      /* 14/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }
  .btn {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Button-Background, #c1ff99);
    color: var(--Button-Text, #33801a);
    width: fit-content;

    /* 14/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    outline: none;
    :hover {
      background: var(--Button-Background, #a5f372);
    }
  }
`;

const MoonWrapper = styled.div`
  position: relative;
  width: fit-content;
  overflow: hidden;
  .moon {
    @media (max-width: 767px) {
      width: 286.157px;
      height: 295.449px;
    }
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  inset: -2px;
  background: linear-gradient(-15deg, #000 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.4) 70%, transparent 100%);
  mask-image: url(${ImageMoonBera});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
`;
