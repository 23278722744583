import React, { useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from "react";
import { Trans, t, Plural } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { ethers } from "ethers";
import cx from "classnames";
import ArrowUp from "img/icons/icon-up.svg";
import ChartPad from "img/icons/chart-pad.svg";
import ChartPadLight from "img/chart-pad-light.svg";
import {
  FUNDING_RATE_PRECISION,
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  getPositionKey,
  getPositionContractKey,
  getLeverage,
  getDeltaStr,
  useAccountOrders,
  getPageTitle,
} from "lib/legacy";
import { getConstant, getExplorerUrl } from "config/chains";
import { approvePlugin, useMinExecutionFee, cancelMultipleOrders } from "domain/legacy";

import { getContract } from "config/contracts";

import Reader from "abis/ReaderV2.json";
import VaultV2 from "abis/VaultV2.json";
import Router from "abis/Router.json";
import Token from "abis/Token.json";

import Checkbox from "components/Checkbox/Checkbox";
import SwapBox from "components/Exchange/SwapBox";
import ExchangeTVChart, { getChartToken } from "components/Exchange/ExchangeTVChart";
import PositionsList from "components/Exchange/PositionsList";
import OrdersList from "components/Exchange/OrdersList";
import TradeHistory from "components/Exchange/TradeHistory";
import ExchangeWalletTokens from "components/Exchange/ExchangeWalletTokens";
import ExchangeBanner from "components/Exchange/ExchangeBanner";
import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";

import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo } from "domain/tokens/utils";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import TradingView from "../../components/Exchange/TradingView";
import TradeHistoryV2 from "components/Exchange/TradeHistoryV2";
import OrdersListV2 from "components/Exchange/OrdersListV2";
import ExchangeTVChartPad from "components/Exchange/ExchangeTVChartPad";
import { useMediaQuery } from "react-responsive";
import SwapBoxSwap from "components/Exchange/SwapBoxSwap";
import ExchangeTVChartPadSwap from "components/Exchange/ExchangeTVChartPadSwap";
import ExchangeTVChartSwap from "components/Exchange/ExchangeTVChartSwap";
import TradeHistorySwap from "components/Exchange/TradeHistorySwap";
import styled from "styled-components";
import "styles/Spot.scss";
import { useThemeContext } from "contexts/ThemeProvider";

const { AddressZero } = ethers.constants;

const PENDING_POSITION_VALID_DURATION = 600 * 1000;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;

const notifications = {};

function pushSuccessNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function pushErrorNotification(chainId, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainId) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}{" "}
      <ExternalLink href={txUrl}>
        <span>View</span>
      </ExternalLink>
    </div>
  );
}

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data;
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  }
  return;
}

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};

function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return;
  }
  const { key } = position;

  if (
    pendingPositions[key] &&
    pendingPositions[key].updatedAt &&
    pendingPositions[key].pendingChanges &&
    pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
  ) {
    const { pendingChanges } = pendingPositions[key];
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return;
    }

    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return;
    }

    position.hasPendingChanges = true;
    position.pendingChanges = pendingChanges;
  }
}

export function getPositions(
  chainId,
  positionQuery,
  positionData,
  infoTokens,
  includeDelta,
  showPnlAfterFees,
  account,
  pendingPositions,
  updatedPositions
) {
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions = [];
  const positionsMap = {};
  if (!positionData) {
    return { positions, positionsMap };
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    }

    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
    };

    if (
      updatedPositions &&
      updatedPositions[key] &&
      updatedPositions[key].updatedAt &&
      updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
    ) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }

    let fundingFee = getFundingFee(position);
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);

    position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
    position.totalFees = position.positionFee.add(position.fundingFee);

    position.pendingDelta = position.delta;

    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral);

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit,
      });

      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta.gt(position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
      }

      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
        .mul(BASIS_POINTS_DIVISOR)
        .div(position.collateral);

      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees,
      });

      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
      }

      let netValue = position.hasProfit
        ? position.collateral.add(position.pendingDelta)
        : position.collateral.sub(position.pendingDelta);

      netValue = netValue.sub(position.fundingFee);

      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee);
      }

      position.netValue = netValue;
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
    });

    positionsMap[key] = position;

    applyPendingChanges(position, pendingPositions);

    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position);
    }
  }

  return { positions, positionsMap };
}

export function getPositionQuery(tokens, nativeTokenAddress) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
    indexTokens.push(getTokenAddress(token, nativeTokenAddress));
    isLong.push(true);
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token, nativeTokenAddress));
      isLong.push(false);
    }
  }

  return { collateralTokens, indexTokens, isLong };
}

export const SwapV2 = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    connectWallet,
    savedShouldDisableValidationForTesting,
    setIsSettingsSlippageVisible,
  } = props;
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey("showBanner", true);
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey("bannerHidden", null);

  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});
  const [showChart, setShowChart] = useState(false);
  const hideBanner = () => {
    const hiddenLimit = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };
  const isPadScreen = useMediaQuery({ query: "(max-width: 1110px)" });
  const isMobileScreen = useMediaQuery({ query: "(max-width: 700px)" });
  useEffect(() => {
    if (new Date() > new Date("2021-11-30")) {
      setShowBanner(false);
    } else {
      if (bannerHidden && new Date(bannerHidden) > new Date()) {
        setShowBanner(false);
      } else {
        setBannerHidden(null);
        setShowBanner(true);
      }
    }
  }, [showBanner, bannerHidden, setBannerHidden, setShowBanner]);

  const { active, account, library } = useWeb3React();
  const { chainId } = useChainId();
  const currentAccount = account;

  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const readerAddress = getContract(chainId, "Reader");
  const usdgAddress = getContract(chainId, "USDG");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress);
  const theme = useThemeContext();
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol]
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    "swap-Exchange-token-selection-v3",
    defaultTokenSelection
  );
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, "swap-v1-Swap-option", SWAP);

  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const tokens = getTokens(chainId);

  const tokenAddresses = tokens.map((token) => token.address);
  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, "getTokenBalances", account], {
    fetcher: contractFetcher(library, Reader, [tokenAddresses]),
  });

  const { data: positionData, error: positionDataError } = useSWR(
    active && [active, chainId, readerAddress, "getPositions", vaultAddress, account],
    {
      fetcher: contractFetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    }
  );

  const positionsDataIsLoading = active && !positionData && !positionDataError;

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, "getFundingRates"], {
    fetcher: contractFetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  });

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  const { data: usdgSupply } = useSWR([`Exchange:usdgSupply:${active}`, chainId, usdgAddress, "totalSupply"], {
    fetcher: contractFetcher(library, Token),
  });

  const orderBookAddress = getContract(chainId, "OrderBook");
  const routerAddress = getContract(chainId, "Router");
  const { data: orderBookApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, orderBookAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { data: positionRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, "approvedPlugins", account, positionRouterAddress],
    {
      fetcher: contractFetcher(library, Router),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo);

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens
  );

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId);
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true);
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`);
    document.title = title;
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress]);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;
      let message;
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD into ${tokenSymbol} ${longOrShortText}`;
      } else {
        message = `Increased ${tokenSymbol} ${longOrShortText}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onDecreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return;
      }

      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD from ${tokenSymbol} ${longOrShortText}.`;
      } else {
        message = `Decreased ${tokenSymbol} ${longOrShortText}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD.`;
      }

      pushSuccessNotification(chainId, message, e);
    },

    onCancelIncreasePosition(
      account,
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not increase ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;
      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account,
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (account !== currentAccount) {
        return;
      }
      const indexTokenItem = getToken(chainId, indexToken);
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexTokenItem.symbol;
      const longOrShortText = isLong ? `Long` : `Short`;

      const message = `Could not decrease ${tokenSymbol} ${longOrShortText} within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainId, message, e);

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong);
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const flagOrdersEnabled = true;
  const [ordersData] = useAccountOrders(flagOrdersEnabled);
  const orders = (ordersData && ordersData.filter((x) => x.type === SWAP)) || [];
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false);
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);
  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  });
  const [isProChart, setIsProChart] = useState(false);

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(chainId, library, cancelOrderIdList, {
          successMsg: `Orders cancelled.`,
          failMsg: `Cancel failed.`,
          sentMsg: `Cancel submitted.`,
          pendingTxns,
          setPendingTxns,
        });
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainId,
      library,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ]
  );

  const approveOrderBook = () => {
    setIsPluginApproving(true);
    return approvePlugin(chainId, orderBookAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg: `Enable orders sent.`,
      failMsg: `Enable orders failed.`,
    })
      .then(() => {
        setIsWaitingForPluginApproval(true);
      })
      .finally(() => {
        setIsPluginApproving(false);
      });
  };

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    setIsPositionRouterApproving(true);
    return approvePlugin(chainId, positionRouterAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true);
      })
      .finally(() => {
        setIsPositionRouterApproving(false);
      });
  };
  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trade";

  const LIST_SECTIONS = [flagOrdersEnabled && ORDERS, TRADES].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, "swap-List-section-v1", LIST_SECTIONS[0]);
  const LIST_SECTIONS_LABELS = {
    [ORDERS]: orders.length ? `Orders (${orders.length})` : `Orders`,
    [POSITIONS]: positions.length ? `Positions (${positions.length})` : `Positions`,
    [TRADES]: `Swap History`,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }

  if (!getToken(chainId, toTokenAddress)) {
    return null;
  }

  const renderCancelOrderButton = () => {
    if (cancelOrderIdList.length === 0) return;
    return (
      <button
        className="muted font-base cancel-order-btn"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        <Plural value={cancelOrderIdList.length} one="Cancel order" other="Cancel # orders" />
        {cancelOrderIdList.length === 1 ? "Cancel order" : `Cancel ${cancelOrderIdList.length} orders`}
      </button>
    );
  };

  const getListSection = () => {
    return (
      <div className="section-with-scroll">
        <div className="Exchange-list-tab-container">
          <Tab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
            type="inline"
            className="Exchange-list-tabs"
          />
          <div className="align-right Exchange-should-show-position-lines">
            {renderCancelOrderButton()}
            {!isProChart ? (
              <Checkbox
                isChecked={savedShouldShowPositionLines}
                setIsChecked={setSavedShouldShowPositionLines}
                className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
              >
                <div className="group-actions">
                  <span>Chart positions</span>
                </div>
              </Checkbox>
            ) : null}

            {/* <div className="group-switch"
              onClick={() => setIsProChart(!isProChart)}
            >
              <div style={{
                color: !isProChart ? '#ffffff' : 'rgb(117, 117, 143)'
              }}>Basic</div>
              <span>/</span>
              <div
                style={{
                  color: isProChart ? "#ffffff" : "rgb(117, 117, 143)",
                }}
              >Pro</div>
            </div> */}
          </div>
        </div>

        <div className="group-switch-mobile" onClick={() => setIsProChart(!isProChart)}>
          <div
            style={{
              color: !isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Basic
          </div>
          <span>/</span>
          <div
            style={{
              color: isProChart ? "#ffffff" : "rgb(117, 117, 143)",
            }}
          >
            Pro
          </div>
        </div>
        {listSection === ORDERS && (
          <OrdersListV2
            account={account}
            active={active}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            chainId={chainId}
            orders={orders}
            totalTokenWeights={totalTokenWeights}
            usdgSupply={usdgSupply}
            savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
            cancelOrderIdList={cancelOrderIdList}
            setCancelOrderIdList={setCancelOrderIdList}
          />
        )}
        {listSection === TRADES && (
          <TradeHistorySwap
            account={account}
            forSingleAccount={true}
            infoTokens={infoTokens}
            getTokenInfo={getTokenInfo}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            shouldShowPaginationButtons={true}
          />
        )}
      </div>
    );
  };

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
  };

  const renderChart = () => {
    return (
      <ExchangeTVChartSwap
        isProChart={isProChart}
        chartToken={chartToken}
        setChartToken={setChartToken}
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        infoTokens={infoTokens}
        swapOption={swapOption}
        chainId={chainId}
        positions={positions}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        orders={orders}
        setToTokenAddress={setToTokenAddress}
        isSwap={true}
      />
    );
  };

  return (
    <WrapperContainer className="swap-v2-wrapper">
      <div className="Exchange page-layout Exchange-updated-ui exchange-v1 swap-v1-container">
        {showBanner && <ExchangeBanner hideBanner={hideBanner} />}
        <HeaderTitle>Spot native</HeaderTitle>
        <div className={`Exchange-content ${showChart ? "chart-pad-show-content" : ""}`}>
          <div className="Exchange-left">
            {renderChart()}
            {isProChart ? <TradingView symbol={chartToken?.symbol} /> : null}
            <div className="Exchange-lists large">{getListSection()}</div>
          </div>
          <div className="Exchange-right swap-box-swap-container">
            <SwapBoxSwap
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              orders={orders}
              flagOrdersEnabled={flagOrdersEnabled}
              chainId={chainId}
              infoTokens={infoTokens}
              active={active}
              connectWallet={connectWallet}
              library={library}
              account={account}
              positionsMap={positionsMap}
              fromTokenAddress={fromTokenAddress}
              setFromTokenAddress={setFromTokenAddress}
              toTokenAddress={toTokenAddress}
              setToTokenAddress={setToTokenAddress}
              swapOption={swapOption}
              setSwapOption={setSwapOption}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              tokenSelection={tokenSelection}
              setTokenSelection={setTokenSelection}
              isConfirming={isConfirming}
              setIsConfirming={setIsConfirming}
              isPendingConfirmation={isPendingConfirmation}
              setIsPendingConfirmation={setIsPendingConfirmation}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
              nativeTokenAddress={nativeTokenAddress}
              savedSlippageAmount={savedSlippageAmount}
              totalTokenWeights={totalTokenWeights}
              usdgSupply={usdgSupply}
              savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
              setChartToken={setChartToken}
              setIsSettingsSlippageVisible={setIsSettingsSlippageVisible}
              chartToken={chartToken}
            />
            <div className="Exchange-wallet-tokens">
              <div className="Exchange-wallet-tokens-content">
                <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />
              </div>
            </div>
          </div>
          <div className="Exchange-lists small">{getListSection()}</div>
        </div>
        {isPadScreen && (
          <div className="pad-chart-container">
            <div className="show-ui-content">
              <div className="index-chart">
                <img src={theme?.isLightTheme ? ChartPadLight : ChartPad} alt="" />{" "}
                {isMobileScreen ? "Chart" : "Index chart"}
              </div>
              <div className="chart-tick-container">
                {!isProChart && showChart ? (
                  <Checkbox
                    isChecked={savedShouldShowPositionLines}
                    setIsChecked={setSavedShouldShowPositionLines}
                    className={cx("muted chart-positions", { active: savedShouldShowPositionLines })}
                  >
                    <div className="group-actions">
                      <span>Chart positions</span>
                    </div>
                  </Checkbox>
                ) : null}
                <div className="view-chart" onClick={() => setShowChart(!showChart)}>
                  {showChart ? "Hide" : "Show"} chart
                  <img style={showChart ? { transform: "rotate(180deg)" } : {}} src={ArrowUp} alt="" />
                </div>
              </div>
            </div>
            <div style={!showChart ? { display: "none" } : {}}>
              <ExchangeTVChartPadSwap
                isProChart={isProChart}
                chartToken={chartToken}
                setChartToken={setChartToken}
                fromTokenAddress={fromTokenAddress}
                toTokenAddress={toTokenAddress}
                infoTokens={infoTokens}
                swapOption={swapOption}
                chainId={chainId}
                positions={positions}
                savedShouldShowPositionLines={savedShouldShowPositionLines}
                orders={orders}
                setToTokenAddress={setToTokenAddress}
              />
            </div>
          </div>
        )}

        {/* <Footer /> */}
      </div>
    </WrapperContainer>
  );
});

const WrapperContainer = styled.div`
  width: 100%;
  .Exchange {
    display: flex;
    flex-direction: column;
    max-width: 1320px;
    margin: auto;
    width: 100%;
    margin-top: 24px;
  }
  .Exchange-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    gap: 24px;
    margin-top: 16px !important;
  }
  .Exchange-left {
    width: 100%;
    margin-bottom: 40px;
  }
  .Exchange-right {
    border-left: none !important;
  }
  .Exchange-swap-box {
    flex: none !important;
  }
  .ExchangeChart {
    background-color: #141312;
    border-radius: 16px;
  }
  .Exchange-updated-ui .Exchange-content .ExchangeChart-bottom {
    top: 95px !important;
    left: 24px !important;
    right: 24px !important;
    bottom: 24px !important;
  }
  .ExchangeChart.tv .ExchangeChart-top {
    padding: 16px 24px !important;
    height: auto !important;
    border-bottom: none !important;
  }
  .exchange-section-first {
    margin-bottom: 16px !important;
  }
  .Exchange-swap-ball {
    @media screen and (min-width: 701px) {
      top: -26px !important;
    }
  }
  .Tab-option {
    font-size: 14px !important;
    &:hover {
      background: hsla(0, 0%, 100%, 0.2);
    }
  }
  .exchange-v1 .Exchange-list-tabs .Tab-option {
    font-size: 14px !important;
  }
  .swap-box-v2 {
    height: auto !important;
    padding: 24px;
    border-bottom: none !important;
    padding-bottom: 0px;
  }
  .Exchange-swap-box-inner {
    padding: 24px;
    padding-top: 16px;
  }
  .Exchange-swap-box-info {
    margin-bottom: 24px;
  }
  .Exchange-swap-box {
    max-width: 424px;
    width: 100%;
  }
  .Exchange-swap-section {
    margin-bottom: 16px !important;
  }
  .Exchange-lists.large {
    padding: 0;
    margin-top: 24px;
  }
  .box-trade-swap {
    border-radius: 16px !important;
  }
  .exchange-v1 {
    height: auto !important;
  }
  .Exchange-should-show-position-lines {
    display: none;
  }
  .no-trade-history {
    margin-top: 10px !important;
  }
  .Exchange-swap-max {
    outline: none !important;
  }
  .PositionEditor-token-symbol {
    font-size: 16px !important;
  }

  @media screen and (max-width: 1110px) {
    .Exchange {
      max-width: 534px;
      justify-content: normal;
    }
    .Exchange-content {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      margin-top: 0 !important;
      gap: 16px;
      height: auto !important;
    }
    .Exchange-left {
      display: none;
    }
    .Exchange-right {
      flex-direction: column;
      width: 100%;
    }
    .Exchange-lists.small {
      display: flex;
      flex-direction: column;
      padding-bottom: 80px !important;
    }
    .swap-box-v2 {
      padding: 16px;
    }
    .Exchange-swap-box-inner {
      padding: 16px;
    }

    .Exchange-lists {
      padding: 0 !important;
    }
    .Exchange-swap-box {
      max-width: none;
    }
    .Exchange-list-tab-container {
      margin-bottom: 16px !important;
    }
  }
  @media screen and (max-width: 767px) {
    .Exchange-content {
      padding: 0 !important;
    }
    .Exchange-lists {
      padding: 0 16px !important;
    }
    .Exchange-list-tab-container {
      margin-top: 0 !important;
    }
    .Exchange-updated-ui {
      padding-top: 24px !important;
    }
    .Exchange-swap-box-info {
      margin-bottom: 16px;
    }
  }
`;
const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  @media screen and (max-width: 767px) {
    padding: 0px 16px;
  }
`;
