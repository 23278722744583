import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useGmyPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import arbIcon from "img/ic_arb_24.svg";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  GLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import gmyIcon from "img/ic_gmy_custom.svg";
import GMY_FTM from "img/GMY-FTM.svg";
import GMY_ETH from "img/GMY-ETH.svg";
import tokensIcon from "img/tokens.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import GlpManager from "abis/GlpManager.json";
import VaultStrategyGMY from "abis/VaultStrategyGMY.json";
import VaultStrategyGLP from "abis/VaultStrategyGLP.json";
import VaultStrategyGMY_WFTM_OLD from "abis/VaultStrategyGMY_WFTM_OLD.json";
import VaultStrategyGMY_WFTM from "abis/VaultStrategyGMY_WFTM.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment, { now } from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useChainId } from "lib/chains";
import { FANTOM, OP } from "config/chains";
import { Trans } from "@lingui/react";
import RouterTab from "components/RouterTab";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";

export const GMY_VAULT = "GMY vault";
export const GLP_VAULT = "GLP vault";
export const GMY_WFTM_OLD_VAULT = "GMY_WFTM_OLD vault";
export const GMY_WFTM_VAULT = "GMY_WFTM vault";
export const GMY_WETH_VAULT = "GMY_WETH vault";

const TAB_ICONS = {
  [GMY_VAULT]: gmyIcon,
  [GLP_VAULT]: tokensIcon,
  [GMY_WFTM_OLD_VAULT]: GMY_FTM,
  [GMY_WFTM_VAULT]: GMY_FTM,
  [GMY_WETH_VAULT]: GMY_ETH,
};

const StyledTab = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 22.5px;
  background: #11121b;
  border-radius: 16px;
  width: fit-content;
  display: inline-flex;
  padding: 8px;

  @media (max-width: 768px) {
    width: 100%;

    display: flex !important;
    flex-wrap: wrap;

    height: fit-content;

    .Tab-option {
      width: 50%;

      padding: 8px !important;
    }
  }

  > div {
    height: 45px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    &:nth-child(2) {
      img {
        width: 36px;
      }
    }
  }
`;

export default function Vault(props) {
  const isMobile = useMedia("(max-width: 600px)");
  const { lightThemeClassName } = useThemeContext();
  const { chainId } = useChainId();

  const [activeTab, setActiveTab] = useState(chainId !== OP ? GMY_VAULT : GMY_WETH_VAULT);
  const TAB_OPTION_LABELS = {
    [GMY_VAULT]: `GMY`,
    [GLP_VAULT]: `GLP`,
    [GMY_WFTM_OLD_VAULT]: `GMY-WFTM V1`,
    [GMY_WFTM_VAULT]: `GMY-WFTM`,
    [GMY_WETH_VAULT]: `GMY-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsGMYWFTM, setStatsGMYWFTM] = useState({
    price: 0,
    apr: 10,
  });

  const fetchStatsGMYWFTM = async () => {
    const res = await axios.get(`https://api.gummy.exchange/api/tokens/info?symbols=GMY/FTM&chain=FANTOM`, {});

    if (res && res.data) {
      const { price } = res.data["GMY/FTM"];

      setStatsGMYWFTM({
        ...statsGMYWFTM,
        price,
      });
    }
  };

  useEffect(() => {
    fetchStatsGMYWFTM();

    const interval = setInterval(async () => {
      fetchStatsGMYWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const TAB_OPTIONS = chainId !== OP ? [GMY_VAULT, GLP_VAULT] : [GMY_WETH_VAULT];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultGMYAddress = getContract(chainId, "VaultGMY");
  const vaultGLPAddress = getContract(chainId, "VaultGLP");

  const currentVaultAddress =
    activeTab === GMY_VAULT
      ? vaultGMYAddress
      : activeTab === GLP_VAULT
      ? vaultGLPAddress
      : activeTab === GMY_WFTM_VAULT;

  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const gmyAddress = getContract(chainId, "GMY");
  const esGmyAddress = getContract(chainId, "ES_GMY");
  const bnGmyAddress = getContract(chainId, "BN_GMY");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");

  const stakedGmyTrackerAddress = getContract(chainId, "StakedGmyTracker");
  const bonusGmyTrackerAddress = getContract(chainId, "BonusGmyTracker");
  const feeGmyTrackerAddress = getContract(chainId, "FeeGmyTracker");

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(chainId, "FeeGlpTracker");

  const glpManagerAddress = getContract(chainId, "GlpManager");

  const gmyVesterAddress = getContract(chainId, "GmyVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const vesterAddresses = [gmyVesterAddress, glpVesterAddress];

  const walletTokens = [gmyAddress, esGmyAddress, glpAddress, stakedGmyTrackerAddress];
  const depositTokens = [
    gmyAddress,
    esGmyAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    bnGmyAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmyTrackerAddress,
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGmyTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmyTrackerAddress,
    bonusGmyTrackerAddress,
    feeGmyTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesESGMY } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0xc55E86aF2F11e912a48e47f82a51eA782e082ed2" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesGLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x703Dc0F50fEB31F040F92158A2dd571DB1A472B2" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmySupply } = useSWR(
    [`StakeV2:stakedGmySupply:${active}`, chainId, gmyAddress, "balanceOf", stakedGmyTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  //GMY
  const { data: strategyAddressGMY } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultGMYAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestGMY } = useSWR(
    [`StakeV2:lastHarvestGMY:${active}`, chainId, strategyAddressGMY, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyGMY),
    }
  );
  const { data: balanceOfGMY } = useSWR(
    () => [`StakeV2:balanceOfGMY:${active}:${account}`, chainId, vaultGMYAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareGMY } = useSWR(
    () => [`StakeV2:getPricePerFullShareGMY:${active}:${account}`, chainId, vaultGMYAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyGMY } = useSWR([`StakeV2:totalSupplyGMY:${active}`, chainId, vaultGMYAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  //GLP
  const { data: strategyAddressGLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultGLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestGLP } = useSWR(
    [`StakeV2:lastHarvestGLP:${active}`, chainId, strategyAddressGLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyGLP),
    }
  );
  const { data: balanceOfGLP } = useSWR(
    [`StakeV2:balanceOfGLP:${active}:${account}`, chainId, vaultGLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareGLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareGLP:${active}:${account}`, chainId, vaultGLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyGLP } = useSWR([`StakeV2:totalSupplyGLP:${active}`, chainId, vaultGLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  // GMY_WFTM

  // const { data: strategyAddressGMY_WFTM } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultGMY_WFTMAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestGMY_WFTM } = useSWR(
  //   [`StakeV2:lastHarvestGMY:${active}`, chainId, strategyAddressGMY_WFTM, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyGMY_WFTM),
  //   }
  // );

  // GMY_WFTM_OLD

  // const { data: strategyAddressGMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultGMY_WFTM_OLDAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestGMY_WFTM_OLD } = useSWR(
  //   [`StakeV2:lastHarvestGMY:${active}`, chainId, strategyAddressGMY_WFTM_OLD, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyGMY_WFTM),
  //   }
  // );

  const tokensForSupplyQuery = [gmyAddress, glpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { gmyPrice } = useGmyPrice(chainId, {}, active);

  let gmySupply;
  if (totalSupplies && totalSupplies[1]) {
    gmySupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);

  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESGMY = getDepositBalanceData(depositBalancesESGMY);
  const depositBalanceDataGLP = getDepositBalanceData(depositBalancesGLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const amountTokenDeposit =
    activeTab === GMY_VAULT
      ? formatAmountFree(balanceOfGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmountFree(balanceOfGLP, 18)
      : activeTab === GMY_WFTM_VAULT;
  const rateWithdraw =
    activeTab === GMY_VAULT
      ? formatAmountFree(pricePerFullShareGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmountFree(pricePerFullShareGLP, 18)
      : activeTab === GMY_WFTM_VAULT;
  const amountTVL =
    activeTab === GMY_VAULT
      ? formatAmount(totalSupplyGMY, 18)
      : activeTab === GLP_VAULT
      ? formatAmount(totalSupplyGLP, 18)
      : activeTab === GMY_WFTM_VAULT;
  const amountLastHarvest =
    activeTab === GMY_VAULT
      ? lastHarvestGMY
        ? moment(Number(lastHarvestGMY?.toString() || "") * 1000).fromNow()
        : "0"
      : activeTab === GLP_VAULT
      ? lastHarvestGLP
        ? moment(Number(lastHarvestGLP?.toString() || "") * 1000).fromNow()
        : "0"
      : // : lastHarvestGMY_WFTM ? moment(Number(lastHarvestGMY_WFTM?.toString() || "") * 1000).fromNow() : "0"; // 3 vault
      lastHarvestGLP
      ? moment(Number(lastHarvestGLP?.toString() || "") * 1000).fromNow()
      : "0"; // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 4080) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmySupply,
    gmyPrice,
    gmySupply
  );

  let nameToken = "gmyBalance";

  switch (activeTab) {
    case GMY_VAULT:
      nameToken = "gmyBalance";
      break;

    case GLP_VAULT:
      nameToken = "glpBalance";
      break;

    case GMY_WFTM_OLD_VAULT:
      nameToken = "gmy_wftmBalance";
      break;

    case GMY_WFTM_VAULT:
      nameToken = "gmy_wftmBalance";
      break;

    case GMY_WETH_VAULT:
      nameToken = "gmy_wethBalance";
      break;

    default:
      nameToken = "gmyBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);

  if (processedData && vestingData && processedData?.glpBalance) {
    if (activeTab === GLP_VAULT) {
      balance = formatAmountFree(processedData?.glpBalance.sub(vestingData?.glpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const priceToken =
    activeTab === GMY_VAULT
      ? Number(formatAmount(gmyPrice, USD_DECIMALS))
      : activeTab === GLP_VAULT
      ? Number(formatKeyAmount(processedData, "glpPrice", USD_DECIMALS, 3, true))
      : statsGMYWFTM.price;

  const esGmyInStakedESGMY =
    depositBalanceDataESGMY &&
    gmyPrice &&
    depositBalanceDataESGMY["esGmyInStakedGmy"].mul(gmyPrice).div(expandDecimals(1, GLP_DECIMALS));

  const esGmyInStakedGLP =
    depositBalanceDataGLP &&
    gmyPrice &&
    depositBalanceDataGLP["esGmyInStakedGmy"].mul(gmyPrice).div(expandDecimals(1, GLP_DECIMALS));

  const aprFarm = Number(
    formatKeyAmount(
      processedData,
      activeTab === GMY_VAULT ? "gmyAprForNativeToken" : "glpAprForNativeToken",
      2,
      2,
      true
    )
  );

  const aprFarmEsGMY = Number(formatKeyAmount(processedData, "gmyAprForNativeToken", 2, 2, true));

  const totalStakedWithOutEsGMY = Number(amountTVL * priceToken);
  const totalEsGMY = Number(formatAmount(activeTab === GMY_VAULT ? esGmyInStakedESGMY : esGmyInStakedGLP, 30, 2));

  const aprVault = aprFarm + aprFarmEsGMY * (totalEsGMY / totalStakedWithOutEsGMY);

  const apr = [GMY_WFTM_OLD_VAULT, GMY_WFTM_VAULT].includes(activeTab) ? statsGMYWFTM.apr : aprVault;

  const apy = !isNaN(formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2)
    : "--";

  const totalApr = [GMY_WFTM_OLD_VAULT, GMY_WFTM_VAULT].includes(activeTab)
    ? statsGMYWFTM.apr
    : formatKeyAmount(processedData, activeTab === GMY_VAULT ? "gmyAprTotal" : "glpAprTotal", 2, 2, true); // 3 vault

  const totalApy = !isNaN(formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2)
    : "--";

  const [daily, setDaily] = useState("0.0000");
  const [dailyFTM, setDailyFTM] = useState("0.0000");
  const [dailyEsGMY, setDailyEsGMY] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(caculateAPYDaily(Number(totalApr), 1), 4));
      setDailyFTM(formatNumber(caculateAPYDaily(Number(apr), 1), 4));
      setDailyEsGMY(formatNumber(caculateAPYDaily(Number(totalApr), 1) - caculateAPYDaily(Number(apr), 1), 4));
    }
  }, [totalApy, totalApr]);

  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div>TVL</div>
            {[GMY_WFTM_OLD_VAULT, GMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">${priceToken === 0 ? "--" : formatNumber(amountTVL * priceToken, 2)}</span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken ? (
                    formatNumber(
                      amountTVL * priceToken +
                        Number(formatAmount(activeTab === GMY_VAULT ? esGmyInStakedESGMY : esGmyInStakedGLP, 30)),
                      2
                    )
                  ) : (
                    <div className="skeleton-box" />
                  )
                }`}
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === GMY_VAULT ? "GMY" : "GLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked esGMY`}
                        value={formatNumber(
                          Number(formatAmount(activeTab === GMY_VAULT ? esGmyInStakedESGMY : esGmyInStakedGLP, 30, 2)),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            <div>APY</div>
            {[GMY_WFTM_OLD_VAULT, GMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">{activeTab === GMY_WFTM_OLD_VAULT ? 0 : totalApy}%</span>
            ) : totalApy !== "--" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {totalApy}%
                  </span>
                }
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Escrowed GMY APY`}
                        value={`${formatNumber(totalApy - apy, 2)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow label={`FTM APY`} value={`${formatNumber(apy, 2)}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="Daily">
            <div>Daily</div>
            {[GMY_WFTM_OLD_VAULT, GMY_WFTM_VAULT].includes(activeTab) ? (
              <span className="Daily-value">{activeTab === GMY_WFTM_OLD_VAULT ? 0 : daily}%</span>
            ) : daily !== "0.0000" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {daily}%
                  </span>
                }
                position="left-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow label={`Daily Escrowed GMY`} value={`${dailyEsGMY}%`} showDollar={false} />
                      <StatsTooltipRow label={`Daily FTM`} value={`${dailyFTM}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <Wrapper className={`Vault default-container page-layout ${lightThemeClassName}`}>
      <div
        style={{
          maxWidth: "100%",
        }}
        className="section-title-block"
      >
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div
            style={{
              marginBottom: 8,
            }}
            className="Page-title"
          >
            <div>
              Vault <img alt="" src={chainId === FANTOM ? ftmIcon : chainId === OP ? opIcon : arbIcon} />
            </div>
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.6)",
            }}
            className="description"
          >
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </div>
        </div>
      </div>
      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />

      {activeTab === GMY_WFTM_OLD_VAULT && (
        <div className="old_vault">
          We have deployed a new contract to V2. Please move ALL of your LP Tokens to the new GMY-WFTM vault.
        </div>
      )}

      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "100px", height: "25px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}
      <Footer />
    </Wrapper>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  /* .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  } */
`;

const Wrapper = styled.div`
  max-width: unset;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    /* padding-bottom: 0 !important; */
    min-height: unset !important;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .old_vault {
    margin-bottom: 24px;

    padding: 12px 24px;
    gap: 10px;

    background: #f6475d;
    border-radius: 12px;
  }
  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

    & > .Tab.block {
      .Tab-option {
        color: var(--Text-Text_Primary, #0d1a16);

        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .section-title-content {
      .description {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }

    .Tab.block {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      border-radius: 16px;

      /* .Tab-option {
        &:hover,
        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
          color: var(--Text-Text_Primary, #0d1a16);
        }
      } */
    }

    ${StyledVaultInfoSection} {
      background-color: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
    }
  }
`;
