import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { Table, TDCell, THead, TRow } from "../Account.styled";
import share from "img/ic-view-arrow.svg";
import shareLight from "img/ic-view-arrowLight.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useParams } from "react-router-dom";

import "../../styles/datePicker.scss";
import Button from "components/Common/Button";
import MobileTradeHistoryItem from "../MobileTradeHistoryItem";
import LongShortTag, { StyledLongShortTag } from "../LongShortTag";
import { useMedia } from "react-use";
import { formatAmount } from "lib/numbers";
import NoItemFound from "../NoItemFound";
import DateFilter from "components/DateFilter/DateFilter";
import { getApiAnalytics } from "pages/Analytics/config";
import { getTokenDecimal, getTokenSymbol } from "config/tokens";
import { getExplorerUrl } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import { DataTable, DateAndTime, PositionHead, SkeletonLoadingTable, TableRow } from "pages/Analytics/table";
import Pagination from "../Pagination";
import { SECONDS_PER_DAY } from "lib/legacy";

// const LoadmoreBtn = styled(Button)`
//   padding: 5px 10px;
//   font-weight: 500;
//   display: block;
//   margin: 25px auto 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   > * {
//     font-size: 14px;
//     margin: 0;
//   }
// `;

// const StyledExternalLink = styled(ExternalLink)`
//   display: inline-flex;
//   align-items: center;
//   justify-content: flex-end;
//   &:hover {
//     img {
//       filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
//     }
//   }
// `;

const Duration = styled.span`
  cursor: pointer;
  font-size: 13px;

  &:hover {
    color: #34f5ae !important;
  }
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  padding: 12px;
  background: #191b2e;
  border-radius: 16px;
  align-items: center;
  border: 1px solid transparent;

  .reload {
    &:hover {
      svg {
        fill: #34f5ae;
      }
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    .time {
      margin-top: 12px;
      font-size: 13px;
      flex-wrap: wrap;
      gap: 10px;
      > *:not(.react-datepicker__tab-loop) {
        margin-right: 0px !important;
      }
      .react-datepicker-wrapper {
        &:nth-child(4) {
          margin-right: 25px !important;
        }
      }
      input {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }

  .duration {
    display: flex;
    gap: 20px;

    > *:hover {
      color: rgb(4, 211, 151);
    }
  }

  .time {
    display: flex;
    align-items: center;
    /* gap: 10px; */

    > *:not(.react-datepicker__tab-loop) {
      margin-right: 10px;
    }

    span {
      cursor: pointer;
    }

    img {
      width: 16px;
      height: 16px;
    }

    .title {
      margin-right: 8px;
    }

    .reload {
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(110%) contrast(110%);
      }
      &:hover {
        img {
          filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
        }
      }
    }
  }

  &.theme--light {
    ${Duration} {
      &:hover {
        color: #02b27f !important;
      }
    }

    .reload {
      &:hover {
        svg {
          fill: #02b27f;
        }
      }
    }
  }
`;

// const StyledTable = styled(Table)`
//   width: 100%;
//   /* @media screen {
//     display: none;
//   } */
// `;

// const StyledMobileHistoryContainer = styled.div`
//   display: none;

//   /* background: #ffffff10; */
//   padding: 0;

//   @media (max-width: 1023px) {
//     display: block;
//   }

//   .table-mobile {
//     padding: 0;
//     margin-bottom: 0 !important;
//     border: none !important;
//     border-radius: 0 !important;
//     background: #212333 !important;
//     border-radius: 16px !important;
//   }
// `;

// const DURATIONS = ["1 Day", "1 Week", "1 Month", "3 Months"];
const FILTER_BY_OPTIONS = [
  {
    label: "1 Day",
    value: moment().unix() - SECONDS_PER_DAY,
  },
  {
    label: "1 Week",
    value: moment().unix() - SECONDS_PER_DAY * 7,
  },
  {
    label: "1 Month",
    value: moment().unix() - SECONDS_PER_DAY * 30,
  },
  {
    label: "3 Months",
    value: moment().unix() - SECONDS_PER_DAY * 30 * 3,
  },
];

const TradeHistoryAcc = ({ chainId }) => {
  const { lightThemeClassName } = useThemeContext();
  const [updateCount, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { account } = useParams();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { isLightTheme } = useThemeContext();

  useEffect(() => {
    async function getTopPositions() {
      try {
        setIsLoading(true);
        const { data: response } = await axios(
          `${getApiAnalytics(chainId)}api/trade-histories?address=${account.toLowerCase()}`,
          {
            params: {
              page: currentPage,
              pageSize: perPage,
              from,
              to: to && Math.floor(to + SECONDS_PER_DAY),
            },
          }
        );

        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    getTopPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, currentPage, perPage, updateCount]);

  const handleRefresh = () => {
    setFrom();
    setTo();
    forceUpdate();
  };

  const handleSearch = () => {
    if (to && from && from < to) {
      forceUpdate();
    }
    setCurrentPage(1);
  };

  const handleFilterByDuration = (milisecons) => {
    setFrom(milisecons);
    setTo(Date.now() / 1000);
    setCurrentPage(1);
    forceUpdate();
  };

  return (
    <>
      <FilterSection className={`filter-duration-wrapper ${lightThemeClassName}`}>
        <div className="duration">
          {FILTER_BY_OPTIONS.map(({ label, value }) => (
            <Duration
              key={value}
              style={{
                color: value === from ? (isLightTheme ? "#02B27F" : "#C1FF99") : isLightTheme ? "#000" : "#fff",
              }}
              onClick={() => handleFilterByDuration(value)}
            >
              {label}
            </Duration>
          ))}
        </div>
        <DateFilter
          startDate={from * 1000}
          endDate={to * 1000}
          handleStartDate={(value) => setFrom(value / 1000)}
          handleEndDate={(value) => setTo(value / 1000)}
          handleSearch={handleSearch}
          handleRefresh={handleRefresh}
          isLightTheme={isLightTheme}
          localTime
        />
      </FilterSection>
      <DataTable $noIndex $autoHeight $rowHeightSkeleton="63.19px" className={lightThemeClassName}>
        <div className="head">
          <div className="col-name">Time</div>
          <div className="col-name">Market</div>
          <div
            className="col-name"
            style={{
              minWidth: 600,
            }}
          >
            Action Value
          </div>
        </div>
        <div className="body">
          {!isLoading &&
            data?.rows?.map((position, i) => {
              const indexToken = getTokenSymbol(chainId, position.indexToken);
              const isLongOrShort = position.isLong ? "LONG" : "SHORT";
              const CollateralDelta = formatAmount(position.collateralDelta, 30, 3, true);
              return (
                <TableRow key={position.id || i} href={`${getExplorerUrl(chainId)}tx/${position.txhash}`}>
                  <div d-label="Time" className="col-body">
                    <div className="col-body-content">
                      <DateAndTime unix={position.timestamp} />
                    </div>
                  </div>
                  <div d-label="Market" className="col-body">
                    {position.action === "Swap" ? (
                      <>
                        <StyledLongShortTag
                          style={{
                            background: isLightTheme ? "#0D1A1612" : undefined,
                          }}
                          className="longOrShort"
                        >
                          <span
                            className="longOrShort"
                            style={{
                              color: isLightTheme ? "#0D1A16B3" : "#fff",
                            }}
                          >
                            SWAP
                          </span>
                        </StyledLongShortTag>
                        <span className="longOrShort" style={{ marginLeft: "8px" }}>
                          {getTokenSymbol(chainId, position.tokenIn)}/{getTokenSymbol(chainId, position.tokenOut)}
                        </span>
                      </>
                    ) : (
                      <>
                        <LongShortTag
                          isLong={position.isLong}
                          style={{
                            marginRight: 8,
                            fontWeight: "500",
                            background: isLightTheme ? (position.isLong ? "#02B27F1A" : "#D62F441A") : "#ffffff10",
                            color: position.isLong
                              ? isLightTheme
                                ? "#02B27F"
                                : "#C1FF99"
                              : isLightTheme
                              ? "#D62F44"
                              : "#f6475d",
                          }}
                          isLightTheme={isLightTheme}
                        />
                        <span className="longOrShort" style={{ color: isLightTheme ? "#0D1A16" : undefined }}>
                          {indexToken}/USD
                        </span>
                      </>
                    )}
                    {/* <PositionHead position={position} defaultTokenSymbol={getTokenSymbol(chainId, position.indexToken)} /> */}
                  </div>
                  <div
                    d-label="Action Value"
                    className="col-body"
                    style={{
                      minWidth: 600,
                    }}
                  >
                    {position.action === "DecreasePosition" || position.action === "IncreasePosition"
                      ? position.sizeDelta == 0
                        ? position.action == "IncreasePosition"
                          ? `Deposited $${CollateralDelta} to ${indexToken} ${isLongOrShort}`
                          : position.collateralValue !== 0
                          ? `Withdrew  $${CollateralDelta} from ${indexToken} ${isLongOrShort}`
                          : ""
                        : `${indexToken} ${isLongOrShort} ${String(position.action)
                            .replace("Position", "d")
                            .toLowerCase()} by $${formatAmount(
                            position.sizeDelta,
                            30,
                            3,
                            true
                          )} (price = $${formatAmount(position.price, 30, 3, true)})`
                      : position.action === "Swap"
                      ? `Swapped ${formatAmount(
                          position.amountIn,
                          getTokenDecimal(chainId, position.tokenIn),
                          3,
                          true
                        )} ${getTokenSymbol(chainId, position.tokenIn)} for ${formatAmount(
                          position.amountOutAfterFees,
                          getTokenDecimal(chainId, position.tokenOut),
                          3,
                          true
                        )} ${getTokenSymbol(chainId, position.tokenOut)}`
                      : `${getTokenSymbol(chainId, position.indexToken)} ${isLongOrShort} of ${formatAmount(
                          position.size,
                          30,
                          3,
                          true
                        )} has been liquidated (price = $${formatAmount(position.markPrice, 30, 3, true)})`}
                  </div>
                  <img className="icon-view" src={isLightTheme ? shareLight : share} alt="view_ic" />
                </TableRow>
              );
            })}
        </div>
        {isLoading && <SkeletonLoadingTable noHeading />}
      </DataTable>
      {data?.rows?.length > 0 && !isLoading && (
        <Pagination
          rowsPerPage={perPage}
          setRowsPerPage={setPerPage}
          row={(from || to) && from < to ? data?.rows?.length : data?.count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {data?.rows?.length === 0 && !isLoading && <NoItemFound />}
      {/* {!isMobile && (
        <>
          {!isLoading ? (
            filteredData.length > 0 ? (
              <StyledTable className="account-trade-history border-theme">
                <TRow>
                  <THead style={{ fontWeight: 500 }}>Time</THead>
                  <THead style={{ fontWeight: 500 }}>Market</THead>
                  <THead style={{ fontWeight: 500 }}>Action Value</THead>
                  <THead></THead>
                </TRow>
                {renderData(filteredData)}
              </StyledTable>
            ) : (
              <NoItemFound />
            )
          ) : (
            // <Loader />
            <div className="skeleton-box" style={{ height: "30px", marginTop: "20px" }} />
          )}
        </>
      )} */}
      {/* {isMobile && (
        <StyledMobileHistoryContainer>
          {filteredData.length > 0 ? (
            filteredData.map((item) => <MobileTradeHistoryItem data={item} />)
          ) : (
            <NoItemFound />
          )}
        </StyledMobileHistoryContainer>
      )} */}
      {/* {showNumber < filteredData.length && (
        <LoadmoreBtn onClick={() => setShowNumber((prev) => prev + 10)}>Load more</LoadmoreBtn>
      )} */}
    </>
  );
};

export default TradeHistoryAcc;
